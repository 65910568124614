import { Button, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import {
  getApplicationStatus,
  updateLicenseAndApplicationStatus,
} from '../../../../../utils/application-status.utils';

import { AgencyLicenseType } from '../../../../../enums/agencyLicenseType.enum';
import { AllLicenseResponse } from '../../../../../types/response/all-license.type';
import { AllLicensesAgencyType } from '../../../../../types/agency/allLicensesAgency.type';
import { ApplicationStatusEnum } from '../../../../../enums/applicationStatus.enum';
import { ColumnsType } from 'antd/es/table';
import CopyTextAnimation from '../../../../../components/common/copyTextAnimation/CopyTextAnimation';
import DaysToExpireCell from '../../../../../components/licenses/daysToExpireCell';
import { ErrorResponse } from '../../../../../types/response/errorResponse.type';
import { ExceptionName } from '../../../../../enums/exceptionName.enum';
import { GatewayTransactionApplyError } from '../../../../../types/nipr/gatewayTransaction.types';
import LicenseModal from '../../../../../utils/modal/application-status/applictionStatus.modal';
import { LicenseStatus } from '../../../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../../../services/licenses.service';
import { LoaDetail } from '../../../../../types/data/loaDetail.type';
import { LoaStatus } from '../../../../../enums/loaStatus.enum';
import NiprFeesCell from '../../../../../components/applications/NiprFeesCell';
import { PortalType } from '../../../../../enums/portalType.enum';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { STATUS_CHIP } from '../../../../../constants/style.constants';
import StateCell from '../../../../../components/licenses/stateCell';
import { StateConstants } from '../../../../../constants/state.constants';
import WarningBanner from './warningBanner';
import { WarningBannerType } from '../../../../../enums/warningBannerType.enum';
import activeLoaIcon from '../../../../../assets/icons/success rectangle.svg';
import { appStateInfoStore } from '../../../../../stores/appStateInfo.store';
import { applySort } from '../../../../../utils/common.utils';
import error from '../../../../../assets/icons/error.svg';
import { getAssignedDrlp } from '../../../../../components/agencyLicensing/reviewSection';
import missingLoaIcon from '../../../../../assets/icons/reject rectangle.svg';
import { statesWithAffiliation } from '../../../../../components/agencyLicensing/statesWithAffiliationsConst';
import success from '../../../../../assets/icons/success.svg';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../../../auth/authProvider';
import { useState } from 'react';

const AllLicensesAgencyList: React.FC<AllLicensesAgencyType> = ({
  allLicenses,
  refetch,
  setErrors,
  setApplyLicenseErrorModalVisibility,
  setSortValue,
  setLicenseCount,
  sortValue,
  selectedFilters,
  selectedLicenseType,
}) => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const { bearerToken } = useAuth();
  const [isApplying, setIsApplying] = useState('');
  const [selectedLicenseDetails, setSelectedLicenseDetails] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const applyLicenses = async (licenseId: string, isRenewal?: boolean) => {
    if (!bearerToken) return;
    try {
      if (!allLicenses?.isOwnerDetailsFilled) {
        message.error(
          'Please fill the ownership details to proceed forward with the agency licenses application'
        );
        return;
      }
      setIsApplying(licenseId);
      const resp = await LicensesService.applyLicenseForAgency(
        bearerToken,
        licenseId
      );
      if (resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse) {
        const error =
          resp?.data?.gatewayTransaction?.niprGatewaySuccessResponse?.license;
        const lcRespMessages = Array.isArray(error?.response)
          ? error?.response
              ?.filter((itr: any) => !!itr && itr?.approved !== 'Y')
              ?.flatMap((itr: any) => itr?.messages)
              ?.map((d: any) => d?.comments) || []
          : error?.response?.approved !== 'Y'
            ? error?.response?.map((msg: any) => msg?.comments) || []
            : [];
        const loaRespMessages = Array.isArray(error?.loaCodes)
          ? error?.loaCodes
              ?.flatMap((d: any) => d?.response)
              ?.filter((itr: any) => !!itr)
              ?.flatMap((itr: any) =>
                itr?.approved !== 'Y' ? itr?.messages : []
              )
              ?.map((d: any) => d?.comments) || []
          : error?.loaCodes?.approved !== 'Y'
            ? error?.loaCodes?.response
                ?.filter((itr: any) => !!itr)
                ?.flatMap((itr: any) =>
                  itr?.approved !== 'Y' ? itr?.messages : []
                )
                ?.map((d: any) => d?.comments) || []
            : [];
        const intermediateMessages = Array.isArray(error?.intResponse)
          ? error?.intResponse
              ?.filter((itr: any) => !!itr)
              ?.flatMap((d: any) => d?.messages)
              ?.map((itr: any) => {
                return {
                  actionable: itr.actionRequired,
                  message: itr.comments,
                };
              })
          : error?.intResponse?.messages
            ? error?.intResponse?.messages?.map((itr: any) => {
                return {
                  actionable: itr.actionRequired,
                  message: itr.comments,
                };
              })
            : [];
        const lcErrorDetails = { errorMessages: lcRespMessages };
        const loaErrorDetails = { errorMessages: loaRespMessages };
        const mainErrorDetails = { errorMessages: [] };
        const intermediateErrorDetails = {
          errorMessages: intermediateMessages,
        };
        if (setErrors) {
          setErrors([
            {
              LcErrorDetails: lcErrorDetails,
              LoaErrorDetails: loaErrorDetails,
              mainErrorDetails: mainErrorDetails,
              intermediateErrorDetails: intermediateErrorDetails,
            },
          ]);
        }

        if (setApplyLicenseErrorModalVisibility) {
          setApplyLicenseErrorModalVisibility(true);
        }
      } else if (resp?.data?.gatewayTransaction?.niprGatewayErrorResponse) {
        const errorResponse =
          resp?.data?.gatewayTransaction?.niprGatewayErrorResponse;
        const errors =
          (Array.isArray(errorResponse)
            ? errorResponse
            : errorResponse?.response?.errors) || [];
        if (setErrors) {
          setErrors([
            {
              LcErrorDetails: {
                errorMessages: [],
              },
              LoaErrorDetails: {
                errorMessages: [],
              },
              mainErrorDetails: {
                errorMessages: errors?.map((d: any) => d.description) || [],
              },
              intermediateErrorDetails: {
                errorMessages: [],
              },
            },
          ]);
        }
        if (setApplyLicenseErrorModalVisibility) {
          setApplyLicenseErrorModalVisibility(true);
        }
      }
      // todo :: error modal integration
      if (resp?.data?.status === 'pending') {
        refetch();
        if (isRenewal)
          setLicenseCount((prev) => ({
            ...prev,
            renewalsCount: prev.renewalsCount - 1,
          }));
        else
          setLicenseCount((prev) => ({
            ...prev,
            needsAttentionCount: prev.needsAttentionCount - 1,
          }));
        message.success('License applied successfully');
      }
    } catch (error: any) {
      console.error(error);
      const e = error?.response?.data as ErrorResponse;
      if (
        e?.error?.exceptionName ===
        ExceptionName.NIPR_GATEWAY_TRANSACTION_EXCEPTION
      ) {
        const error = e.error.data as GatewayTransactionApplyError;
        const lcRespMessages = error.lcRespMessages
          .filter((itr) => !!itr)
          .map((itr) => itr.comment);
        const loaRespMessages = error.loaRespMessages
          .filter((itr) => !!itr)
          .flatMap((itr) =>
            itr.approved !== 'Y'
              ? itr.respMessages.map((msg) => msg.comment)
              : []
          );
        const mainErrorMessages = error.mainErrorMessages
          .filter((itr) => !!itr)
          .flatMap((itr) => itr.description);
        const intermediateMessages = error.intermediateMessages
          .filter((itr) => !!itr)
          .flatMap((itr) => {
            return { actionable: itr.actionRequired, message: itr.comment };
          });
        const lcErrorDetails = { errorMessages: lcRespMessages };
        const loaErrorDetails = { errorMessages: loaRespMessages };
        const mainErrorDetails = { errorMessages: mainErrorMessages };
        const intermediateErrorDetails = {
          errorMessages: intermediateMessages,
        };
        if (setErrors) {
          setErrors([
            {
              LcErrorDetails: lcErrorDetails,
              LoaErrorDetails: loaErrorDetails,
              mainErrorDetails: mainErrorDetails,
              intermediateErrorDetails: intermediateErrorDetails,
            },
          ]);
        }
        if (setApplyLicenseErrorModalVisibility) {
          setApplyLicenseErrorModalVisibility(true);
        }
      } else
        message.error(
          error?.response?.data?.message ||
            'Failed to apply license.please try again'
        );
    } finally {
      setIsApplying('');
    }
  };

  const showApplyButton = (record?: any) => {
    return record.status !== LicenseStatus.Active || record?.isRenewal;
  };

  const columns: ColumnsType<AllLicenseResponse> = [
    {
      title: <div>State</div>,
      dataIndex: 'stateCode',
      sorter: subscriptionStatusInactive ? false : true,
      sortOrder:
        sortValue?.sortField === 'stateCode'
          ? sortValue?.sortOrder === 1
            ? 'ascend'
            : 'descend'
          : null,
      key: 'stateCode',
      width: 140,
      className: 'agency-license-state-row custom-header-cell',
      render: (text: string, record: AllLicenseResponse) => {
        const applicationStatus = getApplicationStatus(record, record?.status);
        const { updatedStatus, updatedApplicationStatus } =
          updateLicenseAndApplicationStatus(
            record?.status as LicenseStatus,
            applicationStatus,
            record
          );

        return (
          <StateCell
            state={StateConstants[text]}
            licenseStatus={updatedStatus}
            agencyLicenses={true}
            residencyType={record.residencyType}
            hidePerpetualTag
            applicationStatus={updatedApplicationStatus}
            isMissingLOA={record?.status === LicenseStatus.MISSING_LOA}
            overrideStyles={STATUS_CHIP}
          />
        );
      },
    },
    {
      title: <div>DRLP Name</div>,
      dataIndex: 'drlps',
      width: 100,
      className: 'custom-header-cell',
      render: (drlps: any, record: any) => {
        const displayedDrlps = drlps?.slice(0, 2);
        const remainingDrlpsCount = drlps?.length - 2;

        return drlps?.length ? (
          <div>
            {displayedDrlps.map((data: any, index: number) => (
              <div key={index} className="flex flex-wrap items-center">
                <Typography.Paragraph className="mb-0 inline-block text-xs leading-5 text-secondary font-normal break-words">
                  {getAssignedDrlp({ ...data })}
                  {index < displayedDrlps.length - 1 ? ',' : ''}
                  {remainingDrlpsCount > 0 && index === 1 && (
                    <Tooltip
                      title={drlps.slice(2).map((data: any, index: number) => (
                        <div key={index}>{getAssignedDrlp({ ...data })}</div>
                      ))}
                    >
                      <span className="cursor-pointer">
                        {`, +${remainingDrlpsCount}`}
                      </span>
                    </Tooltip>
                  )}
                </Typography.Paragraph>
              </div>
            ))}
          </div>
        ) : (
          <Typography.Paragraph className="mb-0 text-cement [text-align-last:start] ">
            No DRLPs reported in NIPR
          </Typography.Paragraph>
        );
      },
    },
    {
      title: <div>License No</div>,
      dataIndex: 'licenseNumber',
      sorter: true,
      sortOrder:
        sortValue?.sortField === 'licenseNumber'
          ? sortValue?.sortOrder === 1
            ? 'ascend'
            : 'descend'
          : null,
      key: 'licenseNumber',
      width: 130,
      className: 'agency-license-state-row custom-header-cell',
      render: (text: string, record: AllLicenseResponse) => {
        return (
          <div>
            {text && !record?.isDuplicateLicenseNumber ? (
              <span className="flex gap-1 items-center">
                {text}{' '}
                <CopyTextAnimation
                  text={text}
                  toastMessage="License Number copied to clipboard"
                />
              </span>
            ) : (
              <span className="ml-2">-</span>
            )}
          </div>
        );
      },
    },
    {
      title: <div>License Class & LOA&apos;s</div>,
      dataIndex: 'licenseConfigurations',
      key: 'licenseConfigurations.licenseClass',
      width: '300px',
      sorter: subscriptionStatusInactive ? false : true,
      sortOrder:
        sortValue?.sortField === 'licenseConfigurations.licenseClass'
          ? sortValue?.sortOrder === 1
            ? 'ascend'
            : 'descend'
          : null,
      className: 'cell-with-lines agency-license-state-row center-column',
      render: (loas: any, record: any) => {
        return (
          <div className="flex justify-center min-h-[60px] h-fit">
            <Row className="w-[40%] content-center">
              <div className="w-full box-border flex-1 gap-2">
                <Tooltip
                  title={`${appStateInfoStore.getLcNameFromCode(
                    record.licenseConfigurations.licenseClass
                  )} (${record.licenseConfigurations.licenseClassCode})`}
                >
                  <Typography.Paragraph
                    className="margin-unset whitespace-nowrap mb-0 [text-align-last:start] text-[#001F45] shrink-0 font-medium pr-5 basis-[25%] text-xs max-w-[90%] cursor-pointer text-ellipsis overflow-hidden leading-normal"
                    ellipsis
                  >
                    {`${appStateInfoStore.getLcNameFromCode(
                      record.licenseConfigurations.licenseClass
                    )} (${record.licenseConfigurations.licenseClassCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            </Row>

            <div className="absolute top-0 left-[40%] bottom-0 w-[1px] bg-[#d9d9d9] h-full" />

            <div className="w-[60%] flex flex-col pl-5 self-center">
              {record.licenseConfigurations.loaDetails.map(
                (loa: LoaDetail, index: number) => {
                  return (
                    <Space size={[4, 16]} key={index}>
                      {loa.status?.toLocaleLowerCase() ===
                      LoaStatus.ACTIVE.toLocaleLowerCase() ? (
                        <img src={activeLoaIcon} alt="activeLoa" />
                      ) : (
                        <img src={missingLoaIcon} alt="nonActiveLoa" />
                      )}
                      <Typography.Paragraph className="m-0 text-[#222222] leading-normal [text-align-last:start]">
                        {appStateInfoStore.getLoaNameFromCode(loa.loa)}
                      </Typography.Paragraph>
                    </Space>
                  );
                }
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: <div className="custom-header-cell text-center">BG Questions</div>,
      dataIndex: 'bgQuestionsEngineLight',
      key: 'bgQuestionsEngineLight',
      // sorter: true,
      width: 85,
      render: (bgQuestionsEngineLight: boolean, record: any) => {
        return bgQuestionsEngineLight ? (
          <div className="grid place-content-center">
            <img alt="logo" src={success} />
          </div>
        ) : (
          <div className="grid place-content-center">
            <img alt="logo" src={error} />
          </div>
        );
      },
    },
    {
      title: (
        <div className="custom-header-cell text-center">Expiration Date</div>
      ),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      sorter: true,
      sortOrder:
        sortValue?.sortField === 'expiryDate'
          ? sortValue?.sortOrder === 1
            ? 'ascend'
            : 'descend'
          : null,
      width: 170,
      render: (text: string, record: any) => {
        return (
          <DaysToExpireCell
            expiryDate={text}
            licenseStatus={record?.status as LicenseStatus}
            licenseState={record.stateCode}
          />
        );
      },
    },
    ...(selectedLicenseType === AgencyLicenseType.ACTIVE
      ? []
      : [
          {
            title: 'Fee',
            dataIndex: 'licenseFee',
            key: 'licenseFee',
            sorter: true,
            sortOrder: (sortValue?.sortField === 'licenseFee'
              ? sortValue?.sortOrder === 1
                ? 'ascend'
                : 'descend'
              : null) as any,
            width: '100px',
            render: (_: any, record: any) => {
              return !showApplyButton(record) ? (
                <div className="ml-4">-</div>
              ) : (
                <NiprFeesCell
                  isLoading={record?.isLicenseFeeUpdating}
                  fees={record}
                />
              );
            },
          },
          {
            title: '',
            dataIndex: 'apply',
            key: 'apply',
            width: '150px',
            render: (text: string, record: any) => {
              const isDisabled =
                !!isApplying ||
                record?.latestApplication?.[0]?.status ===
                  ApplicationStatusEnum.PENDING ||
                statesWithAffiliation.includes(record?.stateName) ||
                !record.drlps?.length ||
                !record.bgQuestionsEngineLight ||
                !allLicenses?.isOwnerDetailsFilled;
              return (
                <div className="text-xs font-figtree mr-5">
                  {showApplyButton(record) ? (
                    <div className="flex items-center min-w-fit gap-6">
                      <Tooltip
                        title={
                          !record?.drlps?.length
                            ? 'Please assign drlp for this state'
                            : !record.bgQuestionsEngineLight
                              ? 'Please fill background questions before applying for this license.'
                              : !allLicenses?.isOwnerDetailsFilled
                                ? 'Please fill the ownership details to proceed forward with the agency licenses application'
                                : record?.latestApplication?.[0]?.status ===
                                    ApplicationStatusEnum.PENDING
                                  ? 'A pending application already exists for this license'
                                  : ''
                        }
                      >
                        <Button
                          type="primary"
                          id="agency-all-licenses-apply-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (applyLicenses)
                              applyLicenses(record.id, record.isRenewal);
                          }}
                          className={twMerge(
                            'text-[12px] min-w-[120px] secondary-button h-[22px] text-white font-medium min-h-[22px] flex items-center justify-center px-3',
                            'font-[Figtree] bg-[#001836]', // Custom font and background
                            !isDisabled ? 'opacity-100' : 'opacity-40'
                          )}
                          disabled={isDisabled || subscriptionStatusInactive}
                          loading={isApplying === record.id}
                        >
                          {record?.isRenewal
                            ? 'Renew'
                            : record?.latestApplication?.[0]?.status ===
                                'declined'
                              ? 'Re-Apply'
                              : 'Apply'}
                        </Button>
                        {record?.latestApplication?.[0]?.status ===
                          ApplicationStatusEnum.Declined ||
                        record?.latestApplication?.[0]?.status ===
                          ApplicationStatusEnum.PENDING ? (
                          <div
                            className="font-12 text-[#EB3131] font-normal font-figtree cursor-pointer flex items-center justify-center gap-1 mt-[2px]"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsModalVisible(true);
                              setSelectedLicenseDetails({
                                ...(record?.latestApplication?.[0] || {}),
                                licenseConfigurations:
                                  record?.licenseConfigurations,
                                license: {
                                  stateName: StateConstants[record?.stateCode],
                                  status: record?.status,
                                },
                              });
                            }}
                          >
                            <QuestionCircleOutlined />{' '}
                            <span className="inline-block  underline">
                              Learn More
                            </span>
                          </div>
                        ) : null}
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <div className="table-layout-v2">
      {allLicenses && !allLicenses?.isOwnerDetailsFilled ? (
        <WarningBanner type={WarningBannerType.OWNERSHIP_DETAILS} />
      ) : null}
      <Table
        columns={columns as any}
        dataSource={allLicenses?.agencyLicenses}
        pagination={false}
        loading={!!isApplying}
        onChange={(pagination, filters, sorter) => {
          const sort = applySort(sorter, (a, b, c, sort) => refetch(sort));
          setSortValue(sort);
        }}
        scroll={{
          y: `calc(100vh - ${
            Object.values(selectedFilters?.data)?.length ? '279px' : '245px'
          })`,
        }}
        className="table-sort-center agency-license-table"
      />
      <LicenseModal
        visible={!!isModalVisible}
        setVisible={() => {
          setIsModalVisible(false);
        }}
        agentLicenseDetails={{
          ...selectedLicenseDetails,
        }}
        portal={PortalType.AGENT}
        showTransactionDetails={false}
        autoRenewEnabled={false}
      />
    </div>
  );
};

export default AllLicensesAgencyList;
