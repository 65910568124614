import '../details/generalPageStyle.css';

import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Skeleton,
  message,
  notification,
} from 'antd';
import {
  CheckCircleTwoTone,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { getInitials, getNameString } from '../../../utils/name.utils';
import { useEffect, useState } from 'react';

import { AdminService } from '../../../services/admin.service';
import AgencyLogo from '../../../components/common/agencyLogo/agencyLogo';
import { AgentService } from '../../../services/agent.service';
import { Auth0Service } from '../../../services/auth0.service';
import CustomButton from '../../../components/common/button/CustomButton';
import { IdConstants } from '../../../constants/id.constants';
import { Name } from '../../../types/data/name.type';
import { RoleType } from '../../../enums/roles.enum';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { auth0Store } from '../../../stores/auth0Store';
import { isEmpty } from 'lodash';
import { signupStore } from '../../../stores/signupStore';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../auth/authProvider';
import { useForm } from 'antd/es/form/Form';
import { userProfileProps } from './MyAccount';
import { withRoles } from '../../../auth/useRoles';

const UserProfile: React.FC<userProfileProps> = ({ hideDetails }) => {
  const { getAccessTokenSilently } = useAuth();

  const [profileForm] = useForm();
  const profileFirstName = adminStore.account?.name?.firstName;
  const profileLastName = adminStore.account?.name?.lastName;
  const isEmailVerified = auth0Store.user?.email_verified;
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const [resetPasswordEmailSentModal, setResetPasswordEmailSentModal] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  // const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [resendVerificationEmailLoader, setResendVerificationEmailLoader] =
    useState<boolean>(false);
  const [
    resendEmailVerificationSuccessModal,
    setResendEmailVerificationSuccessModal,
  ] = useState(false);
  const [profileModal, setProfileModal] = useState<boolean>(false);

  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();

  const loadUser = async () => {
    const bearerToken = await getAccessTokenSilently();
    let inUserInvite = false;
    if (window.location.pathname.includes('user-invite')) inUserInvite = true;
    if (bearerToken) {
      await auth0Store.loadUser(bearerToken, inUserInvite);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const updateAdminInformation = async () => {
    try {
      const firstName = profileForm.getFieldValue('firstName');
      const lastName = profileForm.getFieldValue('lastName');
      if (isEmpty(firstName) || isEmpty(lastName)) {
        notification.error({
          message: 'Error',
          description: `Please Enter ${!firstName ? 'First' : 'Last'} Name`,
        });
        return;
      }

      setIsProfileUpdating(true);
      if (adminStore) {
        const bearerToken = await getAccessTokenSilently();
        await AdminService.updateAccountName(
          firstName,
          lastName,
          bearerToken
        ).catch((error: any) => {
          console.error(error);
        });
        adminStore.updateName(firstName, '', lastName);
        setProfileModal(false);
        message.success('Account details updated successfully.');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsProfileUpdating(false);
    }
  };

  const sendResetPasswordEmail = async () => {
    try {
      const token = await getAccessTokenSilently();
      setResetPasswordLoader(true);
      const response: any = await Auth0Service.sendResetPasswordMail(token);
      if (response.status === 'SUCCESS') setResetPasswordEmailSentModal(true);
      else message.error('Something went wrong , Please try again later');
      setResetPasswordLoader(false);
    } catch (error) {
      setResetPasswordLoader(false);
      console.error(error);
    }
  };

  const handleResendVerificationEmail = async () => {
    if (signupStore.isAdminAuthorized) {
      try {
        const token = await getAccessTokenSilently();
        setResendVerificationEmailLoader(true);
        const response: any = await AgentService.resendVerificationEmail(token);
        if (response.status === 'SUCCESS')
          setResendEmailVerificationSuccessModal(true);
        else message.error('Something went wrong , Please try again later');
        setResendVerificationEmailLoader(false);
      } catch (error) {
        setResendVerificationEmailLoader(false);
        console.error(error);
      }
    } else if (signupStore.isAgentAuthorized) {
      try {
        const token = await getAccessTokenSilently();
        setResendVerificationEmailLoader(true);
        const response: any = await AgentService.resendVerificationEmail(token);
        if (response.status === 'SUCCESS')
          setResendEmailVerificationSuccessModal(true);
        else message.error('Something went wrong , Please try again later');
        setResendVerificationEmailLoader(false);
      } catch (error) {
        setResendVerificationEmailLoader(false);
        console.error(error);
      }
    }
  };

  // const handleImageUpload = () => {};

  return (
    <div className="w-full">
      {loading ? (
        <div className="spin-style flex justify-center items-center">
          <Skeleton active />
        </div>
      ) : (
        <div>
          <Card className="p-0 grid gap-y-[240px] mt-[10px]">
            <div className="h-[110px] bg-[#EDF9FF] mb-[14px] rounded-md py-4 px-5 flex items-center gap-4">
              <AgencyLogo
                agencyNameInitial={getInitials(
                  getNameString(adminStore.account?.name as Name)
                )}
                imageUrl={null} // todo
              />
              {/* <img
                src={editLogo}
                alt="editLogo"
                className="absolute top-[82px] left-[80px] h-10 w-10 rounded-[50%] cursor-pointer"
                onClick={() => {
                  if (!subscriptionStatusInactive) setProfileModal(true);
                }}
              /> */}
              <div className="flex flex-col gap-1">
                <div
                  onClick={() => {
                    if (!subscriptionStatusInactive && !hideDetails) {
                      setProfileModal(true);
                      profileForm.setFieldsValue({
                        firstName: profileFirstName,
                        lastName: profileLastName,
                      });
                    }
                  }}
                  className={twMerge(
                    'text-black-blue font-medium  px-2 rounded-sm text-lg overide-icon-size font-12 leading-6 font-poppins',
                    hideDetails
                      ? ''
                      : 'cursor-pointer hover:border hover:bg-[#DAF2FF] hover:border-[#B5E6FF]'
                  )}
                >
                  <span className="text-lg">
                    {profileFirstName} {profileLastName}
                  </span>{' '}
                  {hideDetails ? null : <EditOutlined className="ml-1" />}
                </div>
                <div className="text-[#1D2C50] capitalize font-normal text-xs px-2 leading-5 font-poppins">
                  Role: {adminStore?.role}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 pt-4">
              <div className="flex items-center justify-between pb-3 border-b border-[#E6E6E6]">
                <div className="flex gap-3">
                  <div className="min-w-[200px]">Email address</div>
                  <div className="flex gap-3">
                    {adminStore?.account?.email}{' '}
                    <div
                      className={twMerge(
                        'font-figtree text-xs leading-[14.4px] flex gap-2 reset-color items-center py-1 px-2 rounded-md overide-icon-size font-12',
                        isEmailVerified
                          ? 'text-[#0B703F] bg-[#E9FFF4]'
                          : 'text-[#EB3131] bg-[#FFE9E9]'
                      )}
                    >
                      <InfoCircleOutlined
                        color={isEmailVerified ? '#0B703F' : '#EB3131'}
                      />{' '}
                      Email Address {isEmailVerified ? '' : 'Not'} Verified{' '}
                    </div>
                  </div>
                </div>
                <CustomButton
                  id={
                    IdConstants.AGENCY.GENERAL.VERIFY_EMAIL + '-agency-general'
                  }
                  loading={resendVerificationEmailLoader}
                  onClick={handleResendVerificationEmail}
                  disabled={subscriptionStatusInactive}
                  type="internalNavigation"
                  classNames="h-6 text-xs"
                  buttonName="Verify Email"
                  buttonStyle="danger"
                />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex gap-3">
                  <div className="min-w-[200px]">Password</div>
                  <div>**********</div>
                </div>
                <CustomButton
                  id={
                    IdConstants.AGENCY.GENERAL.CHANGE_PASSWORD +
                    '-agency-general'
                  }
                  loading={resetPasswordLoader}
                  buttonStyle="secondary"
                  type="internalNavigation"
                  onClick={() => {
                    sendResetPasswordEmail();
                  }}
                  classNames="h-6 text-xs"
                  buttonName="Reset Password"
                  disabled={subscriptionStatusInactive}
                />
              </div>
            </div>
          </Card>

          {/* <ChangePasswordModal
            visible={updatePasswordModal}
            setVisible={setUpdatePasswordModal}
          /> */}

          {resendEmailVerificationSuccessModal && (
            <Modal
              centered
              closable={false}
              open={resendEmailVerificationSuccessModal}
              footer={null}
            >
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: '10px' }}>
                  Verification email successfully sent!
                </h2>
                <CheckCircleTwoTone
                  style={{ fontSize: '50px' }}
                  color="#00FF00"
                />
                <p>
                  Please check your email inbox for a verification email. Click
                  on the verification link to confirm your email
                </p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  onClick={() => {
                    setResendEmailVerificationSuccessModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>
          )}
          {resetPasswordEmailSentModal && (
            <Modal
              centered
              closable={false}
              open={resetPasswordEmailSentModal}
              footer={null}
            >
              <div style={{ textAlign: 'center' }}>
                <h2 style={{ marginBottom: '10px' }}>
                  Password Reset Email Sent Successfully
                </h2>
                <CheckCircleTwoTone
                  style={{ fontSize: '36px' }}
                  color="#00FF00"
                />
                <p>Please check your email to reset your password</p>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  onClick={() => {
                    setResetPasswordEmailSentModal(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </Modal>
          )}
          {/* <UploadProfileModal
            setModalVisibility={}
            modalVisibility={}
            name={getNameString(adminStore.account?.name as Name)}
            logo={''}
            setFormData={setFormData}
            handleSave={handleImageUpload}
          /> */}
          {profileModal ? (
            <Modal
              title={
                <div className="font-figtree font-semibold mb-4 text-base leading-7 text-[#07212D]">
                  Update Account Name
                </div>
              }
              width={580}
              open={profileModal}
              onOk={() => updateAdminInformation()}
              confirmLoading={isProfileUpdating}
              onCancel={() => setProfileModal(false)}
              okText="Save Changes"
              closable={!isProfileUpdating}
              okButtonProps={{
                style: {
                  background: '#001F45',
                  color: '#ffffff',
                },
                loading: isProfileUpdating,
                className: 'secondary-button',
              }}
              cancelButtonProps={{
                disabled: isProfileUpdating,
              }}
              destroyOnClose
            >
              <Form
                form={profileForm}
                layout="vertical"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                autoComplete="false"
              >
                <Form.Item
                  label="First Name"
                  name="firstName"
                  style={{ width: '49%' }}
                  initialValue={profileFirstName}
                  rules={[
                    { required: true },
                    {
                      pattern: /^[a-zA-Z0-9\s,;&.]+$/,
                      message:
                        'Name can only contain letters, numbers, spaces, `,`, `&`, and `;`',
                    },
                    {
                      validator: (_, value) =>
                        !value || value?.trim() === ''
                          ? Promise.reject(
                              new Error('First name cannot contain only spaces')
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    id={
                      IdConstants.AGENCY.GENERAL.EDIT_INFORMATION
                        .FIRST_NAME_INPUT + '-agency-general'
                    }
                    placeholder="Enter your first name"
                    defaultValue={profileFirstName}
                    onChange={(e) => {
                      const value = e.target.value;
                      const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
                      const capitalizedValue =
                        cleanedValue.charAt(0).toUpperCase() +
                        cleanedValue.slice(1);
                      profileForm.setFieldsValue({
                        firstName: capitalizedValue,
                      });
                    }}
                    onKeyPress={(event) => {
                      const regex = /^[A-Za-z\s]+$/;
                      const char = String.fromCharCode(
                        event.which || event.keyCode
                      );
                      if (!regex.test(char)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      const pastedText =
                        event.clipboardData.getData('text/plain');
                      const regex = /^[A-Za-z\s]+$/;
                      if (!regex.test(pastedText)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    { required: true },
                    {
                      pattern: /^[a-zA-Z0-9\s,;&.]+$/,
                      message:
                        'Name can only contain letters, numbers, spaces, `,`, `&`, and `;`',
                    },
                    {
                      validator: (_, value) =>
                        !value || value?.trim() === ''
                          ? Promise.reject(
                              new Error('Last name cannot contain only spaces')
                            )
                          : Promise.resolve(),
                    },
                  ]}
                  style={{ width: '49%' }}
                  initialValue={profileLastName}
                >
                  <Input
                    id={
                      IdConstants.AGENCY.GENERAL.EDIT_INFORMATION
                        .LAST_NAME_INPUT + '-agency-general'
                    }
                    placeholder="Enter your last name"
                    defaultValue={profileLastName}
                    onChange={(e) => {
                      const value = e.target.value;
                      const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
                      const capitalizedValue =
                        cleanedValue.charAt(0).toUpperCase() +
                        cleanedValue.slice(1);
                      profileForm.setFieldsValue({
                        lastName: capitalizedValue,
                      });
                    }}
                    onKeyPress={(event) => {
                      const regex = /^[A-Za-z\s]+$/;
                      const char = String.fromCharCode(
                        event.which || event.keyCode
                      );
                      if (!regex.test(char)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      const pastedText =
                        event.clipboardData.getData('text/plain');
                      const regex = /^[A-Za-z\s]+$/;
                      if (!regex.test(pastedText)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </Modal>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default withRoles(UserProfile, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
]);
