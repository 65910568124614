import { RouteConstants } from '../constants/routes.constants';
import { siderMenuKey } from '../types/common/sidebar.type';

export const getDefaultSideBarOpenKey = (
  menu: { key: string }[],
  pathName: string,
  isInGuidedTour?: boolean,
  isProducerPortal?: boolean
) => {
  if (isInGuidedTour) {
    return menu.map((data) => `${data?.key}`);
  }

  // to find active sider menu key for the current route
  const siderKey = (
    Object.values(RouteConstants)?.find(
      (route) =>
        route.path === pathName || dynamicPathsMatch(route.path, pathName)
    ) as { siderMenuKey: string }
  )?.siderMenuKey;

  const activeKey =
    siderKey ||
    (isProducerPortal
      ? siderMenuKey.PRODUCER_PORTAL_MY_PRODUCER
      : siderMenuKey.ADMIN_PORTAL_PRODUCERS);

  // invites page route is common for both producer and admin, so need to give siderMenuKey based on the loginType
  // if (isProducerPortal && RouteConstants.invites.path === pathName) {
  //   return [siderMenuKey.PRODUCER_PORTAL_MY_ACCOUNT];
  // }

  return [activeKey];
};

/**
 * Compares two paths (a route path and the current path) to determine if they match,
 * based on their first and last segments.
 *
 * @param {string} routePath - The route path (e.g., "/agent/:agentId/pending-applications").
 * @param {string} currentPath - The current path (e.g., "/agent/12345/pending-applications").
 * @returns {boolean} - Returns `true` if the first and last segments of the paths match, otherwise `false`.
 *
 * @example
 * dynamicPathsMatch('/agent/:agentId/pending-applications', '/agent/12345/pending-applications');
 * Returns: true
 *
 * dynamicPathsMatch('/agent/:agentId/manage-licenses', '/agent/12345/pending-applications');
 *  Returns: false
 */
export const dynamicPathsMatch = (
  routePath: string,
  currentPath: string
): boolean => {
  if (routePath === currentPath) return true;

  // if no dynamic route included, return false
  if (!routePath?.includes(':agentId')) return false;

  // Remove trailing slashes and split the paths into segments
  const routeSegments = routePath
    ?.replace(/\/+$/, '')
    ?.split('/')
    ?.filter(Boolean);
  const currentSegments = currentPath
    ?.replace(/\/+$/, '')
    ?.split('/')
    ?.filter(Boolean);

  // Check if the first and last segments match
  const firstSegmentMatches = routeSegments?.[0] === currentSegments?.[0];
  const lastSegmentMatches =
    currentSegments?.length > 2 // if it contains dynamic route at end only, then don't need to compare it (eg: /producer/:producerId)
      ? routeSegments?.[routeSegments?.length - 1] ===
        currentSegments?.[currentSegments?.length - 1]
      : true;

  // Return true if both match
  return firstSegmentMatches && lastSegmentMatches;
};
