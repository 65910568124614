import {
  Avatar,
  Button,
  Card,
  Col,
  Progress,
  Row,
  Skeleton,
  Tooltip,
  message,
} from 'antd';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import {
  HUNDRENT_PERCENT,
  PRODUCER_STAT_CIRCLE_WIDTH,
} from '../../../constants/common.constants';
import {
  ProducerDashboardProps,
  editInfoModalProps,
} from '../../../types/request/agents/dashboard.type';
import {
  ProducerDetails,
  UpdatedPaymentConfig,
} from '../../agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import {
  STATE_CONSTANTS_COUNT,
  StateConstants,
} from '../../../constants/state.constants';
import USMapView, { USMapViewDataProp } from '../../../components/usMap';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import ActiveDot from '../../../components/ActiveDot';
import { AddressType } from '../../../enums/addressType.enum';
import { AgencyService } from '../../../services/agency.service';
import AgentPaymentConfig from './paymentConfig';
import { AgentService } from '../../../services/agent.service';
import AgentStatus from '../../../components/common/agentStatus/AgentStatus';
import EditInfo from './EditInfo';
import { IdConstants } from '../../../constants/id.constants';
import { LicenseMapColor } from '../../../enums/licenseMapColor.enum';
import { Name } from '../../../types/data/name.type';
import NiprIcon from '../../../components/common/icons/NiprIcon';
import { PROGRESS_DONUT } from '../../../constants/style.constants';
import PageNotFound from '../../404';
import { ProducerStatDetailsType } from '../../../types/data/agentDetail.type';
import ProfileLabel from '../../../components/profileLabel/ProfileLabel';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import StripeOnboardingRequiredToast from '../../../components/common/stripeOnboardingRequiredToast';
import { UserType } from '../../../enums/userType.enum';
import { agentSideStore } from '../../../stores/agentPortalStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { costFormatter } from '../../../utils/input.utils';
import { getAddressLine } from '../../agents/onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { getAssignmentStatusTooltip } from '../../../utils/licenses/getAssignmentStatusTooltip.utils';
import { getNameString } from '../../../utils/name.utils';
import { getStateNameFromCode } from '../../../utils/common.utils';
import { isEmpty } from 'lodash';
import { isErrorStripeOnboardingRequired } from '../../../utils/errorUtils';
import { maskSSN } from '../../../utils/hash.utils';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../../../stores/signupStore';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../auth/authProvider';
import { withRoles } from '../../../auth/useRoles';

/**
 * Producer Dashboard Component
 *
 * This component renders the dashboard view based on the user's role.
 *
 * - **Admin Portal**: Displays a producer overview for Admin users (`isAdmin` flag).
 * - **Single Producer Offering**: Displays an individual producer view for single producers (`isIndividualProducer` flag).
 * - **Producer Portal**: General view for producers (default view if no specific role applies).
 *
 * @param {ProducerDashboardProps} props - The component props.
 * @param {boolean} props.isAdmin - Flag to identify if the user is an Admin.
 */
const ProducerDashboard: React.FC<ProducerDashboardProps> = ({ isAdmin }) => {
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { bearerToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [statLoading, setStatLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [mapLoader, setMapLoader] = useState(true);
  const [agentDetails, setAgentDetails] = useState<
    ProducerDetails | undefined
  >();
  const [paymentConfigLoader, setPaymentConfigLoader] = useState(false);
  const [stateDataForMap, setStateDataForMap] = useState<USMapViewDataProp>({});
  const [mapStatusCount, setMapStatusCount] = useState<{
    active: number;
    pending: number;
    missing: number;
  }>({ active: 0, pending: 0, missing: 0 });
  const isIndividualProducer =
    signupStore?.getUserType() === UserType.INDIVIDUAL_PRODUCER;
  // biography info - edit states
  const [ssn, setSsn] = useState<string>('');
  const [bioInfoLoading, setBioInfoLoading] = useState(false);
  const [isEditInfoVisible, setIsEditInfoVisible] =
    useState<editInfoModalProps>(null);
  const [producerStatDetails, setProducerStatDetails] =
    useState<ProducerStatDetailsType | null>(null);
  const isAutoRenewalActive = agentDetails?.paymentConfig?.isAutoRenewalActive;
  const showManageAssignments = isIndividualProducer || isAdmin;

  useEffect(() => {
    getAgentDetails();
    getProducerStatDetails();
    getMapData();
  }, []);

  const getAgentDetails = async () => {
    try {
      setIsLoading(true);
      if (bearerToken) {
        let agentDetails: any;

        if (isAdmin && agentId) {
          agentDetails = await AgentService.getProducerDetailsById(
            agentId,
            bearerToken
          ).catch((error) => {
            console.error('Error:: ', error.message);
          });
          if (agentDetails?.data) {
            appStateInfoStore.setCurrentAgentId(agentDetails.data?.id);
            appStateInfoStore.setHeader(
              getNameString(agentDetails.data?.name || '')
            );
          }
        } else {
          agentDetails = await AgentService.getCurrentAgent(bearerToken);
        }
        setAgentDetails(agentDetails.data);
        setSsn(agentDetails.data?.ssn);
        agentSideStore.setPaymentConfig(agentDetails.data.paymentConfig);
      }
    } catch (error) {
      console.error('error in getAgentDetails', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProducerStatDetails = async () => {
    try {
      setStatLoading(true);
      if (bearerToken) {
        const agentDetails: any = await AgentService.getProducerStatDetails(
          bearerToken,
          agentId
        );
        setProducerStatDetails(agentDetails?.data);
      }
    } catch (error) {
      console.error('error in getProducerStatDetails', error);
    } finally {
      setStatLoading(false);
    }
  };

  const getMapData = async () => {
    try {
      setMapLoader(true);
      if (bearerToken) {
        let licenseDetails: any;
        if (isAdmin && agentId) {
          licenseDetails = await AgentService.getLicenseStatusByAgent(
            agentId,
            bearerToken
          );
        } else {
          licenseDetails = await AgentService.getLicenseStatus(bearerToken);
        }

        const colorCount = { active: 0, pending: 0, missing: 0 };
        const licenseColor: { [key: string]: string } = {};
        licenseDetails?.forEach(
          (data: { stateCode: string; color: string }) => {
            licenseColor[data.stateCode] = data.color;
            if (data.color === LicenseMapColor.GREEN) {
              colorCount.active++;
            } else if (data.color === LicenseMapColor.YELLOW) {
              colorCount.pending++;
            } else if (data.color === LicenseMapColor.RED) {
              colorCount.missing++;
            }
          }
        );

        const agentStateCustomization: any = {};
        Object.entries(licenseColor)?.forEach(([k, v]) => {
          agentStateCustomization[k] = {
            backgroundColor: v,
          };
        });

        setStateDataForMap(agentStateCustomization);
        setMapStatusCount(colorCount);
      }
    } catch (error) {
      console.error('getMapsData :: Error ::', error);
    } finally {
      setMapLoader(false);
    }
  };

  const onChange = async (
    checked: boolean,
    updatedPaymentConfig: Partial<UpdatedPaymentConfig> = {}
  ) => {
    try {
      setPaymentConfigLoader(true);
      if (bearerToken) {
        const paymentConfig = {
          ...agentDetails?.paymentConfig,
          ...(updatedPaymentConfig || {}),
          isAutoRenewalActive: checked,
        } as UpdatedPaymentConfig;
        if (isAdmin) {
          await AgentService.updatePaymentConfig(
            [agentId],
            paymentConfig as UpdatedPaymentConfig,
            bearerToken
          );
        } else {
          await AgentService.updatePaymentConfigAutoRenew(
            paymentConfig,
            bearerToken
          );
        }

        setAgentDetails((prev) => {
          if (prev)
            return {
              ...prev,
              paymentConfig: {
                ...paymentConfig,
              },
            };
        });
      }
    } catch (error) {
      console.error(`Error while updating payment config | Error : ${error}`);
    } finally {
      setPaymentConfigLoader(false);
    }
  };

  const handleInfoEdit = async (values: any) => {
    const producerId = agentSideStore.agentId || agentId;
    if (!producerId || !bearerToken) return;
    setBioInfoLoading(true);

    let updatedObject: any;

    if (isEditInfoVisible === 'other-information') {
      updatedObject = {
        gender: values?.['gender'],
        contactEmail: values?.['contactEmail'],
      };
      if (values?.['ssn'] && values?.['ssn']?.[0] !== '*') {
        updatedObject.ssn = values?.['ssn'];
      }
    } else {
      updatedObject = {
        businessEmail: values?.['businessEmail'],
        businessPhone: values?.['businessPhone'],
      };
    }

    try {
      await AgencyService.updateProducerDetails(
        producerId!,
        updatedObject,
        bearerToken,
        !!agentSideStore.agentId
      );
      setAgentDetails((prev) => ({ ...prev, ...updatedObject }));
      if (updatedObject?.ssn) setSsn(updatedObject?.ssn || '');
      message.success('Producer details updated successfully');
      setIsEditInfoVisible(null);
    } catch (error: any) {
      if (isErrorStripeOnboardingRequired(error.response.data)) {
        message.error(<StripeOnboardingRequiredToast />);
      } else {
        message.error(
          `${error?.response?.data?.error?.message || error?.response?.data?.message || 'Failed to update producer details.please try again.'}.`
        );
      }
    } finally {
      setBioInfoLoading(false);
      getAgentDetails();
    }
  };

  const isDisabled =
    !agentDetails?.residentState ||
    agentDetails?.processingFlags?.isAssignmentProcessing ||
    agentDetails?.processingFlags?.isLicenseProcessing;

  const otherInformationDetails = [
    {
      label: 'SSN',
      value: agentDetails?.ssn ? maskSSN(agentDetails?.ssn) : '-',
    },
    { label: 'Gender', value: agentDetails?.gender },
    { label: 'Contact Email', value: agentDetails?.contactEmail },
  ];

  const renderAddress = (addressType: AddressType) => {
    const address = agentDetails?.address?.find(
      (d) => d.addressType === addressType
    );
    if (!address) return null;
    const addressDetails = getAddressLine(address);
    return `${addressDetails?.addressLine1} ${addressDetails?.addressLine2}`;
  };

  const niprContactInformationDetails = [
    {
      label: 'Email Address',
      value: agentDetails?.businessEmail,
      icon: <MailOutlined />,
    },
    {
      label: 'Phone',
      value: agentDetails?.businessPhone,
      icon: <PhoneOutlined />,
    },
    { label: 'Fax', value: agentDetails?.fax, icon: <PrinterOutlined /> },
    {
      label: 'Date of Birth',
      value: agentDetails?.dateOfBirth,
      icon: <CalendarOutlined />,
    },
    {
      label: 'Business address',
      value: renderAddress(AddressType.BUSINESS),
      icon: <EnvironmentOutlined />,
    },
    {
      label: 'Resident address',
      value: renderAddress(AddressType.RESIDENCE),
      icon: <EnvironmentOutlined />,
    },
    {
      label: 'Mailing address',
      value: renderAddress(AddressType.MAILING),
      icon: <EnvironmentOutlined />,
    },
  ];

  const producerStatDetailsInfo = [
    {
      label: (
        <div>
          States with <br /> Active Licenses
        </div>
      ),
      value: (
        <div>
          {producerStatDetails?.activeLicenseCount || 0}/{STATE_CONSTANTS_COUNT}
        </div>
      ),
      percent:
        ((producerStatDetails?.activeLicenseCount || 1) /
          STATE_CONSTANTS_COUNT) *
        HUNDRENT_PERCENT,
    },
    {
      label: 'Upcoming Renewals',
      value: producerStatDetails?.upcomingRenewalsCount || 0,
    },
    {
      label: 'Total Pending Applications',
      value: producerStatDetails?.pendingApplicationsCount || 0,
    },
    {
      label: 'Follow-Ups',
      value: producerStatDetails?.pendingApplicationsFollowUpCount || 0,
    },
    {
      label: 'States Assigned',
      value: (
        <div>
          {producerStatDetails?.assignedStatesCount || 0}/
          {STATE_CONSTANTS_COUNT}
        </div>
      ),
      percent:
        ((producerStatDetails?.assignedStatesCount || 1) /
          STATE_CONSTANTS_COUNT) *
        HUNDRENT_PERCENT,
    },
  ];

  const stateLicenseConfigs =
    agentDetails?.stateAssignments?.[0]?.stateAssignments;

  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();

  const RefreshNiprDetails = async () => {
    try {
      setIsRefreshing(true);
      const agentDetails = await AgentService.refreshProducerNiprDetails(
        bearerToken!,
        agentId!
      );
      setAgentDetails(agentDetails.data);
      setSsn(agentDetails.data?.ssn);
      message.success('Producer NIPR details refreshed successfully');
    } catch (error) {
      console.error(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const getAssignedTerritories = () => {
    if (!stateLicenseConfigs || !stateLicenseConfigs?.length)
      return (
        <div className="figtree no-territories">No Territories Assigned</div>
      );

    const terriotoryDetails = new Set();

    const territoryNames = stateLicenseConfigs?.filter((d) => {
      if (
        d.territoryId &&
        d.territoryName &&
        !terriotoryDetails.has(d.territoryId)
      ) {
        terriotoryDetails.add(d.territoryId);
        return true;
      }
      return false;
    });

    if (!territoryNames?.length)
      return (
        <div className="figtree no-territories">No Territories Assigned</div>
      );

    return (
      <div className="flex flex-wrap gap-3">
        {territoryNames?.map((d) => (
          <div
            className={twMerge(
              'custom-territory-name',
              showManageAssignments ? 'cursor-pointer' : ''
            )}
            onClick={() => navigateManageAssignments()}
            key={d.territoryId}
          >
            {d.territoryName}
          </div>
        ))}
      </div>
    );
  };

  const getStateAssignments = () => {
    if (!stateLicenseConfigs || !stateLicenseConfigs?.length)
      return <div className="figtree no-territories">No States Assigned</div>;

    const stateDetails: string[] = [];

    stateLicenseConfigs.forEach((config) => {
      if (!stateDetails.includes(config.stateCode))
        stateDetails.push(config.stateCode);
    });
    return (
      <div className="custom-avatar-group pl-[5px] flex my-2 flex-wrap gap-y-2 mr-2">
        {stateDetails?.map((stateCode) => {
          return (
            <Tooltip
              key={stateCode}
              title={`${getStateNameFromCode(stateCode)}`}
            >
              <Avatar
                size={'small'}
                onClick={() => navigateManageAssignments()}
                className={twMerge(
                  'ant-custom-avatar ml-[-6px]',
                  showManageAssignments ? 'cursor-pointer' : ''
                )}
              >
                {stateCode}
              </Avatar>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const navigateManageAssignments = () => {
    if (!showManageAssignments) return;
    if (isAdmin) {
      navigate(
        RouteConstants.manageAssignmentsByProducers.path.replace(
          ':producerId',
          agentId!
        )
      );
    } else {
      navigate(RouteConstants.manageAssignmentProducerPortal.path);
    }
  };

  return (
    <>
      {isLoading || statLoading ? (
        <div className="spin-style w-full flex justify-center">
          <Skeleton active />
        </div>
      ) : !isEmpty(agentDetails) ? (
        <div className="flex flex-col gap-[10px] w-full">
          <Card>
            <div className="flex flex-col md:flex-row font-figtree md:justify-between px-5 py-4 items-center bg-[#EDF9FF] rounded-md">
              <div className="flex gap-2 items-center">
                <ProfileLabel label={agentDetails?.name as Name} />
                <div className="font-figtree flex flex-col gap-1">
                  <div className="font-medium leading-6 text-lg text-[#222222]">
                    {getNameString(agentDetails?.name as Name)}
                  </div>
                  <div className="leading-3 text-[10px] text-[#1D2C50]">
                    NPN: {agentDetails?.npn}
                  </div>
                  <div className="leading-3 text-[10px] text-[#1D2C50]">
                    Home State:{' '}
                    {StateConstants[agentDetails?.residentState] ||
                      agentDetails?.residentState}
                  </div>
                </div>
              </div>
              <AgentPaymentConfig
                agent={agentDetails}
                onChange={onChange}
                loading={paymentConfigLoader}
                isIndividualProducer={isIndividualProducer}
                isAdmin={!!isAdmin}
              />
            </div>
            <div className="pt-4">
              <div className="flex items-center justify-between">
                <div className="font-figtree leading-7 font-semibold text-sm text-dark-blue">
                  Other Information
                </div>
                <Button
                  id={IdConstants.AGENT_INFO.EDIT_INFO}
                  size="middle"
                  type="primary"
                  className="button h-6 grid text-xs font-medium leading-[14.4px]"
                  onClick={() => {
                    setIsEditInfoVisible('other-information');
                  }}
                  disabled={subscriptionStatusInactive}
                >
                  Edit Info
                </Button>
              </div>
              <div className="flex flex-col gap-1 justify-center pt-2 md:justify-start">
                {otherInformationDetails?.map((d, index) => {
                  return (
                    <div className="flex gap-3 font-figtree" key={index}>
                      <div className="text-dark-blue leading-5 min-w-[80px] text-xs">
                        {d.label}
                      </div>
                      <div className="text-dark-blue leading-5 text-xs font-medium">
                        {d.value || '-'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
          <div className="flex flex-col md:flex-row gap-4">
            <AgentStatus
              title="Background Questions"
              percentage={
                ((producerStatDetails?.backgroundQuestions?.answered || 0) /
                  (producerStatDetails?.backgroundQuestions?.total || 0)) *
                HUNDRENT_PERCENT
              }
              subText={`${producerStatDetails?.backgroundQuestions?.answered}/${producerStatDetails?.backgroundQuestions?.total} Completed`}
              onClick={() => {
                if (isAdmin) {
                  navigate(
                    RouteConstants.agencyBackgroundQuestions.path.replace(
                      ':agentId',
                      agentId!
                    )
                  );
                } else {
                  navigate(RouteConstants.backgroundQuestions.path);
                }
              }}
            >
              You’ve completed all the background questions. <br />
              Everything is ready to proceed!{' '}
            </AgentStatus>
            <AgentStatus
              title="Employment history"
              percentage={
                ((producerStatDetails?.employmentHistory?.actualYearsServed ||
                  0) /
                  (producerStatDetails?.employmentHistory
                    ?.minimumYearsAllowed || 0)) *
                HUNDRENT_PERCENT
              }
              subText={`${producerStatDetails?.employmentHistory?.actualYearsServed}/${
                producerStatDetails?.employmentHistory?.minimumYearsAllowed
              } Years`}
              onClick={() => {
                if (isAdmin) {
                  navigate(
                    RouteConstants.agencyEmploymentHistory.path.replace(
                      ':agentId',
                      agentId!
                    )
                  );
                } else {
                  navigate(RouteConstants.employmentHistory.path);
                }
              }}
            >
              Please provide your employment history details in order to proceed
              with the producer application for licenses and other related
              matters.
            </AgentStatus>
          </div>
          <Card>
            <div className="flex font-figtree justify-between items-center">
              <div className="text-sm leading-7 font-semibold">Assignments</div>
              {showManageAssignments ? (
                <Tooltip
                  title={getAssignmentStatusTooltip(
                    agentDetails?.processingFlags
                  )}
                >
                  <Button
                    id={IdConstants.AGENT_INFO.MANAGE_ASSIGNMENT}
                    className="figtree h-7 bg-dark-blue disabled:text-white seconday-button text-white"
                    disabled={isDisabled || subscriptionStatusInactive}
                    onClick={() => {
                      navigateManageAssignments();
                    }}
                    size="middle"
                  >
                    Manage States {isAdmin ? '& Territories' : ''}
                  </Button>
                </Tooltip>
              ) : null}
            </div>
            <div className="flex flex-col gap-3 pt-3">
              {isIndividualProducer && !isAdmin ? null : (
                <div className="flex gap-2 items-center">
                  <div className="min-w-[120px] text-xs leading-5 text-dark-blue">
                    Assigned Territories
                  </div>
                  <div>{getAssignedTerritories()}</div>
                </div>
              )}
              <div className="flex gap-2 items-center">
                <div className="min-w-[120px] text-xs leading-5  text-dark-blue">
                  Assigned States
                </div>
                {getStateAssignments()}
              </div>
            </div>
          </Card>
          <Card className="remove-padding font-figtree">
            <div className="bg-[#EDF9FF] flex justify-between px-2 py-1 items-center">
              <div className="flex items-center gap-1">
                <NiprIcon />{' '}
                <span className="font-semibold text-[#072A60] text-sm leading-[16.8px]">
                  {' '}
                  NIPR Contact Information
                </span>
              </div>
              <div className="flex items-center gap-4">
                {' '}
                <Button
                  id={IdConstants.AGENT_INFO.EDIT_INFO}
                  size="middle"
                  type="primary"
                  className="button h-6 grid place-content-center text-xs font-medium leading-[14.4px]"
                  onClick={() => {
                    setIsEditInfoVisible('nipr');
                  }}
                  disabled={subscriptionStatusInactive}
                >
                  Edit Info
                </Button>
                <Button
                  id={IdConstants.AGENT_INFO.REFRESH_NIPR_DETAILS}
                  className=" h-6 flex items-center text-xs font-medium leading-[14.4px] bg-dark-blue text-white"
                  onClick={() => {
                    RefreshNiprDetails();
                  }}
                  loading={isRefreshing}
                  size="middle"
                  disabled={subscriptionStatusInactive}
                >
                  Refresh
                </Button>
              </div>
            </div>
            <div className="border-t border-[#E6E6E6] icon-width-14">
              {niprContactInformationDetails?.map((d, index) => {
                return (
                  <div
                    key={index}
                    className={`${index + 1 !== niprContactInformationDetails?.length ? 'border-b border-[#E6E6E6]' : ''}  flex items-center px-2`}
                  >
                    <div className="border-r flex items-center font-semibold leading-7 py-2 border-[#E6E6E6] text-sm min-w-[180px]">
                      {d?.icon} <span className="ml-3">{d?.label}</span>
                    </div>
                    <div className="text-sm leading-7 py-2 pl-3 text-[#5D5D5D]">
                      {d?.value || '-'}
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
          <div
            className={twMerge(
              `grid gap-2 font-figtree items-center grid-cols-1 md:grid-cols-3`,
              isAutoRenewalActive ? 'xl:grid-cols-6' : 'xl:grid-cols-5'
            )}
          >
            {producerStatDetailsInfo?.map((d, index) => {
              return (
                <Card className="flex-1 min-h-[144px]" key={index}>
                  <div className="flex-col-center justify-between">
                    {d?.percent ? (
                      <Progress
                        type="circle"
                        trailColor={PROGRESS_DONUT.TRAIL_COLOR}
                        strokeWidth={12}
                        strokeColor={PROGRESS_DONUT.STROKE_COLOR}
                        format={() => (
                          <span className="font-medium leading-6 text-[10px]">
                            {d?.value}
                          </span>
                        )}
                        percent={d?.percent}
                        size={PRODUCER_STAT_CIRCLE_WIDTH}
                      />
                    ) : (
                      <div className="w-[71.67px] h-[71.67px] grid place-content-center rounded-full font-medium text-[30.72px] leading-[40.96px] bg-[#EDF9FF] font-poppins text-[#0074B0]">
                        {d.value}
                      </div>
                    )}
                    <div className="text-sm min-h-[32px] font-medium mt-2 leading-[14.4px] text-center">
                      {d?.label}
                    </div>
                  </div>
                </Card>
              );
            })}
            {isAutoRenewalActive ? (
              <Card className="flex-1 flex items-center justify-center min-h-[144px]">
                <div className="text-[23px] leading-6 text-center mb-2 min-h-[71.67px] pb-1 flex items-end justify-center font-semibold text-[#0F9D58]">
                  $ {costFormatter(producerStatDetails?.forecastedDebits)}
                </div>
                <div className="font-figtree min-h-[32px] mt-2 text-sm font-medium leading-[14.4px]">
                  Forecasted debits
                </div>
              </Card>
            ) : null}
          </div>
          <Row gutter={8} className="pb-5">
            <Col span={24} className="stats-card">
              <Card className="h-full">
                <Row className="justify-between">
                  <div className="font-semibold text-dark-blue">
                    Assignments in Map view
                  </div>
                </Row>
                {mapLoader ? (
                  <Skeleton active />
                ) : (
                  <Row className="items-center justify-center">
                    <USMapView data={stateDataForMap} />
                    <div className="flex gap-10 mt-6 items-center justify-center font-figtree text-sm text-[#222222]">
                      <div>
                        <ActiveDot className="bg-[#b2ecd0]" />
                        Active ({mapStatusCount.active})
                      </div>
                      <div>
                        <ActiveDot className="bg-[#f7dd74]" />
                        Pending ({mapStatusCount.pending})
                      </div>
                      <div>
                        <ActiveDot className="bg-[#ffccc7]" />
                        Missing ({mapStatusCount.missing})
                      </div>
                    </div>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      ) : null}
      <EditInfo
        ssn={ssn}
        setSsn={setSsn}
        handleInfoEdit={handleInfoEdit}
        producer={agentDetails!}
        isOpen={isEditInfoVisible}
        setIsOpen={setIsEditInfoVisible}
        loading={bioInfoLoading}
      />
    </>
  );
};

export default withRoles(observer(ProducerDashboard), [
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
