import { Button, Col, Layout, Row, Typography } from 'antd';
import {
  DESKTOP_SCREEN,
  TAB_SCREEN,
  isAboveDesktopWidth,
} from '../../utils/screen.utils';
import { ReactNode, useEffect, useState } from 'react';

import { IdConstants } from '../../constants/id.constants';
import Loading from '../loading';
import { LogoutOutlined } from '@ant-design/icons';
import { ReferralConfig } from '../../types/data/referralConfig.type';
import { RouteConstants } from '../../constants/routes.constants';
import { SignupService } from '../../services/signup.service';
import featuresImage from '../../assets/images/onboarding/insuretrek-onboarding-features.png';
import { getQueryParams } from '../../utils/queryParams.utils';
import itLogo from '../../assets/icons/it-logo.svg';
import leftPanelImage from '../../assets/images/onboarding/insuretrek-left-panel.png';
import mobileBackgroundImage from '../../assets/icons/mobile-bg-image.svg';
import { useAuth } from '../../auth/authProvider';
import { useLocation } from 'react-router';
import useWidth from '../../hooks/useWidth';

type Props = {
  flowType?:
    | 'SignIn'
    | 'SignUp'
    | 'MagicLink'
    | 'ForgotPassword'
    | 'Onboarding';
  step?: number;
  children?: ReactNode;
};
export const OnboardingLayout = ({
  flowType = 'SignIn',
  step = 1,
  children,
}: Props) => {
  const windowWidth = useWidth();
  const { performLogout, isAuthenticated } = useAuth();
  const queryParams = getQueryParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [hideLogoutButton, setHideLogoutButton] = useState(false);

  useEffect(() => {
    setHideLogoutButton(location.pathname === RouteConstants.rootRedirect.path);
  }, [location]);

  useEffect(() => {
    setTitle(
      !isAboveDesktopWidth()
        ? ''
        : flowType === 'SignUp' || flowType === 'Onboarding'
          ? "Let's Get Started!"
          : flowType === 'ForgotPassword'
            ? 'Forgot Password?'
            : 'Welcome Back!'
    );
    setSubtitle(
      !isAboveDesktopWidth()
        ? ''
        : 'Insuretrek helps you streamline your Health & Life producer licensing, and keep your whole team up to date.'
    );
  }, [windowWidth, flowType]);

  useEffect(() => {
    if (
      queryParams['ref'] &&
      location.pathname === '/signup' &&
      flowType !== 'ForgotPassword'
    ) {
      setLoading(true);
      SignupService.fetchReferralConfig(queryParams['ref'])
        .then((data: ReferralConfig) => {
          if (data) {
            setTitle(data.title);
            setSubtitle(data.subtitle);
            setBackgroundImage(data?.backgroundImageUrl);
          }
        })
        .catch((error) => {
          console.error(
            'Error while fetching referral config : ',
            queryParams['ref']
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <Loading logo={true} />;
  }

  return (
    <Layout style={{ height: '100%', margin: 0, padding: 0 }}>
      <Row
        justify="center"
        align="top"
        className="w-full"
        style={{
          backgroundColor: 'white',
          position: !isAboveDesktopWidth() ? 'fixed' : 'relative',
        }}
      >
        <Col
          lg={24}
          xl={12}
          style={{
            height: window.innerWidth > DESKTOP_SCREEN ? '100vh' : '168px',
            width: '100%',
            backgroundImage: `url(${!isAboveDesktopWidth() ? mobileBackgroundImage : backgroundImage || leftPanelImage})`,
            backgroundRepeat: 'no-repeat',
            justifyContent: 'center',
            alignItems:
              !backgroundImage || step !== 1 ? 'center' : 'flex-start',
            display: 'flex',
            backgroundSize: !isAboveDesktopWidth() ? 'cover' : '100% 100vh',
            ...(!isAboveDesktopWidth() ? { backgroundPosition: 'center' } : {}),
          }}
        >
          <div
            className="flex-col justify-center"
            //todo
            style={{
              padding: !isAboveDesktopWidth()
                ? '12px 0 0'
                : step === 1
                  ? !backgroundImage
                    ? '24px 100px 50px'
                    : '48px 24px 0 260px'
                  : '0px 0px 75px 0px',
            }}
          >
            {step === 1 || !isAboveDesktopWidth() ? (
              <>
                {!backgroundImage ? (
                  <img alt="logo" src={itLogo} className="signup-logo" />
                ) : null}
                {!backgroundImage ? (
                  <>
                    <Typography.Title style={{ color: 'white' }}>
                      {title}
                    </Typography.Title>
                    {flowType !== 'ForgotPassword' && (
                      <Typography.Paragraph
                        style={{ color: 'var(--primary-color)', fontSize: 18 }}
                      >
                        {subtitle}
                      </Typography.Paragraph>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 18,
                        fontWeight: 500,
                        paddingBottom: 12,
                        color: 'var(--primary-color)',
                      }}
                    >
                      {title}
                    </div>
                    {flowType !== 'ForgotPassword' && (
                      <div
                        style={{
                          fontFamily: 'Poppins',
                          fontSize: 24,
                          fontWeight: 600,
                          color: '#001f45',
                        }}
                      >
                        {subtitle}
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <img src={featuresImage} alt="Features" />
            )}
          </div>
        </Col>
        <Col
          lg={24}
          xl={12}
          style={{
            minHeight: !isAboveDesktopWidth() ? 'calc(100vh - 120px)' : '100vh',
            overflowY: 'auto',
            position: 'relative',
          }}
          className="w-full"
        >
          <div className="p-2 sm:px-[44px] sm:pt-[44px] sm:pb-[88px] flex min-h-[100%] sm:h-[100vh] flex-col justify-center items-center gap-6">
            {!hideLogoutButton &&
            flowType !== 'ForgotPassword' &&
            isAuthenticated() ? (
              <Button
                id={IdConstants.SIGNUPFLOW.LOGOUT_BUTTON}
                type="primary"
                className="button important-action-button items-end"
                style={{ alignSelf: 'end' }}
                onClick={() => {
                  performLogout(window.location.origin);
                }}
                icon={<LogoutOutlined />}
              >
                Logout
              </Button>
            ) : null}
            {children}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
