export const MyAccountKeys = {
  YOUR_INFORMATION: 'your-information',
  TEAM_INVITES: 'team-invites',
};

export const MyAccountTabOptions = [
  {
    label: 'Your Information',
    key: MyAccountKeys.YOUR_INFORMATION,
  },
  {
    label: 'Team Invites',
    key: MyAccountKeys.TEAM_INVITES,
  },
];
