const TickSuccessIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      //{...props}
    >
      <path
        d="M7.32292 13.4759C3.64102 13.4759 0.65625 10.4911 0.65625 6.80924C0.65625 3.12734 3.64102 0.142578 7.32292 0.142578C11.0048 0.142578 13.9896 3.12734 13.9896 6.80924C13.9896 10.4911 11.0048 13.4759 7.32292 13.4759ZM6.65798 9.47591L11.372 4.76186L10.4292 3.81906L6.65798 7.59031L4.77239 5.70464L3.82958 6.64751L6.65798 9.47591Z"
        fill="#0F9D58"
      />
    </svg>
  );
};

export default TickSuccessIcon;
