import { Switch, Tooltip } from 'antd';
import {
  autoRenewProps,
  autoRenewalBottomToastProps,
} from '../../../types/data/common.type';

import BottomToast from '../bottomToast/BottomToast';
import { PayeeEnum } from '../../../enums/payee.enum';
import React from 'react';

const AutoRenewSwitch: React.FC<autoRenewProps> = ({
  paymentConfig,
  onChange,
  loading,
  isProducerPortal,
}) => {
  const disabled = isProducerPortal
    ? paymentConfig?.licenseRenewalPayee !== PayeeEnum.AGENT
    : paymentConfig?.licenseRenewalPayee !== PayeeEnum.AGENCY;

  const tooltipText = !disabled
    ? paymentConfig?.isAutoRenewalActive
      ? 'Your assigned licenses will be applied automatically and the required amount will be deducted'
      : 'Your assigned licenses will not be applied automatically and you will have to apply them manually'
    : !paymentConfig?.licenseRenewalPayee
      ? 'Renewal payment is not configured'
      : isProducerPortal
        ? 'Agency will have to configure their auto apply settings'
        : 'Producer will have to configure their auto apply settings';

  return (
    <div className="flex gap-3 items-center font-figtree text-dark-blue text-xs leading-5">
      <div className="w-[60px]">
        <Tooltip title={tooltipText}>
          <Switch
            loading={loading}
            disabled={disabled}
            onChange={(checked: boolean) => {
              onChange({
                ...paymentConfig,
                isAutoRenewalActive: checked,
              });
            }}
            checked={paymentConfig?.isAutoRenewalActive}
            size="default"
            className="auto-renew-switch"
          />
        </Tooltip>
      </div>
      <div className="absolute top-0 left-[80px] bottom-0 w-px bg-[#d9d9d9] h-full" />
      <span className="capitalize flex-1">
        {paymentConfig?.licenseRenewalPayee || '-'}
      </span>
    </div>
  );
};

export const AutoRenewalBottomToast: React.FC<autoRenewalBottomToastProps> = ({
  updatedPaymentConfig,
  setUpdatedPaymentConfig,
  onUpdatePaymentConfig,
}) => {
  return (
    <BottomToast
      timeOut={5000}
      isOpen={!!updatedPaymentConfig?.isOpen}
      onClose={() => {
        setUpdatedPaymentConfig((prev) =>
          prev ? { ...prev, isOpen: false } : null
        );
      }}
      type={
        updatedPaymentConfig?.paymentConfig?.isAutoRenewalActive
          ? 'success'
          : 'failure'
      }
    >
      <span className="font-figtree text-sm">
        {`Auto Renew ${updatedPaymentConfig?.paymentConfig?.isAutoRenewalActive ? 'Enabled' : 'Disabled'}`}{' '}
        <span
          onClick={() => {
            if (updatedPaymentConfig) {
              onUpdatePaymentConfig(
                {
                  ...updatedPaymentConfig?.paymentConfig,
                  isAutoRenewalActive:
                    !updatedPaymentConfig?.paymentConfig?.isAutoRenewalActive,
                },
                updatedPaymentConfig?.producerId
              );
              setUpdatedPaymentConfig((prev) =>
                prev ? { ...prev, isOpen: false } : null
              );
            }
          }}
          className="px-2 ml-2 font-figtree text-xs leading-[22px] font-medium rounded-md cursor-pointer py-1 border border-[#989898]"
        >
          Undo
        </span>
      </span>
    </BottomToast>
  );
};

export default AutoRenewSwitch;
