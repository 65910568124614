import './uploadProfileModal.css';

import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import AgencyLogo from '../../../../../../components/common/agencyLogo/agencyLogo';
import UploadComponent from '../../../../../../components/common/uploadPhoto/uploadComponent';
import { getInitials } from '../../../../../../utils/name.utils';

interface uploadProfileModalProps {
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  logo: string;
  setFormData: (formData: FormData) => void;
  handleSave: (previewImage: string) => void;
}

const UploadProfileModal: React.FC<uploadProfileModalProps> = (props) => {
  const {
    modalVisibility,
    setModalVisibility,
    name,
    logo,
    setFormData,
    handleSave,
  } = props;
  const [previewImage, setPreviewImage] = useState<string | null>(logo);
  const [disabled, setDisabled] = useState<boolean>(true);

  return (
    <Modal
      open={modalVisibility}
      style={{
        height: '470px',
        width: '671px',
      }}
      bodyStyle={{ overflow: 'auto' }}
      title="Upload Profile Photo"
      className="profile-upload"
      centered
      onCancel={() => setModalVisibility(false)}
      closable={false}
      footer={null}
    >
      <div className="flex flex-col mt-8">
        <span className="tex-[#222] font-semibold text-base">{name}</span>
        <div className="flex flex-row gap-[22px] mt-4 mb-[22px]">
          <AgencyLogo
            agencyNameInitial={getInitials(name)}
            imageUrl={previewImage}
            style={{
              height: '188px',
              width: '188px',
              borderRadius: '50%',
              fontSize: '80.5px',
              fontWeight: 500,
            }}
          />
          <UploadComponent
            setPreviewImage={setPreviewImage}
            setFormData={setFormData}
            setDisabled={setDisabled}
          />
        </div>
        <div className="flex flex-row gap-[22px]">
          <div className="flex justify-center w-[188px]">
            <Button
              className="w-96px h-4px rounded-md font-medium text-sm font-figtree"
              style={{
                border: '1px solid #001f45',
                opacity: '0.4',
                color: '#001F45',
              }}
              onClick={() => {
                setPreviewImage('');
                setDisabled(false);
              }}
              disabled={previewImage === ''}
            >
              Remove Photo
            </Button>
          </div>
          <div className="flex flex-col gap-1 text-[#7D8C9F] text-xs font-figtree font-normal mb-8">
            <span>Accepted file types: jpg, jpeg, png.</span>
            <span>
              For best results, upload a square image at least 200 px by 200 px.
            </span>
          </div>
        </div>
        <div className="flex flex-row gap-3 justify-end">
          <Button
            className="w-96px h-4px rounded-md font-medium text-sm font-figtree"
            style={{
              border: '1px solid #001f45',
              color: '#001F45',
            }}
            onClick={() => {
              setModalVisibility(false);
              setDisabled(true);
              if (logo) setPreviewImage(logo);
            }}
          >
            Cancel
          </Button>
          <Button
            className="w-96px h-4px rounded-md font-medium text-sm font-figtree"
            style={{
              border: '1px solid #001f45',
              color: 'white',
              backgroundColor: '#001f45',
            }}
            onClick={() => {
              handleSave(previewImage || '');
              setModalVisibility(false);
              setDisabled(true);
            }}
            disabled={disabled}
          >
            Save changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadProfileModal;
