import { PayeeEnum } from '../../enums/payee.enum';

export const NOT_CONFIGURED = 'Not Configured';

const getPayee = (
  payeeType: PayeeEnum,
  isAdmin: boolean,
  agencyName: string
) => {
  if (payeeType === PayeeEnum.AGENCY) {
    return isAdmin ? PayeeEnum.AGENCY : agencyName;
  } else if (payeeType === PayeeEnum.AGENT) {
    return isAdmin ? 'producer' : 'Self';
  }
  return NOT_CONFIGURED;
};

export { getPayee };
