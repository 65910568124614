import { Button, Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { ApplicantType } from '../../enums/applicantType.enum';
import { CurrentStateProps } from './agencyLicensingInterfaces.type';
import LicenseClass from './licenseClass';
import { LicenseConfigurationDto } from '../../types/data/licenseConfiguration.type';
import { ResidencyType } from '../../enums/residencyType.enum';
import { agencyProfileStore } from '../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { observer } from 'mobx-react-lite';
import { states } from '../../constants/state.constants';

interface loaDuplicate {
  loaCode: string;
  isSelected: boolean;
  referenceLoa: string;
  referenceSelected: boolean;
}

const CurrentState: React.FC<CurrentStateProps> = observer(
  ({
    currentState,
    onAgencyStateAssignment,
    prefilledLoaIds,
    drlpOptions,
    loading,
    onUpdateDropDown,
    selectedIds,
    setSelectedIds,
    onStateSelect,
    sortedStates,
    onAddNewProducer,
    setAddedLoaIds,
    setRemovedLoaIds,
  }) => {
    const drlpIds: string[] = [];

    // const [percent, setPercent] = useState<number>(0);
    const [currentStateLicenseClassName, setCurrentStateLicenseClassName] =
      useState<string[]>([]);
    const [currentStateAllIds, setCurrentStateAllIds] = useState<string[][]>(
      []
    );
    const [currentStateLoas, setCurrentStateLoas] = useState<string[][]>([]);
    const [currentStateLoaCode, setCurrentStateLoaCode] = useState<string[][]>(
      []
    );
    const [currentStateLicenseClass, setCurrentStateLicenseClass] = useState<
      string[]
    >([]);

    const selectCoupledConfigs = (
      id: string,
      allLoaDetails: string[],
      selected?: string[]
    ): any => {
      const licenseConfigs = appStateInfoStore
        ?.getLicenseConfigs()
        ?.find((d) => d.id === id);

      if (!licenseConfigs || !licenseConfigs.isCoupled) return [];

      if (selected) {
        const isSuperSet =
          licenseConfigs?.referenceCoupledConfiguration?.isSuperSet;
        if (isSuperSet) {
          const subsets = licenseConfigs?.referenceCoupledConfiguration?.subSet;
          return allLoaDetails?.filter((d) => subsets?.includes(d!));
        } else {
          const superSet =
            licenseConfigs?.referenceCoupledConfiguration?.superSet;

          // supset of the superset
          const subsets =
            appStateInfoStore
              ?.getLicenseConfigs()
              ?.find((d) => superSet?.includes(d.id!))
              ?.referenceCoupledConfiguration?.subSet || [];

          const isAllSubsetSelected = subsets?.every((d) =>
            selected?.map((d) => d)?.includes(d)
          );

          if (!isAllSubsetSelected) return [];

          return allLoaDetails?.filter((d) => superSet?.includes(d!));
        }
      } else {
        const isSuperSet =
          licenseConfigs?.referenceCoupledConfiguration?.isSuperSet;
        if (isSuperSet) {
          return [];
        } else {
          return licenseConfigs?.referenceCoupledConfiguration?.superSet;
        }
      }
    };

    const handleCheckboxChange = (
      licenseClassCode: string,
      id: string,
      loaId: string | null,
      checked: boolean,
      index: number,
      allLoas: string[]
    ) => {
      setSelectedIds((prevState) => {
        const currentIds =
          prevState?.[currentState?.code]?.[licenseClassCode] || [];
        if (!loaId) {
          return {
            ...prevState,
            [currentState.code]: {
              ...prevState[currentState?.code],
              [licenseClassCode]: checked ? currentStateAllIds?.[index] : [],
            },
          };
        } else if (checked && loaId) {
          const selectedLoas = [...currentIds, loaId];
          return {
            ...prevState,
            [currentState.code]: {
              ...prevState[currentState?.code],
              [licenseClassCode]: [
                ...selectedLoas,
                ...selectCoupledConfigs(loaId, allLoas!, selectedLoas),
              ],
            },
          };
        } else {
          return {
            ...prevState,
            [currentState.code]: {
              ...prevState[currentState?.code],
              [licenseClassCode]: currentIds?.filter(
                (currentId) =>
                  currentId !== loaId &&
                  !selectCoupledConfigs(loaId, allLoas!)?.includes(currentId)
              ),
            },
          };
        }
      });
    };

    const agencyHomeState = agencyProfileStore?.getAgencyDetails()?.homeState;
    const licenseConfigs = appStateInfoStore?.getLicenseConfigs();
    // const licenseConfigs = JSON.parse(JSON.stringify(licenseConfig));
    const supportedLicenseConfigs = licenseConfigs?.filter((config) => {
      const validResidencyTypes = [
        ResidencyType.NotApplicable,
        agencyHomeState === config.stateCode
          ? ResidencyType.Resident
          : ResidencyType.NonResident,
      ];

      return (
        config?.isSupported === true &&
        (config?.applicantType === ApplicantType.BOTH ||
          config?.applicantType === ApplicantType.FIRM) &&
        validResidencyTypes?.includes(config?.residencyType)
      );
    });

    const currentStateLicenseConfigs = supportedLicenseConfigs?.filter(
      (config: { stateCode: string }) => config?.stateCode === currentState.code
    );

    function getAddedAndRemovedIds(
      selectedIds: any,
      prefilledLoaIds: any,
      stateCode?: string
    ) {
      const selectedIdSet: any = new Set();
      const stateSpecificSelectedIdSet: Set<any> = new Set();

      Object.entries(selectedIds).forEach(([state, stateObj]: any) => {
        Object.values(stateObj).forEach((ids: any) => {
          ids.forEach((id: any) => {
            selectedIdSet.add(id);
            if (stateCode && state === stateCode) {
              stateSpecificSelectedIdSet.add(id);
            }
          });
        });
      });

      const prefilledIdSet = new Set(prefilledLoaIds);

      const addedIds = [...selectedIdSet].filter(
        (id) => !prefilledIdSet.has(id)
      );
      const removedIdsForCurrentState: any = [];
      const removedIds = [...prefilledIdSet].filter((id) => {
        const result = masterData.find((data) =>
          data.licenseClasses.some((lc) =>
            lc.loaData.some((loa) => loa.id?.toString() === id)
          )
        );

        if (result?.stateCode === stateCode && !selectedIdSet.has(id)) {
          const matchedLcCodes = result?.licenseClasses
            .filter((lc) => lc.loaData.some((loa) => loa.id?.toString() === id))
            .map((lc) => lc.lcCode);

          removedIdsForCurrentState.push({ id, lcCodes: matchedLcCodes });
        }

        return !selectedIdSet.has(id);
      });
      const addedIdsForCurrentState = addedIds.filter((id) =>
        stateSpecificSelectedIdSet.has(id)
      );

      setAddedLoaIds(addedIds);
      setRemovedLoaIds(removedIds as any);

      return {
        addedIdsForCurrentState,
        removedIdsForCurrentState,
      };
    }
    const masterData = [
      ...appStateInfoStore.getLicenseConfigsMasterData(ApplicantType.FIRM),
    ];

    useEffect(() => {
      // removing the duplicate lcs and loas
      // todo it needs to be revisited and improvised
      const uniqueLicenseClass = new Set();
      const currentStateLicenseWithOld = currentStateLicenseConfigs.filter(
        (d) => {
          if (uniqueLicenseClass.has(d.licenseClassCode)) return false;
          uniqueLicenseClass.add(d.licenseClassCode);
          return true;
        }
      );

      const oldLcs: LicenseConfigurationDto[] = [];
      const newLcs: LicenseConfigurationDto[] = [];

      const currentStateLicense = currentStateLicenseWithOld
        ?.map((d) => {
          const isOldLc = d.isRetired ? d : null;
          const isNewLc = !d.isRetired ? d : null;
          if (isOldLc) oldLcs.push(isOldLc);
          if (isNewLc) newLcs.push(isNewLc);
          return d;
        })
        .filter((data) => {
          const isOld = oldLcs.find(
            (d) => d.licenseClass === data.licenseClassCode
          );
          const isNew = newLcs.find(
            (d) => d.licenseClass === data.licenseClassCode
          );

          const isLoaSelected = currentStateLicenseConfigs
            ?.filter((config: any) => {
              return config?.licenseClassCode === data.licenseClassCode;
            })
            .some((d) => prefilledLoaIds?.includes(d._id || d.id || ''));

          if (isLoaSelected) return true;

          if (
            (isOld &&
              currentStateLicenseWithOld.find(
                (d) =>
                  d.licenseClassCode === isOld?.licenseClassCode &&
                  data.licenseClassCode !== d.licenseClassCode
              )) ||
            (isNew &&
              prefilledLoaIds?.includes(
                currentStateLicenseWithOld.find(
                  (d) =>
                    d.licenseClassCode === isNew?.licenseClassCode &&
                    data.licenseClassCode !== d.licenseClassCode
                )?.id || ''
              ))
          ) {
            return false;
          }

          return true;
        });

      const currentStateLicenseClass: string[] = currentStateLicense?.map(
        (config: any) => config?.licenseClassCode
      );
      setCurrentStateLicenseClass(currentStateLicenseClass);
      const currentStateLicenseClassName: string[] = currentStateLicense?.map(
        (config: any) => config?.licenseClass
      );
      setCurrentStateLicenseClassName(currentStateLicenseClassName);
      const oldLoas: loaDuplicate[] = [];
      const newLoas: loaDuplicate[] = [];

      const currentStateData = currentStateLicenseClass?.map(
        (licenseClassCode) => {
          return currentStateLicenseConfigs
            ?.filter((config: any) => {
              return config?.licenseClassCode === licenseClassCode;
            })
            ?.map((data) => {
              const isOldLoa = data.isRetired ? data : null;
              const isNewLoa = !data.isRetired ? data : null;
              if (isOldLoa) {
                const newLoa = data?.referenceLicenseConfiguration?.find(
                  (d) => !d.isRetired
                );
                oldLoas.push({
                  loaCode: isOldLoa?.loaCode,
                  referenceLoa: newLoa?.loaCode,
                  referenceSelected: !!prefilledLoaIds?.includes(newLoa?.id),
                  isSelected: !!prefilledLoaIds?.includes(data?.id || ''),
                });
              }
              if (isNewLoa) {
                const oldLoa = data?.referenceLicenseConfiguration?.find(
                  (d) => d.isRetired
                );
                newLoas.push({
                  loaCode: isNewLoa?.loaCode,
                  referenceLoa: oldLoa?.loaCode,
                  referenceSelected: !!prefilledLoaIds?.includes(oldLoa?.id),
                  isSelected: !!prefilledLoaIds?.includes(data?.id || ''),
                });
              }
              return data;
            })
            ?.filter((config: any) => {
              const isOld = oldLoas.find(
                (data) => data.loaCode === config.loaCode
              );
              const isNew = newLoas.find(
                (data) => data.loaCode === config.loaCode
              );
              const isOldSelected = isOld && isOld.isSelected;
              const isNewSelected = isNew && isNew.isSelected;
              if (isOldSelected || isNewSelected) return true;
              // if old selected & new Loa not selected then don't need to show the new ones
              // if old is not selected, then don't need to show the old ones
              if (
                (isNew && !isNewSelected && isNew.referenceSelected) ||
                (isOld && !isOldSelected)
              ) {
                return false;
              }

              return true;
            })
            ?.map((config: any) => ({
              loa: config?.loa,
              _id: config?._id || config?.id,
              loaCode: config?.loaCode,
            }));
        }
      );
      const currentStateLoas: string[][] = currentStateData?.map((data) =>
        data?.map((item: { loa: any }) => item?.loa)
      );
      const currentStateLoaCodes: string[][] = currentStateData?.map((data) =>
        data?.map((item) => item?.loaCode)
      );
      setCurrentStateLoaCode(currentStateLoaCodes);
      setCurrentStateLoas(currentStateLoas);
      const currentStateAllIds: string[][] = currentStateData?.map((data) =>
        data?.map((item: { _id: any }) => item?._id)
      );
      setCurrentStateAllIds(currentStateAllIds);
      //

      const agencyLicenseClassConfigs = currentStateAllIds?.map(
        (licenseConfigIds, index) => {
          const licenseClassCode = currentStateLicenseClass[index];
          const selectedConfigIds =
            selectedIds?.[currentState?.code]?.[licenseClassCode];
          const { addedIdsForCurrentState, removedIdsForCurrentState } =
            getAddedAndRemovedIds(
              selectedIds,
              prefilledLoaIds,
              currentState?.code
            );

          const selectedDrlp = drlpOptions?.[licenseClassCode as any]?.some(
            (data) =>
              (data.selected && !data.isPrefilled) ||
              (data.isPrefilled && !data.selected)
          );
          const loaIds = currentStateAllIds[index];

          const selectedLoadIds = prefilledLoaIds?.filter((element) =>
            loaIds.includes(element)
          );
          const prevSelectedConfigs = selectedDrlp ? selectedLoadIds : null;
          return {
            licenseConfigIds:
              addedIdsForCurrentState.length > 0
                ? selectedConfigIds
                : prevSelectedConfigs,
            removedConfigIds:
              removedIdsForCurrentState.length > 0
                ? removedIdsForCurrentState
                    .filter(({ lcCodes }: any) =>
                      lcCodes.includes(licenseClassCode)
                    )
                    .map(({ id }: any) => id)
                : [],
            drlpIds,
            licenseClassCode,
          };
        }
      );
      const filteredAgencyLicenseClassConfigs =
        agencyLicenseClassConfigs?.filter((config) => {
          return Object.entries(config).every(([key, value]) =>
            key === 'drlpIds' ||
            key === 'removedConfigIds' ||
            key === 'licenseConfigIds'
              ? true
              : Array.isArray(value)
                ? value.length > 0
                : value !== '' && value !== undefined
          );
        });
      const result =
        filteredAgencyLicenseClassConfigs?.length > 0
          ? {
              stateCode: currentState.code,
              filteredAgencyLicenseClassConfigs,
            }
          : null;
      onAgencyStateAssignment(result);
    }, [prefilledLoaIds, currentState.code, selectedIds, drlpOptions]);

    const handleRightClick = () => {
      const currentIndex = sortedStates.findIndex(
        (state) => state?.name === currentState.name
      );
      const currentStateCode = states?.filter(
        (state) => state.name === sortedStates[currentIndex + 1]?.name
      )?.[0]?.code;
      if (currentStateCode) {
        onStateSelect({
          name: sortedStates[currentIndex + 1]?.name,
          code: currentStateCode,
        });
      }
    };
    const handleLeftClick = () => {
      const currentIndex = sortedStates.findIndex(
        (state) => state?.name === currentState.name
      );
      const currentStateCode = states?.filter(
        (state) => state?.name === sortedStates[currentIndex - 1]?.name
      )?.[0]?.code;
      if (currentStateCode) {
        onStateSelect({
          name: sortedStates[currentIndex - 1]?.name,
          code: currentStateCode,
        });
      }
    };

    // todo
    // in future
    // useEffect(() => {
    //   let percent = 0;
    //   if (currentStateLicenseClass.length > 0) {
    //     percent += 50;
    //   }

    //   currentStateLicenseClass.forEach((_, id) => {
    //     const drlp = drlpOptions?.[id]?.some((data) => data.selected);
    //     if (drlp) {
    //       percent += 50;
    //     }
    //   });
    //   setPercent(percent);
    // }, [currentStateLicenseClass, drlpOptions]);

    const licenseData = currentStateLicenseClassName
      ?.map((licenseClassName, index) => {
        if (licenseClassName) {
          const currentStateLicenseConfigs = supportedLicenseConfigs?.filter(
            (config: any) => config.stateCode === currentState.code
          );

          const foundConfig = currentStateLicenseConfigs?.find(
            (config: any) => config.licenseClass === licenseClassName
          );

          return { licenseClassName, index, foundConfig };
        }
        return null;
      })
      ?.filter(
        (
          item
        ): item is {
          licenseClassName: string;
          index: number;
          foundConfig: any;
        } => item !== null
      );

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '6px',
          border: '1px solid #D6E4EB',
          padding: '30px',
          gap: '34px',
          backgroundColor: '#FFFFFF',
          boxSizing: 'border-box',
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '56px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%', // Hug content
              height: '100%', // Hug content
              marginBottom: '20px',
            }}
          >
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
                fontSize: '12px',
                color: '#07212D',
                margin: 0,
              }}
            >
              Current State:
            </p>
            <p
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
                fontSize: '26px',
                color: '#001F45',
                marginTop: '6px',
              }}
            >
              {currentState.name}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              marginBottom: '20px',
            }}
          >
            <Button
              icon={<LeftOutlined />}
              onClick={handleLeftClick}
              disabled={currentState.name === sortedStates[0]?.name}
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '6px',
                backgroundColor: '#E8EBEE',
                padding: '0px 1px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '20px',
              }}
            />
            <Button
              icon={<RightOutlined style={{ color: 'white' }} />}
              onClick={handleRightClick}
              disabled={
                currentState.name ===
                sortedStates[sortedStates.length - 1]?.name
              }
              style={{
                width: '36px',
                height: '36px',
                borderRadius: '6px',
                backgroundColor: '#00A6FB',
                padding: '0px 1px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </div>
        </div>
        <div
          style={{
            overflowY: 'auto',
            height: '570px',
          }}
        >
          <Spin spinning={loading || licenseData.length === 0}>
            {licenseData?.map(({ licenseClassName, index }) => {
              return (
                <LicenseClass
                  key={index}
                  ids={currentStateAllIds[index]}
                  text={
                    licenseClassName +
                    ' (' +
                    currentStateLicenseClass[index] +
                    ')'
                  }
                  loaTexts={currentStateLoas[index]}
                  loaCodes={currentStateLoaCode[index]}
                  licenseClassCode={currentStateLicenseClass[index]} // licenseCode
                  selectedIds={
                    selectedIds?.[currentState.code]?.[
                      currentStateLicenseClass?.[index]
                    ] || []
                  }
                  handleCheckboxChange={(
                    licenseClassCode,
                    id,
                    loaId,
                    isChecked
                  ) =>
                    handleCheckboxChange(
                      licenseClassCode,
                      id,
                      loaId,
                      isChecked,
                      index,
                      currentStateAllIds?.[index]
                    )
                  }
                  prefilledLoaIds={prefilledLoaIds}
                  drlpOptions={
                    drlpOptions?.[currentStateLicenseClass?.[index] as any]
                  }
                  stateCode={currentState.code}
                  onAddNewProducer={onAddNewProducer}
                  onUpdateDropDown={(value, type) =>
                    onUpdateDropDown(
                      value,
                      currentStateLicenseClass[index],
                      type
                    )
                  }
                />
              );
            })}
          </Spin>
        </div>
      </div>
    );
  }
);

export default CurrentState;
