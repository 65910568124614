import { ApplicationStatusEnum } from '../../enums/applicationStatus.enum';
import { LoaDetail } from '../data/loaDetail.type';
import { UpdatedPaymentConfig } from '../../pages/agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';

export enum LicenseApplicationType {
  CREATION = 'creation',
  RENEWAL = 'renewal',
  LATE_RENEWAL = 'late-renewal',
  REINSTATEMENT = 'reinstatement',
  AMENDMENT = 'loa-addition',
}

export interface AllLicenseResponse {
  id: string;
  state: string;
  status: string;
  stateName: string;
  licenseNumber: string;
  latestApplication: LatestApplication[] | LicenseApplication[];
  licenseClass: string;
  licenseClassCode: string;
  expiryDate: string;
  isAssigned: boolean;
  agent: any;
  licenseConfigurations: {
    licenseClass: string;
    loaDetails: LoaDetail[];
    agencyAssignment: any[];
  };
  producerDetails: {
    producerId: string;
    paymentConfig: UpdatedPaymentConfig;
  };
  residencyType: string;
  isDuplicateLicenseNumber?: boolean;
  license?: any;
  latestApplicationStatus?: ApplicationStatusEnum;
  applicationType?: LicenseApplicationType;
}

export interface licenseConfigurations {
  licenseClass: string;
  loaDetails: LoaDetail[];
}

export interface LatestApplication {
  applicationId: string;
  applicationStatus: ApplicationStatusEnum;
  licenseApplicationType: LicenseApplicationType;
}

export interface LicenseApplication {
  licenseId: string;
  agencyId: string;
  status: ApplicationStatusEnum;
  licenseConfig: string[];
  applicationType: LicenseApplicationType;
  gatewayTransaction: any;
  transactionPolls: any;
}
