import {
  AllLicensesAgencyResponseType,
  agencyLicensesType,
} from '../../../../../types/request/agency/allLicenses/allLicenses.type';
import {
  AutoComplete,
  Button,
  Checkbox,
  Input,
  Modal,
  Popover,
  Tooltip,
  message,
} from 'antd';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../../../types/common/filters.type';
import { MoreOutlined, SyncOutlined } from '@ant-design/icons';
import {
  activeLicenseFilter,
  needsAttentionLicenseFilter,
  renewalLicenseFilter,
} from '../../../../../constants/active-license.constants';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../../../utils/search.utils';
import { useEffect, useRef, useState } from 'react';

import { ActionableLicenseResponseError } from '../../../../../types/response/actionable-license.type';
import { AgencyLicenseType } from '../../../../../enums/agencyLicenseType.enum';
import AllLicensesAgencyList from './allLicensesAgencyList';
import { ApplicationStatusEnum } from '../../../../../enums/applicationStatus.enum';
import ApplyLicenseErrorModal from '../../../../../components/actionableLicenseModal/applyLicenseErrorModal';
import { FilterType } from '../../../../../enums/filterType.enum';
import { IdConstants } from '../../../../../constants/id.constants';
import { LicenseStatus } from '../../../../../enums/licenseStatus.enum';
import { LicensesService } from '../../../../../services/licenses.service';
import { PageConstants } from '../../../../../constants/page.constants';
import { RenderFilterChip } from '../../../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../../../enums/roles.enum';
import { RouteConstants } from '../../../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../../../constants/regex.constants';
import SimpleFilter from '../../../../../components/common/simpleFilter/simpleFilter';
import { Spin } from 'antd';
import { appStateInfoStore } from '../../../../../stores/appStateInfo.store';
import { cancelPreviousRequest } from '../../../../../utils/api.utils';
import filterIcon from '../../../../../assets/icons/filter-icon.svg';
import { isEmpty } from 'lodash';
import { isEnumValue } from '../../../../../utils/common.utils';
import { isXDaysOld } from '../../../../../utils/date.utils';
import manageStatesIcon from '../../../../../assets/icons/manage-states.svg';
import searchIcon from '../../../../../assets/icons/search.svg';
import { setFilterGroups } from '../../../../../utils/setSimpleFiltersRequest.utils';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../../../auth/authProvider';
import { useNavigate } from 'react-router';
import { useQueryState } from '../../../../../utils/sync-query-param/use-query-state';
import { useSearchParams } from 'react-router-dom';
import { withRoles } from '../../../../../auth/useRoles';

export const listColors = [
  {
    type: ['info', 'pending'],
    borderColor: 'rgba(248, 215, 154, 1)',
    color: 'rgba(252, 239, 207, 1)',
  },
  {
    type: ['success', 'active'],
    borderColor: 'rgba(49, 235, 145, 1)',
    color: 'rgba(152, 245, 200, 0.5)',
  },
  {
    type: ['error', 'expired', 'inactive'],
    borderColor: 'rgba(235, 49, 49, 1)',
    color: 'rgba(245, 152, 152, 0.5)',
  },
  {
    type: ['missing'],
    borderColor: 'rgba(255, 201, 66, 1)',
    color: 'rgba(252, 239, 207, 1)',
  },
  {
    type: ['warning'],
    borderColor: '#F8D79A',
    color: '#FCEFCF',
  },
];

const isNeedsAttentionLicenses = (licenses: agencyLicensesType) => {
  return (
    (licenses?.status?.toLocaleLowerCase() === LicenseStatus.Missing ||
      licenses?.status?.toLocaleLowerCase() === LicenseStatus.MISSING_LOA ||
      licenses?.status?.toLocaleLowerCase() === LicenseStatus.Inactive) &&
    !licenses?.latestApplication?.some(
      (d) => d.status === ApplicationStatusEnum.PENDING
    )
  );
};

function AllLicensesAgencyTab() {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [licenseCount, setLicenseCount] = useState({
    activeCount: 0,
    needsAttentionCount: 0,
    renewalsCount: 0,
  });

  const [selectedLicenseType, setSelectedLicenseType] =
    useState<AgencyLicenseType>(
      (searchParams.get('quickFilter') as AgencyLicenseType) ||
        AgencyLicenseType.ALL
    );
  const popOverRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [refreshModal, setRefreshModal] = useState({
    isOpen: false,
    loading: false,
    showAgain: false,
    checkBox: false,
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [requestBody, setRequestBody] = useState<object>({});
  const [searchText, setSearchText] = useState<string>('');
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [queryText, setQueryText] = useQueryState('searchAll');
  const [allLicenses, setAllLicenses] =
    useState<AllLicensesAgencyResponseType | null>(null);
  const [allRenewalLicenses, setAllRenewalLicenses] =
    useState<AllLicensesAgencyResponseType | null>(null);
  const [allActiveLicenses, setAllActiveLicenses] =
    useState<AllLicensesAgencyResponseType | null>(null);
  const [allNeedsAttentionLicenses, setAllNeedsAttentionLicenses] =
    useState<AllLicensesAgencyResponseType | null>(null);

  const [isRefreshable, setIsRefreshable] = useState(false);
  const [
    applyLicenseErrorModalVisibility,
    setApplyLicenseErrorModalVisibility,
  ] = useState<boolean>(false);
  const [errors, setErrors] = useState<ActionableLicenseResponseError[]>([]);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [sortValue, setSortValue] = useState({});

  // simple filters
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [filterInfoLoading, setFilterInfoLoading] = useState<boolean>(false);
  const [controlledFilters, setControlledFilters] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);

  //
  const { getAccessTokenSilently, bearerToken } = useAuth();

  const fetchFiltersInfoForAllLicenses = async () => {
    try {
      setFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any =
          await LicensesService.getFilterInfoForFindAllLicensesMapListAgencyTab(
            token
          );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.ALL_AGENCY_LICENSES
          )
        );
        setControlledFilters(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setFilterInfoLoading(false);
    }
  };

  useEffect(() => {
    fetchFiltersInfoForAllLicenses();
  }, []);

  const fetchAllLicenses = async (searchText: string, sort?: any) => {
    if (!bearerToken) return;
    try {
      if (
        searchText ||
        sort ||
        Object.keys(requestBody)?.length ||
        allLicenses?.agencyLicenses?.length
      ) {
        setSearchLoading(true);
      } else {
        setLoading(true);
      }
      if (cancelToken) {
        cancelPreviousRequest(cancelToken);
      }
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      const allLicense: AllLicensesAgencyResponseType =
        await LicensesService.getAgencyLicensesForList_Map(
          bearerToken,
          cancelTokenSource,
          searchText,
          searchType,
          { ...requestBody, ...(sort || sortValue || {}) }
        );
      if (isEmpty(requestBody) && isEmpty(searchText)) {
        const licenseCounts = allLicense.agencyLicenses.reduce(
          (count, license) => {
            if (
              [LicenseStatus.Active, LicenseStatus.MISSING_LOA].includes(
                license.status.toLocaleLowerCase() as LicenseStatus
              )
            ) {
              count.activeCount += 1;
            }
            if (isNeedsAttentionLicenses(license)) {
              count.needsAttentionCount += 1;
            }
            if (license.isRenewal) {
              count.renewalsCount += 1;
            }
            return count;
          },
          { activeCount: 0, needsAttentionCount: 0, renewalsCount: 0 }
        );

        setLicenseCount((prevCounts) => ({
          ...prevCounts,
          activeCount: licenseCounts.activeCount,
          needsAttentionCount: licenseCounts.needsAttentionCount,
          renewalsCount: licenseCounts.renewalsCount,
        }));
      }

      setAllActiveLicenses({
        ...allLicense,
        agencyLicenses: allLicense.agencyLicenses.filter((lic) =>
          [LicenseStatus.Active, LicenseStatus.MISSING_LOA].includes(
            lic.status.toLocaleLowerCase() as LicenseStatus
          )
        ),
      });
      setAllNeedsAttentionLicenses({
        ...allLicense,
        agencyLicenses: allLicense.agencyLicenses.filter((lic) =>
          isNeedsAttentionLicenses(lic)
        ),
      });

      setAllLicenses(allLicense);
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (!axios.isCancel(error)) {
        setSearchLoading(false);
        setLoading(false);
      }
    }
  };

  const fetchAllRenewalLicenses = async (searchText: string, sort?: any) => {
    if (!bearerToken) return;
    try {
      if (
        searchText ||
        Object.keys(sort || {})?.length ||
        Object.keys(requestBody)?.length ||
        allLicenses?.agencyLicenses?.length
      ) {
        setSearchLoading(true);
      } else {
        setLoading(true);
      }
      if (cancelToken) {
        cancelPreviousRequest(cancelToken);
      }
      const cancelTokenSource = axios.CancelToken.source();
      setCancelToken(cancelTokenSource);
      const allLicense: AllLicensesAgencyResponseType =
        await LicensesService.getAgencyRenewalLicenses(
          bearerToken,
          cancelTokenSource,
          searchText,
          searchType,
          { ...requestBody, ...(sort || sortValue || {}) }
        );
      setAllRenewalLicenses(allLicense);
      setSearchLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (!axios.isCancel(error)) {
        setSearchLoading(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedLicenseType === AgencyLicenseType.RENEWALS)
      fetchAllRenewalLicenses(queryText || '');
    else fetchAllLicenses(queryText || '');
  }, [requestBody, bearerToken, queryText, searchType]);

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const refreshLicenses = async () => {
    if (!bearerToken) return;

    try {
      setRefreshModal((prev) => ({ ...prev, loading: true }));
      if (refreshModal.checkBox) {
        await LicensesService.refreshAgencyLicensesFlag(bearerToken);
      }
      await LicensesService.refreshAgencyLicenses(bearerToken);
      setRefreshModal((prev) => ({
        ...prev,
        loading: false,
        isOpen: false,
        showAgain: true,
        checkBox: false,
      }));
      fetchAllLicenses(queryText || '');
    } catch (error: any) {
      console.error(error);
      message.error(
        error?.response?.data?.error?.message ||
          'Failed to refresh licenses. Please try again later'
      );
    } finally {
      setRefreshModal((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (allLicenses?.dontShowAgainLicenseRefresh) {
      setRefreshModal((prev) => ({
        ...prev,
        showAgain: allLicenses?.dontShowAgainLicenseRefresh,
      }));
    }
    if (!allLicenses?.licensesLastUpdatedAt) {
      setIsRefreshable(true);
    } else {
      const isRefreshAllowed = isXDaysOld(
        allLicenses?.licensesLastUpdatedAt,
        1
      );
      setIsRefreshable(isRefreshAllowed);
    }
  }, [allLicenses]);

  useEffect(() => {
    if (refreshModal.isOpen) {
      setPopUpOpen(false);
    }
  }, [refreshModal.isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popOverRef.current &&
        !popOverRef.current.contains(event.target as Node)
      ) {
        setPopUpOpen(false);
      }
    };

    if (popUpOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popUpOpen]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value.target.value) {
      const match = value.target.value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value.target.value);
      }
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);

  useEffect(() => {
    const licenseType = searchParams.get('quickFilter');
    if (licenseType && isEnumValue(AgencyLicenseType, licenseType)) {
      setSelectedLicenseType(licenseType as AgencyLicenseType);
    }
  }, []);

  const QUICK_FILTERS = [
    {
      type: AgencyLicenseType.ALL,
      label: `All`,
    },
    {
      type: AgencyLicenseType.RENEWALS,
      label: `Upcoming Renewals (${licenseCount.renewalsCount})`,
    },
    {
      type: AgencyLicenseType.ACTIVE,
      label: `Active (${licenseCount.activeCount})`,
    },
    {
      type: AgencyLicenseType.NEEDS_ATTENTION,
      label: `Needs Attention (${licenseCount.needsAttentionCount})`,
    },
  ];

  return (
    <div
      className="override-icon-size agency-all-licenses full-width-on-large"
      id="agency-all-cards"
    >
      <div
        className="flex justify-between items-center h-7 overflow-x-auto "
        style={{
          scrollbarWidth: 'none',
        }}
      >
        <div className="tabs custom-autocomplete">
          <AutoComplete
            options={options}
            disabled={
              loading || (!queryText && !allLicenses?.agencyLicenses?.length)
            }
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            className="w-full h-7"
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Input
              id={IdConstants.SEARCH_INPUT + '-agency-all-licenses-tab'}
              placeholder="Search"
              onPressEnter={handleOnSearch}
              prefix={<img src={searchIcon} alt="search" />}
              className="custom-search w-full min-w-[311px] h-7"
            />
          </AutoComplete>
        </div>
        <div className="flex items-center gap-3">
          <div className="flex gap-4">
            <Button
              onClick={() =>
                navigate(RouteConstants.myAgencyManageAssignments.path)
              }
              className="h-7 rounded-md  bg-[#e8ebee] text-[#001F45] font-figtree text-xs flex items-center border-none"
              icon={<img src={manageStatesIcon} alt="logo" />}
              disabled={subscriptionStatusInactive}
            >
              Manage States
            </Button>
            <Button
              id={IdConstants.FILTERS_BUTTON}
              loading={filterInfoLoading}
              icon={<img src={filterIcon} alt="logo" />}
              className="h-7 rounded-md  bg-[#e8ebee] text-[#001F45] font-figtree text-xs flex items-center border-none"
              onClick={() => {
                setIsFilterVisible(true);
              }}
              disabled={
                loading || filterInfoLoading || subscriptionStatusInactive
              }
            >
              Filters
            </Button>{' '}
          </div>
          <Popover
            placement="bottomRight"
            open={popUpOpen}
            content={
              <div
                ref={popOverRef}
                className="flex flex-col min-w-[150px] justify-center items-center"
                onMouseLeave={(e) => {
                  setPopUpOpen(false);
                }}
              >
                <Tooltip
                  title={
                    isRefreshable || subscriptionStatusInactive
                      ? null
                      : 'Licenses that have been refreshed once cannot be refreshed again within 24 hours.'
                  }
                >
                  <p
                    className={twMerge(
                      'flex-center cursor-pointer',
                      isRefreshable &&
                        !refreshModal.loading &&
                        !subscriptionStatusInactive
                        ? 'cursor-pointer'
                        : 'cursor-not-allowed',
                      !isRefreshable || subscriptionStatusInactive
                        ? 'opacity-50'
                        : 'opacity-100'
                    )}
                    onClick={() => {
                      if (!isRefreshable || subscriptionStatusInactive) return;
                      if (!refreshModal.showAgain) {
                        setRefreshModal((prev) => ({ ...prev, isOpen: true }));
                      } else {
                        refreshLicenses();
                      }
                    }}
                  >
                    {refreshModal.loading ? (
                      <Spin size="small" className="mr-3" />
                    ) : (
                      <SyncOutlined className="mr-3" />
                    )}
                    Refresh Licenses
                  </p>
                </Tooltip>
              </div>
            }
          >
            <MoreOutlined
              id="agency-all-licenses-popover-cta"
              className={`${!loading ? 'cursor-pointer ' : ''} outlined`}
              size={24}
              onClick={() => {
                if (!loading && !refreshModal.isOpen) {
                  setPopUpOpen(true);
                }
              }}
              onMouseEnter={() => {
                if (!loading && !refreshModal.isOpen) {
                  setPopUpOpen(true);
                }
              }}
              onMouseLeave={(e) => {
                if (!popOverRef.current) {
                  setPopUpOpen(false);
                }
              }}
            />
          </Popover>
        </div>
      </div>
      <RenderFilterChip
        {...{
          selectedFilters,
          removeFilter,
          clearFilters,
          pageName: 'all-licenses-agency-tab',
        }}
      />

      {loading ? (
        <div className="grid place-content-center min-h-[500px]">
          <Spin />{' '}
        </div>
      ) : (
        <Spin spinning={searchLoading}>
          <div>
            <div
              style={{
                overflowX: 'scroll',
              }}
              className="flex overflow-x-auto gap-2 py-[15px] px-0 "
            >
              <div className="flex gap-2 w-[50%]">
                {QUICK_FILTERS.map((filter) => {
                  return (
                    <Button
                      key={filter.type}
                      style={{
                        backgroundColor:
                          selectedLicenseType === filter.type ? '#001F45' : '',
                        color:
                          selectedLicenseType === filter.type ? '#fff' : '',
                      }}
                      onClick={() => {
                        const licenseType = filter.type;

                        if (selectedLicenseType === licenseType) return;

                        setSelectedLicenseType(licenseType);

                        if (licenseType === AgencyLicenseType.RENEWALS) {
                          fetchAllRenewalLicenses('', {});
                        } else {
                          fetchAllLicenses('', {});
                        }

                        // resetting the search and filters
                        setRequestBody({});
                        setSearchType('');
                        setSearchText('');
                        setSelectedFilters({ data: {} });
                        if (queryText) setQueryText('');

                        const newSearchParams = new URLSearchParams(
                          searchParams
                        );

                        if (queryText) {
                          newSearchParams.delete('search');
                        }

                        newSearchParams.set('quickFilter', filter.type);
                        setSearchParams(newSearchParams);
                      }}
                      className="agency-license-buttons"
                      disabled={subscriptionStatusInactive}
                    >
                      {filter.label}
                    </Button>
                  );
                })}
              </div>
            </div>
            <AllLicensesAgencyList
              allLicenses={
                selectedLicenseType === AgencyLicenseType.ACTIVE
                  ? allActiveLicenses
                  : selectedLicenseType === AgencyLicenseType.NEEDS_ATTENTION
                    ? allNeedsAttentionLicenses
                    : selectedLicenseType === AgencyLicenseType.RENEWALS
                      ? allRenewalLicenses
                      : allLicenses
              }
              refetch={(sort) => {
                if (selectedLicenseType === AgencyLicenseType.RENEWALS) {
                  fetchAllRenewalLicenses(queryText || '', sort);
                } else {
                  fetchAllLicenses(queryText || '', sort);
                }
              }}
              setErrors={setErrors}
              setApplyLicenseErrorModalVisibility={
                setApplyLicenseErrorModalVisibility
              }
              selectedLicenseType={selectedLicenseType}
              setSortValue={setSortValue}
              setLicenseCount={setLicenseCount}
              sortValue={sortValue}
              selectedFilters={selectedFilters}
            />
          </div>
        </Spin>
      )}
      <Modal
        title={null}
        closable={!refreshModal.loading}
        maskClosable={false}
        centered
        onCancel={() => {
          setRefreshModal((prev) => ({
            ...prev,
            isOpen: false,
            showAgain: false,
            loading: false,
          }));
        }}
        open={refreshModal.isOpen}
        footer={[
          <Button
            id="all-licenses-cancel-button"
            key="cancel-button"
            disabled={refreshModal.loading || subscriptionStatusInactive}
            onClick={() => {
              setRefreshModal((prev) => ({
                ...prev,
                isOpen: false,
                showAgain: false,
                loading: false,
              }));
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            id="all-licenses-refresh-licenses-button"
            onClick={refreshLicenses}
            loading={refreshModal.loading}
            disabled={refreshModal.loading || subscriptionStatusInactive}
          >
            Refresh Licenses
          </Button>,
        ]}
      >
        <p className="font-bold text-xl" id="refresh-licenses">
          Do you want to refresh licenses ?
        </p>
        <p className="text-[#99ACB5]">
          Licenses that have been refreshed once cannot be refreshed again
          within 24 hours.
        </p>
        {refreshModal.showAgain ? (
          <div className="flex gap-2 mt-3 items-center">
            <Checkbox
              checked={refreshModal.checkBox}
              onChange={(e) => {
                setRefreshModal((prev) => ({
                  ...prev,
                  checkBox: true,
                }));
              }}
              className="cursor-pointer"
              id={'refresh-licenses-do-not-show-again'}
            />
            Do not show this again
          </div>
        ) : (
          false
        )}
      </Modal>
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        controlledFilters={controlledFilters.map((filter: any) => {
          if (filter.key === 'status') {
            // Define filters based on selectedLicenseType
            let applicableFilters: LicenseStatus[] = [];
            switch (selectedLicenseType) {
              case AgencyLicenseType.ACTIVE:
                applicableFilters = activeLicenseFilter; // Only active-related statuses
                break;
              case AgencyLicenseType.RENEWALS:
                applicableFilters = renewalLicenseFilter; // Renewal-related statuses
                break;
              case AgencyLicenseType.NEEDS_ATTENTION:
                applicableFilters = needsAttentionLicenseFilter; // Needs-attention-related statuses
                break;
              default:
                break;
            }

            // Apply the filter logic
            return {
              ...filter,
              filterOptions: filter?.filterOptions?.filter((d: any) =>
                applicableFilters.includes(d?.value)
              ),
            };
          }
          return filter;
        })}
        fetchFilters={() => {
          return new Promise((resolve) =>
            resolve([] as FilterFieldsMetaData[])
          );
        }}
        clearFilters={() => {
          clearFilters();
        }}
        pageName={'All Licenses Agency List'}
        filterPage={PageConstants.ALL_AGENCY_LICENSES}
      />
      <ApplyLicenseErrorModal
        applyLicenseErrorModalVisibility={applyLicenseErrorModalVisibility}
        setApplyLicenseErrorModalVisibility={
          setApplyLicenseErrorModalVisibility
        }
        errors={errors}
        fetchLicense={() => {
          if (selectedLicenseType === AgencyLicenseType.RENEWALS)
            fetchAllRenewalLicenses(queryText || '');
          else fetchAllLicenses(queryText || '');
        }}
      />
    </div>
  );
}

export default withRoles(AllLicensesAgencyTab, [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
