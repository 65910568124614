import React, { ReactNode } from 'react';

import { Button } from 'antd';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

type fileInfo = {
  link: string;
  fileName: string;
};

type Props = {
  classNames?: string;
  type: 'internalNavigation' | 'externalNavigation' | 'download' | 'tabSwitch';
  styles?: React.CSSProperties;
  downloadFile?: fileInfo[];
  destination?: string;
  buttonName: string | ReactNode;
  file?: fileInfo;
  setButtonState?: (option: string) => void;
  setModalState?: (option: boolean) => void;
  tabKey?: string;
  size?: 'large' | 'small' | 'middle';
  buttonStyle?: 'ternary' | 'primary' | 'secondary' | 'danger';
  id?: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
};
export default function CustomButton({
  classNames,
  styles,
  type,
  destination,
  buttonName,
  file,
  setButtonState,
  setModalState,
  tabKey,
  size,
  buttonStyle,
  id,
  loading,
  onClick,
  disabled,
}: Props) {
  const navigate = useNavigate();
  const handleClicks = {
    externalNavigation: () => window.open(destination!, '_blank'),
    internalNavigation: () => navigate(destination!),
    download: () => {
      if (file) {
        const { link, fileName } = file;
        const downloadLink = document.createElement('a');
        downloadLink.href = link;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },
    tabSwitch: () => {
      if (tabKey && setButtonState) {
        setButtonState(tabKey);
      }
    },
    modalPopup: () => {
      if (setModalState) setModalState(true);
    },
  };

  const handleClick: (() => void) | undefined = onClick
    ? onClick
    : handleClicks[type];

  return (
    <>
      <Button
        id={id || buttonName?.toString().toLowerCase().replace(' ', '-')}
        size={size}
        className={twMerge(
          buttonStyle === 'ternary'
            ? 'text-dark-blue border border-dark-blue font-figtree leading-22 font-medium text-sm'
            : buttonStyle === 'secondary'
              ? 'flex gap-1 items-center min-w-[120px] rounded-md justify-center bg-dark-blue px-3 py-3 text-white'
              : buttonStyle === 'danger'
                ? 'bg-[#EB3131] text-white flex gap-1 items-center justify-center min-w-[120px] rounded-md px-3 py-3'
                : '',
          classNames
        )}
        {...(buttonStyle === 'primary' ? { type: buttonStyle } : {})}
        style={styles ? styles : {}}
        onClick={handleClick}
        loading={loading}
        disabled={disabled}
      >
        {buttonName}
      </Button>
    </>
  );
}
