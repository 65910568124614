import {
  AgencyLicenseType,
  ProducerLicenseType,
} from '../../../enums/agencyLicenseType.enum';
import {
  AgencyStatsData,
  AgentOverview,
} from '../../../types/data/allAgents.type';
import {
  AutoComplete,
  Button,
  Card,
  Col,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Spin,
  Statistic,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  EyeOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import { applySort, isDisableFilter } from '../../../utils/common.utils';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { forEach, isEmpty, isFinite } from 'lodash';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useEffect, useRef, useState } from 'react';

import ActionableEngineLights from '../../../components/licenses/actionableEngineLights';
import AgencyAssignmentOverview from './agencyAgentOverview/agencyAssignmentOverview';
import { AgentService } from '../../../services/agent.service';
import { ColumnsType } from 'antd/es/table';
import EmptyState from '../../../components/common/emptyState/emptyState';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import { PageConstants } from '../../../constants/page.constants';
import PaymentConfigModal from '../../../components/paymentConfig/paymentConfigModal';
import { PlausibleEventNameConstants } from '../../../constants/eventName.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import { StateConstants } from '../../../constants/state.constants';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { costFormatter } from '../../../utils/input.utils';
import { getNameString } from '../../../utils/name.utils';
import { hierarchyViewStore } from '../../../stores/hierarchyViewStore';
import { observer } from 'mobx-react';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { trackCustomEvent } from '../../../utils/trackingUtils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const { Search } = Input;
enum ColumnName {
  INITIAL_PAYMENT = 'initialPaymentPayee',
  RENEWAL_PAYMENT = 'renewalPaymentPayee',
  PAYMENT_TYPE = 'paymentType',
  AUTO_RENEWAL = 'autoRenewal',
}

enum PayeeType {
  AGENCY = 'Agency',
  PRODUCER = 'Producer',
}

export type FilterCondition = 'equals' | 'contains' | 'startsWith' | 'endsWith';

export const getProducerName = (name: string) => {
  if (!isEmpty(name)) {
    if (name.toLowerCase() === 'agent' || name.toLowerCase() === 'producer')
      return 'Producer';
    else if (name.toLowerCase() === 'agency') return 'Agency';
    return name;
  } else {
    return 'Not configured';
  }
};

function AgencyAgentOverview() {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const lineOnlyProducersRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [displayPaymentConfigNullAgents, setDisplayPaymentConfigNullAgents] =
    useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [lineOnlyAgents, setLineOnlyAgents] = useState<AgentOverview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAgentTableLoading, setIsAgentTableLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { getAccessTokenSilently } = useAuth();

  const [searchText, setSearchText] = useState('');
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false);
  const [prevSearchText, setPrevSearchText] = useState<string>('');
  const [queryText, setQueryText] = useQueryState('search');

  const [agencyId, setAgencyId] = useState(
    hierarchyViewStore.currentSelectedAgencyId
  );
  const [paymentConfigModalVisibility, setPaymentConfigModalVisibility] =
    useState(false);
  const [unconfiguredPaymentAgents, setUnconfiguredPaymentAgents] = useState<
    AgentOverview[]
  >([]);

  const [lineOnlyCount, setLineOnlyCount] = useState(0);
  const [paymentUnconfiguredCount, setPaymentUnconfiguredCount] =
    useState<number>();
  const [currentAgents, setCurrentAgents] = useState<AgentOverview[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<AgentOverview[]>([]);
  const [isHoveringInitialPayment, setIsHoveringInitialPayment] = useState<
    boolean[]
  >(Array(lineOnlyAgents.length).fill(false));
  const [isHoveringRenewalPayment, setIsHoveringRenewalPayment] = useState<
    boolean[]
  >(Array(lineOnlyAgents.length).fill(false));
  const [isHoveringPaymentType, setIsHoveringPaymentType] = useState<boolean[]>(
    Array(lineOnlyAgents.length).fill(false)
  );
  const [isHoveringAutoRenewal, setIsHoveringAutoRenewal] = useState<boolean[]>(
    Array(lineOnlyAgents.length).fill(false)
  );
  const [isLoaRowClickable, setLoaRowClickable] = useState<boolean[]>(
    Array(lineOnlyAgents.length).fill(true)
  );

  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<object>({});

  const [statLoading, setStatLoading] = useState<boolean>(false);
  const [actionableAgentData, setActionableAgentData] = useState([]);
  const [actionableAgentTotalCount, setActionableAgentTotalCount] = useState(0);
  const isAgentOverviewFetching = adminStore.isActionableAgentFetching;
  const [actionablePageSize, setActionablePageSize] = useState(10);
  const [actionablePageNumber, setActionablePageNumber] = useState(1);
  const [selectedState, setSelectedState] = useState('');
  const [isViewActionableData, setIsViewActionableData] =
    useState<boolean>(false);
  const [controlledFilters, setControlledFilters] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState<boolean | string>(false);
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const filterGroups = setFilterGroups({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups,
    };
    setRequestBody(updatedRequestBody);
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);

      return updatedFilters;
    });
  };

  const fetchFiltersInfoForAgents = async () => {
    try {
      const path = '/account/producer';
      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await AgentService.getFilterDetails(
          token,
          pageSize,
          pageNumber,
          path,
          searchText
        );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.HOME
          )
        );
        setControlledFilters(response.data);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
  };

  useEffect(() => {
    if (adminStore.agency) {
      setAgencyId(adminStore?.agency.id);
    }
    fetchFiltersInfoForAgents();
  }, [adminStore.agency]);

  const [actionableSortValue, setActionableSortValue] = useState({});
  const [sortValue, setSortValue] = useState({});

  const fetchAgentActionableDetails = async (
    newPage?: number,
    newSize?: number,
    searchString?: string,
    sort?: object
  ) => {
    const token = await getAccessTokenSilently();
    const actionableTableData = await AgentService.getActionableAgentDetails(
      token,
      {
        page: newPage || actionablePageNumber,
        size: newSize || actionablePageSize,
      },
      selectedState,
      isViewActionableData,
      { ...(sort || actionableSortValue || {}) }
    );
    setActionableAgentTotalCount(actionableTableData?.totalCount || 0);
    setActionableAgentData(actionableTableData?.data);
  };

  const getActionableAgents = async (
    selectedState: string,
    isViewActionableData: boolean,
    refreshing: boolean
  ) => {
    if (!refreshing) {
      setIsAgentTableLoading(true);
    }
    setIsRefreshing(true);
    setActionablePageNumber(1);
    setActionablePageSize(10);
    const token = await getAccessTokenSilently();
    const actionableTableData = await AgentService.getActionableAgentDetails(
      token,
      { page: 1, size: 10 },
      selectedState,
      isViewActionableData
    );
    setActionableAgentTotalCount(actionableTableData?.totalCount || 0);
    setActionableAgentData(actionableTableData?.data);
    if (!refreshing) setIsAgentTableLoading(false);
    setIsRefreshing(false);
  };

  useEffect(() => {
    setSelectedAgents([]);
    if (displayPaymentConfigNullAgents) {
      if (unconfiguredPaymentAgents)
        setLoaRowClickable(unconfiguredPaymentAgents.map((itr) => true));
      else setLoaRowClickable([]);
    } else setLoaRowClickable(lineOnlyAgents.map((itr) => true));
  }, [displayPaymentConfigNullAgents]);

  useEffect(() => {
    setAgencyId(hierarchyViewStore.currentSelectedAgencyId);
  }, [hierarchyViewStore.currentSelectedAgencyId]);

  useEffect(() => {
    if (hierarchyViewStore.currentSelectedAgencyId === agencyId) {
      if (agencyId) {
        loadAgents(agencyId);
        loadStatData();
      }
    }
  }, [agencyId]);

  useEffect(() => {
    if (agencyId) {
      loadAgents(agencyId);
    }
  }, [queryText, searchType]);

  useEffect(() => {
    if (paymentUnconfiguredCount === 0) {
      setDisplayPaymentConfigNullAgents(false);
      loadAgents(agencyId);
    }
    if (
      unconfiguredPaymentAgents?.length === 0 &&
      pageNumber > 1 &&
      displayPaymentConfigNullAgents
    ) {
      setPageNumber((prev) => prev - 1);
    }
  }, [paymentUnconfiguredCount]);

  useEffect(() => {
    setIsHoveringPaymentType(lineOnlyAgents.map((itr) => false));
    setIsHoveringRenewalPayment(lineOnlyAgents.map((itr) => false));
    setIsHoveringInitialPayment(lineOnlyAgents.map((itr) => false));
  }, [lineOnlyAgents]);

  useEffect(() => {
    getActionableAgents(selectedState, isViewActionableData, false);
  }, [selectedState, isViewActionableData, agencyId]);

  useEffect(() => {
    setSearchText(queryText || '');
    if (queryText !== prevSearchText && prevSearchText !== '') {
      if (displayPaymentConfigNullAgents) {
        loadAgents(agencyId, {
          searchTextProp: queryText || '',
          paymentConfig: true,
        });
        setPrevSearchText(queryText || '');
      } else {
        if (agencyId && !isSearchLoading) {
          loadAgents(agencyId, { searchTextProp: queryText || '' });
        }

        setPrevSearchText(queryText || '');
      }
    }
  }, [queryText]);

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      setPageNumber(1);
      loadAgents(agencyId, {
        page: 1,
        paymentConfig: displayPaymentConfigNullAgents,
      });
    }
  }, [requestBody]);

  const handleBulkChanges = () => {
    setCurrentAgents(selectedAgents);
  };

  const handleIndividualChanges = (agentId: string) => {
    if (displayPaymentConfigNullAgents && unconfiguredPaymentAgents) {
      const agent = unconfiguredPaymentAgents.find((itr) => itr.id === agentId);
      if (agent) {
        setCurrentAgents([agent]);
      }
    } else {
      const agent = lineOnlyAgents.find((itr) => itr.id === agentId);
      if (agent) {
        setCurrentAgents([agent]);
      }
    }
  };

  const handleMouseEnter = (rowIndex: number, columnName?: ColumnName) => {
    if (columnName === ColumnName.PAYMENT_TYPE) {
      const updatedIsHoveringCellArr = isHoveringPaymentType.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringPaymentType(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.INITIAL_PAYMENT) {
      const updatedIsHoveringCellArr = isHoveringInitialPayment.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringInitialPayment(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.RENEWAL_PAYMENT) {
      const updatedIsHoveringCellArr = isHoveringRenewalPayment.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringRenewalPayment(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.AUTO_RENEWAL) {
      const updatedIsHoveringCellArr = isHoveringAutoRenewal.map(
        (itr, index) => index === rowIndex
      );
      setIsHoveringAutoRenewal(updatedIsHoveringCellArr);
    }
  };

  const handleMouseLeave = (index: number, columnName?: ColumnName) => {
    if (columnName === ColumnName.PAYMENT_TYPE) {
      const updatedIsHoveringCellArr = [...isHoveringPaymentType];
      updatedIsHoveringCellArr[index] = false;
      setIsHoveringPaymentType(updatedIsHoveringCellArr);
    } else if (columnName === ColumnName.INITIAL_PAYMENT) {
      const updatedIsHoveringCellArr = [...isHoveringInitialPayment];
      updatedIsHoveringCellArr[index] = false;
      setIsHoveringInitialPayment(updatedIsHoveringCellArr);
    } else {
      const updatedIsHoveringCellArr = [...isHoveringRenewalPayment];
      updatedIsHoveringCellArr[index] = false;
      setIsHoveringRenewalPayment(updatedIsHoveringCellArr);
    }
  };

  const [statData, setStatData] = useState<AgencyStatsData>({
    totalAgents: 0,
    activeAgentLicenseCount: 0,
    activeAgencyLicenseCount: 0,
    forcastedDebits: 0,
    balance: 0,
  });

  const loadStatData = async () => {
    setStatLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (token) {
        const response = await AgentService.getAgencyStats(token);
        setStatData((prev) => ({
          ...prev,
          totalAgents: response.data.onboarderProducerCount,
          activeAgentLicenseCount: response.data.producerActiveLicenses,
          activeAgencyLicenseCount: response.data.activeAgencyLicenseCount,
          forcastedDebits: response.data.forecastedDebt,
        }));
      }
    } catch (e) {
      console.error(e);
      setStatLoading(false);
    } finally {
      setStatLoading(false);
    }
  };
  const loadAgents = async (
    agencyId: string,
    props?: {
      page?: number;
      size?: number;
      paymentConfig?: boolean;
      searchTextProp?: string;
    },
    sort?: object
  ) => {
    let errorTypeRequestCancel = false;
    setSearchLoading(true);
    const accessToken = await getAccessTokenSilently();
    const response = await AgentService.getAllExpiryCountForAgents(
      {},
      props?.size || pageSize,
      props?.page || pageNumber,
      props?.searchTextProp !== undefined
        ? props.searchTextProp
        : queryText || '',
      accessToken,
      props?.paymentConfig,
      { ...requestBody, ...(sort || sortValue || {}) },
      undefined,
      searchType ? searchType : searchType || ''
    ).catch((error) => {
      if (error.code === 'ERR_CANCELED') {
        errorTypeRequestCancel = true;
      }

      setErrorMessage('Error! Unable to fetch agents at the moment');
    });

    if (response?.data) {
      const newAgents: AgentOverview[] = response.data.map(
        (agentDocument: any) => {
          return {
            name: agentDocument.name,
            id: agentDocument?.id || '',
            npn: agentDocument.npn,
            agencyId: {
              id: agentDocument?.agencyId?.id,
              agencyName: agentDocument?.agencyName,
            },
            agencyName: agentDocument?.agencyName,
            expiringLicenses: agentDocument.expiryLicensesCount,
            paymentConfig: {
              initialPaymentPayee:
                agentDocument.paymentConfig?.licenseInitialPayee || '',
              renewalPaymentPayee:
                agentDocument.paymentConfig?.licenseRenewalPayee || '',
              paymentType: agentDocument.paymentConfig?.paymentType,
              amountCap: agentDocument.paymentConfig?.amountCap || undefined,
              isAutoRenewalActive:
                agentDocument?.paymentConfig?.isAutoRenewEnabled ||
                agentDocument?.paymentConfig?.isAutoRenewalActive,
            },
            isLicenseProcessing:
              agentDocument?.processingFlags?.isLicenseProcessing,
            isAssignmentProcessing:
              agentDocument?.processingFlags?.isAssignmentProcessing,
          };
        }
      );

      if (isFinite(response?.unconfiguredProducersCount)) {
        setPaymentUnconfiguredCount(response?.unconfiguredProducersCount || 0);
      }
      if (displayPaymentConfigNullAgents) {
        setUnconfiguredPaymentAgents(newAgents);
      }
      setLineOnlyAgents(newAgents || []);
      setUnconfiguredPaymentAgents(newAgents || []);
      setLineOnlyCount(response.totalCount);
      if (
        agencyId === adminStore.agency?.id &&
        searchText.length === 0 &&
        !props?.paymentConfig &&
        Object.keys(requestBody).length === 0
      ) {
        hierarchyViewStore.lineOnlyAgentsCount = response.totalCount;
      }
      if (!displayPaymentConfigNullAgents) {
        setLoaRowClickable(newAgents.map((itr) => true));
      }
    }

    if (!errorTypeRequestCancel) {
      setIsLoading(false);
      setSearchLoading(false);
    }
  };

  const onSearch = async () => {
    try {
      if (searchText === prevSearchText) return;
      setQueryText(searchText);
      setSearchLoading(true);
      if (displayPaymentConfigNullAgents) {
        await loadAgents(agencyId, {
          page: 1,
          size: pageSize,
          paymentConfig: true,
          searchTextProp: searchText,
        });
      } else {
        await loadAgents(agencyId, {
          page: 1,
          size: pageSize,
          searchTextProp: searchText,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setPageNumber(1);
      setSearchLoading(false);
      setPrevSearchText(searchText);
    }
  };

  const onChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setPageSize(newPageSize);
    if (displayPaymentConfigNullAgents) {
      loadAgents(agencyId, {
        page: newPageNumber,
        size: newPageSize,
        paymentConfig: displayPaymentConfigNullAgents,
      });
    } else {
      if (agencyId) {
        loadAgents(agencyId, {
          page: newPageNumber,
          size: newPageSize,
        });
      }
    }
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
  };

  const onActionablePageChange: PaginationProps['onChange'] = (
    newPageNumber,
    newPageSize
  ) => {
    setActionablePageSize(newPageSize);
    setActionablePageNumber(newPageNumber);
    fetchAgentActionableDetails(newPageNumber, newPageSize);
  };

  const redirect = (record: AgentOverview) => {
    appStateInfoStore.setHeader(getNameString(record.name));
    navigate(RouteConstants.agentOverview.path.replace(':agentId', record.id));
  };

  // const LineOnlyAgentsColumns: ColumnsType<AgentOverview> = [
  //   {
  //     title: 'Producer',
  //     dataIndex: 'name',
  //     key: 'agentFullName',
  //     sorter: false,
  //     width: '200px',
  //     render: (text: string, record: any) => {
  //       return (
  //         <div>
  //           <Row>
  //             <Typography.Paragraph
  //               style={{
  //                 fontSize: '14px',
  //                 fontWeight: 400,
  //                 display: 'flex',
  //                 alignItems: 'center',
  //                 color: 'var(--primary-color)',
  //                 marginBottom: '0.5em',
  //               }}
  //             >
  //               {record.paymentConfig?.licenseInitialPayee &&
  //               record.paymentConfig?.licenseRenewalPayee &&
  //               record.paymentConfig?.paymentType ? (
  //                 <>
  //                   <div style={{ color: '#f00', fontWeight: 500 }}>
  //                     {(record?.processingFlags?.isLicenseProcessing ||
  //                       record?.processingFlags?.isAssignmentProcessing) && (
  //                       <ConfigProvider
  //                         theme={{ token: { colorTextLightSolid: '#000' } }}
  //                       >
  //                         <Tooltip
  //                           title={
  //                             record?.processingFlags?.isLicenseProcessing &&
  //                             record.processingFlags?.isAssignmentProcessing
  //                               ? 'License(s) and Assignment(s) are currently being processed'
  //                               : record.isLicenseProcessing
  //                               ? 'License(s) currently being processed'
  //                               : 'Assignment(s) currently being processed'
  //                           }
  //                           color="#fff"
  //                           key="#fff"
  //                           style={{ color: 'var(--secondary-color)' }}
  //                         >
  //                           <InfoCircleOutlined
  //                             style={{ marginRight: '5px' }}
  //                           />
  //                         </Tooltip>
  //                       </ConfigProvider>
  //                     )}
  //                   </div>
  //                   <span
  //                     className="cursor-pointer"
  //                     onClick={() => redirect(record)}
  //                   >
  //                     {getNameString(text)}
  //                   </span>
  //                 </>
  //               ) : (
  //                 <div style={{ color: '#f00', fontWeight: 500 }}>
  //                   <ConfigProvider
  //                     theme={{ token: { colorTextLightSolid: '#000' } }}
  //                   >
  //                     <Tooltip
  //                       title={
  //                         record?.processingFlags?.isLicenseProcessing &&
  //                         record?.processingFlags?.isAssignmentProcessing
  //                           ? ' License(s) and Assignment(s) are currently being processed'
  //                           : record?.processingFlags?.isLicenseProcessing
  //                           ? ' License(s) currently being processed'
  //                           : record?.processingFlags?.isAssignmentProcessing
  //                           ? ' Assignment(s) currently being processed'
  //                           : ''
  //                       }
  //                       color="#fff"
  //                       key="#fff"
  //                       style={{ color: 'var(--secondary-color)' }}
  //                     >
  //                       <InfoCircleOutlined style={{ marginRight: '5px' }} />
  //                     </Tooltip>
  //                   </ConfigProvider>
  //                   <span
  //                     className="cursor-pointer"
  //                     onClick={() => redirect(record)}
  //                   >
  //                     {getNameString(text)}
  //                   </span>
  //                 </div>
  //               )}
  //             </Typography.Paragraph>
  //           </Row>
  //           <Row>
  //             <Typography.Paragraph
  //               style={{
  //                 fontWeight: 500,
  //                 fontSize: '10px',
  //                 marginBottom: '0.5em',
  //               }}
  //             >
  //               NPN: {record.npn}
  //             </Typography.Paragraph>
  //           </Row>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     title: 'Initial Payment Account',
  //     dataIndex: 'paymentConfig',
  //     key: 'paymentConfig.initialPaymentPayee',
  //     width: '190px',
  //     sorter: false,
  //     render: (text: any, record: AgentOverview, index: number) => {
  //       return (
  //         <Row
  //           id={
  //             IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
  //             record.id +
  //             '-line-only-agents'
  //           }
  //           style={{
  //             justifyContent: 'flex-start',
  //             cursor: 'pointer',
  //           }}
  //         >
  //           <img
  //             src={penIcon}
  //             style={{
  //               marginRight: 10,
  //               cursor: 'pointer',
  //             }}
  //             alt="pen"
  //             onClick={() => {
  //               if (selectedAgents.length > 0) {
  //                 handleBulkChanges();
  //               } else {
  //                 handleIndividualChanges(record.id);
  //               }
  //               setPaymentConfigModalVisibility(true);
  //             }}
  //           />
  //           {record.paymentConfig?.initialPaymentPayee ? (
  //             getProducerName(record.paymentConfig?.initialPaymentPayee)
  //           ) : (
  //             <span style={{ color: '#92A69E', fontSize: '12px' }}>
  //               Select Payee
  //             </span>
  //           )}
  //         </Row>
  //       );
  //     },
  //     shouldCellUpdate: () => true,
  //     onCell: (record: AgentOverview, index) => {
  //       const onCellObject = {
  //         onMouseEnter: () => {
  //           handleMouseEnter(index || 0, ColumnName.INITIAL_PAYMENT);
  //         },
  //         onMouseLeave: () => {
  //           handleMouseLeave(index || 0, ColumnName.INITIAL_PAYMENT);
  //         },
  //         onClick: () => {},
  //       };
  //       if (
  //         selectedAgents.find((agent) => agent.id === record.id) ||
  //         (selectedAgents.length === 0 &&
  //           isHoveringInitialPayment[index as number])
  //       )
  //         onCellObject.onClick = () => {
  //           if (selectedAgents.length > 0) {
  //             handleBulkChanges();
  //           } else {
  //             handleIndividualChanges(record.id);
  //           }
  //           setPaymentConfigModalVisibility(true);
  //         };
  //       return onCellObject;
  //     },
  //   },
  //   {
  //     title: 'Renewal Payment Account',
  //     dataIndex: 'paymentConfig.renewalPaymentPayee',
  //     key: 'paymentConfig.renewalPaymentPayee',
  //     width: '225px',
  //     sorter: false,
  //     render: (text: any, record: AgentOverview, index: number) => {
  //       return (
  //         <Row
  //           id={
  //             IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.RENEWAL_PAYMENT +
  //             record.id +
  //             '-line-only-agents'
  //           }
  //           style={{
  //             justifyContent: 'flex-start',
  //             cursor: 'pointer',
  //           }}
  //         >
  //           <img
  //             src={penIcon}
  //             style={{
  //               marginRight: 10,
  //             }}
  //             alt="pen"
  //             onClick={() => {
  //               if (selectedAgents.length > 0) {
  //                 handleBulkChanges();
  //               } else {
  //                 handleIndividualChanges(record.id);
  //               }
  //               setPaymentConfigModalVisibility(true);
  //             }}
  //           />
  //           {record.paymentConfig?.renewalPaymentPayee ? (
  //             getProducerName(record.paymentConfig?.renewalPaymentPayee)
  //           ) : (
  //             <span style={{ color: '#92A69E', fontSize: '12px' }}>
  //               Select Payee
  //             </span>
  //           )}
  //         </Row>
  //       );
  //     },
  //     shouldCellUpdate: () => true,
  //     onCell: (record: AgentOverview, index) => {
  //       const onCellObject = {
  //         onMouseEnter: () => {
  //           handleMouseEnter(index || 0, ColumnName.RENEWAL_PAYMENT);
  //         },
  //         onMouseLeave: () => {
  //           handleMouseLeave(index || 0, ColumnName.RENEWAL_PAYMENT);
  //         },
  //         onClick: () => {},
  //       };

  //       if (
  //         selectedAgents.find((agent) => agent.id === record.id) ||
  //         (selectedAgents.length === 0 &&
  //           isHoveringRenewalPayment[index as number])
  //       )
  //         onCellObject.onClick = () => {
  //           if (selectedAgents.length > 0) {
  //             handleBulkChanges();
  //           } else {
  //             handleIndividualChanges(record.id);
  //           }
  //           setPaymentConfigModalVisibility(true);
  //         };
  //       return onCellObject;
  //     },
  //   },
  //   {
  //     title: 'Auto Apply',
  //     dataIndex: 'autoRenewEnabled',
  //     key: 'autoRenewEnabled',
  //     width: '115px',
  //     render: (text: any, record: AgentOverview, index: any) => {
  //       return (
  //         <ConfigProvider
  //           theme={{
  //             token: {
  //               colorPrimary: '#0588ca',
  //             },
  //           }}
  //         >
  //           <TooltipWrapper
  //             isDisabled={
  //               record.paymentConfig?.renewalPaymentPayee !== 'Agency' ||
  //               !record.paymentConfig?.paymentType
  //                 ? true
  //                 : selectedAgents.length > 0
  //                 ? !(
  //                     isLoaRowClickable[index] &&
  //                     selectedAgents.length > 0 &&
  //                     selectedAgents.every(
  //                       (agent) =>
  //                         agent.paymentConfig?.renewalPaymentPayee ===
  //                           'Agency' && agent.paymentConfig?.paymentType
  //                     )
  //                   )
  //                 : !isLoaRowClickable[index]
  //             }
  //             message={
  //               !record.paymentConfig?.renewalPaymentPayee &&
  //               !record.paymentConfig?.paymentType
  //                 ? 'Auto Apply cannot be modified as Renewal Type and Payment Configuration are not set.'
  //                 : record.paymentConfig?.renewalPaymentPayee === 'Agent'
  //                 ? 'Auto Apply cannot be modified as Renewal Type is set to Producer'
  //                 : !record.paymentConfig?.paymentType
  //                 ? 'Auto Apply cannot be modified as Payment Configuration is not specified.'
  //                 : 'Auto Apply cannot be modified'
  //             }
  //           >
  //             <Switch
  //               id={
  //                 IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.AUTO_RENEWAL +
  //                 record.id +
  //                 '-line-only-agents'
  //               }
  //               checked={
  //                 record.paymentConfig?.isAutoRenewalActive
  //                   ? record.paymentConfig?.isAutoRenewalActive
  //                   : false
  //               }
  //               disabled={
  //                 record.paymentConfig?.renewalPaymentPayee !== 'Agency' ||
  //                 !record.paymentConfig?.paymentType
  //                   ? true
  //                   : selectedAgents.length > 0
  //                   ? !(
  //                       isLoaRowClickable[index] &&
  //                       selectedAgents.length > 0 &&
  //                       selectedAgents.every(
  //                         (agent) =>
  //                           agent.paymentConfig?.renewalPaymentPayee ===
  //                             'Agency' && agent.paymentConfig?.paymentType
  //                       )
  //                     )
  //                   : !isLoaRowClickable[index]
  //               }
  //               onClick={() => {
  //                 if (
  //                   record.paymentConfig?.renewalPaymentPayee !== 'Agent' &&
  //                   selectedAgents.every(
  //                     (agent) =>
  //                       agent.paymentConfig?.renewalPaymentPayee === 'Agency' &&
  //                       agent.paymentConfig?.paymentType
  //                   )
  //                 ) {
  //                   if (selectedAgents.length > 0) {
  //                     handleBulkChanges();
  //                   } else {
  //                     handleIndividualChanges(record.id);
  //                   }
  //                   setPaymentConfigModalVisibility(true);
  //                 }
  //               }}
  //             />
  //           </TooltipWrapper>
  //         </ConfigProvider>
  //       );
  //     },
  //     onCell: (record: AgentOverview, index) => {
  //       const onCellObject = {
  //         onMouseEnter: () => {
  //           handleMouseEnter(index || 0, ColumnName.AUTO_RENEWAL);
  //         },
  //         onMouseLeave: () => {
  //           handleMouseLeave(index || 0, ColumnName.AUTO_RENEWAL);
  //         },
  //         onClick: () => {},
  //       };

  //       if (
  //         record.paymentConfig?.renewalPaymentPayee !== 'Agent' &&
  //         record.paymentConfig?.paymentType &&
  //         selectedAgents.every((agent) => {
  //           if (agent.paymentConfig?.renewalPaymentPayee !== 'Agent') {
  //             return false;
  //           }
  //         })
  //       ) {
  //         if (
  //           selectedAgents.find((agent) => agent.id === record.id) ||
  //           (selectedAgents.length === 0 &&
  //             isHoveringAutoRenewal[index as number])
  //         )
  //           onCellObject.onClick = () => () => {
  //             if (selectedAgents.length > 0) {
  //               handleBulkChanges();
  //             } else {
  //               handleIndividualChanges(record.id);
  //             }
  //             setPaymentConfigModalVisibility(true);
  //           };
  //       }

  //       return onCellObject;
  //     },
  //   },
  //   {
  //     title: 'Expiring in 30 days',
  //     dataIndex: 'expiringLicenses',
  //     key: 'count30',
  //     width: '175px',
  //     sorter: false,

  //     render: (text: number, record: AgentOverview) => (
  //       <Row
  //         style={{ alignItems: 'center', justifyContent: 'center', gap: 24 }}
  //       >
  //         <div className="ml-auto">{text ? text : 0}</div>
  //         <Tooltip title="View Licenses">
  //           <Button
  //             size="middle"
  //             className="ml-auto"
  //             onClick={() => {
  //               navigate({
  //                 pathname: RouteConstants.agencyLicenses.path,
  //                 search: createSearchParams({
  //                   search: getNameString(record.name),
  //                 }).toString(),
  //               });
  //             }}
  //           >
  //             <EyeOutlined />
  //           </Button>
  //         </Tooltip>
  //       </Row>
  //     ),
  //   },
  // ];
  const OtherLineOnlyAgentsColumns: ColumnsType<AgentOverview> = [
    {
      title: 'Producer',
      dataIndex: 'name',
      key: 'name',
      width: '220px',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: string, record: AgentOverview) => (
        <div>
          <Row>
            <Typography.Paragraph
              style={{
                fontSize: '14px',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center',
                color: '#000000',
              }}
            >
              {(record?.processingFlags?.isLicenseProcessing ||
                record?.processingFlags?.isAssignmentProcessing ||
                !record?.paymentConfig?.initialPaymentPayee) && (
                <ConfigProvider
                  theme={{ token: { colorTextLightSolid: '#000' } }}
                >
                  <Tooltip
                    title={
                      !record?.paymentConfig?.initialPaymentPayee
                        ? 'Payments not configured.'
                        : record?.processingFlags?.isLicenseProcessing &&
                            record?.processingFlags?.isAssignmentProcessing
                          ? 'License(s) and Assignment(s) are currently being processed'
                          : record?.processingFlags?.isLicenseProcessing
                            ? 'License(s) currently being processed'
                            : 'Assignment(s) currently being processed'
                    }
                    color="#fff"
                    key="#fff"
                    style={{ color: 'var(--secondary-color)' }}
                  >
                    <InfoCircleOutlined style={{ marginRight: '5px' }} />
                  </Tooltip>
                </ConfigProvider>
              )}
              <span
                className="cursor-pointer"
                onClick={() => redirect(record)}
                style={{
                  color:
                    record?.paymentConfig?.initialPaymentPayee &&
                    record?.paymentConfig?.renewalPaymentPayee
                      ? 'var(--primary-color)'
                      : '#ff0000',
                }}
              >
                {getNameString(text)}
              </span>
            </Typography.Paragraph>
          </Row>
          <Row>
            {/* <Typography.Paragraph
                style={{
                  fontWeight: 700,
                  fontSize: '10px',
                  color: 'var(--secondary-color)',
                  marginRight: '10px',
                }}
              >
                LOA
              </Typography.Paragraph> */}
            <Typography.Paragraph
              style={{
                fontWeight: 500,
                fontSize: '10px',
              }}
            >
              NPN: {record.npn}
            </Typography.Paragraph>
          </Row>
          {/* <Row>
              <img
                src={agencyIcon}
                alt="agency"
                style={{
                  color: '#000',
                  width: '12px',
                  height: '12px',
                  marginRight: '7px',
                }}
              />
              <span
                style={{
                  fontWeight: 500,
                  fontSize: '10px',
                  marginTop: '-1.5px',
                }}
              >
                {record?.agencyId?.agencyName}
              </span>
            </Row> */}
        </div>
      ),
    },
    {
      title: 'Expiring in 30 days',
      dataIndex: 'expiringLicenses',
      key: 'expiryLicensesCount',
      width: '175px',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: number, record: AgentOverview) => (
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            gap: 24,
            marginRight: 12,
          }}
        >
          <div className="ml-auto">{text ? text : 0}</div>
          <Tooltip title="View Licenses">
            <Button
              // shape="circle"
              size="middle"
              className="ml-auto grid place-content-center"
              onClick={() => {
                navigate({
                  pathname: RouteConstants.agencyLicenses.path,
                  search: createSearchParams({
                    producerId: record.id,
                    producerName: getNameString(record.name),
                  }).toString(),
                });
              }}
              disabled={subscriptionStatusInactive}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        </Row>
      ),
    },
    {
      title: 'Initial Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseInitialPayee',
      width: '190px',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.INITIAL_PAYMENT +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
              marginLeft: 12,
            }}
          >
            {record.paymentConfig?.initialPaymentPayee ? (
              getProducerName(record.paymentConfig?.initialPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Payee not selected
              </span>
            )}
          </Row>
        );
      },
    },
    {
      title: 'Renewal Payment Account',
      dataIndex: 'paymentConfig',
      key: 'paymentConfig.licenseRenewalPayee',
      width: '225px',
      sorter: subscriptionStatusInactive ? false : true,
      render: (text: any, record: AgentOverview, index: number) => {
        return (
          <Row
            id={
              IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.RENEWAL_PAYMENT +
              record.id +
              '-other-line-only-agents'
            }
            style={{
              justifyContent: 'space-between',
            }}
          >
            {record.paymentConfig?.renewalPaymentPayee ? (
              getProducerName(record.paymentConfig?.renewalPaymentPayee)
            ) : (
              <span style={{ color: '#92A69E', fontSize: '12px' }}>
                Payee not selected
              </span>
            )}{' '}
          </Row>
        );
      },
    },
    // {
    //   title: 'Payment Type',
    //   dataIndex: 'paymentConfig',
    //   key: 'paymentConfig',
    //   width: '170px',
    //   render: (text: any, record: AgentOverview, index: number) => {
    //     return (
    //       <Row
    //         id={
    //           IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.PAYMENT_TYPE +
    //           record.id +
    //           '-other-line-only-agents'
    //         }
    //         style={{
    //           justifyContent: 'space-between',
    //         }}
    //       >
    //         <div>
    //           {record.paymentConfig?.paymentType === PaymentTypeEnum.NA ? (
    //             <span style={{ color: '#92A69E', fontSize: '12px' }}>N/A</span>
    //           ) : record.paymentConfig?.paymentType === PaymentTypeEnum.CAP ? (
    //             '$ ' + record.paymentConfig?.amountCap
    //           ) : record.paymentConfig?.paymentType === PaymentTypeEnum.FULL ? (
    //             record.paymentConfig?.paymentType
    //           ) : (
    //             <span style={{ color: '#92A69E', fontSize: '12px' }}>
    //               Payment Type not configured
    //             </span>
    //           )}
    //         </div>
    //       </Row>
    //     );
    //   },
    // },
    {
      title: 'Auto Apply',
      dataIndex: 'autoRenewEnabled',
      key: 'autoRenewEnabled',
      width: '110px',
      render: (text: any, record: AgentOverview, index: any) => {
        return (
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#0588ca',
              },
            }}
          >
            <Switch
              id={
                IdConstants.AGENTS.AGENT_OVERVIEW.TABLE.AUTO_RENEWAL +
                record.id +
                '-other-line-only-agents'
              }
              checked={
                record.paymentConfig?.isAutoRenewalActive
                  ? record.paymentConfig?.isAutoRenewalActive
                  : false
              }
              disabled
            />
          </ConfigProvider>
        );
      },
    },
  ];

  const actionableAgentColumns = [
    {
      title: (
        <>
          {!isViewActionableData ? (
            <span className="text-sm">
              Showing Producers in{' '}
              {selectedState ? StateConstants[selectedState] : 'All States'}
            </span>
          ) : (
            <span className="text-sm">
              Showing Producers Who Need Attention in{' '}
              {selectedState ? StateConstants[selectedState] : 'All States'}
            </span>
          )}
        </>
      ),
      dataIndex: 'name',
      key: 'missingLicensesCount',
      sorter: subscriptionStatusInactive ? false : true,
      width: 220,
      render: (text: string, record: any) => {
        return (
          <div>
            <Row>
              <Typography.Paragraph
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#000000',
                  marginBottom: '0.5em',
                }}
              >
                {record.paymentConfig?.licenseInitialPayee &&
                record.paymentConfig?.licenseRenewalPayee &&
                record.paymentConfig?.paymentType ? (
                  <span
                    className="cursor-pointer"
                    onClick={() => redirect(record)}
                    style={{ color: 'var(--primary-color)' }}
                  >
                    {getNameString(text)}
                  </span>
                ) : (
                  <div style={{ color: '#f00', fontWeight: 500 }}>
                    <ConfigProvider
                      theme={{ token: { colorTextLightSolid: '#000' } }}
                    >
                      <Tooltip
                        title="Payments not configured"
                        color="#fff"
                        key="#fff"
                        style={{ color: '#f00' }}
                      >
                        <InfoCircleOutlined style={{ marginRight: '5px' }} />
                      </Tooltip>
                    </ConfigProvider>
                    <span
                      className="cursor-pointer"
                      onClick={() => redirect(record)}
                    >
                      {getNameString(text)}
                    </span>
                  </div>
                )}
              </Typography.Paragraph>
            </Row>
            <Row>
              <Typography.Paragraph
                style={{
                  fontWeight: 500,
                  fontSize: '12px',
                  marginBottom: '0.5em',
                }}
              >
                NPN: {record.npn}
              </Typography.Paragraph>
            </Row>

            <Row className="reset-icon-size">
              <Typography.Paragraph
                style={{
                  fontWeight: 500,
                  fontSize: '12px',
                  textAlign: 'center',
                  color: record?.missingLicensesCount !== 0 ? '#f00' : '',
                }}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(
                      RouteConstants.agencyLicenses.path +
                        `?overview=true&quickFilter=${ProducerLicenseType.NEEDS_ATTENTION}&producer=${getNameString(
                          record.name
                        )}&id=${record.id}`
                    )
                  }
                >
                  Missing Licenses:{' '}
                  <span
                    style={{
                      marginLeft: 3,
                      cursor: 'pointer',
                      textAlign: 'center',
                    }}
                  >
                    {' '}
                    <span style={{ fontSize: 12 }}>
                      {' '}
                      {record?.missingLicensesCount}
                    </span>
                  </span>
                </span>
              </Typography.Paragraph>
            </Row>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            gap: 12,
            justifyContent: 'flex-end',
            width: '100%',
            display: 'flex',
          }}
        >
          <Tooltip
            title={isRefreshing === true ? 'Refreshing...' : 'Refresh Table'}
          >
            <ReloadOutlined
              style={{ fontSize: 14, color: '#0588ca' }}
              onClick={() => {
                if (!subscriptionStatusInactive)
                  getActionableAgents(
                    selectedState,
                    isViewActionableData,
                    true
                  );
              }}
              className="cursor-pointer primary-color"
            />
          </Tooltip>
        </div>
      ),
      width: 140,
      render: (record: any) => {
        return (
          <Col
            style={{
              alignItems: 'center',
              rowGap: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ActionableEngineLights
              record={record}
              selectedState={selectedState.length ? selectedState : 'ALL'}
              setPayerCallBack={() => {
                setCurrentAgents([record]);
                setPaymentConfigModalVisibility(true);
              }}
            />
          </Col>
        );
      },
    },
  ];

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const pageName = PlausiblePageConstants.AGENCY_OVERVIEW;
  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);
  const ctaEnabled = !statLoading && !subscriptionStatusInactive;

  const cardStyle = {
    flex: 'auto',
    width: '100%',
    cursor: ctaEnabled ? 'pointer' : 'default',
  };

  return (
    <div className="block w-full">
      <Row gutter={8}>
        <Col span={24}>
          <Row gutter={[8, 8]} className="statistic-card">
            <Col className="w-full" xl={6} lg={12} md={12} sm={12}>
              <Card
                style={{
                  ...cardStyle,
                }}
                onClick={() => {
                  if (ctaEnabled) {
                    lineOnlyProducersRef?.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }}
              >
                {statLoading ? (
                  <Skeleton.Input size="small" />
                ) : (
                  <Statistic
                    title={
                      <div className="flex justify-between">
                        <Typography.Paragraph
                          style={{ fontSize: '16px', marginBottom: '0' }}
                        >
                          Number of Producers{' '}
                        </Typography.Paragraph>
                        <Tooltip title="Total number of producers under this agency.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    }
                    formatter={(value) => {
                      return (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span style={{ fontSize: '24px' }}>
                            {statData?.totalAgents}
                          </span>
                        </div>
                      );
                    }}
                  />
                )}
              </Card>
            </Col>
            <Col className="w-full" xl={6} lg={12} md={12} sm={12}>
              <Card
                style={{
                  ...cardStyle,
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  if (ctaEnabled) {
                    navigate(
                      RouteConstants.agencyLicenses.path +
                        `?quickFilter=${ProducerLicenseType.ACTIVE}`
                    );
                  }
                }}
              >
                {statLoading ? (
                  <Skeleton.Input size="small" />
                ) : (
                  <Statistic
                    title={
                      <>
                        <div className="flex justify-between">
                          <Typography.Paragraph
                            style={{ fontSize: '16px', marginBottom: '0px' }}
                          >
                            Number of Active Producer Licenses
                          </Typography.Paragraph>

                          <Tooltip title="Total number of active licenses of all producers.">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                      </>
                    }
                    formatter={(value) => {
                      return (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span style={{ fontSize: '24px' }}>
                            {statData?.activeAgentLicenseCount || 0}
                          </span>
                        </div>
                      );
                    }}
                  />
                )}
              </Card>
            </Col>
            <Col className="w-full" xl={6} lg={12} md={12} sm={12}>
              <Card
                style={{
                  ...cardStyle,
                }}
                onClick={(e) => {
                  e.stopPropagation();

                  if (ctaEnabled) {
                    navigate(
                      RouteConstants.licensesAgency.path +
                        `?quickFilter=${AgencyLicenseType.ACTIVE}`
                    );
                  }
                }}
              >
                {statLoading ? (
                  <Skeleton.Input size="small" />
                ) : (
                  <Statistic
                    title={
                      <>
                        <div className="flex justify-between">
                          <Typography.Paragraph
                            style={{ fontSize: '16px', marginBottom: '0px' }}
                          >
                            Number of Active Agency Licenses
                          </Typography.Paragraph>

                          <Tooltip title="Total number of active licenses of this agency.">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                      </>
                    }
                    formatter={(value) => {
                      return (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span style={{ fontSize: '24px' }}>
                            {statData?.activeAgencyLicenseCount || 0}
                          </span>
                        </div>
                      );
                    }}
                  />
                )}
              </Card>
            </Col>

            <Col className="w-full" xl={6} lg={12} md={12} sm={12}>
              <Card
                style={{
                  flex: 'auto',
                  width: '100%',
                }}
              >
                {statLoading ? (
                  <Skeleton.Input size="small" />
                ) : (
                  <Statistic
                    style={{ margin: '0px' }}
                    title={
                      <>
                        <div className="flex justify-between">
                          <Typography.Paragraph
                            style={{ fontSize: '16px', marginBottom: '0px' }}
                          >
                            Forecasted Debits
                          </Typography.Paragraph>

                          <Tooltip title="Get forecasted debits for next 45 days.">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                      </>
                    }
                    formatter={(value) => {
                      return (
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span style={{ fontSize: '24px' }}>
                            $
                            {' ' +
                              (costFormatter(statData?.forcastedDebits) || 0)}
                          </span>
                        </div>
                      );
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginBottom: 20, marginTop: 20 }}>
        <Col xl={16} lg={24} md={24} sm={24}>
          <Card
            bodyStyle={{
              height: '100%',
              width: '100%',
            }}
            style={{ height: '100%', width: '100%' }}
            className="agency-chart"
          >
            <AgencyAssignmentOverview
              selectedState={selectedState}
              isUpdated={false}
              agencyId={agencyId}
              isViewActionableData={isViewActionableData}
              setSelectedState={setSelectedState}
              setIsViewActionableData={setIsViewActionableData}
            />
          </Card>
        </Col>
        <Col xl={8} lg={24} md={24} sm={24}>
          <Card className="agent-actionable-table min-h-full">
            {isAgentTableLoading ? (
              <Skeleton />
            ) : (
              <>
                <Table
                  columns={actionableAgentColumns}
                  dataSource={actionableAgentData}
                  loading={isAgentOverviewFetching || isAgentTableLoading}
                  style={{ margin: '6px 0' }}
                  onChange={(pagination, filters, sorter) => {
                    const sort = applySort(sorter, fetchAgentActionableDetails);
                    setActionableSortValue(sort);
                  }}
                  pagination={false}
                  locale={{
                    emptyText() {
                      return (
                        <div className="flex items-center justify-center h-[400px]">
                          {isAgentOverviewFetching ? null : (
                            <EmptyState
                              heading="No Producers"
                              image="empty data"
                              content=""
                            />
                          )}
                        </div>
                      );
                    },
                  }}
                  scroll={{
                    y: 480,
                  }}
                />
                <Row
                  justify="end"
                  style={{ paddingTop: 24, paddingBottom: 24 }}
                >
                  <Pagination
                    size="small"
                    total={actionableAgentTotalCount}
                    pageSize={actionablePageSize}
                    current={actionablePageNumber}
                    showSizeChanger={false}
                    onChange={onActionablePageChange}
                  />
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Card
        style={{ marginTop: 10 }}
        ref={lineOnlyProducersRef}
        className="bottom-remove-padding"
      >
        <Row>
          <Typography.Paragraph
            style={{
              color: 'var(--primary-color)',
              marginBottom: '10px',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            <span>License Only Agents</span>
          </Typography.Paragraph>
        </Row>
        <div className="search flex mb-[10px] gap-x-[10px]">
          <AutoComplete
            options={options}
            style={{ width: '100%' }}
            disabled={
              isSearchLoading || isLoading || subscriptionStatusInactive
            }
            value={getSearchDisplayText(
              searchType,
              displaySearchType,
              searchText
            )}
            onSelect={handleOnSelect}
            onChange={handleOnChange}
          >
            <Search
              id={IdConstants.SEARCH_INPUT + '-loa'}
              placeholder="Search by Producer or NPN"
              size="middle"
              style={{
                width: '100%',
              }}
              onSearch={handleOnSearch}
            />
          </AutoComplete>
          <Button
            id={IdConstants.FILTERS_BUTTON}
            loading={filterInfoLoading}
            icon={<FilterOutlined />}
            style={{
              background: 'var(--hint-primary-color)',
              color: 'white',
            }}
            onClick={() => {
              setIsFilterVisible(true);
              trackCustomEvent(
                PlausibleEventNameConstants.FILTER_OPTION,
                pageName
              );
            }}
            disabled={
              isDisableFilter(lineOnlyAgents, requestBody) ||
              filterInfoLoading ||
              isLoading ||
              subscriptionStatusInactive
            }
          >
            Filters
          </Button>
        </div>
        <div className="mt-[10px]">
          <RenderFilterChip
            {...{ selectedFilters, removeFilter, clearFilters, pageName }}
          />
        </div>
        <Row
          style={{
            marginTop: '10px',
          }}
          justify="space-between"
        >
          {paymentUnconfiguredCount && paymentUnconfiguredCount > 0 ? (
            <>
              <Typography.Paragraph
                className="reset-icon-size"
                style={{
                  color: 'var(--secondary-color)',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                <Tooltip
                  title={`The payment setup for  ${paymentUnconfiguredCount} ${
                    paymentUnconfiguredCount > 1 ? 'producers' : 'producer'
                  } is not complete. Please proceed with configuring their ${
                    paymentUnconfiguredCount > 1 ? 'payments' : 'payment'
                  }`}
                >
                  <InfoCircleOutlined
                    style={{ color: '#f00', marginRight: '5px' }}
                  />
                </Tooltip>
                Payments for {paymentUnconfiguredCount}{' '}
                {paymentUnconfiguredCount > 1
                  ? 'producers are '
                  : 'producer is '}
                not configured.
                {!displayPaymentConfigNullAgents && (
                  <Button
                    disabled={isLoading}
                    id={IdConstants.AGENTS.AGENT_OVERVIEW.VIEW_AGENTS}
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      fontWeight: 400,
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onClick={() => {
                      setPageNumber(1);
                      setDisplayPaymentConfigNullAgents(true);
                      setSearchText('');
                      setPrevSearchText('');
                      setIsLoading(true);
                      setSortValue({});
                      loadAgents(
                        agencyId,
                        {
                          page: 1,
                          size: pageSize,
                          paymentConfig: true,
                          searchTextProp: '',
                        },
                        {}
                      );
                    }}
                  >
                    View Producer{paymentUnconfiguredCount > 1 ? 's' : ''}{' '}
                    <RightOutlined style={{ color: '#f00' }} />
                  </Button>
                )}
              </Typography.Paragraph>

              {displayPaymentConfigNullAgents && (
                <Typography.Paragraph
                  style={{
                    color: 'var(--secondary-color)',
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                >
                  <Button
                    disabled={isLoading || subscriptionStatusInactive}
                    style={{}}
                    size="middle"
                    onClick={() => {
                      setSearchText('');
                      if (agencyId) {
                        loadAgents(
                          agencyId,
                          {
                            page: 1,
                            size: pageSize,
                            searchTextProp: '',
                          },
                          {}
                        );
                      }
                      setIsLoading(true);
                      setSortValue({});
                      setPageNumber(1);
                      setDisplayPaymentConfigNullAgents(false);
                    }}
                  >
                    Show All Producers
                  </Button>
                </Typography.Paragraph>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <div className="table-layout-v2 relative flex flex-col gap-y-[10px] mb-[15px]">
              <Table
                style={{ width: '100%' }}
                onChange={(pagination, filters, sorter) => {
                  const sort = applySort(sorter, (_, __, ___, sort) => {
                    loadAgents(
                      agencyId,
                      { paymentConfig: displayPaymentConfigNullAgents },
                      sort
                    );
                  });
                  setSortValue(sort);
                }}
                rowSelection={
                  subscriptionStatusInactive
                    ? undefined
                    : {
                        type: 'checkbox',
                        onChange(selectedRowKeys, selectedRows, info) {
                          setSelectedAgents(selectedRows);
                          if (displayPaymentConfigNullAgents) {
                            const rowClickable = [...isLoaRowClickable];
                            forEach(unconfiguredPaymentAgents, (a, index) => {
                              if (selectedRows.length > 0) {
                                if (
                                  selectedRows.findIndex(
                                    (r) => r.id === a.id
                                  ) >= 0
                                )
                                  rowClickable[index] = true;
                                else rowClickable[index] = false;
                              } else rowClickable[index] = true;
                            });
                            setLoaRowClickable(rowClickable);
                          } else {
                            const rowClickable = [...isLoaRowClickable];
                            forEach(lineOnlyAgents, (a, index) => {
                              if (selectedRows.length > 0) {
                                if (
                                  selectedRows.findIndex(
                                    (r) => r.id === a.id
                                  ) >= 0
                                )
                                  rowClickable[index] = true;
                                else rowClickable[index] = false;
                              } else rowClickable[index] = true;
                            });
                            setLoaRowClickable(rowClickable);
                          }
                        },
                        selectedRowKeys: selectedAgents.map((sa) => sa.id),
                      }
                }
                rowKey={(record) => {
                  return record.id + record.agencyName;
                }}
                locale={{
                  emptyText() {
                    return isLoading ? (
                      <Spin />
                    ) : lineOnlyAgents &&
                      adminStore.agency &&
                      adminStore.agency?.id &&
                      lineOnlyAgents[0]?.agencyId &&
                      lineOnlyAgents[0].agencyId?.id ===
                        adminStore.agency?.id &&
                      true ? (
                      <div
                        style={{
                          textAlign: 'center',
                          marginLeft: '20%',
                          marginTop: '5%',
                        }}
                      >
                        <EmptyState
                          heading="No license only agents to show yet"
                          route={
                            RouteConstants.onboardProducer.path +
                            '?type=individual'
                          }
                          button
                          buttonText="Producer Onboarding"
                          content={
                            errorMessage
                              ? errorMessage
                              : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                          }
                        />
                      </div>
                    ) : (
                      <div className="text-center ml-[20%] mt-[5%]">
                        <EmptyState
                          image="empty data"
                          heading="No license only agents to show yet"
                          content={
                            errorMessage
                              ? errorMessage
                              : "You're all set to get started with managing your producers. It looks like you're starting with a clean slate."
                          }
                        />
                      </div>
                    );
                  },
                }}
                columns={OtherLineOnlyAgentsColumns}
                dataSource={
                  displayPaymentConfigNullAgents
                    ? unconfiguredPaymentAgents
                    : lineOnlyAgents
                }
                loading={isSearchLoading || isLoading}
                scroll={{ y: 400 }}
                pagination={false}
              />
              <Row justify="end" style={{ marginTop: 24 }}>
                <Pagination
                  total={
                    displayPaymentConfigNullAgents
                      ? paymentUnconfiguredCount
                      : lineOnlyCount
                  }
                  pageSize={pageSize}
                  current={pageNumber}
                  showSizeChanger={false}
                  onChange={onChange}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              </Row>
            </div>

            <PaymentConfigModal
              agents={currentAgents ? currentAgents : undefined}
              visible={paymentConfigModalVisibility}
              handleVisibility={() => {
                setPaymentConfigModalVisibility(false);
                setCurrentAgents([]);
              }}
              successCallback={() => {
                if (adminStore.agency?.id) {
                  fetchAgentActionableDetails();
                }
                loadAgents(agencyId, {
                  paymentConfig: displayPaymentConfigNullAgents,
                });
              }}
            />
            <SimpleFilter
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              setApplyFilter={() => {}}
              setRequestBody={setRequestBody}
              requestBody={requestBody}
              filterLoader={filterInfoLoading}
              visibility={isFilterVisible}
              setVisibility={() => {
                setIsFilterVisible(false);
              }}
              fetchFilters={() => {
                return new Promise((resolve) =>
                  resolve([] as FilterFieldsMetaData[])
                );
              }}
              controlledFilters={controlledFilters}
              clearFilters={() => {
                clearFilters();
              }}
              pageName={pageName}
              filterPage={PageConstants.HOME}
            />
          </>
        )}
      </Card>
    </div>
  );
}

export default withRoles(
  observer(AgencyAgentOverview),
  [RoleType.ADMIN, RoleType.SUPER_ADMIN],
  true
);
