import {
  AgencyStateAssignment,
  AgencyStateAssignmentDto,
  dropDownTYpe,
} from './agencyLicensingInterfaces.type';
import { Button, Layout, Spin, message } from 'antd';
import { StateConstants, states } from '../../constants/state.constants';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../services/agency.service';
import { ApplicantType } from '../../enums/applicantType.enum';
import CustomModal from './customModal';
import CustomSteps from '../common/steps/customStepper';
import { LeftOutlined } from '@ant-design/icons';
import LicenseSelection from './licenseSelection';
import ReviewSectionLicenseClassAndLoa from './reviewSection';
import StateDisplay from './stateDisplay';
import { UserType } from '../../enums/userType.enum';
import { agencyProfileStore } from '../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { auth0Store } from '../../stores/auth0Store';
import insureTrekLogo from '../../assets/images/insureTrekLogo.png';
import siderImage from '../../assets/images/agencyLicensingSidebar.png';
import { signupStore } from '../../stores/signupStore';
import { useAuth } from '../../auth/authProvider';
import { useNavigate } from 'react-router';

const { Content, Sider, Footer } = Layout;

const updateIsOnboardedField = (data: any, producerDetails: any) => {
  const producerNpn = producerDetails?.existingProducerDetails?.npn;
  const producerName = producerDetails?.existingProducerDetails?.name;
  for (const stateCode in data) {
    if (Object.prototype.hasOwnProperty.call(data, stateCode)) {
      const stateObj = data[stateCode];
      for (const key in stateObj) {
        if (Object.prototype.hasOwnProperty.call(stateObj, key)) {
          stateObj[key].forEach((doc: any) => {
            if (
              producerNpn &&
              (doc?.npn === producerNpn || doc?.drlpNpn === producerNpn)
            ) {
              if (!doc.drlpFlags) {
                doc.drlpFlags = {};
              }

              doc.drlpFlags.isOnboarded = true;
              doc.isOnboarded = true;
              if (!doc.name && producerName) {
                doc.name = producerName;
              }
            }
          });
        }
      }
    }
  }

  return data;
};

const filterNonNullAndUndefined = (
  obj: Record<string, any> = {},
  removedConfigIds: any[] = [] //for unassigning all loas when state is unchecked
): Record<string, any> => {
  return Object.fromEntries([
    ...Object.entries(obj)
      .map(([key, value]) => {
        const removedState = removedConfigIds.find(({ stateCode }) => {
          return StateConstants[stateCode] === key;
        });

        return [
          key,
          removedState
            ? {
                stateCode: key,
                filteredAgencyLicenseClassConfigs: Object.entries(
                  removedState.data || {}
                ).map(([licenseClassCode, configIds]) => ({
                  licenseClassCode,
                  licenseConfigIds: [],
                  removedConfigIds: configIds,
                  drlpIds: [],
                })),
              }
            : {
                ...(value || {}),
                filteredAgencyLicenseClassConfigs: value
                  ?.filteredAgencyLicenseClassConfigs?.length
                  ? value.filteredAgencyLicenseClassConfigs.filter(
                      (data: any) =>
                        (data?.licenseConfigIds &&
                          data?.licenseConfigIds.length > 0) ||
                        (data?.removedConfigIds &&
                          data?.removedConfigIds.length > 0)
                    )
                  : [],
              },
        ];
      })
      .filter(([_, value]) => {
        return value?.filteredAgencyLicenseClassConfigs?.filter(
          (data: any) =>
            data?.licenseConfigIds || data?.removedConfigIds.length !== 0
        )?.length;
      }),

    ...(
      (removedConfigIds || []).filter(({ stateCode }) => !obj[stateCode]) || []
    ).map(({ stateCode, data }) => [
      StateConstants[stateCode],
      {
        stateCode,
        filteredAgencyLicenseClassConfigs: Object.entries(data || {}).map(
          ([licenseClassCode, configIds]) => ({
            licenseClassCode,
            licenseConfigIds: null,
            removedConfigIds: configIds,
            drlpIds: [],
          })
        ),
      },
    ]),
  ]);
};

const stepInfo = [
  {
    steps: 'Select States',
    key: 0,
  },
  {
    steps: "Select License Class and LOA's",
    key: 1,
  },
  {
    steps: 'Review Selection',
    key: 2,
  },
];
const steps = stepInfo?.map((info) => info.steps);

const AgencyLicensingLayout = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [responseData, setResponseData] = useState<any | null>(null);
  const [selectedIds, setSelectedIds] = useState<
    Record<string, Record<string, string[]>>
  >({});
  const [prefilledStates, setPrefilledStates] = useState<string[]>([]);
  const [prefilledLoaIds, setPrefilledLoaId] = useState<string[]>([]);
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState(false);
  const [agencyStateAssignment, setAgencyStateAssignment] =
    useState<AgencyStateAssignment>({});
  const [dropdownOptions, setDropdownOptions] = useState<dropDownTYpe>({});
  const [addedLoaIds, setAddedLoaIds] = useState<string[]>([]);
  const [removedLoaIds, setRemovedLoaIds] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [sourceDropdowns, setSourceDropDownOptions] = useState<any>({});
  const [stateCount, setStateCount] = useState<number>(0);
  const [prefilledSelectedIds, setPrefilledSelectedIds] = useState<
    Record<string, Record<string, string[]>>
  >({});

  const handleStepClick = (step: number) => {
    if (!Object.entries(agencyStateAssignmentFin || {}).length && step === 2)
      return;
    setCurrentStep(step);
  };

  const handleFinish = async () => {
    const tempUpdatedData = renameFilteredAgencyLicenseClassConfigs();
    const updateAgencyStateAssignmentDTOs = addAgencyLicenseId(tempUpdatedData);
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      await AgencyService.manageAgencyStateAssignment(token, {
        updateAgencyStateAssignmentDTOs,
      });
      setStateCount(Object.keys(updateAgencyStateAssignmentDTOs || {})?.length);
      setIsModalVisible(true);
    } catch (err) {
      message.error('An error occurred while fetching data.');
    } finally {
      setIsLoading(false);
    }
  };
  const handleNext = () => {
    const nextStep = currentStep + 1;
    if (nextStep < steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const handlePrevious = () => {
    if (currentStep === 0) {
      navigate(-1);
      return;
    }
    const prevStep = currentStep - 1;
    if (prevStep >= 0) {
      setCurrentStep(prevStep);
    }
  };

  const showModal = () => {
    handleFinish();
  };

  const closeModal = () => {
    navigate(-1);
  };

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      const result = await AgencyService.getAgencyStateAssignments(token);
      const record: any = {};
      result.forEach((config: any) => {
        const drlpIds: any = {};
        config?.agencyLicenseClassConfigs?.forEach(
          (data: any, index: number) => {
            drlpIds[data?.licenseConfigIds?.[0]] = data?.drlpIds?.map(
              (data: any) => {
                return {
                  ...data,
                  ...(data?.drlpFetchedData || {}),
                  ...(data?.drlpProducer || {}),
                  id: data._id,
                  selected: true,
                  isPrefilled: true,
                  isOnboarded: data?.drlpFlags?.isOnboarded,
                };
              }
            );
            record[config.stateCode] = drlpIds;
          }
        );
      });
      setSourceDropDownOptions(record);
      setResponseData(result);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const extractLicenseConfigIds = (
    responseData: AgencyStateAssignmentDto[]
  ): string[] => {
    const licenseConfigIds: string[] = [];

    responseData?.forEach((item) => {
      item.agencyLicenseClassConfigs?.forEach((config) => {
        if (config.agencyLicenseId.length > 0)
          config.licenseConfigIds?.forEach((id) => {
            licenseConfigIds.push(id);
          });
      });
    });

    return licenseConfigIds;
  };

  function frameLcLoaCombination(masterData: any, prefilledLoaIds: string[]) {
    const result: Record<string, Record<string, string[]>> = {};

    for (const state of masterData) {
      const stateCode = state.stateCode;

      for (const licenseClass of state.licenseClasses) {
        const lcCode = licenseClass.lcCode;
        const matchingIds = licenseClass.loaData
          .filter((loa: any) => prefilledLoaIds.includes(loa.id))
          .map((loa: any) => loa.id);

        if (matchingIds.length > 0) {
          if (!result[stateCode]) {
            result[stateCode] = {};
          }
          result[stateCode][lcCode] = matchingIds;
        }
      }
    }

    return result;
  }

  const extractUniqueStateCodes = (
    data: AgencyStateAssignmentDto[]
  ): string[] => {
    const stateCodesSet: Set<string> = new Set();

    data?.forEach((item) => {
      item.agencyLicenseClassConfigs?.forEach((config) => {
        if (
          config.agencyLicenseId.length > 0 &&
          config.licenseConfigIds.length > 0
        ) {
          stateCodesSet.add(item.stateCode); // Add state code to the set
        }
      });
    });

    return Array.from(stateCodesSet); // Convert set to array
  };

  const findStateNames = (codes: string[]): string[] => {
    return codes
      ?.map((code) => {
        const state = states.find((state) => state.code === code);
        return state ? state.name : '';
      })
      .filter((name) => name !== ''); // Filter out any empty strings
  };

  useEffect(() => {
    const initializeStores = async () => {
      const token = await getAccessTokenSilently();

      await appStateInfoStore.initializeStore(token);

      if (
        signupStore.userType === UserType.ADMIN &&
        !agencyProfileStore.agencyDetails?.homeState
      )
        await agencyProfileStore.loadAgencyDetails(token);

      // inviteStore.loadInvites(token).then((response) => {
      //   if (response) {
      //     if (response.data.length > 0) {
      //       navigate(RouteConstants.invites.path);
      //     }
      //   }
      // });
    };

    if (auth0Store.getProfilesLoaded()) {
      initializeStores();
      fetchData();
    }
  }, [auth0Store.getProfilesLoaded()]);
  const masterData = [
    ...appStateInfoStore.getLicenseConfigsMasterData(ApplicantType.FIRM),
  ];
  useEffect(() => {
    const prefilledStateCodes = extractUniqueStateCodes(responseData);
    const prefilledStates = findStateNames(prefilledStateCodes);
    const prefilledLoaIds = extractLicenseConfigIds(responseData);
    setPrefilledLoaId(prefilledLoaIds);
    setPrefilledStates(prefilledStates);
    setSelectedStates(prefilledStates);
  }, [responseData]);

  const [unassignedStateConfig, setUnassignedStateConfig] = useState<any>();
  useEffect(() => {
    const unassignedStates = prefilledStates.filter(
      (state) => !selectedStates.includes(state)
    );
    const unassignedStateCodes = Object.entries(StateConstants)
      .filter(([code, name]) => unassignedStates.includes(name))
      .map(([code]) => code);

    const prefilledLcLoaCombinationData = frameLcLoaCombination(
      masterData,
      prefilledLoaIds
    );
    setSelectedIds(prefilledLcLoaCombinationData);
    setUnassignedStateConfig(
      unassignedStateCodes
        .filter((stateCode) => prefilledLcLoaCombinationData[stateCode])
        .map((stateCode) => ({
          stateCode,
          data: prefilledLcLoaCombinationData[stateCode],
        }))
    );
  }, [responseData, selectedStates]);

  const agencyStateAssignmentFin = filterNonNullAndUndefined(
    agencyStateAssignment,
    unassignedStateConfig
  );

  const renameFilteredAgencyLicenseClassConfigs = () => {
    const data = Object.values(agencyStateAssignmentFin);
    return data?.map((item) => {
      const { filteredAgencyLicenseClassConfigs, ...rest } = item;
      return {
        ...rest,
        agencyLicenseConfigs: filteredAgencyLicenseClassConfigs,
      };
    });
  };

  const addAgencyLicenseId = (data: any[]) => {
    return data?.map((item) => {
      const prevSelectedLcConfigs = responseData.filter(
        (d: any) => d.stateCode === item.stateCode
      )?.[0];
      const updatedConfigs = item.agencyLicenseConfigs?.map(
        (config: any, index: number) => {
          return {
            ...config,
            licenseConfigIds: [
              ...(config.licenseConfigIds || []),
              ...(
                prevSelectedLcConfigs?.agencyLicenseClassConfigs?.[index]
                  ?.licenseConfigIds || []
              ).filter(
                (id: any) => !(config.removedConfigIds || []).includes(id)
              ),
            ],

            drlpIds: dropdownOptions?.[item.stateCode]?.[
              config?.licenseClassCode
            ]
              ?.filter((data) => data.isPrefilled && data.selected) // prefilled need only ids
              ?.map((data) => data.id),
            createDrlpDtos:
              dropdownOptions?.[item.stateCode]?.[config?.licenseClassCode]
                ?.filter((data) => data.selected && !data.isPrefilled)
                ?.map((data) => {
                  if (data.isNewlyOnboardedAgent) {
                    // newly onboard agent dto
                    return {
                      agencyId: responseData?.[0]?.id, // agencyId coming from agency-state-assignments
                      ...data,
                      npn: data.npn || data.drlpNpn,
                      stateCode: item?.stateCode,
                      drlpFetchedData: {
                        drlpNpn: data.npn || data.drlpNpn,
                        drlpLicenseNumber: data.drlpLicenseNumber,
                        drlpLicenseId: data.drlpLicenseId,
                        drlpPdbLicenseClass: data.drlpPdbLicenseClass,
                        firmNpn: data.firmNpn,
                        firmLicenseClass: data.firmLicenseClass,
                      },
                      firmLicenseClass: config?.licenseClassCode,
                    };
                  }
                  // already onboarded dto
                  return {
                    agencyId: responseData?.[0]?.id, // agencyId coming from agency-state-assignments
                    ...data,
                    firmLicenseClass: config?.licenseClassCode,
                    stateCode: item?.stateCode,
                    npn: data.npn || data.drlpNpn,
                    drlpFetchedData: {
                      drlpNpn: data.npn || data.drlpNpn,
                      drlpLicenseNumber: data.drlpLicenseNumber,
                      drlpLicenseId: data.drlpLicenseId,
                      drlpPdbLicenseClass: data.drlpPdbLicenseClass,
                      firmNpn: data.firmNpn,
                      firmLicenseClass: data.firmLicenseClass,
                    },
                  };
                }) || [],
            agencyLicenseId: responseData?.[0]?.id,
          };
        }
      );
      return {
        ...item,
        agencyLicenseConfigs: updatedConfigs,
      };
    });
  };

  const onAddNewProducer = (producerDetails: any) => {
    setSourceDropDownOptions((prev: any) => {
      return updateIsOnboardedField(prev, producerDetails);
    });
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Sider Component */}
      <Sider
        style={{
          height: '100vh',
          position: 'sticky',
          top: '0',
          backgroundImage: `url(${siderImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        width={258}
        theme="light"
      >
        {/* Content inside Sider */}
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: '30px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '40px',
            }}
          >
            <img
              alt="logo"
              style={{
                width: '164px',
                height: '42.8px',
              }}
              src={insureTrekLogo}
            />
          </div>
          <div
            style={{
              width: '198px',
              height: '27px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginBottom: '20px',
            }}
          >
            Agency Licenses
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              style={{
                width: '201px',
                height: '36px',
                borderRadius: '6px',
                padding: '12px 24px',
                backgroundColor: '#E8EBEE',
                border: 'none',
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <LeftOutlined style={{ fontSize: '4px' }} />
              Go Back
            </Button>
          </div>
          <div
            style={{
              width: '201px',
              height: '1px',
              border: '1px solid #374F6D',
              marginTop: '30px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <p
              style={{
                width: '198px',
                height: '54px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '27px',
                color: '#FFFFFF',
                textAlign: 'left',
                marginTop: '30px',
              }}
            >
              Add New States to your agency
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                width: '198px',
                height: '54px',
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '18px',
                color: '#00A6FB',
                textAlign: 'left',
                margin: 0,
              }}
            >
              Lets Select the states you wish to operate and start building your
              journey!
            </p>
          </div>
        </div>
      </Sider>
      {/* Main Content Layout */}
      <Layout>
        {loading ? (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              minHeight: 'calc(100vh - 64px)',
            }}
          >
            {' '}
            <Spin />
          </div>
        ) : (
          <Content style={{ flex: 1, padding: '20px', overflow: 'auto' }}>
            {currentStep === 0 && (
              <StateDisplay
                setSelectedStates={setSelectedStates}
                selectedStates={selectedStates}
                prefilledStates={prefilledStates}
                setSelectedIds={setSelectedIds}
              />
            )}
            {currentStep === 1 && (
              <LicenseSelection
                selectedStates={selectedStates}
                setAgencyStateAssignment={setAgencyStateAssignment}
                prefilledLoaIds={prefilledLoaIds}
                dropdownOptions={dropdownOptions}
                setDropdownOptions={setDropdownOptions}
                sourceDropdowns={sourceDropdowns}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                onAddNewProducer={onAddNewProducer}
                setAddedLoaIds={setAddedLoaIds}
                setRemovedLoaIds={setRemovedLoaIds}
              />
            )}
            {currentStep === 2 && (
              <ReviewSectionLicenseClassAndLoa
                dropdownOptions={dropdownOptions}
                agencyStateAssignment={agencyStateAssignmentFin}
              />
            )}
            <CustomModal
              visible={isModalVisible}
              stateCount={stateCount}
              onClose={closeModal}
            />
          </Content>
        )}
        <Footer
          style={{
            height: '64px',
            border: '1px solid #E6E6E6',
            backgroundColor: '#FFFFFF',
            padding: '20px',
            gap: '4px',
            position: 'sticky',
            bottom: '0px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <CustomSteps
            steps={steps}
            disabled={isLoading}
            current={currentStep}
            onClickStep={handleStepClick}
            maxStep={steps.length}
          />
          <Button
            style={{
              height: '35px',
              borderRadius: '6px',
              padding: '12px 24px 12px 16px',
              backgroundColor: '#E8EBEE',
              color: '#000000',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '16px',
              marginLeft: '40px',
            }}
            onClick={handlePrevious}
            disabled={currentStep === 0 || isLoading}
          >
            <span
              style={{
                fontSize: '22px',
                lineHeight: '1em',
                marginRight: '5px', // Optional: add some space between text and symbol
              }}
            >
              &lt;
            </span>
            <span
              style={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '20px',
                color: '#222222',
              }}
            >
              Previous
            </span>
          </Button>
          <Button
            loading={isLoading}
            disabled={
              isLoading ||
              !selectedStates?.length ||
              (currentStep === 1 &&
                addedLoaIds.length === 0 &&
                removedLoaIds.length === 0 &&
                !Object.entries(agencyStateAssignmentFin || {}).length)
            }
            style={{
              marginLeft: '10px',
              height: '36px',
              backgroundColor: '#00A6FB',
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={currentStep === steps.length - 1 ? showModal : handleNext}
          >
            {currentStep === 2 ? 'Finish' : 'Next'}
            {!(currentStep === 2) && (
              <span
                style={{
                  fontSize: '22px',
                  lineHeight: '1em',
                  marginLeft: '5px', // Optional: add some space between text and symbol
                }}
              >
                &gt;
              </span>
            )}
          </Button>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AgencyLicensingLayout;
