import {
  NiprLicenseFeesDto,
  niprFeesCellProps,
} from '../../types/data/feeCalculation.type';

import FeesCell from './feesCell';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';

const NiprFeesCell: React.FC<niprFeesCellProps> = ({ isLoading, fees }) => {
  const licenseFee = fees?.licenseFee;
  const totalFees = {
    stateFee:
      ((licenseFee as NiprLicenseFeesDto)?.totalFees || 0) -
      ((licenseFee as NiprLicenseFeesDto)?.transactionFee || 0),
    transFee: (licenseFee as NiprLicenseFeesDto)?.transactionFee,
    withinLateRenewalPeriod: fees?.withinLateRenewalPeriod,
    withinReinstatementPeriod: fees?.withinReinstatementPeriod,
  };

  if (isLoading) {
    return (
      <div className="text-dark-blue font-figtree leading-5 text-xs">
        <InfoCircleOutlined /> In progress
      </div>
    );
  }

  return <FeesCell fees={totalFees} />;
};

export default NiprFeesCell;
