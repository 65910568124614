import {
  DollarOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  HomeOutlined,
} from '@ant-design/icons';

import CustomIcon from '../common/customiseIcon/customIcon';
import EngineLight from './engineLight';
import EngineLightProfileIcon from '../common/icons/EngineLightProfileIcon';
import ProfileIcon from '../../assets/icons/profile-line.svg';
import { RouteConstants } from '../../constants/routes.constants';
import { Row } from 'antd';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getEmploymentHistoryTooltip } from './actionableEngineLights';
import { getNameString } from '../../utils/name.utils';
import { getPayerTooltip } from '../../utils/licenses/engineLight.utils';
import { isArray } from 'lodash';

type EngineLightsProps = {
  payer: string;
  setPayerCallBack: () => void;
  disable?: boolean;
  isAgentPortal?: boolean;
  record: any;
};

function EngineLights(props: EngineLightsProps) {
  const handlePayerClick = () => {
    props.setPayerCallBack();
  };
  let backgroundQuestionsAnswered = false;
  let commonQuestionsAnswered = false;
  let nonUniformAnswered = true;
  if (props.record?.producerDetails?.engineLights) {
    const backgroundQuestion =
      props.record?.producerDetails?.engineLights?.backgroundQuestion;
    if (isArray(backgroundQuestion)) {
      backgroundQuestion?.forEach((status: any) => {
        if (status.stateCode === 'ALL') {
          commonQuestionsAnswered = status.isAllAnswered;
        } else if (status.stateCode === props.record?.stateCode) {
          nonUniformAnswered = status.isAllAnswered;
        }
      });
      backgroundQuestionsAnswered =
        commonQuestionsAnswered && nonUniformAnswered;
    }
  }

  const profileEngineLight =
    props.record?.producerDetails?.engineLights?.profile;
  const employmentHistoyEngineLight =
    props.record.producerDetails?.engineLights?.employmentHistory;

  return (
    <Row
      style={{
        height: '20px',
        flexFlow: 'nowrap',
        paddingTop: 3,
      }}
    >
      <EngineLight
        icon={<EngineLightProfileIcon />}
        status={!profileEngineLight ? 'red' : 'green'}
        styles={{
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
          borderRight: `1px solid ${
            !profileEngineLight ? '#EB3131' : '#31EB91'
          }`,
        }}
        tooltip={props?.record?.producerDetails?.profileMessage}
        onClick={() => {
          if (props.record?.producerDetails?.producerId) {
            appStateInfoStore.setHeader(
              getNameString(props.record.producerDetails.name)
            );
            window.open(
              props.isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.agentDashBoard.path}`
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agentOverview.path.replace(
                    ':agentId',
                    props.record?.producerDetails?.producerId
                  )}`,
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<FieldTimeOutlined />}
        status={!employmentHistoyEngineLight ? 'red' : 'green'}
        tooltip={
          employmentHistoyEngineLight
            ? 'Agent employment history is filled'
            : getEmploymentHistoryTooltip(employmentHistoyEngineLight)
        }
        styles={{
          borderRight: `1px solid ${
            !employmentHistoyEngineLight ? '#EB3131' : '#31EB91'
          }`,
        }}
        onClick={() => {
          if (props.record?.producerDetails?.producerId) {
            appStateInfoStore.setHeader(
              getNameString(props.record.producerDetails.name)
            );
            window.open(
              props.isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.employmentHistory.path}`
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyEmploymentHistory.path.replace(
                    ':agentId',
                    props.record?.producerDetails?.producerId
                  )}`,
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<FileTextOutlined />}
        status={!backgroundQuestionsAnswered ? 'red' : 'green'}
        tooltip={
          backgroundQuestionsAnswered
            ? 'Producer background questions are answered'
            : 'Producer background questions are not answered'
        }
        styles={{
          borderRight: `1px solid ${
            !backgroundQuestionsAnswered ? '#EB3131' : '#31EB91'
          }`,
        }}
        onClick={() => {
          if (props.record?.producerDetails?.producerId) {
            const stateCode = !nonUniformAnswered
              ? props?.record?.stateCode
              : !commonQuestionsAnswered
                ? 'uniform'
                : '';
            appStateInfoStore.setHeader(
              getNameString(props.record.producerDetails.name)
            );
            window.open(
              props.isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.backgroundQuestions.path}` +
                    (stateCode ? `?stateCode=${stateCode}` : '')
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyBackgroundQuestions.path.replace(
                    ':agentId',
                    props.record?.producerDetails?.producerId
                  )}` + (stateCode ? `?stateCode=${stateCode}` : ''),
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<HomeOutlined />}
        status={
          props.record.producerDetails?.engineLights?.isResidentLicenseActive
            ? 'green'
            : 'red'
        }
        styles={{
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
        tooltip={
          props.disable
            ? null
            : props.record.producerDetails?.engineLights
                  ?.isResidentLicenseActive
              ? 'Resident License is active'
              : // ? `Resident state: ${getStateNameFromCode(
                //     StateConstants[props.record.producerDetails?.residentState]
                //   )}`
                'Resident license is missing'
        }
      />
    </Row>
  );
}

export default EngineLights;
