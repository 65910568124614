import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LoaProps } from './agencyLicensingInterfaces.type';
import React from 'react';

const Loa: React.FC<LoaProps> = ({
  text,
  loaId,
  licenseClassCode,
  isCheckboxNeeded,
  selectedIds,
  handleCheckboxChange,
  prefilledLoaIds,
}) => {
  const handleLoaCheckboxChange = (e: CheckboxChangeEvent) => {
    handleCheckboxChange(licenseClassCode, text, loaId, e.target.checked);
  };
  return (
    <div
      className="override-checkbox-bg"
      style={{
        height: '40px',
        borderRadius: '4px',
        border: '1px solid #E6E6E6',
        padding: '8px 12px',
        gap: '12px',
        backgroundColor: '#FFFFFF',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {isCheckboxNeeded && (
        <Checkbox
          style={{ width: '24px', height: '24px' }}
          checked={selectedIds?.includes(loaId)}
          onChange={handleLoaCheckboxChange}
        />
      )}
      <p
        style={{
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          color: '#222222',
          margin: 0,
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default Loa;
