import { Button, Form, Input, Typography, notification } from 'antd';
import {
  EMAIL_REGEX,
  NAME_REGEX,
  PASSWORD_REGEX,
} from '../../../constants/regex.constants';
import { Link, useNavigate } from 'react-router-dom';
import { onboardingStore, signupStore } from '../../../stores/signupStore';
import { useEffect, useState } from 'react';

import { DOCUMENTATION_LINKS } from '../../../constants/documentation.url.constants';
import { InviteType } from '../../../enums/signupPage.enum';
import Loading from '../../../components/loading';
import { NiprTransactionType } from '../../../enums/niprTransactionType';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReCaptchaWrapper } from '../../../components/common/recaptcha/recaptcha-wrapper';
import { RouteConstants } from '../../../constants/routes.constants';
import { SignupService } from '../../../services/signup.service';
import { Tooltip } from 'antd';
import { cleanAndCapitalizeString } from '../../../utils/name.utils';
import { getQueryParams } from '../../../utils/queryParams.utils';
import { isAboveTabletWidth } from '../../../utils/screen.utils';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../authProvider';

type Props = {
  onSubmitSuccess: (response: any) => void;
};
const SignUpAccountCreation = ({ onSubmitSuccess }: Props) => {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isInviteApiDone, setIsInviteApiDone] = useState(false);
  const [noInvites, setNoInvites] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const queryParams = getQueryParams();
  const { processSignUp } = useAuth();
  const navigate = useNavigate();

  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };

  const handleSubmit = ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    SignupService.createNewUser(
      firstName,
      lastName,
      email,
      password,
      reCaptchaToken,
      queryParams['inviteToken'],
      {
        utm_medium: queryParams['utm_medium'],
        utm_source: queryParams['utm_source'],
        utm_term: queryParams['utm_term'],
        utm_campaign: queryParams['utm_campaign'],
        utm_content: queryParams['utm_content'],
        invite_token: queryParams['inviteToken'],
        referral_code: queryParams['ref'],
        raw_query_params: queryParams,
      }
    )
      .then((response: any) => {
        onboardingStore.setAccountInfo(firstName, lastName, email, password);
        onboardingStore.setOnboardingData({
          adminInvite: response.inviteType === 'user' ? true : false,
          agency: response.agency,
          producer: response.producer,
        });
        processSignUp(
          response?.auth0Token?.idToken,
          response?.auth0Token?.accessToken,
          response?.auth0Token?.refreshToken
        );
        api['success']({
          message: "Let's get you started",
          description: 'You have created your account successfully',
        });
        onSubmitSuccess(response);
      })
      .catch((err: any) => {
        let message = err?.message;
        if (err?.status === 409) {
          message = err?.response?.errorDescription;
        }
        if (err?.error?.message) message = err?.error?.message;
        showError(message);
        setRefreshReCaptcha((refreshReCaptcha) => !refreshReCaptcha);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (queryParams['inviteToken']) fetchInvites();
    else {
      setNoInvites(true);
      setIsInviteApiDone(true);
    }
  }, []);

  const fetchInvites = async () => {
    try {
      const response = await SignupService.getInviteDetails(
        queryParams['inviteToken']
      ).catch((error: any) => {
        signupStore.setIsInvited(false);
      });
      if (response) {
        const data = response.data;
        if (data.inviteeDetails?.isExistingUser) {
          api['success']({
            message: 'Login to action on invite',
            description:
              'You will find the invite in your team invites section. Please Login to continue',
          });

          setTimeout(
            () =>
              navigate(
                RouteConstants.rootRedirect.path +
                  `?email=${data?.inviteeDetails?.email}`
              ),
            5000
          );
        }
        signupStore.setIsInvited(true);
        signupStore.setInviteType(
          data.inviteType === 'user' ? InviteType.ADMIN : InviteType.DOWNLINE
        );
        if (data.inviteType === 'user') {
          signupStore.setAdminInviteDetails(
            data.inviteeDetails.email,
            data.inviteeDetails.role,
            data.inviterDetails.agencyId,
            data.inviteToken,
            data.inviterDetails.agency.name
          );
        }
        // TODO @Varun - Change based on new code
        if (data.inviteType === 'downline') {
          const inviteDetails = data;
          const inviterType = data.inviterDetails.agency.type
            ? 'agency'
            : 'producer';
          const inviterId = inviteDetails.inviterDetails.agency
            ? inviteDetails.inviterDetails.agency._id
            : inviteDetails.inviterDetails.producer._id;
          const inviterName = inviteDetails.inviterDetails?.agency
            ? inviteDetails.inviterDetails?.agency?.name
            : inviteDetails.inviterDetails?.producer?.name;
          const inviteeEmail = inviteDetails.inviteeDetails?.email;
          signupStore.setDownlineInviteDetails(
            inviterType,
            inviterId,
            inviterName,
            inviteeEmail,
            inviteDetails.inviteToken,
            inviteDetails.creditAmount,
            inviteDetails.npn,
            inviteDetails.homeState,
            inviteDetails.entityType === NiprTransactionType.FIRM
              ? NiprTransactionType.FIRM
              : NiprTransactionType.INDIVIDUAL,
            inviteDetails?.inviterDetails?.agency.name
          );
        }
      }
    } finally {
      setIsInviteApiDone(true);
    }
  };

  const verifyReCaptchaToken = (token: any) => {
    setReCaptchaToken(token);
  };

  return (
    <div className="responsive-container">
      {/* DND Start */}
      {contextHolder}
      {/* DND End */}
      {isInviteApiDone ? (
        <div className="responsive-container">
          {noInvites ? null : (
            <div>
              {signupStore.isInvited ? (
                <div className="onboarding-radio-button-note">
                  You have been invited by{' '}
                  {signupStore.downlineInviteDetails?.agencyName ||
                    signupStore.adminInviteDetails?.agencyName}
                  . By signing up, you choose to accept the invite extended.
                </div>
              ) : (
                <div className="onboarding-radio-button-note">
                  Invalid Invite Token! Please go ahead and signup
                </div>
              )}
            </div>
          )}
          <div className="form-header">
            <Typography.Title level={2}>Create your account</Typography.Title>
            <Typography.Paragraph type="secondary">
              Onboard and manage your producer licenses.
            </Typography.Paragraph>
          </div>
          <Form
            autoComplete="off"
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            className="onboarding-screen"
          >
            <div className="form-row">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your first name',
                  },
                  {
                    pattern: NAME_REGEX,
                    message: 'First name must contain only letters',
                  },
                  {
                    validator: (_, value) =>
                      !value || value?.trim() === ''
                        ? Promise.reject(
                            new Error('First Name cannot contain only spaces')
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  id="first-name-input"
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    const capitalizedValue = cleanAndCapitalizeString(
                      e.target.value
                    );
                    form.setFieldsValue({ firstName: capitalizedValue });
                  }}
                  className="custom-placeholder"
                />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your last name',
                  },
                  {
                    pattern: NAME_REGEX,
                    message: 'Last name must contain only letters',
                  },
                  {
                    validator: (_, value) =>
                      !value || value?.trim() === ''
                        ? Promise.reject(
                            new Error('Last Name cannot contain only spaces')
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  id="last-name-input"
                  placeholder="Enter Last Name"
                  onChange={(e) => {
                    const capitalizedValue = cleanAndCapitalizeString(
                      e.target.value
                    );
                    form.setFieldsValue({ lastName: capitalizedValue });
                  }}
                  className="custom-placeholder"
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Email address"
              name="email"
              initialValue={
                signupStore.isInvited
                  ? signupStore.adminInviteDetails?.email ||
                    signupStore.downlineInviteDetails?.inviteeEmail
                  : undefined
              }
              rules={[
                {
                  required: true,
                  message: 'Please enter your email',
                },
                {
                  pattern: EMAIL_REGEX,
                  message: 'Please enter a valid email',
                },
                {
                  validator: (_, value) =>
                    !value || value?.trim() === ''
                      ? Promise.reject(new Error('Please enter a valid email'))
                      : Promise.resolve(),
                },
                {
                  validator: (_, value) =>
                    /[A-Z]/.test(value)
                      ? Promise.reject(
                          new Error('Email should not contain capital letters')
                        )
                      : Promise.resolve(),
                },
              ]}
            >
              <Input
                id="email-input"
                placeholder="Enter Email address"
                onChange={(e) => {
                  const value = e.target.value;
                  form.setFieldsValue({ email: value });
                }}
                disabled={signupStore.isInvited || isLoading}
                className="custom-placeholder"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter a password' },
                {
                  pattern: PASSWORD_REGEX,
                  message: 'Password does not meet criteria',
                },
              ]}
            >
              <Input.Password
                className="custom-placeholder"
                placeholder="Enter Password"
                onChange={(e) => {
                  const value = e.target.value;
                  form.setFieldsValue({ password: value });
                  form.validateFields(['confirmPassword']);
                }}
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please re-enter the password' },
                (formInstance) => ({
                  message: 'Passwords do not match',
                  validator: (_, value) => {
                    const password = formInstance.getFieldValue('password');
                    return password !== value
                      ? Promise.reject(new Error('Passwords do not match'))
                      : Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Re-enter Password"
                className="custom-placeholder"
              />
            </Form.Item>
            <div className="flex items-center mt-[-16px] pt-[2px]">
              <span className="text-[#97AABF] text-xs">
                Password constraints{' '}
              </span>
              <Tooltip title="Your password should contain at least 8 characters and meet any 3 of the following conditions: 1 uppercase letter, 1 lowercase letter, 1 numeric character, 1 special character">
                <QuestionCircleOutlined
                  style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    color: '#97AABF',
                  }}
                />
              </Tooltip>
            </div>

            <ReCaptchaWrapper
              onVerify={verifyReCaptchaToken}
              refreshReCaptcha={refreshReCaptcha}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  height: isAboveTabletWidth() ? 46 : 36,
                  width: '100%',
                }}
                loading={isLoading}
              >
                Let’s get started
              </Button>
            </ReCaptchaWrapper>
          </Form>
          <Typography.Paragraph
            type="secondary"
            className="text-sm mb-0 text-center"
          >
            Already have an account?{' '}
            {isLoading ? (
              <span
                style={{
                  color: 'var(--primary-color)',
                  textDecoration: 'underline',
                }}
              >
                Log in
              </span>
            ) : (
              <Link
                to="/"
                style={{
                  color: 'var(--primary-color)',
                  textDecoration: 'underline',
                }}
              >
                Log in
              </Link>
            )}
          </Typography.Paragraph>
          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: '13px', marginBottom: 0, textAlign: 'center' }}
          >
            By continuing you agree to the{' '}
            {isLoading ? (
              <span
                style={{
                  color: 'var(--primary-color)',
                  textDecoration: 'underline',
                }}
              >
                Terms
              </span>
            ) : (
              <Link
                to={DOCUMENTATION_LINKS.TERMS}
                style={{
                  color: 'var(--primary-color)',
                  textDecoration: 'underline',
                }}
              >
                Terms
              </Link>
            )}{' '}
          </Typography.Paragraph>
        </div>
      ) : (
        <Loading logo fullHeight />
      )}
    </div>
  );
};

export default observer(SignUpAccountCreation);
