import { Drawer, Table } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { SUPPORT_MAIL } from '../../../constants/mail.constants';

interface emailPreviewModalProps {
  errorEmails: string[];
  modalVisibility: boolean;
  setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreviewErrorEmail: React.FC<emailPreviewModalProps> = (props) => {
  const { modalVisibility, setModalVisibility, errorEmails } = props;

  const errorEmailsObject = errorEmails.map((email) => {
    return { email };
  });

  const columns = [
    {
      title: 'Producer Email',
      dataIndex: 'email',
      key: 1,
      render: (text: string) => (text ? text : '-'),
    },
  ];

  return (
    <Drawer
      open={modalVisibility}
      closable={false}
      width={'1037px !important'}
      headerStyle={{ display: 'none' }}
      destroyOnClose={true}
      placement="right"
      bodyStyle={{
        borderRadius: '16px',
        overflow: 'hidden',
      }}
      onClose={() => setModalVisibility(false)}
      footer={null}
      style={{
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        boxShadow: 'none',
        top: '0 !important',
      }}
      className="multiple-onboard-preview"
      rootClassName="multiple-onboard-preview"
    >
      <div className="flex flex-col gap-2 relative">
        <CloseOutlined
          onClick={() => setModalVisibility(false)}
          className="absolute top-4 right-4 cursor-pointer text-lg text-gray-600 hover:text-gray-800"
        />
        <span className="text-sm font-semibold font-figtree">Failures</span>

        <span className="text-m font-semibold font-figtree text-[#848484] mt-[20px]">
          Here is the list of producers that we were not able to onboard. Please
          contact support to onboard these producers{' '}
          <Link to={`mailto:${SUPPORT_MAIL}`} target="_blank">
            {SUPPORT_MAIL}
          </Link>
        </span>
      </div>

      <Table
        columns={columns}
        dataSource={errorEmailsObject}
        className="agency-license-table"
        style={{ marginTop: '22px' }}
        pagination={false}
      />
    </Drawer>
  );
};

export default PreviewErrorEmail;
