import {
  ActiveLicense,
  AssignmentOptions,
  IndividualOnboardStateGroupProps,
  ProducerDetails,
  StateLicenses,
  updateProducerAssignmetPayload,
} from '../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { Button, Layout, Spin, message } from 'antd';
import ProducerStatDetails, { statDetails } from './ProducerStatDetails';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../utils/common.utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import { LeftOutlined } from '@ant-design/icons';
import ProducerAssignments from './producerAssignments';
import { ResidencyType } from '../../../enums/residencyType.enum';
import { RoleType } from '../../../enums/roles.enum';
import { STATE_CONSTANTS_COUNT } from '../../../constants/state.constants';
import { StateDetails } from '../../../types/data/masterData';
import { UserType } from '../../../enums/userType.enum';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { auth0Store } from '../../../stores/auth0Store';
import { groupBy } from 'lodash';
import { individualOnboardStore } from '../../../stores/individualOnboardStore';
import insureTrekLogo from '../../../assets/images/insureTrekLogo.png';
import { observer } from 'mobx-react-lite';
import siderImage from '../../../assets/images/agencyLicensingSidebar.png';
import { signupStore } from '../../../stores/signupStore';
import { useAuth } from '../../../auth/authProvider';
import { validateAssignmentStep } from '../onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { withRoles } from '../../../auth/useRoles';

const { Content, Sider, Footer } = Layout;

const ManageAssignmentsByProducer = ({ isBulk = false }) => {
  const navigate = useNavigate();
  const { producerId } = useParams();
  const { getAccessTokenSilently } = useAuth();
  const [loading, setLoading] = useState(false);
  const [statloading, setStatLoading] = useState(isBulk ? false : true);
  const [producerDetails, setProducerDetails] = useState<ProducerDetails>();

  const [selectedItems, setSelectedItems] = useState<StateLicenses[]>([]); // selected states in assignments
  const [selectedTerritory, setSelectedTerritory] = useState('');
  const [selectedState, setSelectedState] = useState('Alabama');
  const [selectedTab, setSelectedTab] = useState(AssignmentOptions.STATE);
  const [stateGroups, setStateGroups] = useState<
    IndividualOnboardStateGroupProps[]
  >([]);
  const [masterData, setMasterData] = useState<StateDetails[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [statDetails, setStatDetails] = useState<statDetails>({
    totalStates: STATE_CONSTANTS_COUNT,
    activeStates: 0,
    statesSelected: 0,
    territoriesSelected: 0,
    totalTerriotories: 0,
    totalEstimatedCost: 0,
  });

  const [disableSave, setDisableSave] = useState(false);
  const [producerIds, setProducerIds] = useState<[]>([]);

  const fetchProducerDetails = async () => {
    if (!producerId) return;
    try {
      const bearerToken = await getAccessTokenSilently();
      setStatLoading(true);
      const producerDetails = await AgentService.getProducerDetailsById(
        producerId,
        bearerToken
      );
      setProducerDetails(producerDetails.data);
    } catch (err) {
      console.error(err);
    } finally {
      setStatLoading(false);
    }
  };

  // fee calculation
  useEffect(() => {
    let fees = 0;
    const stateCodes: string[] = [];
    // territory fee
    stateGroups
      ?.filter((d) => d.isSelected)
      ?.forEach((d) => {
        d?.stateLicenseConfigs?.forEach((data) => {
          data?.licenseConfigs?.forEach((d) => {
            const activeDetails =
              individualOnboardStore.producerActiveLicenses?.filter(
                (d1) => d1.stateCode === data.stateCode
              )?.[0];
            if (
              (data.stateCode &&
                stateCodes.includes(`${data.stateCode}${d.lcCode}`)) ||
              activeDetails?.licenseClassCode === d.lcCode
            )
              return;
            if (data.stateCode) stateCodes.push(`${data.stateCode}${d.lcCode}`);

            const homeState = producerDetails?.residentState;
            const isDisabled =
              (!homeState?.includes(data.stateCode) &&
                d?.loaConfigs?.[0]?.residencyType === ResidencyType.Resident) ||
              (homeState?.includes(data.stateCode) &&
                d?.loaConfigs?.[0]?.residencyType === ResidencyType.NonResident)
                ? true
                : null;
            if (isDisabled) return;

            fees +=
              appStateInfoStore.calculateFeesForProducerLicenses(
                data.stateCode,
                activeDetails?.licenseClassCode === d.lcCode
                // d.residencyType
              ) || 0;
          });
        });
      });

    // state fee
    selectedItems?.forEach((d) => {
      const stateCode = getStateCodeFromName(d?.name);
      Object.entries(d)?.forEach(([k, v]) => {
        if (k === 'name' || typeof v === 'string') return;
        const activeDetails =
          individualOnboardStore.producerActiveLicenses?.filter(
            (d1) => d1.stateCode === stateCode
          )?.[0];
        if (
          (stateCode && stateCodes.includes(`${stateCode}${d.lcCode}`)) ||
          (stateCode && activeDetails?.licenseClassCode === k)
        )
          return;
        if (stateCode) stateCodes.push(`${d.stateCode}${k}`);

        fees +=
          appStateInfoStore.calculateFeesForProducerLicenses(
            stateCode!,
            activeDetails?.licenseClassCode === k
            // ResidencyType.NotApplicable
          ) || 0;
      });
    });

    setStatDetails((prev) => ({ ...prev, totalEstimatedCost: fees }));
  }, [
    selectedItems,
    stateGroups,
    individualOnboardStore.producerActiveLicenses,
  ]);

  const handleIndividualAssignStates = async (
    activeLicenses: ActiveLicense[],
    isBulk?: boolean
  ) => {
    try {
      if (isBulk) {
        const masterData = [...appStateInfoStore.getLicenseConfigsMasterData()];

        masterData.sort((a, b) => {
          const nameA = getStateNameFromCode(a.stateCode);
          const nameB = getStateNameFromCode(b.stateCode);
          return nameA.localeCompare(nameB);
        });

        setMasterData(masterData);
        return;
      }

      setLoading(true);
      const token = await getAccessTokenSilently();
      const response: any =
        await AgentService.getLicenseConfigurationByProducerId(
          producerId!,
          token
        );
      const territoryIds =
        response?.data?.map((d: any) => d?.territoryId) || [];
      setStateGroups((prev) => {
        return prev.map((d) =>
          territoryIds?.includes(d.id) ? { ...d, isSelected: true } : d
        );
      });
      const selectedStates =
        response?.data
          ?.map((d: any) => {
            return !d?.territoryId && d;
          })
          ?.filter(Boolean) || [];

      const selectedItems: StateLicenses[] = [];
      selectedStates.forEach((d: any) => {
        const stateDetails: any = {};
        const stateName = getStateNameFromCode(d?.stateCode || '');
        if (stateName) {
          stateDetails['name'] = stateName;
          const loas = groupBy(d?.licenseConfigurations, 'licenseClassCode');
          Object.entries(loas).forEach(([k, v]) => {
            stateDetails[k] = {
              name: v?.[0]?.licenseClass,
              loas:
                v?.map((d) => ({
                  id: d?.id,
                  loaCode: d?.loaCode,
                  name: d?.loa,
                })) || [],
            };
          });
          selectedItems.push(stateDetails);
        }
      });
      setSelectedItems(selectedItems as StateLicenses[]);

      const masterData = [...appStateInfoStore.getLicenseConfigsMasterData()];

      masterData.sort((a, b) => {
        const nameA = getStateNameFromCode(a.stateCode);
        const nameB = getStateNameFromCode(b.stateCode);
        return nameA.localeCompare(nameB);
      });

      // converting master data
      const updatedMasterData = masterData.map((d) => {
        const licenses = d?.licenseClasses?.map((lc) => {
          const activeLicense = activeLicenses.find(
            (al) =>
              al.stateCode === d.stateCode && lc.lcCode === al?.licenseClassCode
          );
          const updatedConfigs: string[] = [];
          const activeLoas: string[] = [];
          const selectedLoas: string[] = [];
          const selectedState = selectedItems?.find(
            (item) =>
              item.name === getStateNameFromCode(d.stateCode) &&
              item?.[lc.lcCode]
          );
          //  already selected loas, must be shown
          if (selectedState) {
            selectedLoas?.push(
              ...(selectedState?.[lc.lcCode]?.loas?.map((d) => d.loaCode) || [])
            );
          }

          // if retired loa is selected, don't show the respective non retired loas
          const retiredSelectedLoas = lc.loaData
            ?.filter(
              (loa) => selectedLoas?.includes(loa.loaCode) && loa.isRetired
            )
            ?.map((d) => d.referenceLicenseConfig)
            ?.filter(Boolean);

          // if retired loa is active, then don't show the respective non retired loas
          lc.loaData?.forEach((d) => {
            const activeLoa = activeLicense?.loaDetails?.find(
              (loa) => loa.loaCode === d?.loaCode
            );
            if (activeLoa) {
              activeLoas.push(activeLoa.loaCode);
            }
            if (activeLoa && d?.referenceLicenseConfig) {
              updatedConfigs.push(d?.referenceLicenseConfig);
            }
          });

          const loas = lc.loaData?.filter((d) => {
            if (
              d?.referenceLicenseConfig &&
              (updatedConfigs.includes(d?.id || '') ||
                retiredSelectedLoas.includes(d?.id))
            ) {
              return false;
            }

            // should be active loa or non retired loa or already selected loa
            return (
              activeLoas.includes(d.loaCode) ||
              !d.isRetired ||
              selectedLoas.includes(d.loaCode)
            );
          });
          return {
            ...lc,
            loaData: loas,
          };
        });
        return {
          ...d,
          licenseClasses: licenses,
        };
      });

      setMasterData(updatedMasterData);
    } catch (error) {
      console.error(error);
      message.error('Failed to get producer assignments.Please Try Again');
    } finally {
      setLoading(false);
    }
  };

  const updateAssignmentDetails = async () => {
    const isError = validateAssignmentStep(selectedItems, stateGroups);
    if (isError) return;
    try {
      setIsSaving(true);
      const bearerToken = await getAccessTokenSilently();
      let payload: updateProducerAssignmetPayload[] = [];
      const selectedStateGroup = stateGroups?.filter((d) => d.isSelected) || [];

      const homeState = producerDetails?.residentState;
      selectedStateGroup.forEach((d) => {
        d.stateLicenseConfigs.forEach((data) => {
          payload.push({
            territoryId: d.id,
            stateCode: data?.stateCode,
            licenseConfigurations: data?.licenseConfigs
              ?.flatMap((d) => d.loaConfigs)
              ?.filter(
                (d) =>
                  !(
                    (!homeState?.includes(data.stateCode) &&
                      d?.residencyType === ResidencyType.Resident) ||
                    (homeState?.includes(data.stateCode) &&
                      d?.residencyType === ResidencyType.NonResident)
                  )
              )
              .map((d) => d.id || '')
              .filter((d) => d),
          });
        });
      });

      payload = payload.filter((config) => config.licenseConfigurations.length);

      selectedItems.forEach((data) => {
        const licenseConfigurations: string[] = [];
        Object.entries(data).forEach(([k, v]) => {
          if (typeof v === 'string') return;
          v?.loas?.forEach((element) => {
            licenseConfigurations.push(element?.id || '');
          });
        });
        payload.push({
          stateCode: getStateCodeFromName(data.name) || '',
          licenseConfigurations,
        });
      });
      const response = await AgencyService.updateBulkAssignmentsForProducer(
        isBulk ? producerIds : [producerId!],
        payload,
        bearerToken
      );
      message.success(
        response?.message || 'Producer Assignments Updated Successfully'
      );
      navigate(-1);
      setIsSaving(true);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const initializeStores = async () => {
      const token = await getAccessTokenSilently();

      await appStateInfoStore.initializeStore(token);

      if (
        signupStore.userType === UserType.ADMIN &&
        !agencyProfileStore.agencyDetails?.homeState
      )
        await agencyProfileStore.loadAgencyDetails(token);
    };

    if (auth0Store.getProfilesLoaded()) {
      initializeStores();
      fetchProducerDetails();
    }
  }, [auth0Store.getProfilesLoaded(), producerId]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* Sider Component */}
      <Sider
        style={{
          height: '100vh',
          position: 'sticky',
          top: '0',
          backgroundImage: `url(${siderImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        width={258}
        theme="light"
      >
        {/* Content inside Sider */}
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingTop: '30px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '40px',
            }}
          >
            <img
              alt="logo"
              style={{
                width: '164px',
                height: '42.8px',
              }}
              src={insureTrekLogo}
            />
          </div>
          <div
            style={{
              width: '198px',
              height: '27px',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '27px',
              color: '#FFFFFF',
              marginBottom: '20px',
            }}
          >
            Manage States & Territories
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              margin: '24px 0 0',
            }}
          >
            <Button
              style={{
                width: '201px',
                height: '36px',
                borderRadius: '6px',
                padding: '12px 24px',
                backgroundColor: '#E8EBEE',
                border: 'none',
                gap: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <LeftOutlined style={{ fontSize: '4px' }} />
              Go Back
            </Button>
          </div>
          <div
            style={{
              width: '201px',
              height: '1px',
              border: '1px solid #374F6D',
              marginTop: '24px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 8,
            }}
          >
            <p
              className="figtree"
              style={{
                width: '198px',
                height: '28px',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '14.4px',
                color: '#00A6FB',
                textAlign: 'left',
              }}
            >
              Edit Assigned states and Assigned Territories to your Producer
            </p>
          </div>
        </div>
      </Sider>
      {/* Main Content Layout */}
      <Layout>
        {!isBulk && statloading ? (
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              minHeight: 'calc(100vh - 64px)',
            }}
          >
            {' '}
            <Spin />
          </div>
        ) : (
          <Content style={{ flex: 1, padding: '20px', overflow: 'auto' }}>
            <ProducerStatDetails
              producerDetails={producerDetails!}
              producerStatDetails={statDetails}
              isBulk={isBulk}
              setProducerIds={setProducerIds}
            />
            <ProducerAssignments
              selectedItems={selectedItems}
              selectedState={selectedState}
              selectedTab={selectedTab}
              selectedTerritory={selectedTerritory}
              setSelectedTerritory={setSelectedTerritory}
              setSelectedItems={setSelectedItems}
              setSelectedState={setSelectedState}
              setSelectedTab={setSelectedTab}
              stateGroups={stateGroups}
              setStateGroups={setStateGroups}
              setStatDetails={setStatDetails}
              handleIndividualAssignStates={handleIndividualAssignStates}
              isLoading={loading}
              masterData={masterData}
              statDetails={statDetails}
              setDisableSave={setDisableSave}
              producerDetails={producerDetails!}
              producerId={producerId!}
              isBulk={isBulk}
            />
          </Content>
        )}
        <Footer
          style={{
            height: '64px',
            border: '1px solid #E6E6E6',
            backgroundColor: '#FFFFFF',
            padding: '20px',
            gap: '4px',
            position: 'sticky',
            bottom: '0px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            type="primary"
            onClick={updateAssignmentDetails}
            style={{ height: 46, width: '277px' }}
            disabled={disableSave || loading || statloading}
            loading={isSaving}
          >
            Save Changes
          </Button>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default withRoles(observer(ManageAssignmentsByProducer), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
