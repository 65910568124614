import {
  IndividualOnboardAgentProps,
  paymentOptionsType,
} from './individualOnboardV2Type.type';

import { getStateNameFromCode } from '../../../../utils/common.utils';
import { message } from 'antd';

export const stepInfo = [
  {
    steps: 'Onboard',
    key: 0,
  },
  {
    steps: 'Assign',
    key: 1,
  },
  {
    steps: 'Review',
    key: 2,
  },
];
export const steps = stepInfo?.map((info) => info.steps);

export const getAddressLine = (value: any) => {
  return {
    addressLine1: value?.addressLine1
      ? `${value?.addressLine1 || ''},${value?.addressLine2 || ''}`
      : null,
    addressLine2: `${value?.city || ''}, ${
      value?.stateCode ? getStateNameFromCode(value?.stateCode) : ''
    }, ${value?.country || ''} ${value?.zip || ''}`,
  };
};

export const validateOnboardingStep = (
  agentBioInfo: IndividualOnboardAgentProps,
  paymentOptions: paymentOptionsType
): boolean => {
  const validations = [
    {
      condition: !agentBioInfo.agentUpdatedEmail && !agentBioInfo.agentEmail,
      message: 'Please enter your email',
    },
    // {
    //   condition: !agentBioInfo.agentSsn && !agentBioInfo.agentUpdatedSSN,
    //   message: 'Please enter your ssn',
    // },
    {
      condition:
        !agentBioInfo.agentDateOfBirth && !agentBioInfo.agentUpdatedDOB,
      message: 'Please select your date of birth',
    },
    // {
    //   condition:
    //     (!agentBioInfo.agentGender || agentBioInfo.agentGender === 'Unknown') &&
    //     !agentBioInfo.agentUpdatedGender,
    //   message: 'Please select your gender',
    // },
    {
      condition: !paymentOptions.newLicenses && paymentOptions.renewalLicenses,
      message: 'Please choose new licenses type',
    },
    {
      condition: paymentOptions.newLicenses && !paymentOptions.renewalLicenses,
      message: 'Please choose renewal licenses type',
    },
  ];

  for (const validation of validations) {
    if (validation.condition) {
      message.error(validation.message);
      return true; // isError
    }
  }

  return false; // no error
};

export const validateAssignmentStep = (
  selectedItems: any[],
  stateGroups: any[]
): boolean => {
  const selectedStates = selectedItems.filter(
    (data) =>
      !stateGroups
        .filter((data) => data.isSelected)
        ?.flatMap((data) => data.stateLicenseConfigs)
        ?.map((data) => getStateNameFromCode(data.stateCode))
        .includes(data.name)
  );

  for (const state of selectedStates) {
    const keys = Object.keys(state);
    if (keys.length === 1) {
      message.error(
        `Please assign at least one license class for ${state.name}`
      );
      return true; // isError
    }
    const filteredKeys = keys.filter((key) => key !== 'name');
    for (const key of filteredKeys) {
      const loas = state?.[key]?.loas;
      if (!loas?.length) {
        message.error(
          `Please assign at least one loa for ${state?.[key]?.name} license class in ${state?.name}`
        );
        return true; // isError
      }
    }
  }

  return false; // no error
};

export const updateAgentBioInfo = (
  prev: IndividualOnboardAgentProps
): IndividualOnboardAgentProps => ({
  ...prev,
  agentSsn: prev.agentSsn || prev.agentUpdatedSSN || '',
  agentDateOfBirth: prev.agentDateOfBirth || prev.agentUpdatedDOB || '',
  agentEmail: prev.agentEmail || prev.agentUpdatedEmail || '',
  agentGender: prev.agentGender || prev.agentUpdatedGender || '',
});

export const baseFontStyles = {
  subHeading: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
  },
};

export const calculateCompletenessPercentage = (
  agentInfo: IndividualOnboardAgentProps,
  paymentOptions: paymentOptionsType
): number => {
  const fields = [
    paymentOptions.newLicenses && paymentOptions.renewalLicenses,
    agentInfo.agentEmail || agentInfo.agentUpdatedEmail,
    agentInfo.agentSsn || agentInfo.agentUpdatedSSN,
    (agentInfo.agentGender === 'Unknown' ? null : agentInfo.agentGender) ||
      agentInfo.agentUpdatedGender,
    agentInfo.agentDateOfBirth || agentInfo.agentUpdatedDOB,
  ];

  const score = fields.reduce((acc, field) => acc + (field ? 20 : 0), 0);

  return score;
};

export const paymentConfigOptions = {
  AGENCY: 'agency',
  PRODUCER: 'producer',
};
