import {
  InsureTrekAgency,
  InsureTrekPaymentConfig,
  InsureTrekProducer,
} from './auth0Store';
import dayjs, { Dayjs } from 'dayjs';

import { AgentType } from '../enums/agentType.enum';
import { EmploymentType } from '../pages/agentSidePortal/agentInformation/employmentHistory/getDetails';
import ObjectID from 'bson-objectid';
import { PayeeEnum } from '../enums/payee.enum';
import { getNameString } from '../utils/name.utils';
import { makeAutoObservable } from 'mobx';

export interface EmployeeHistory {
  id: ObjectID | null;
  title: string;
  companyName?: string;
  employmentType: string;
  city: string;
  stateCode?: string | null;
  state: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  description: string;
  isEditing?: boolean;
  endDateDisabled: boolean;
  isCurrentWorkingRole?: boolean;
}
export interface PaymentConfigDetails {
  agentName: string;
  amountCap: number | undefined;
  initialPaymentPayee: string | null;
  renewalPaymentPayee: string | null;
  autoRenewEnabled: boolean;
  paymentType: string | null;
}
export interface BiographicInfo {
  _id: ObjectID | null;
  name: string;
  mailId: string;
  npn: string;
  mailingAddress: string;
  ssn: string;
  agencyName: string;
  birthDate: Dayjs;
  agencyNpn: string;
  gender: string;
  state: string;
  citizenship: string;
  phoneNo: string;
  fax: string;
  otherAddress: string;
}

class AgentPortalStore {
  bioInfo: BiographicInfo = {} as BiographicInfo;
  empHistory: EmployeeHistory[] = [];
  paymentConfig: PaymentConfigDetails = {} as PaymentConfigDetails;
  agentName: string = '';
  agencyName: string = '';
  agentType: AgentType | null = null;

  newEmpHistoryItem: EmployeeHistory = {
    id: new ObjectID(),
    title: '',
    employmentType: EmploymentType.FULL_TIME,
    startDate: dayjs(new Date()),
    description: '',
    endDateDisabled: false,
    isEditing: true,
    city: '',
    state: '',
    stateCode: '',
  };
  agentId: string | null = null;
  private isEditing: boolean = true;
  private isUpdating: boolean = true;

  getAgentId = () => this.agentId;
  getAutoRenewal = () => this.paymentConfig.autoRenewEnabled;
  getPaymentConfig = () => this.paymentConfig;
  setAgentId = (value: string) => (this.agentId = value);

  getBiographicInfo = () => this.bioInfo;
  getEmpHistory = () => this.empHistory;
  getIsEditing = () => this.isEditing;
  setIsEditing = (value: boolean) => (this.isEditing = value);
  getIsUpdating = () => this.isUpdating;
  setIsUpdating = (value: boolean) => (this.isUpdating = value);
  setAgentName = (value: string) => (this.agentName = value);
  setAgencyName = (value: string) => (this.agencyName = value);
  getAgencyName = (): string => this.agencyName;
  setAgentType = (value: AgentType | null) => (this.agentType = value);
  setAutoRenewal = (value: boolean) =>
    (this.paymentConfig.autoRenewEnabled = value);

  constructor() {
    makeAutoObservable(this);
  }

  setEmploymentHistory(empHistory: EmployeeHistory[]) {
    const newEmpHistory: EmployeeHistory[] = [];
    for (const history of empHistory) {
      if (!history?.id) {
        history.id = ObjectID();
      }
      history.isEditing = false;
      history.startDate = dayjs(history.startDate);
      history.endDate =
        history.endDate && !history.isCurrentWorkingRole
          ? dayjs(history.endDate)
          : undefined;
      history.endDateDisabled = !!history.isCurrentWorkingRole;
      newEmpHistory.push(history);
    }
    this.empHistory = newEmpHistory;
  }

  setPaymentConfig(paymentConfig: InsureTrekPaymentConfig) {
    this.paymentConfig.initialPaymentPayee = paymentConfig?.licenseInitialPayee
      ? paymentConfig?.licenseInitialPayee === PayeeEnum.AGENT
        ? PayeeEnum.AGENT
        : PayeeEnum.AGENCY
      : null;
    this.paymentConfig.renewalPaymentPayee = paymentConfig?.licenseRenewalPayee
      ? paymentConfig?.licenseRenewalPayee === PayeeEnum.AGENT
        ? PayeeEnum.AGENT
        : PayeeEnum.AGENCY
      : null;
    this.paymentConfig.autoRenewEnabled = paymentConfig.isAutoRenewalActive;
    this.paymentConfig.amountCap = paymentConfig?.cappedAmount;
    this.paymentConfig.paymentType = paymentConfig?.paymentType
      ? paymentConfig?.paymentType
      : null;
  }

  setGeneralAgentConfig(
    uplineAgencyDetails: any,
    producerDetails: InsureTrekProducer
  ) {
    this.paymentConfig.initialPaymentPayee = PayeeEnum.AGENT;
    this.paymentConfig.renewalPaymentPayee = PayeeEnum.AGENT;
    this.paymentConfig.autoRenewEnabled =
      producerDetails.paymentConfig.isAutoRenewalActive;
    this.paymentConfig.amountCap = uplineAgencyDetails.creditAmount;
  }

  addEmpHistory(empHistory: EmployeeHistory) {
    const existingIndex = this.empHistory.findIndex(
      (item) => item.id === empHistory.id
    );
    empHistory.isEditing = false;
    if (existingIndex !== -1) {
      this.updateEmpHistory(empHistory);
    } else {
      this.empHistory.push(empHistory);
    }
    this.clearNewEmpHistoryItem();
  }

  isSavedHistory(id: ObjectID) {
    return this.getEmpHistory().some((itr) => itr.id === id);
  }

  setIsEditingFalse() {
    this.empHistory = this.empHistory.map((emp) => ({
      ...emp,
      isEditing: false,
    }));
  }

  clearNewEmpHistoryItem() {
    this.newEmpHistoryItem = {
      id: new ObjectID(),
      title: '',
      employmentType: EmploymentType.FULL_TIME,
      startDate: dayjs(new Date()),
      description: '',
      endDateDisabled: false,
      isEditing: true,
      city: '',
      state: '',
      stateCode: '',
    };
  }

  updateEmpHistory(updatedInfo: EmployeeHistory) {
    const foundAgent = this.empHistory.find(
      (empInfo) => empInfo.id === updatedInfo.id
    );

    if (foundAgent) {
      foundAgent.id = updatedInfo.id;
      foundAgent.title = updatedInfo.title;
      foundAgent.companyName = updatedInfo.companyName;
      foundAgent.employmentType = updatedInfo.employmentType;
      foundAgent.startDate = updatedInfo.startDate;
      foundAgent.description = updatedInfo.description;
      foundAgent.isEditing = false;
      foundAgent.city = updatedInfo.city;
      foundAgent.stateCode = updatedInfo.stateCode;
      foundAgent.endDateDisabled = updatedInfo.endDateDisabled;
    }
    if (foundAgent && !updatedInfo.endDateDisabled) {
      foundAgent.endDate = updatedInfo.endDate;
    } else if (foundAgent) {
      delete foundAgent.endDate;
    }
  }

  updateBioInfo(updatedInfo: BiographicInfo) {
    const foundAgent = this.getBiographicInfo();
    foundAgent.name = updatedInfo.name;
    foundAgent.npn = updatedInfo.npn;
    foundAgent.mailId = updatedInfo.mailId;
    foundAgent.mailingAddress = updatedInfo.mailingAddress;
    foundAgent.otherAddress = updatedInfo.otherAddress;
    foundAgent.birthDate = updatedInfo.birthDate;
    foundAgent.phoneNo = updatedInfo.phoneNo;
    foundAgent.fax = updatedInfo.fax;
    foundAgent.agencyName = updatedInfo.agencyName;
    foundAgent.agencyNpn = updatedInfo.agencyNpn;
    foundAgent.state = updatedInfo.state;
    foundAgent.gender = updatedInfo.gender;
    foundAgent.citizenship = updatedInfo.citizenship;
    foundAgent.ssn = updatedInfo.ssn;
  }
  removeEmpHistory(index: number) {
    if (index !== -1) {
      this.empHistory.splice(index, 1);
    }
  }

  loadAgentByDocument(
    producerData: InsureTrekProducer,
    agencyData: InsureTrekAgency,
    uplineAgencyDetails?: any
  ) {
    this.setAgentId(producerData.id);
    // producerData.employmentHistory &&
    //   this.setEmploymentHistory(producerData.employmentHistory);

    this.setAgentName(getNameString(producerData.name));
    if (agencyData) {
      this.setAgencyName(agencyData.name);
      if (producerData?.paymentConfig)
        this.setPaymentConfig(producerData?.paymentConfig);
      this.setAgentType(AgentType.LOA);
    } else if (uplineAgencyDetails) {
      this.setAgentType(AgentType.GENERAL);
      this.setAgencyName(uplineAgencyDetails.uplineAgencyId.name);
      this.setGeneralAgentConfig(uplineAgencyDetails, producerData);
    }
  }

  loadGeneralAgentDocument(
    uplineAgencyDetails: any,
    producerData: InsureTrekProducer
  ) {
    this.setAgentType(AgentType.GENERAL);
    this.setAgencyName(uplineAgencyDetails.uplineAgencyId.name);
    this.setGeneralAgentConfig(uplineAgencyDetails, producerData);
  }
}

export const agentSideStore = new AgentPortalStore();
