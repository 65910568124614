import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import CustomIcon from '../common/customiseIcon/customIcon';
import CustomMenu from '../common/customMenu/customMenu';
import { IconConstants } from '../../constants/icons.constants';
import MenuItem from 'antd/es/menu/MenuItem';
import type { MenuProps } from 'antd';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import { agencyProfileStore } from '../../stores/agencyProfileStore';
import buildingIcon from '../../assets/icons/buildingIcon.svg';
import { getDefaultSideBarOpenKey } from '../../utils/siderMenu.utils';
import homeIcon from '../../assets/icons/homeIcon.svg';
import information from '../../assets/icons/agentInfo.svg';
import { observer } from 'mobx-react-lite';
import { siderMenuKey } from '../../types/common/sidebar.type';
import { tourStore } from '../../stores/tour.store';
import userSettingsIcon from '../../assets/icons/userSettingsIcon.svg';
import { withRoles } from '../../auth/useRoles';

// also add in layout.tsx - 133 for showing the sidebar on refresh
export const ADMIN_AGENT_MENU_ROUTES = [
  '/Home',
  '/activity',
  '/appointments',
  '/employment-history',
  '/support-documents',
  '/background-questions',
  '/manage-licenses',
  '/pending-applications',
];

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: string,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void,
  id?: string
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    id: id || `sider-menu-${label.toLowerCase().replace(' ', '-')}`, // changing this will cause issue in the component tour, refer tour.constant.ts file
  } as MenuItem;
}

type menuProp = {
  collapsed: boolean;
  type: 'main' | 'subMenu';
};

function AdminMenu({ collapsed, type }: menuProp) {
  const navigate = useNavigate();
  const location = useLocation();
  const [agentId, setAgentId] = useState<string>('');
  const handleNavigate = (path: string): void => {
    navigate(path);
  };

  const adminMenu = useMemo(
    () => [
      getItem(
        'Home',
        RouteConstants.allAgents.key,
        <CustomIcon icon={homeIcon} />,
        undefined,
        () => handleNavigate(RouteConstants.allAgents.path)
      ),
      getItem(
        'Producers',
        siderMenuKey.ADMIN_PORTAL_PRODUCERS,
        <CustomIcon icon={IconConstants.agents} />,
        [
          getItem(
            'Manage Producers',
            RouteConstants.manageAssignments.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.manageAssignments.path)
          ),
          getItem(
            'Manage Licenses',
            RouteConstants.agencyLicenses.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agencyLicenses.path)
          ),
          getItem(
            'Appointments',
            RouteConstants.agentRTS.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agentRTS.path)
          ),
          getItem(
            'Pending Applications',
            RouteConstants.agencyAgentApplications.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agencyAgentApplications.path)
          ),
        ]
      ),

      getItem(
        'Agency',
        siderMenuKey.ADMIN_PORTAL_AGENCY,
        <CustomIcon icon={buildingIcon} />,
        [
          getItem(
            'Licenses',
            RouteConstants.licensesAgency.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.licensesAgency.path)
          ),

          getItem(
            'Territories',
            RouteConstants.stateGroups.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.stateGroups.path)
          ),
          getItem(
            'Appointments',
            RouteConstants.agencyRTS.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agencyRTS.path)
          ),
          getItem(
            'Pending Applications',
            RouteConstants.agencyApplications.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agencyApplications.path)
          ),

          getItem(
            'Organization',
            RouteConstants.agencyHierarchy.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agencyHierarchy.path)
          ),

          getItem(
            'Details',
            RouteConstants.agencyGeneralProfile.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.agencyGeneralProfile.path)
          ),
        ]
      ),
      getItem(
        'Setup',
        siderMenuKey.ADMIN_PORTAL_SETUP,
        <CustomIcon icon={userSettingsIcon} />,
        [
          getItem(
            'Billing',
            RouteConstants.billing.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.billing.path),
            'sider-menu-admin-billing'
          ),
          getItem(
            'Users',
            RouteConstants.admins.key,
            undefined,
            undefined,
            () => handleNavigate(RouteConstants.admins.path)
          ),
        ]
      ),
    ],
    []
  );

  // please update the newly added routes in the ADMIN_AGENT_MENU_ROUTES constant for navigating back to previous section
  const adminAgentMenu: MenuItem[] = [
    getItem(
      'Home',
      RouteConstants.agentOverview.key,
      RouteConstants.agentOverview.icon,
      undefined,
      () =>
        handleNavigate(
          RouteConstants.agentOverview.path.replace(':agentId', agentId)
        )
    ),
    getItem(
      'My Producer',
      siderMenuKey.ADMIN_PORTAL_PRODUCERS_OVERVIEW_MY_PRODUCER,
      <CustomIcon icon={IconConstants.agentLicenses} />,
      [
        getItem(
          'Manage Licenses',
          RouteConstants.agentOverviewAllLicenses.key,
          undefined,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agentOverviewAllLicenses.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Pending Applications',
          RouteConstants.agentOverviewPendingApplications.key,
          undefined,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agentOverviewPendingApplications.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Appointments',
          RouteConstants.rtsViewAgentsFromOverView.key,
          undefined,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.rtsViewAgentsFromOverView.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
      ],
      undefined
    ),

    getItem(
      'Onboarding',
      siderMenuKey.ADMIN_PORTAL_PRODUCERS_OVERVIEW_ONBOARDING,
      <CustomIcon icon={information} />,
      [
        getItem(
          'Employment History',
          RouteConstants.agencyEmploymentHistory.key,
          undefined,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agencyEmploymentHistory.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Background Questions',
          RouteConstants.agencyBackgroundQuestions.key,
          undefined,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agencyBackgroundQuestions.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
        getItem(
          'Supporting Documents',
          RouteConstants.agencySupportDocuments.key,
          undefined,
          undefined,
          () =>
            handleNavigate(
              RouteConstants.agencySupportDocuments.path.replace(
                ':agentId',
                agentId
              )
            )
        ),
      ]
    ),
  ];

  const menu = type === 'main' ? adminMenu : adminAgentMenu;

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      navigate(RouteConstants.allAgents.path);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const match =
      path.match(/\/agent\/([0-9a-fA-F]{24})\/home/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/activity/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/appointments/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/support-documents/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/employment-history/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/background-questions/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/manage-licenses/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/pending-applications/) ||
      path.match(/\/agent\/([0-9a-fA-F]{24})\/licenses/);
    if (match) {
      const agentId = match[1];
      setAgentId(agentId);
    }
    if (path === '/') {
      navigate(RouteConstants.allAgents.path);
    }
  }, [
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/home/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/activity/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/appointments/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/suppport-documents/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/employment-history/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/background-questions/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/licenses/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/manage-licenses/),
    location.pathname.match(/\/agent\/([0-9a-fA-F]{24})\/pending-applications/),
  ]);

  return (
    <CustomMenu
      menuKeys={menu.map((itr, index) => itr?.key || index)}
      items={menu}
      defaultOpenKey={getDefaultSideBarOpenKey(
        menu as { key: string }[],
        location.pathname,
        tourStore.sideBarOpen
      )}
      defaultSelectedKey={[type === 'main' ? 'admin-agents' : '1']}
      style={{
        width: collapsed ? 100 : 260,
        boxSizing: 'border-box',
        overflow: 'auto',
        userSelect: 'none',
      }}
    />
  );
}

AdminMenu.displayName = 'AdminMenu';

export default withRoles(observer(AdminMenu), [
  RoleType.SUPER_ADMIN,
  RoleType.ADMIN,
]);
