import { MyAccountKeys, MyAccountTabOptions } from './settings-constant';
import React, { useEffect, useState } from 'react';

import Invites from '../../invites';
import TabView from '../../../components/common/tabview/tabview';
import UserProfile from './userProfile';
import { UserType } from '../../../enums/userType.enum';
import { observer } from 'mobx-react';
import { signupStore } from '../../../stores/signupStore';
import { useSearchParams } from 'react-router-dom';

export interface userProfileProps {
  hideDetails?: boolean;
}

const MyAccount: React.FC<userProfileProps> = ({ hideDetails }) => {
  const isProducerPortal = UserType.AGENT === signupStore.getUserType();
  const [selectedTab, setSelectedTab] = useState(
    MyAccountKeys.YOUR_INFORMATION
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (selected: string) => {
    setSelectedTab(selected);
    setSearchParams({ tab: selected }, { replace: true });
  };

  useEffect(() => {
    const params = searchParams.get('tab');
    if (params && Object.values(MyAccountKeys).includes(params)) {
      setSelectedTab(params);
    }
  }, []);

  return (
    <div className="onboard-active w-full remove-margin">
      <TabView
        options={MyAccountTabOptions}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
      <div className="mt-3">
        {selectedTab === MyAccountKeys.YOUR_INFORMATION && (
          <UserProfile hideDetails={hideDetails || !!isProducerPortal} />
        )}
        {selectedTab === MyAccountKeys.TEAM_INVITES && <Invites />}
      </div>
    </div>
  );
};

export default observer(MyAccount);
