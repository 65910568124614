import React from 'react';

const EngineLightProfileIcon = ({ color = 'white' }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5041 1.5C10.778 1.5 11 1.72248 11 1.9967V10.0033C11 10.2776 10.7723 10.5 10.5041 10.5H1.4959C1.22203 10.5 1 10.2775 1 10.0033V1.9967C1 1.72238 1.22766 1.5 1.4959 1.5H10.5041ZM10 2.5H2V9.5H10V2.5ZM9 7.5V8.5H3V7.5H9ZM6 3.5V6.5H3V3.5H6ZM9 5.5V6.5H7V5.5H9ZM5 4.5H4V5.5H5V4.5ZM9 3.5V4.5H7V3.5H9Z"
        fill={color}
      />
    </svg>
  );
};

export default EngineLightProfileIcon;
