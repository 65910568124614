import { Button, Drawer, Form, Input, Select, message } from 'antd';
import {
  EditInfoFormFields,
  EditInfoProps,
} from '../../../types/request/agents/dashboard.type';
import {
  formatPhoneAndFax,
  isPhoneNumberValid,
} from '../../../utils/form-utils/format-phone-fax.utils';

import { GenderType } from '../../../enums/genderType.enum';
import { IdConstants } from '../../../constants/id.constants';
import LinkOutline from '../../../components/common/icons/LinkOutline';
import { NIPR_EDIT_CONTACT_INFO } from '../../../constants/url.constants';
import React from 'react';

const EditInfo: React.FC<EditInfoProps> = ({
  isOpen,
  setIsOpen,
  setSsn,
  ssn,
  handleInfoEdit,
  producer,
  loading,
}) => {
  const isOtherInformation = isOpen === 'other-information';
  const [form] = Form.useForm();

  const onCancel = () => {
    if (loading) return;
    setIsOpen(null);
    setSsn(producer?.ssn);
  };

  const validateFields = (fields: EditInfoFormFields) => {
    const validations: { field: keyof EditInfoFormFields; message: string }[] =
      isOtherInformation
        ? [
            { field: 'ssn', message: 'Please enter a valid SSN' },
            { field: 'gender', message: 'Please enter a gender' },
          ]
        : [
            {
              field: 'businessEmail',
              message: 'Please enter a valid email address',
            },
            {
              field: 'businessPhone',
              message: 'Please enter a valid phone number',
            },
          ];

    for (const validation of validations) {
      if (
        !fields?.[validation.field] ||
        (validation.field === 'businessPhone' &&
          !isPhoneNumberValid(fields?.[validation?.field] || ''))
      ) {
        message.error(validation.message);
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    const updatedFields = form.getFieldsValue();

    if (validateFields(updatedFields)) {
      handleInfoEdit(updatedFields);
    }
  };

  return (
    <Drawer
      destroyOnClose
      open={!!isOpen}
      title={null}
      closable={!!loading}
      onClose={() => {
        onCancel();
      }}
      key={'agent-edit-details-form'}
      width={isOtherInformation ? 720 : 840}
      headerStyle={{ display: 'none' }}
      placement="right"
      rootClassName="top-0-drawer"
      bodyStyle={{
        borderRadius: '16px',
        overflow: 'hidden',
      }}
      style={{
        top: 0,
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '16px',
        boxShadow: 'none',
      }}
    >
      <div className="flex items-center font-figtree justify-between">
        <div className="font-semibold text-base text-dark-blue leading-7">
          {isOtherInformation ? (
            'Edit Information'
          ) : (
            <div>
              Edit NIPR Contact Information{' '}
              <div className="text-xs text-[#5D5D5D]">
                This will not be updated in NIPR. It is only updated on
                InsureTrek.
              </div>
            </div>
          )}
        </div>
        <div className="flex items-center gap-3">
          {isOtherInformation ? null : (
            <a href={NIPR_EDIT_CONTACT_INFO} target="_blank" rel="noreferrer">
              <div
                onClick={() => {}}
                className="flex gap-1 pr-1 cursor-pointer"
              >
                <LinkOutline fill="#001F45" />
                <span className="text-[#001F45] cursor-pointer text-xs font-medium leading-[14.4px] underline">
                  Edit In NIPR{' '}
                </span>
              </div>
            </a>
          )}
          <Button
            id={IdConstants.AGENT_INFO.EDIT_INFO_CANCEL}
            className="h-7 rounded-md hover-reset bg-[#F5F5F5] min-w-[120px] grid place-content-center  text-dark-blue hover:text-dark-blue border-none hover:border text-xs font-figtree"
            onClick={() => {
              onCancel();
            }}
            disabled={loading}
            size="middle"
          >
            Cancel
          </Button>
          <Button
            id={IdConstants.AGENT_INFO.EDIT_INFO_APPLY}
            className="figtree h-7 text-xs min-w-[120px] grid place-content-center  bg-dark-blue text-white "
            onClick={() => {
              handleSubmit();
            }}
            loading={loading}
            size="middle"
          >
            Save Changes
          </Button>
        </div>
      </div>{' '}
      <Form
        layout="vertical"
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}
        form={form}
        disabled={loading}
        preserve={false}
        onFinish={handleInfoEdit}
      >
        {isOtherInformation ? (
          <>
            <Form.Item
              label="SSN"
              name="ssn"
              className="mt-6"
              initialValue={ssn || producer?.ssn || ''}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^.{6,9}$/,
                  message: 'SSN must be between 6 and 9 digits',
                },
              ]}
            >
              <Input
                className="display-input"
                placeholder="Enter a Social Security Number"
                value={ssn}
                autoComplete="off"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.length <= 9) setSsn(value);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  const pastedText = event.clipboardData.getData('text/plain');
                  if (!/^[0-9]+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && ssn?.startsWith('**')) {
                    form.setFieldsValue({ ssn: '' });
                    setSsn('');
                    event.preventDefault();
                  }
                }}
                minLength={9}
                maxLength={9}
              />
            </Form.Item>
            <Form.Item
              label="Gender"
              name="gender"
              initialValue={producer?.gender || undefined}
              rules={[
                {
                  required: true,
                  message: 'Please select your gender',
                },
              ]}
            >
              <Select placeholder="Select Gender">
                <Select.Option value={GenderType.Male}>Male</Select.Option>
                <Select.Option value={GenderType.Female}>Female</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Contact Email address"
              name="contactEmail"
              rules={[{ required: true }]}
              initialValue={producer?.contactEmail}
            >
              <Input
                className="display-input"
                disabled
                placeholder="Enter Contact Email"
              />
            </Form.Item>{' '}
          </>
        ) : (
          <>
            <Form.Item
              label="Email address"
              name="businessEmail"
              className="mt-6"
              rules={[{ required: true }]}
              initialValue={producer?.businessEmail}
            >
              <Input
                className="display-input"
                placeholder="Enter Email Address"
              />
            </Form.Item>
            <Form.Item
              name="businessPhone"
              label="Phone"
              rules={[
                { required: true },
                () => ({
                  validator(_, value) {
                    const numericValue = value.replace(/\D/g, '');
                    const formattedValue = formatPhoneAndFax(numericValue);
                    if (!value || isPhoneNumberValid(formattedValue)) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Please enter a valid phone number');
                  },
                }),
              ]}
              initialValue={producer?.businessPhone}
              className="w-full"
            >
              <Input
                onChange={(e) => {
                  const formattedValue = formatPhoneAndFax(e.target.value);
                  if (!e.target.value || isPhoneNumberValid(formattedValue)) {
                    form.setFieldsValue({ businessPhone: formattedValue });
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={(event) => {
                  const pastedText = event.clipboardData.getData('text/plain');
                  if (!/^[0-9]+$/.test(pastedText)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>{' '}
          </>
        )}
      </Form>
    </Drawer>
  );
};

export default EditInfo;
