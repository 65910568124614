import { Breadcrumb, Card, Col, Row, Typography } from 'antd';
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import { HeaderProps } from './header';
import LinkOutline from '../common/icons/LinkOutline';
import { RoleType } from '../../enums/roles.enum';
import { RouteConstants } from '../../constants/routes.constants';
import SelectProfile from '../common/userProfile/selectProfile';
import { StripeService } from '../../services/stripe.service';
import { agentSideStore } from '../../stores/agentPortalStore';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../auth/authProvider';
import { useLocation } from 'react-router';
import { useState } from 'react';
import { withRoles } from '../../auth/useRoles';

function AgentHeader({ headerText }: HeaderProps) {
  const location = useLocation();
  const path = location.pathname;
  const [stripeLoader, setStripeLoader] = useState(false);
  const { bearerToken } = useAuth();
  const navigateToBilling = () => {
    try {
      setStripeLoader(true);
      if (bearerToken) {
        StripeService.getBillingPortalURL(bearerToken)
          .then((response: any) => {
            window.open(
              response?.data?.billingDashboardUrl,
              '_blank',
              'rel=noopener noreferrer'
            );
          })
          .catch((err) => {
            console.error('Error while getting billing portal :: ', err);
          })
          .finally(() => {
            setStripeLoader(false);
          });
      }
    } catch (error) {
      console.error(
        `Error while navigating to stripe billing : Error | ${error}`
      );
    }
  };
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();

  return (
    <>
      {subscriptionStatusInactive && (
        <div className="w-full bg-[#FFC942]">
          <div className="flex justify-between items-center px-4 py-1 w-full">
            <div className="flex items-center ml-1 mb-1">
              <InfoCircleOutlined
                className="mr-2 text-[#222222]"
                style={{ color: '#222222' }}
              />
              <span className="min-w-fit">Payment Failed</span>
              <span className="ml-4">
                We were unable to process your payment on Stripe. Please update
                your card details on Stripe.
              </span>
            </div>
            <div
              className="flex items-center min-w-fit cursor-pointer"
              onClick={() => {
                navigateToBilling();
              }}
            >
              {stripeLoader ? (
                <ReloadOutlined className="mr-1" spin={true} />
              ) : (
                <LinkOutline className="mr-1" fill="#222222" />
              )}
              <span className="underline">Visit Billing</span>
            </div>
          </div>
        </div>
      )}
      <Card
        style={{
          borderTop: 'none',
          borderRight: '1px solid rgba(5, 5, 5, 0.06)',
        }}
        type="inner"
        className="header h-[70px] flex w-full items-center  bg-[#fafafa]"
      >
        <Row style={{ justifyContent: 'space-between' }}>
          <Col className="flex flex-col">
            <Row align="middle" className="gap-2">
              {appStateInfoStore.menuIcon}
              <Typography
                style={{
                  color: 'var(--primary-color)',
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '28px',
                }}
              >
                {headerText}
              </Typography>
              <div style={{ width: 'auto', borderRadius: 1, flexGrow: 1 }} />
            </Row>
            <Row
              style={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              {path === RouteConstants.agentApplications.path && (
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: 'My Producer',
                    },
                    {
                      title: 'Pending Applications',
                    },
                  ]}
                />
              )}

              {path === RouteConstants.agentAllLicenses.path && (
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: 'My Producer',
                    },
                    {
                      title: 'Manage Licenses',
                    },
                  ]}
                />
              )}
              {path === RouteConstants.employmentHistory.path && (
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: 'Onboarding',
                    },
                    {
                      title: 'Employment History',
                    },
                  ]}
                />
              )}
              {path === RouteConstants.backgroundQuestions.path && (
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: 'Onboarding',
                    },
                    {
                      title: 'Background Questions',
                    },
                  ]}
                />
              )}
              {path === RouteConstants.agentSupportDocuments.path && (
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: 'Onboarding',
                    },
                    {
                      title: 'Supporting Documents',
                    },
                  ]}
                />
              )}

              {path === RouteConstants.agentPortalRTS.path && (
                <Breadcrumb
                  separator=">"
                  items={[
                    {
                      title: 'My Producer',
                    },
                    {
                      title: 'Appointments',
                    },
                    {
                      title: agentSideStore?.agentName,
                    },
                  ]}
                />
              )}

              {/* {path === RouteConstants.wallet.path && (
          <Breadcrumb
            separator=">"
            items={[
              {
                title: 'Settings',
              },
              {
                title: 'Wallet',
              },
            ]}
          />
        )} */}
            </Row>
          </Col>
          {/* <Col
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <SelectProfile />
        </Col> */}
        </Row>
      </Card>
    </>
  );
}

export default withRoles(observer(AgentHeader), [
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
]);
