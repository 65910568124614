import {
  DollarOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  HomeOutlined,
} from '@ant-design/icons';

import EngineLight from './engineLight';
import { RouteConstants } from '../../constants/routes.constants';
import { Row } from 'antd';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getNameString } from '../../utils/name.utils';
import { getStateNameFromCode } from '../../utils/common.utils';
import { isArray } from 'lodash';

type EngineLightsProps = {
  setPayerCallBack: () => void;
  disable?: boolean;
  isAgentPortal?: boolean;
  record: any;
  selectedState: string;
};

export const getEmploymentHistoryTooltip = (history: object[]) => {
  // if (!history || !history?.length)
  //   return 'Producer employment history is not filled';
  return 'Producer missing 5-year consecutive or latest employment history';
};

function ActionableEngineLights({
  setPayerCallBack,
  disable,
  isAgentPortal,
  record,
  selectedState,
}: EngineLightsProps) {
  let backgroundQuestionStatus = false;
  if (isArray(record.engineLights?.backgroundQuestion)) {
    let commonQuestionsAnswered = false;
    let nonUniformAnswered = true;
    record.engineLights?.backgroundQuestion.forEach((status: any) => {
      if (status.stateCode === selectedState && status.stateCode === 'ALL') {
        commonQuestionsAnswered = status.isAllAnswered;
      } else if (status.stateCode === selectedState) {
        nonUniformAnswered = status.isAllAnswered;
      }
    });
    backgroundQuestionStatus = commonQuestionsAnswered && nonUniformAnswered;
  }
  const handlePayerClick = () => {
    setPayerCallBack();
  };

  const paymentEngineLight =
    record.paymentConfig?.licenseInitialPayee &&
    record.paymentConfig?.licenseRenewalPayee &&
    record.paymentConfig?.paymentType;
  return (
    <Row
      style={{
        height: '20px',
        ...(disable ? { pointerEvents: 'none' } : {}),
      }}
    >
      <EngineLight
        icon={<DollarOutlined />}
        status={!paymentEngineLight ? 'red' : 'green'}
        tooltip={
          disable
            ? null
            : record?.engineLights.paymentConfig
              ? 'Payment is Configured'
              : 'Payment is Not Configured'
        }
        styles={{
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
          borderRight: `1px solid ${
            !paymentEngineLight ? '#EB3131' : '#31EB91'
          }`,
        }}
        onClick={handlePayerClick}
      />
      <EngineLight
        icon={<FieldTimeOutlined />}
        status={record?.engineLights?.employmentHistory ? 'green' : 'red'}
        tooltip={
          disable
            ? null
            : record?.engineLights?.employmentHistory
              ? 'Producer employment history is filled'
              : getEmploymentHistoryTooltip(record?.employmentHistory)
        }
        styles={{
          borderRight: `1px solid ${
            !record?.engineLights?.employmentHistory ? '#EB3131' : '#31EB91'
          }`,
        }}
        onClick={() => {
          if (record?.id) {
            appStateInfoStore.setHeader(getNameString(record?.name));
            window.open(
              isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.employmentHistory.path}`
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyEmploymentHistory.path.replace(
                    ':agentId',
                    record?.id
                  )}`,
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<FileTextOutlined />}
        status={backgroundQuestionStatus ? 'green' : 'red'}
        tooltip={
          disable
            ? null
            : backgroundQuestionStatus
              ? 'Producer background questions are answered'
              : 'Producer background questions are not answered'
        }
        styles={{
          borderRight: `1px solid ${
            !backgroundQuestionStatus ? '#EB3131' : '#31EB91'
          }`,
        }}
        onClick={() => {
          if (record?.id) {
            appStateInfoStore.setHeader(getNameString(record?.name));
            window.open(
              isAgentPortal
                ? `${process.env.REACT_APP_REDIRECT_URI}${RouteConstants.backgroundQuestions.path}`
                : `${
                    process.env.REACT_APP_REDIRECT_URI
                  }${RouteConstants.agencyBackgroundQuestions.path.replace(
                    ':agentId',
                    record?.id
                  )}`,
              '_blank',
              'rel=noopener noreferrer'
            );
          }
        }}
      />
      <EngineLight
        icon={<HomeOutlined />}
        status={
          record?.engineLights?.isResidentLicenseActive
            ? 'green'
            : record?.residentState
              ? 'yellow'
              : 'red'
        }
        styles={{
          borderTopRightRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
        tooltip={
          record?.residentState
            ? `Resident state: ${getStateNameFromCode(record?.residentState)}`
            : 'Resident license is missing'
        }
      />
    </Row>
  );
}

export default ActionableEngineLights;
