import './bgQuestions.css';

import {
  Button,
  Card,
  Skeleton,
  Typography,
  message,
  notification,
} from 'antd';
import {
  EmployeeHistory,
  agentSideStore,
} from '../../../../stores/agentPortalStore';
import { useEffect, useState } from 'react';

import { AgentService } from '../../../../services/agent.service';
import DisplayDetails from '../employmentHistory/displayDetails';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import GetDetails from '../employmentHistory/getDetails';
import { IdConstants } from '../../../../constants/id.constants';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RoleType } from '../../../../enums/roles.enum';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { calculateLongestContinuousDuration } from '../../agentSignupSteps';
import { cloneDeep } from 'lodash';
import { getNameString } from '../../../../utils/name.utils';
import { observer } from 'mobx-react-lite';
import { useAuth } from '../../../../auth/authProvider';
import { useParams } from 'react-router';
import { withRoles } from '../../../../auth/useRoles';

type getEmpHistoryProps = {
  isOnboarded: boolean;
  isAgency?: boolean;
  isOnboardPage?: boolean;
  onBoardedAgentId?: string;
};

function EmploymentHistory({
  isOnboarded,
  isAgency,
  isOnboardPage,
  onBoardedAgentId,
}: getEmpHistoryProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { agentId } = useParams();
  const { bearerToken } = useAuth();
  const [
    calculateEmploymentHistoryDuration,
    setCalculateEmploymentHistoryDuration,
  ] = useState(false);
  const [prevEmployeeHistory, setPrevEmployeeHistory] = useState<
    EmployeeHistory[]
  >(agentSideStore.getEmpHistory());

  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const handleClick = (updating: boolean) => {
    agentSideStore.setIsUpdating(updating);
    agentSideStore.setIsEditing(false);
  };

  const addAnotherCard = () => {
    agentSideStore.setIsUpdating(true);
    agentSideStore.setIsEditing(true);
  };

  useEffect(() => {
    setCalculateEmploymentHistoryDuration(
      calculateLongestContinuousDuration(agentSideStore.getEmpHistory()) >= 60
    );
  }, [JSON.stringify(agentSideStore.empHistory)]);

  const loadAgent = async () => {
    if (agentId && bearerToken) {
      const response: any = await AgentService.getAgentDetails(
        agentId,
        bearerToken
      ).catch((error) => {
        console.error('Error:: ', error.message);
      });
      if (response.data) {
        appStateInfoStore.setCurrentAgentId(response.data.id);
        appStateInfoStore.setHeader(getNameString(response.data.name));
      }
    }
  };

  useEffect(() => {
    if (isAgency) {
      loadAgent();
    }
  }, []);

  const saveEmployeeHistory = async () => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        agentSideStore.setIsEditingFalse();
        const response: any = await AgentService.createEmploymentHistory(
          agentSideStore.getEmpHistory(),
          bearerToken,
          onBoardedAgentId || agentId || ''
        );
        if (response.status === 'SUCCESS') {
          message.success('Employement History Updated');
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      message.error('Error while updating Employement History');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const agent = isAgency
      ? onBoardedAgentId || agentId
      : agentSideStore.agentId;
    if (
      bearerToken &&
      (agentSideStore.getAgentId() || agentId || onBoardedAgentId) &&
      isOnboarded
    ) {
      fetchEmploymentHistory(agent || '', bearerToken);
    }
  }, [agentSideStore.agentId, bearerToken]);

  const fetchEmploymentHistory = async (
    agentId: string,
    bearerToken: string
  ) => {
    try {
      if (bearerToken) {
        setIsLoading(true);
        const response: any = await AgentService.getEmploymentHistory(
          bearerToken,
          isAgency ? agentId : undefined
        );

        if (response.status === 'SUCCESS') {
          agentSideStore.setEmploymentHistory(response.data);
          if (!response.data.length) {
            agentSideStore.setIsUpdating(true);
            agentSideStore.setIsEditing(true);
          } else {
            handleClick(false);
          }
          const prevData = cloneDeep(agentSideStore.getEmpHistory());
          setPrevEmployeeHistory(prevData);
          setIsLoading(false);
        }
      }
    } catch (error) {
      showError(ErrorMessageConstants.COMMON);
      console.error('Error In Getting Employee History ', error);
      setIsLoading(false);
    }
  };

  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  return (
    <Card
      style={{
        width: '100%',
        height: 'fit-content',
      }}
    >
      {contextHolder}
      {isLoading ? (
        <div
          className="spin-style"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Skeleton active />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 200,
            justifyContent: 'space-between',
          }}
        >
          {isOnboarded && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <Typography.Text
                type="secondary"
                style={{ marginBottom: 4, fontSize: 14 }}
              >
                <InfoCircleOutlined /> Please provide employment history for the
                past 5 years consecutively. Latest employment history must be
                from 1 month ago for successful license application processing
              </Typography.Text>
            </div>
          )}
          <div style={{ overflowY: 'auto' }}>
            {agentSideStore.getEmpHistory() &&
              agentSideStore
                .getEmpHistory()
                .map((emp: EmployeeHistory, index: number) => {
                  return emp.isEditing ? (
                    <Card
                      className={`content-card ${
                        isOnboardPage ? ' onboard' : ''
                      } `}
                    >
                      <GetDetails
                        setLoading={setIsLoading}
                        buttonClick={(props: boolean) => {
                          emp.isEditing = props;
                          handleClick(props);
                        }}
                        emp={emp}
                        isOnboarded={isOnboarded}
                        saveEmployeehistory={saveEmployeeHistory}
                        index={index}
                      />
                    </Card>
                  ) : (
                    <Card
                      className={`content-card ${
                        isOnboardPage ? ' onboard' : ''
                      } `}
                    >
                      <DisplayDetails emp={emp} />
                    </Card>
                  );
                })}

            {agentSideStore.getIsEditing() && (
              <div>
                <Card
                  className={`content-card ${isOnboardPage ? ' onboard' : ''} `}
                >
                  <GetDetails
                    isNewRecord={true}
                    isOnboarded={isOnboarded}
                    buttonClick={handleClick}
                    setLoading={setIsLoading}
                    emp={agentSideStore.newEmpHistoryItem}
                    saveEmployeehistory={saveEmployeeHistory}
                  />
                </Card>
              </div>
            )}
          </div>

          <div
            style={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              id={IdConstants.EMPLOYMENT_HISTORY.ADD_ANOTHER}
              className="card-button"
              style={{
                textAlign: 'center',
                width: '250px',
                height: '45px',
                background: agentSideStore.getIsUpdating() ? '' : '#0588ca',
                color: agentSideStore.getIsUpdating() ? '' : 'white',
                borderRadius: '8px',
              }}
              onClick={addAnotherCard}
              disabled={
                agentSideStore.getIsUpdating() || subscriptionStatusInactive
              }
            >
              {agentSideStore.getEmpHistory().length === 0 ? (
                <span>+ Add</span>
              ) : (
                <span>+ Add Another</span>
              )}
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
}

export default withRoles(observer(EmploymentHistory), [
  RoleType.AGENT,
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
  RoleType.INDIVIDUAL_PRODUCER,
]);
