import { Button, Modal, Spin, message } from 'antd';
import OwnershipDetailsCard, {
  OWNERSHIP_TYPE_FIELDS,
} from './OwnershipDetailsCard';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { AgencyService } from '../../../../services/agency.service';
import OwnershipDetailsDisplayCard from './OwnershipDetailsDisplayCard';
import { PlusOutlined } from '@ant-design/icons';
import { appStateInfoStore } from '../../../../stores/appStateInfo.store';
import { formatDate } from '../../../../utils/date.utils';
import { useAuth } from '../../../../auth/authProvider';

export type userDetails = {
  firstName: string;
  id?: string;
  lastName: string;
  npn: number;
  ownershipPercent: number;
  dateOfBirth: string | Date | Dayjs;
  title: string;
  type: string;
  selectedType?: string;
  firmOrLastName?: string;
  fein: string;
  ssn: string;
  agencyId?: string;
  stateCode?: string;
  zipCode?: string;
  state?: string;
  zipcode?: string;
};

export type ModalType = 'delete' | 'edit';

const OwnershipDetails = () => {
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const { bearerToken } = useAuth();
  const [loading, setLoading] = useState({
    confirmLoading: false,
    getLoading: false,
    postLoading: false,
    deleteLoading: false,
  });
  const [ownershipRecords, setOwnershipRecords] = useState<userDetails[]>([]);
  const [addAnotherOwner, setAddAnotherOwner] = useState(false);

  const [modal, setModal] = useState('');
  const [selectedUserDetails, setSelectedUserDetails] =
    useState<userDetails | null>();
  const [initialValues, setInitialValues] = useState<any>();

  const onPopoverCta = (userDetails: userDetails, cta?: ModalType) => {
    setSelectedUserDetails({
      ...userDetails,
      dateOfBirth: userDetails?.dateOfBirth
        ? dayjs(formatDate(userDetails?.dateOfBirth as string))
        : '',
    });
    if (!cta) return;

    setModal(cta);
  };

  const onDeleteOwnershipDetails = async () => {
    if (!bearerToken) return;
    try {
      setLoading((prev) => ({ ...prev, deleteLoading: true }));
      await AgencyService.deleteOwnershipDetails(
        bearerToken,
        selectedUserDetails?.id || ''
      );
      message.success('Owner Deleted Successfully');
      setSelectedUserDetails(null);
      getOwnershipDetails();
      setModal('');
    } catch (error) {
      console.error(error);
      message.error('Failed to delete ownership details.please try again');
    } finally {
      setLoading((prev) => ({ ...prev, deleteLoading: false }));
    }
  };

  const getOwnershipDetails = async () => {
    if (!bearerToken) return;
    try {
      setLoading((prev) => ({ ...prev, getLoading: true }));
      const ownershipDetails =
        await AgencyService.getOwnershipDetails(bearerToken);
      setOwnershipRecords(ownershipDetails as userDetails[]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({ ...prev, getLoading: false }));
    }
  };

  useEffect(() => {
    getOwnershipDetails();
  }, []);

  useEffect(() => {
    setAddAnotherOwner(!ownershipRecords.length);
  }, [ownershipRecords]);

  const isOwnershipPercentageExceeds = (
    details: userDetails,
    isEditMode: boolean
  ) => {
    const percentage = ownershipRecords.reduce((acc, val) => {
      if (isEditMode && val.id === selectedUserDetails?.id) return acc;
      return val.ownershipPercent + acc;
    }, details.ownershipPercent);
    return percentage > 100;
  };

  const onSave = async (
    isEdit: boolean,
    values: userDetails,
    clear: () => void
  ) => {
    if (!bearerToken) return;
    try {
      if (isOwnershipPercentageExceeds(values, isEdit)) {
        message.error('Ownership Percentage should not exceed 100');
        return;
      }
      if (isEdit) {
        setLoading((prev) => ({
          ...prev,
          confirmLoading: true,
        }));
        const { agencyId, ...updatedValues } = values;
        if (updatedValues?.ssn?.toString()?.startsWith('**')) {
          delete (updatedValues as { ssn?: string }).ssn;
        }
        await AgencyService.editOwnershipDetails(
          bearerToken,
          selectedUserDetails?.id as string,
          updatedValues
        );
        setSelectedUserDetails(null);
      } else {
        setLoading((prev) => ({
          ...prev,
          postLoading: true,
        }));
        await AgencyService.postOwnershipDetails(bearerToken, values);
        setAddAnotherOwner(false);
      }
      setModal('');
      getOwnershipDetails();
      clear();
    } catch (error: any) {
      console.error(error);
      message.error(
        error?.response?.data?.message ||
          'Failed to update ownership details.please try again.'
      );
    }
    setLoading((prev) => ({
      ...prev,
      postLoading: false,
      confirmLoading: false,
    }));
  };

  useEffect(() => {
    setInitialValues({
      ...selectedUserDetails,
      fein: selectedUserDetails?.fein,
      ssn: selectedUserDetails?.ssn,
      state: selectedUserDetails?.stateCode,
      zipcode: selectedUserDetails?.zipCode,
    });
  }, [selectedUserDetails]);

  const modalProps = {
    delete: {
      confirmLoading: loading.deleteLoading,
      width: 'fit-content',
      title: (
        <div style={{ marginBottom: 32, marginRight: 48 }}>
          Are you sure to remove{' '}
          {selectedUserDetails?.firmOrLastName ||
            selectedUserDetails?.firstName}
          {selectedUserDetails?.lastName} from ownership ?
        </div>
      ),
      destroyOnClose: true,
      closable: !loading.deleteLoading,
      cancelButtonProps: {
        disabled: loading.deleteLoading,
      },
      onOk: () => {
        onDeleteOwnershipDetails();
      },
      onCancel: () => {
        setModal('');
      },
    },
    edit: {
      confirmLoading: loading.confirmLoading,
      className: 'ownership-details',
      width: 900,
      closable: !loading.confirmLoading,
      footer: null,
      destroyOnClose: true,
      title: <div style={{ marginBottom: 16 }}>Edit Ownership Details</div>,
      children: (
        <>
          <OwnershipDetailsCard
            initialValues={initialValues || {}}
            refreshInitialValues={(type) => {
              setInitialValues({ isReset: true, isClearType: type });
            }}
            selected={selectedUserDetails?.type || OWNERSHIP_TYPE_FIELDS.AGENCY}
            onSave={onSave}
            loading={loading.confirmLoading}
          />
        </>
      ),
      onCancel: () => {
        setModal('');
      },
    },
  };

  return (
    <div className="ownership-details width-100">
      {loading.getLoading ? (
        <div
          style={{ display: 'grid', placeContent: 'center', minHeight: 500 }}
        >
          {' '}
          <Spin />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            {ownershipRecords.map((details) => {
              return (
                <OwnershipDetailsDisplayCard
                  {...details}
                  key={details.npn}
                  isModalOpen={!!modal}
                  onPopoverCta={onPopoverCta}
                />
              );
            })}
          </div>
          {addAnotherOwner ? (
            <OwnershipDetailsCard
              onSave={onSave}
              loading={loading.postLoading || loading.confirmLoading}
            />
          ) : (
            <Button
              type="primary"
              onClick={() => {
                setAddAnotherOwner(true);
              }}
              htmlType="submit"
              style={{
                float: 'right',
                display: 'flex',
                alignItems: 'center',
                gap: 3,
              }}
              id="agency-all-licenses-save-cta"
              disabled={subscriptionStatusInactive}
            >
              <PlusOutlined /> Add Another Owner
            </Button>
          )}
        </div>
      )}
      <Modal open={!!modal} {...(modalProps[modal as ModalType] || {})}></Modal>
    </div>
  );
};

export default OwnershipDetails;
