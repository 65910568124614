import AdminMenu, { ADMIN_AGENT_MENU_ROUTES } from './siderMenu/siderMenu';
import {
  ArrowLeftOutlined,
  LeftOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Modal, Popover, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { getInitials, getNameString } from '../utils/name.utils';
import { useLocation, useNavigate } from 'react-router';

import AgentSiderMenu from './siderMenu/agentSiderMenu';
import { MenuType } from '../enums/menuType.enum';
import { RouteConstants } from '../constants/routes.constants';
import SelectProfileSideNavbar from './common/userProfile/selectProfileSideNavbar';
import Sider from 'antd/es/layout/Sider';
import { UserType } from '../enums/userType.enum';
import { adminStore } from '../stores/admin.store';
import { agentSideStore } from '../stores/agentPortalStore';
import { observer } from 'mobx-react-lite';
import questionIcon from '../assets/icons/question-fill.svg';
import { signupStore } from '../stores/signupStore';
import { useAuth } from '../auth/authProvider';

interface MenuProps {
  menuType: MenuType;
}

interface AccountPopoverItemProps {
  icon: React.FC;
  label: string;
  onClick: () => void;
}

const AccountPopoverItem: React.FC<AccountPopoverItemProps> = ({
  icon: Icon,
  label,
  onClick,
}) => (
  <div
    className="cursor-pointer flex gap-2 hover:bg-[#EEEEEE] active:bg-[#EEEEEE] px-2 py-1 rounded-md"
    onClick={onClick}
  >
    <Icon /> {label}
  </div>
);

function Menu(props: MenuProps) {
  const { performLogout } = useAuth();
  const [logoutModal, setLogoutModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false);
  const [historyStack, setHistoryStack] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const displayName = adminStore.account
    ? `${getNameString(adminStore.account.name)}`
    : `${agentSideStore.agentName}`;
  const initials = getInitials(displayName);
  const accountPopoverContentRef = useRef<HTMLDivElement | null>(null);

  const popoverContent = (
    <div className="flex flex-col items-center">
      <Typography.Paragraph className="text-xs opacity-70 font-figtree text-[#001F45]">
        Have a question, or run into an issue? Reach out to our team.
      </Typography.Paragraph>
      <Button
        type="primary"
        className="self-start bg-[#001F45] text-xs px-3 font-medium font-figtree"
        onClick={() => {
          window.location.href = `mailto:hello@insuretrek.com`;
        }}
      >
        Get in Touch
      </Button>
    </div>
  );

  const accountPopoverContent = (
    <div
      ref={accountPopoverContentRef}
      className="flex flex-col gap-2 font-figtree reset-icon-size font-14 text-[#001F45] text-sm"
    >
      <AccountPopoverItem
        icon={UserOutlined}
        label="My account"
        onClick={() => {
          navigate(RouteConstants.agencyAdminProfile.path);
          setIsAccountPopoverOpen(false);
        }}
      />
      <AccountPopoverItem
        icon={LogoutOutlined}
        label="Logout"
        onClick={() => {
          setLogoutModal(true);
          setIsAccountPopoverOpen(false);
        }}
      />
    </div>
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accountPopoverContentRef.current &&
      !accountPopoverContentRef.current?.contains(event.target as Node)
    ) {
      setIsAccountPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname?.toLowerCase()?.startsWith('/agent') &&
      ADMIN_AGENT_MENU_ROUTES.some((route: string) =>
        location.pathname?.toLowerCase()?.endsWith(route?.toLowerCase())
      )
    )
      return;

    setHistoryStack(location.pathname);
  }, [location.pathname]);

  return (
    <Sider
      collapsible={false}
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);
        adminStore.setCollapsed(value);
      }}
      width={260}
      collapsedWidth={100}
      theme="light"
      className="h-[100vh] top-0 sticky"
    >
      <div
        className="flex flex-col max-h-[100vh] relative overflow-scroll"
        style={{
          boxShadow: '4px 0px 13px 0px rgba(0, 31, 69, 0.06)',
        }}
      >
        <div className="flex-1 justify-center">
          <SelectProfileSideNavbar />
          {props.menuType !== MenuType.MainMenu && (
            <Card className="flex w-full rounded-none bg-[#fafafa] border-t-0 border-b-0">
              <div style={{ flex: 1 }}>
                <Button
                  id="back-to-agents-view-button"
                  {...(!collapsed ? { icon: <ArrowLeftOutlined /> } : {})}
                  style={{
                    color: 'var(--primary-color)',
                    border: 'none',
                    boxShadow: 'none',
                    ...(!collapsed && { padding: 0 }),
                    backgroundColor: '#fafafa',
                  }}
                  onClick={() => {
                    // removed window.history as it saves the state if we navigate within the subsection as well.
                    if (historyStack) {
                      navigate(historyStack);
                    } else {
                      // the current entry in the history stack will be replaced with the new one with { replace: true }
                      navigate(RouteConstants.allAgents.path);
                    }
                  }}
                >
                  {!collapsed && <span>Back</span>}
                  {collapsed && <LeftOutlined />}
                </Button>
              </div>
            </Card>
          )}
          <AgentSiderMenu
            collapsed={collapsed}
            isIndividualProducer={
              signupStore?.getUserType() === UserType.INDIVIDUAL_PRODUCER
            }
          />

          <AdminMenu
            type={props.menuType === MenuType.MainMenu ? 'main' : 'subMenu'}
            collapsed={collapsed}
          />
        </div>

        <Card
          style={{
            marginInlineStart: 'auto',
            borderRight: '1px solid rgba(5, 5, 5, 0.06)',
          }}
          className="sider-menu-img-card rounded-none border-t-0 mx-4"
        >
          <div className="flex items-center justify-between">
            <Popover
              content={accountPopoverContent}
              open={isAccountPopoverOpen}
              placement="rightTop"
              overlayClassName="hide-arrow"
            >
              <div
                className="flex items-center hover:bg-[#EEEEEE] px-2 py-1 rounded-md gap-1 cursor-pointer"
                onClick={() => setIsAccountPopoverOpen((prev) => !prev)}
              >
                <Avatar
                  className=" h-5 w-5 rounded-[4px] border border-[#EBEBEB] bg-[#33485E] text-white flex justify-center items-center text-[7px]"
                  alt="logo"
                >
                  {initials}
                </Avatar>
                <Typography.Paragraph
                  className="mb-0 text-ellipsis max-w-[170px] text-sm font-medium"
                  ellipsis
                >
                  <span className="font-medium text-start font-figtree">
                    {displayName}
                  </span>
                </Typography.Paragraph>
              </div>
            </Popover>
            <Popover
              content={popoverContent}
              trigger="click"
              placement="top"
              overlayInnerStyle={{
                backgroundColor: '#D3E7FF',
                width: '240px',
              }}
              overlayClassName="need-help-sidebar"
            >
              <img
                src={questionIcon}
                alt="questionIcon"
                className="ml-auto h-5 cursor-pointer"
              />
            </Popover>
          </div>
        </Card>
      </div>
      <Modal
        title={
          <div className="font-figtree font-semibold text-xl mt-0 mb-6 text-[#07212D]">
            Are you sure you want to logout of your account?
          </div>
        }
        centered={true}
        width={539}
        closeIcon={false}
        open={!!logoutModal}
        onOk={() => performLogout(window.location.origin)}
        onCancel={() => setLogoutModal(false)}
        okText="Yes"
        okButtonProps={{
          style: {
            background: '#001F45',
            color: '#ffffff',
          },
        }}
        cancelText="No"
        destroyOnClose
      ></Modal>
    </Sider>
  );
}

export default observer(Menu);
