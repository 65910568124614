import { AgentDto, LicenseDto } from '../../types/data/rts.type';

import { Name } from '../../types/data/name.type';

export interface AgencyStateAssignmentDto {
  id: string;
  bgQuestionsEngineLight: boolean;
  stateCode: string;
  agencyLicenseClassConfigs: AgencyLicenseClassConfigDto[];
}

export interface AgencyLicenseClassConfigDto {
  id: string;
  licenseConfigIds: string[];
  drlpIds: DrlpIdDto[];
  agencyLicenseId: any[];
}

export interface DrlpIdDto {
  id: string;
  stateCode: string;
  agentId?: string | AgentDto;
  name?: Name;
  ssn?: string;
  drlpNpn: string;
  drlpLicenseId: string | LicenseDto;
  drlpLicenseNumber: string;
  drlpPdbLicenseClass: string;
  isOnboarded: boolean;
}

export interface AgencyStateAssignment {
  [key: string]: any;
}

export type dropDownTYpe = Record<string, Record<number, DropdownDrlp[]>>;

export interface selectedIds {
  selectedIds: Record<string, Record<string, string[]>>;
  setSelectedIds: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, string[]>>>
  >;
}
export interface LicenseSelectionProps {
  selectedStates: string[];
  setAgencyStateAssignment: React.Dispatch<
    React.SetStateAction<AgencyStateAssignment>
  >;
  prefilledLoaIds?: string[];
  dropdownOptions: dropDownTYpe;
  sourceDropdowns: Record<string, any[]>;
  setDropdownOptions: React.Dispatch<React.SetStateAction<any>>;
  selectedIds: Record<string, Record<string, string[]>>;
  setSelectedIds: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, string[]>>>
  >;
  setAddedLoaIds: React.Dispatch<React.SetStateAction<string[]>>;
  setRemovedLoaIds: React.Dispatch<React.SetStateAction<string[]>>;
  onAddNewProducer: (producerDetails: any) => void;
}
export interface State {
  name: string;
  code: string;
}

// Define the Data interface
export interface DropdownDrlp {
  id: string;
  name: Name;
  npn: string;
  isOnboarded?: boolean;
  selected?: boolean;
  drlpLicenseId?: string;
  drlpLicenseNumber?: number;
  drlpNpn?: string;
  ssn?: string;
  isPrefilled?: boolean;
  isNewlyOnboardedAgent?: boolean;
  firmLicenseClass?: string;
  drlpPdbLicenseClass?: string;
  firmNpn?: string;
}

export interface StateDisplayProps {
  setSelectedStates: React.Dispatch<React.SetStateAction<string[]>>;
  selectedStates: string[];
  prefilledStates: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<any>>;
}

export interface SelectedStateProps {
  onStateSelect: (state: State) => void; // Function to handle state selection
  currentState: string;
  filteredStates: State[];
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

export interface StateReviewProps {
  stateName: string;
}

export interface DetailsReviewProps {
  dropdownOptions: any;
  stateCode: string;
  details: {
    licenseClass: string;
    loa: string[];
  }[];
}

export interface SingleStateReviewProps {
  state: {
    name: string;
    details: {
      licenseClass: string;
      loa: string[];
    }[];
  };
}

export interface ReviewSectionProps {
  dropdownOptions: any;
  states: {
    name: string;
    details: {
      licenseClass: string;
      loa: string[];
    }[];
  }[];
}

export interface CustomRadioGroupProps {
  label: string;
  option1: string;
  option2: string;
}

export interface LoaProps {
  text: string;
  loaId: string;
  licenseClassCode: string;
  isCheckboxNeeded?: boolean;
  selectedIds: string[];
  handleCheckboxChange: (
    licenseClassCode: string,
    id: string,
    loaId: string,
    checked: boolean
  ) => void;
  prefilledLoaIds?: string[];
}

export interface LicensedProducerProps {
  text: string;
  notClosable: boolean;
  onClose: (text: string) => void;
  noMargin?: boolean;
}

export interface LicenseClassProps {
  text: string;
  ids?: string[];
  licenseClassCode: string;
  isCheckboxNeeded?: boolean;
  loaTexts?: string[];
  loaCodes?: string[];
  selectedIds: string[];
  handleCheckboxChange: (
    licenseClassCode: string,
    id: string,
    loaId: string | null,
    checked: boolean
  ) => void;
  prefilledLoaIds?: string[];
  drlpOptions: any[];
  stateCode: string;
  onUpdateDropDown: (option: any, type?: string) => void;
  onAddNewProducer: (producerDetails: any) => void;
}

export interface CustomDropdownProps {
  text: string;
  options: DropdownDrlp[];
  stateCode: string;
  onUpdateDropDown: (option: any, type?: string) => void;
  licenseClassCode: string;
  onAddNewProducer: (producerDetails: any) => void;
}

export interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  stateCount: number;
}

export interface CustomInputProps {
  labelText: string;
  placeholderText: string;
}

export interface CurrentStateProps extends selectedIds {
  currentState: { name: string; code: string };
  onAgencyStateAssignment: (currentAssignment: any) => void;
  prefilledLoaIds?: string[];
  drlpOptions: Record<number, DropdownDrlp[]>;
  loading: boolean;
  onUpdateDropDown: (option: any, index: string, type?: string) => void;
  onStateSelect: (state: State) => void;
  sortedStates: State[];
  onAddNewProducer: (producerDetails: any) => void;
  setAddedLoaIds: React.Dispatch<React.SetStateAction<string[]>>;
  setRemovedLoaIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export enum DRLP_OPTIONS_CTA {
  ADD = 'add',
  DELETE = 'delete',
  MODIFY = 'modify',
}
