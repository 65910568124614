import {
  AgencyQuestionId,
  QuestionId,
} from '../../../../enums/questionId.enum';
import {
  Button,
  Input,
  Radio,
  RadioChangeEvent,
  Spin,
  Typography,
  message,
} from 'antd';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import Upload, { RcFile, UploadFile } from 'antd/es/upload';

import { AgentService } from '../../../../services/agent.service';
import { ClickableLinkTextForBackgroundQuestions } from '../../../../components/clickableLinkForBackgroundQuestions';
import DropdownSelect from '../../../../components/common/dropdowns/dropdownSelect';
import { IdConstants } from '../../../../constants/id.constants';
import { PictureOutlined } from '@ant-design/icons';
import { QuestionCard } from './backgroundQuestions.style';
import { sumBy } from 'lodash';
import { useAuth } from '../../../../auth/authProvider';
import { useParams } from 'react-router';

const { Dragger } = Upload;

const acceptedFileTypes = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'image/gif', // .gif
  'image/jpeg', // .jpg, .jpeg, .jpe
  'application/pdf', // .pdf
];

const getFormattedQuestion = (qn: string) => {
  return qn
    ?.replaceAll('_agency', '')
    ?.replaceAll(' ', '_')
    ?.replaceAll('-', '_')
    ?.replaceAll('/', '_')
    ?.replace('NON_UNIFORM_', '')
    ?.replace(/\d+/g, '')
    ?.toUpperCase();
};

export interface RefObject {
  getFilledResponse: () => any;
}

const IndividualBackgroundQuestion = React.forwardRef<
  RefObject,
  {
    question: any;
    answer: any;
    answerOptions: any;
    editMode: boolean;
    pageLoader: boolean;
    onAnswerChange: (question: any, answer: any) => void;
    onAnswerUpdate: (response: any, questionId: string) => void;
    onChildSave: (questionId: string, formData: FormData) => void;
    answerUpdate: string;
    childFormData: FormData;
    childAnswers: any;
    numberOfSupportingDocuments: number;
    deleteChildAnswerOnCancel: (question: any) => void;
    onBoardedAgentId?: string;
    updateBackgroundAnswers?: (questionId: string, fileList: any[]) => void;
  }
>(
  (
    {
      question,
      answerOptions,
      editMode,
      answer,
      pageLoader,
      onAnswerChange,
      onAnswerUpdate,
      onChildSave,
      answerUpdate,
      childFormData,
      numberOfSupportingDocuments,
      childAnswers,
      deleteChildAnswerOnCancel,
      onBoardedAgentId,
      updateBackgroundAnswers,
    },
    ref
  ) => {
    const { agentId } = useParams();
    const [selectedOption, setSelectedOption] = useState<string>('--');
    const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
    const [editResponse, setEditResponse] = useState<boolean>(false);
    const [textInput, setTextInput] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState(new FormData());
    const { bearerToken } = useAuth();
    const [checkChildQuestion, setCheckChildQuestion] =
      useState<boolean>(false);
    const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [existingFileIds, setExistingFileIds] = useState<string[]>([]);
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [isUploadFileError, setIsUploadFileError] = useState<boolean>(false);
    const [totalFileSize, setTotalFileSize] = useState<number>(0);
    const inputType = ['input_number', 'text'];

    useEffect(() => {
      if (answer && answer[0].answer !== 'null') {
        if (inputType.includes(question.answerType))
          setTextInput(answer[0]?.answer);
        else setSelectedOption(answer[0]?.answer);
        setSelectedFiles(answer[0]?.supportingDocuments);
        const fileIds: string[] = [];
        answer[0]?.supportingDocuments?.forEach((document: any) => {
          fileIds.push(document['uid']);
        });
        setExistingFileIds(fileIds);
      } else if (!answer) {
        setSelectedOption('--');
      }

      if (
        question?.isDependent &&
        answer &&
        (answer[0]?.answer === 'null' || answer[0]?.answer === null)
      ) {
        setCheckChildQuestion(true);
      } else {
        setCheckChildQuestion(false);
      }
    }, [numberOfSupportingDocuments, answerUpdate]);

    useImperativeHandle(ref, () => ({
      getFilledResponse,
    }));

    useEffect(() => {
      let newSize = 0;

      selectedFiles?.forEach((file) => {
        if (file?.size) {
          newSize += file.size / 1024 / 1024;
        }
      });
      setTotalFileSize(newSize);
    }, [selectedFiles]);

    const getFilledResponse = () => {
      const newSize = sumBy(selectedFiles, (file) =>
        file?.size ? file.size / 1024 / 1024 : 0
      );
      if (
        question.hasAttachment &&
        selectedOption === 'true' &&
        !selectedFiles?.length
      ) {
        return null;
      }

      return {
        id: question._id,
        questionId: question.questionId,
        answer: inputType.includes(question.answerType)
          ? textInput
          : selectedOption === '--'
            ? null
            : selectedOption,
        supportingDocuments: selectedFiles,
        isAnswered: inputType.includes(question.answerType)
          ? textInput !== ''
          : question.hasAttachment && selectedOption === 'true'
            ? selectedFiles?.length > 0
            : selectedOption !== '--',
        hasAttachment: question.hasAttachment,
        fileSize: newSize,
        stateCode: question.stateCode,
      };
    };

    const updateBackGroundQuestion = async () => {
      if (selectedOption === 'true' && question.hasAttachment) {
        deletedFiles?.forEach((deletedFileId: any) => {
          formData.append('deleted_file', deletedFileId);
        });
        uploadedFiles?.forEach((updatedFile: any) => {
          if (updatedFile['status'] === 'done') {
            formData.append(
              question.questionId + '__file',
              updatedFile as RcFile
            );
          }
        });
        setDeletedFiles([]);
        setUploadedFiles([]);
      }
      if (
        selectedOption !== answer[0].answer &&
        question.answerType !== 'input_number'
      )
        if (!formData.get(question.questionId)) {
          formData.append(question.questionId, selectedOption);
        }
      if (
        question.hasAttachment &&
        selectedFiles?.length < 1 &&
        selectedOption === 'true'
      ) {
        message.error('Should select at least one file');
        return;
      }

      const selectedOptionBoolean = selectedOption === 'true';
      const fileSizeError = selectedOptionBoolean && totalFileSize >= 25;
      if (fileSizeError) {
        message.error('The total size of files must be 25MB or smaller!');
        return;
      }

      const fileSizeErrorOnNo = !selectedOptionBoolean && totalFileSize >= 25;
      if (fileSizeErrorOnNo && question.hasAttachment)
        setSelectedFiles(answer[0].supportingDocuments);

      if (
        (selectedOption === '--' && question.answerType !== 'input_number') ||
        (question.answerType === 'input_number' && textInput === '')
      ) {
        message.error("Answer Can't be empty");
        return;
      }

      if (
        (selectedOption === 'false' && selectedOption === answer[0].answer) ||
        (selectedOption === 'true' &&
          selectedOption === answer[0].answer &&
          formData.get('deleted_file') === null &&
          formData.get(question.questionId + '__file') === null)
      ) {
        setFormData(new FormData());
        setEditResponse(!editResponse);
        return;
      }
      try {
        if (bearerToken && !fileSizeError) {
          if (
            formData.get(question.questionId) === null &&
            question.answerType !== 'input_number'
          ) {
            formData.append(question.questionId, selectedOption);
          }
          if (question.answerType === 'input_number') {
            formData.append(question.questionId, textInput);
          }
          setLoading(true);
          const checkAllChildQuestionAnswered: string[] = [];
          const checkChildIsNull = [];
          question?.trigger?.forEach((question: any) => {
            if (
              childAnswers[question.questionId][0].answer === 'null' ||
              childAnswers[question.questionId][0].answer === null
            ) {
              checkChildIsNull.push(question.questionId);
            }
          });

          if (!checkChildQuestion) {
            if (
              question.trigger?.length > 0 &&
              selectedOption === 'true' &&
              question.trigger?.length === checkChildIsNull?.length
            ) {
              question?.trigger?.forEach((question: any) => {
                for (const [key, value] of childFormData.entries()) {
                  if (key.startsWith(question.questionId)) {
                    if (formData.get(key) && !key.endsWith('__file'))
                      formData.delete(key);
                    formData.append(key, value);
                  }
                }
              });
              question?.trigger?.forEach((question: any) => {
                const checkChildQuestionAnswered = [];

                for (const [key, value] of formData.entries()) {
                  if (key.startsWith(question.questionId)) {
                    checkChildQuestionAnswered.push(key);
                  }
                }

                if (checkChildQuestionAnswered?.length > 0) {
                  checkAllChildQuestionAnswered.push(question.questionId);
                }
              });

              for (const [key, value] of childFormData.entries()) {
                if (key.startsWith(question.questionId)) {
                  childFormData.delete(key);
                }
              }

              if (
                checkAllChildQuestionAnswered?.length !==
                question.trigger?.length
              ) {
                message.error('Complete all the sub questions');
                setLoading(false);
                return;
              }
            }
            const response: any = await AgentService.ediBackGroundQuestions(
              bearerToken,
              formData,
              question.questionId,
              onBoardedAgentId || agentId || 'me'
            ).catch((err) => {
              message.error('Failed to submit the answers. Please try again.');
              setLoading(false);
              throw err;
            });
            setLoading(false);
            if (response.status === 'SUCCESS') {
              setFormData(new FormData());
              onAnswerUpdate(response.data, question.questionId);
              message.success('Successfully Updated.');
            } else message.error('Something Went Wrong');
          } else {
            onChildSave(question.questionId, formData);
            setLoading(false);
          }
        }
      } catch (err) {
        setLoading(false);
        onCancel();
        message.error('Failed Updating The Question');
        console.error('Error', err);
      }

      setFormData(new FormData());
      setEditResponse(!editResponse);
    };

    const handleInputChange = (e: any) => {
      setTextInput(e.target.value);
    };

    const onCancel = () => {
      if (answer && answer[0].answer !== 'null') {
        if (inputType.includes(question.answerType))
          setTextInput(answer[0].answer);
        else setSelectedOption(answer[0].answer);
      } else {
        if (inputType.includes(question.answerType)) setTextInput('');
        else setSelectedOption('--');
      }
      setSelectedFiles(answer[0].supportingDocuments);
      setEditResponse(!editResponse);
      setFormData(new FormData());
      if (question.trigger?.length > 0) {
        onAnswerChange(question, { answer: answer[0].answer });
        deleteChildAnswerOnCancel(question);
      }
    };

    const uploadProps = {
      onRemove: (file: any) => {
        setSelectedFiles((prevFiles) => {
          const fileIndex = selectedFiles.indexOf(file);
          const updatedFiles = [...prevFiles];
          if (fileIndex > -1)
            return [
              ...updatedFiles.slice(0, fileIndex),
              ...updatedFiles.slice(fileIndex + 1),
            ];
          else return updatedFiles;
        });

        if (editMode) {
          if (existingFileIds.includes(file.uid)) {
            deletedFiles.push(file.uid);
          }
        }
      },
      beforeUpload: (file: any) => {
        file.status = 'done';
        const isFileNameAlreadyAdded = selectedFiles.some(
          (uploadedFile) => uploadedFile.name === file.name
        );
        if (!acceptedFileTypes.includes(file.type)) {
          message.error(
            ` ${file.name} - file type not allowed. Please upload .doc, .docx, .gif, .jpg, .jpeg, .jpe, or .pdf files.`
          );
          setIsUploadFileError(true);
          return false; // Prevent the file from being added
        }

        if (isFileNameAlreadyAdded) {
          message.error(`File with name "${file.name}" is already added.`);
          setIsUploadFileError(true);
          return Upload.LIST_IGNORE; // Prevent the file from being added
        }

        setSelectedFiles((prevFiles) => {
          let updatedFiles = [...prevFiles];
          if (!Array.isArray(updatedFiles)) {
            updatedFiles = [];
          }
          updatedFiles = [...updatedFiles, file];
          return updatedFiles;
        });
        if (editMode) {
          uploadedFiles.push(file);
        }
        return false;
      },
      onChange(info: any) {
        const currentFileData = info.fileList[info.fileList?.length - 1];
        if (currentFileData?.status === 'done') {
          setIsFileUploading(true);
          setTimeout(() => {
            setIsFileUploading(false);
          }, 2000);
        }
        if (updateBackgroundAnswers) {
          updateBackgroundAnswers(question?.questionId, info.fileList);
        }
      },
      fileList: selectedFiles,
    };

    return (
      <Spin spinning={isFileUploading && !isUploadFileError}>
        <div key={question.questionId}>
          <QuestionCard className="question-card">
            <div>
              <Typography.Paragraph>
                {question?.questionId?.includes('_agency')
                  ? AgencyQuestionId[
                      getFormattedQuestion(
                        question.questionId
                      ) as keyof typeof AgencyQuestionId
                    ]
                  : QuestionId[
                      getFormattedQuestion(
                        question.questionId
                      ) as keyof typeof QuestionId
                    ]}
              </Typography.Paragraph>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: '1 0 0',
                paddingRight: '40px',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  flex: '1 0 0',
                  width: '95%',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Paragraph>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ClickableLinkTextForBackgroundQuestions(
                        question?.description || question?.questionText
                      ),
                    }}
                    style={{ textAlign: 'justify' }}
                  />
                </Typography.Paragraph>
              </div>
              <div
                style={{
                  cursor:
                    editResponse || !editMode || !pageLoader
                      ? ''
                      : 'not-allowed',
                }}
              >
                {question.hasAttachment && (
                  <>
                    {selectedOption === 'true' && (
                      <>
                        <Typography.Paragraph>
                          {editResponse
                            ? 'Since you have answered Yes, upload the required documents below'
                            : selectedFiles?.length > 0
                              ? 'Uploaded Documents'
                              : ''}
                        </Typography.Paragraph>
                        <Dragger
                          id={
                            IdConstants.BACKGROUND_QUESTIONS.DRAGGER +
                            '-' +
                            question.questionId
                          }
                          multiple={true}
                          accept=".doc,.docx,.gif,.jpeg,.jpg,.jpe,.pdf"
                          {...uploadProps}
                          style={{
                            width: '250px',
                            pointerEvents:
                              editResponse || (!editMode && !pageLoader)
                                ? 'auto'
                                : 'none',
                          }}
                          showUploadList={
                            editResponse || !editMode
                              ? true
                              : { showRemoveIcon: false }
                          }
                          disabled={isFileUploading && !isUploadFileError}
                        >
                          <div
                            className="ant-upload-drag-icon ant-upload-text"
                            style={{
                              display: 'flex',
                              color: '#6A6A6A',
                              pointerEvents: editResponse ? 'auto' : 'none',
                            }}
                          >
                            <PictureOutlined
                              style={{
                                marginLeft: '10px ',
                                marginRight: '15px',
                                fontSize: '16px',
                              }}
                            />
                            Drag and drop or&nbsp;
                            <span style={{ color: 'var(--primary-color)' }}>
                              Browse files
                            </span>
                          </div>
                        </Dragger>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <div
                style={{
                  cursor: editResponse || !editMode ? '' : 'not-allowed',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                id="background-question"
              >
                {inputType.includes(question.answerType) ? (
                  <Input
                    id={`${IdConstants.BACKGROUND_QUESTIONS.INPUT_FIELD}-${question.questionId}`}
                    style={{
                      pointerEvents:
                        editResponse || (!editMode && !pageLoader)
                          ? 'auto'
                          : 'none',
                      width: editMode ? '160px' : '70px',
                    }}
                    onKeyPress={(event) => {
                      if (
                        question.answerType === 'input_number' &&
                        !/[0-9]/.test(event.key)
                      ) {
                        event.preventDefault();
                      } else if (
                        question.answerType === 'text' &&
                        !/[a-zA-Z0-9]/.test(event.key)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      const pastedText =
                        event.clipboardData.getData('text/plain');
                      if (
                        question.answerType === 'input_number' &&
                        !/^[0-9]+$/.test(pastedText)
                      ) {
                        event.preventDefault();
                      } else if (
                        question.answerType === 'text' &&
                        !/^[a-zA-Z0-9]+$/.test(pastedText)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => {
                      if (question.answerType === 'text') {
                        onAnswerChange(question, { answer: textInput });
                      }
                    }}
                    maxLength={question.answerType === 'text' ? 9 : undefined}
                    placeholder="Enter a value"
                    value={textInput}
                    onChange={handleInputChange}
                  />
                ) : (
                  <Radio.Group
                    id={`${IdConstants.BACKGROUND_QUESTIONS.DROP_DOWN}-${question.questionId}`}
                    value={selectedOption}
                    options={answerOptions}
                    onChange={(e: RadioChangeEvent) => {
                      setSelectedOption(e.target.value);
                      onAnswerChange(question, { answer: e.target.value });
                    }}
                    disabled={isFileUploading && !isUploadFileError}
                  />
                )}

                {editMode ? (
                  editResponse ? (
                    <div style={{ display: 'flex' }}>
                      <Button
                        id={
                          IdConstants.BACKGROUND_QUESTIONS.SAVE_BUTTON +
                          '-' +
                          question.questionId
                        }
                        style={{
                          marginTop: '20px',
                          width: '75px',
                          background: '#2A6265',
                          marginRight: '10px',
                          color: 'white',
                        }}
                        // loading={editLoader}
                        loading={isLoading}
                        onClick={() => updateBackGroundQuestion()}
                      >
                        {isLoading ? '' : 'Save'}
                      </Button>
                      <Button
                        id={
                          IdConstants.BACKGROUND_QUESTIONS.CANCEL_BUTTON +
                          '-' +
                          question.questionId
                        }
                        disabled={isLoading}
                        style={{ marginTop: '20px', width: '75px' }}
                        onClick={() => onCancel()}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button
                      id={
                        IdConstants.BACKGROUND_QUESTIONS.EDIT_RESPONSE +
                        '-' +
                        question.questionId
                      }
                      style={{
                        marginTop: '20px',
                        width: '160px',
                        color: '#2A6265',
                      }}
                      onClick={() => setEditResponse(!editResponse)}
                    >
                      Edit Response
                    </Button>
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </QuestionCard>
        </div>
      </Spin>
    );
  }
);

IndividualBackgroundQuestion.displayName = 'IndividualBackgroundQuestion';

export default React.memo(IndividualBackgroundQuestion);
