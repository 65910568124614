import {
  AssignmentOptions,
  AssignmentStateTerritoriesProps,
  StateProps,
} from './individualOnboardV2Type.type';
import { Checkbox, Tooltip } from 'antd';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';

import EmptyState from '../../../../components/common/emptyState/emptyState';
import React from 'react';
import { State } from '../../../../components/agencyLicensing/agencyLicensingInterfaces.type';

const AssignmentStateTerritories: React.FC<AssignmentStateTerritoriesProps> = ({
  currentSelected,
  onSelect,
  filteredItems,
  searchQuery,
  setSearchQuery,
  placeHolder,
  selectedCount,
  type,
  onCheck,
  checkBoxProps = {},
  disabledItems = [],
  isCheckboxNeeded,
  activeState,
  isAssignmentPage,
  setSelectedTab,
  selectedTab,
  selectedItems,
  stateStyles,
  isIndividualProducer = false,
}) => {
  const handleSelect = (
    item: State | string,
    types: 'State' | 'Territories'
  ) => {
    if (type === 'State' && typeof item !== 'string') {
      onSelect(item.name, types);
      return;
    }
    onSelect(item as string, types);
  };

  return (
    <div
      style={{
        gap: isAssignmentPage && placeHolder === 'Territories' ? 16 : '24px',
      }}
      className="w-[220px] font-figtree rounded-md border border-[#D6E4EB] bg-white p-4 h-fit box-border flex flex-col"
    >
      {isAssignmentPage || !isCheckboxNeeded ? null : (
        <div className="h-6 font-poppins font-medium text-sm leading-6 text-black">
          {selectedCount?.length
            ? `${selectedCount?.length} ${placeHolder} Selected`
            : null}
        </div>
      )}
      {isAssignmentPage && placeHolder === 'Territories' ? (
        <div>
          {!selectedItems?.length && !isCheckboxNeeded ? null : (
            <>
              <div className="sub-title-placeholder text-[#989898]">States</div>
              <div
                onClick={() => {
                  if (setSelectedTab) {
                    handleSelect(
                      !isCheckboxNeeded ? selectedItems?.[0]?.name : 'Alabama',
                      'State'
                    );
                    setSelectedTab(AssignmentOptions.STATE);
                  }
                }}
                className="override-icon-size flex gap-2 p-2 mt-2 mb-4 mx-0 justify-between rounded-md cursor-pointer"
                style={{
                  backgroundColor:
                    selectedTab === AssignmentOptions.STATE
                      ? '#E2F0FD'
                      : '#FFFFFF',
                }}
              >
                <Checkbox
                  // onChange={(e.target.checked)} setSelectedItems([])
                  checked={
                    // AssignmentOptions.STATE === selectedTab ||
                    !!selectedItems?.length
                  }
                />{' '}
                All States <RightOutlined />
              </div>
            </>
          )}

          {(!selectedCount?.length && !isCheckboxNeeded) ||
          isIndividualProducer ? null : (
            <div className="sub-title-placeholder text-[#989898]">
              Territories
            </div>
          )}
        </div>
      ) : null}
      {isIndividualProducer ? (
        <div
          style={{
            height: `calc(100vh - ${
              isAssignmentPage
                ? placeHolder === 'Territories'
                  ? '449px'
                  : '339px'
                : '333px'
            })`,
            ...stateStyles,
          }}
          className="overflow-y-auto"
        />
      ) : (
        <>
          {' '}
          {/* don't show in review page, when no terrritories are selected */}
          {!selectedCount?.length && !isCheckboxNeeded ? null : (
            <div className="w-full flex items-center">
              <div className="flex items-center rounded-md h-8 px-3 bg-[#F5F6F7]">
                <SearchOutlined style={{ marginRight: '8px' }} />
                <input
                  type="text"
                  placeholder={`Search ${placeHolder}`}
                  value={searchQuery}
                  className="w-full border-none outline-none bg-transparent text-sm text-[#333]"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          )}
          <div
            style={{
              height: `calc(100vh - ${
                isAssignmentPage
                  ? placeHolder === 'Territories'
                    ? '516px'
                    : '406px'
                  : '400px'
              })`,
              ...stateStyles,
            }}
            className="overflow-y-auto"
          >
            {filteredItems.length === 0 ? (
              // not needed
              !isCheckboxNeeded ? null : (
                <div className="grid mt-16 h-[475px]">
                  <EmptyState
                    image="empty data"
                    heading=""
                    width="100%"
                    content={`No ${placeHolder} Found`}
                  />
                </div>
              )
            ) : (
              filteredItems.map((item, index) => {
                const isState = type === 'State' && typeof item !== 'string';
                const isSelected =
                  isAssignmentPage && selectedTab === AssignmentOptions.STATE
                    ? false
                    : currentSelected ===
                      (isState ? (item as State).name : (item as string));
                const isSelectedInTerritory = disabledItems?.includes(
                  isState ? (item as State).name : (item as string)
                );

                return (
                  <div
                    key={isState ? (item as State).code : (item as string)}
                    onClick={() => {
                      if (setSelectedTab)
                        setSelectedTab(AssignmentOptions.TERRITORIES);
                      handleSelect(item, 'Territories');
                    }}
                    style={{
                      backgroundColor: isSelected ? '#E2F0FD' : '#FFFFFF',
                    }}
                    className="flex items-center cursor-pointer rounded-md p-2 mb-2 gap-1 "
                  >
                    <Tooltip title={(item as StateProps)?.tooltipText}>
                      {isCheckboxNeeded ? (
                        <Checkbox
                          checked={
                            selectedCount
                              ?.map((d) => d.name)
                              ?.includes(
                                isState
                                  ? (item as State).name
                                  : (item as string)
                              ) || isSelectedInTerritory
                          }
                          onChange={(e) => {
                            e.preventDefault();
                            onCheck(
                              e.target.checked,
                              isState ? (item as State).name : (item as string)
                            );
                          }}
                          disabled={
                            isSelectedInTerritory ||
                            (type === 'State' &&
                              (item as StateProps)?.isDisabled)
                          }
                          {...checkBoxProps}
                          style={{ marginRight: 12 }}
                        />
                      ) : null}
                    </Tooltip>
                    {!isState ? null : (
                      <div
                        className="w-[28px] h-6 box-border rounded-[26px] border  py-[2px] px-[6px] flex justify-center items-center mr-[10px]"
                        style={{
                          backgroundColor: activeState.includes(
                            (item as State).code
                          )
                            ? '#F1FEF4'
                            : '#F1F8FE',
                          borderColor: activeState.includes(
                            (item as State).code
                          )
                            ? '#0F9D58'
                            : '#437EE1',
                        }}
                      >
                        <span
                          style={{
                            color: activeState.includes((item as State).code)
                              ? '#0F9D58'
                              : '#437EE1',
                          }}
                          className="text-[10px] leading-4 font-poppins font-medium"
                        >
                          {(item as State).code}
                        </span>
                      </div>
                    )}
                    <span
                      style={{
                        marginLeft: !isCheckboxNeeded && !isState ? 8 : 0,
                      }}
                      className="font-poppins font-normal text-sm leading-6 text-black"
                    >
                      {isState ? (item as State).name : (item as string)}
                    </span>
                  </div>
                );
              })
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AssignmentStateTerritories;
