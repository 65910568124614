export const HUNDRENT_PERCENT = 100;

export const PRODUCER_STAT_CIRCLE_WIDTH = 71.67;

export const REDIRECT_SESSION_CONSTANTS = {
  AUTH_EMAIL: 'authEmail',
  REDIRECT_AFTER_LOGIN: 'redirectAfterLogin',
  ROLE: 'role',
  IS_BOTH_ADMIN_AND_PRODUCER: 'isBothAdmin&Producer',
};
