import { Input, Modal, message } from 'antd';

import { AgencyService } from '../../../../services/agency.service';
import { CloseCircleFilled } from '@ant-design/icons';
import { ErrorMessageConstants } from '../../../../constants/error-message.constants';
import { ErrorResponse } from '../../../../types/response/errorResponse.type';
import { ExceptionName } from '../../../../enums/exceptionName.enum';
import { UpdatedStateGroup } from '../../../../types/data/stateGroup.types';
import { useAuth } from '../../../../auth/authProvider';

export type cloneState = {
  loading: boolean;
  isOpen: boolean;
  title: string;
  isManage: boolean;
};

interface CloneTerritoryProps {
  selectedRows: any[];
  cloneTerritory: cloneState;
  setCloneTerritory: React.Dispatch<React.SetStateAction<cloneState>>;
  selectedTerritory: any;
  handleSuccess: (id?: string) => void;
  isEdit?: boolean;
  stateGroups: UpdatedStateGroup[];
}

const mapLicenseConfigs = (stateConfigs: any[]) => {
  return stateConfigs.map((d: any) => ({
    stateCode: d.stateCode,
    licenseConfigurations: d.licenseConfigs.flatMap(
      (config: any) => config?.loaConfigs?.map((d: any) => d.id) || ''
    ),
  }));
};

const CloneTerritory: React.FC<CloneTerritoryProps> = ({
  selectedRows,
  setCloneTerritory,
  cloneTerritory,
  selectedTerritory,
  handleSuccess,
  isEdit,
  stateGroups,
}) => {
  const { getAccessTokenSilently } = useAuth();

  const getTerritoryName = (name: string, payload: any[]): string => {
    const isListNamePresent = [...stateGroups, ...payload]?.find(
      (d) => d.name === name
    );

    if (!isListNamePresent) return name;

    return getTerritoryName(`${name} (1)`, payload);
  };

  const onCloneTerritory = async () => {
    setCloneTerritory((prev) => ({ ...prev, loading: true }));
    const token = await getAccessTokenSilently();
    if (isEdit) {
      try {
        const resp = await AgencyService.createStateGroup(
          cloneTerritory.title!,
          selectedTerritory,
          token
        );
        handleSuccess(resp?.data?.id);
        setCloneTerritory((prev) => ({
          ...prev,
          loading: false,
          isManage: false,
          title: '',
          isOpen: false,
        }));
      } catch (error: any) {
        setCloneTerritory((prev) => ({
          ...prev,
          loading: false,
        }));
        console.error(error);
        if (error?.error?.exceptionName === 'DuplicateValueException') {
          message.error(ErrorMessageConstants.DUPLICATE_TERRITORY_NAME);
        } else if (
          error?.error?.message ===
          'A stateGroup with one of the given names already exists'
        ) {
          message.error(ErrorMessageConstants.DUPLICATE_TERRITORY_NAME);
        } else {
          message.error(ErrorMessageConstants.COMMON);
        }
      }
    } else {
      let payload: any[] = [];

      if (cloneTerritory.isManage || selectedRows?.length === 1) {
        payload = [
          {
            name: cloneTerritory.title,
            licenseConfigurationsGroupedByState: mapLicenseConfigs(
              cloneTerritory.isManage
                ? selectedTerritory?.stateLicenseConfigs
                : selectedRows[0]?.stateLicenseConfigs
            ),
          },
        ];
      } else {
        const bulkPayload: any[] = [];
        selectedRows?.forEach((d) => {
          bulkPayload.push({
            licenseConfigurationsGroupedByState: mapLicenseConfigs(
              d.stateLicenseConfigs
            ),
            name: getTerritoryName(`${d?.name} (1)`, bulkPayload),
          });
        });
        payload = bulkPayload;
      }

      return AgencyService.cloneTerritory(payload, token)
        .then((resp) => {
          if (resp?.data?.success?.length) {
            message.success(
              `${resp?.data?.success?.map((d) => d.replace('Copy of ', ''))} ${
                resp?.data?.success?.length > 1 ? 'are' : 'is'
              } cloned successfully`
            );
            handleSuccess();
            setCloneTerritory((prev) => ({
              ...prev,
              loading: false,
              isManage: false,
              title: '',
              isOpen: false,
            }));
          }
          if (resp?.data?.failure?.length) {
            if (
              resp?.data?.failure?.length === 1 &&
              resp?.data?.failure?.[0]?.reason?.exceptionName ===
                ExceptionName.DUPLICATE_VALUE_EXCEPTION
            ) {
              message.error(
                'A territory with this name already exists. Please use a different name.'
              );
            } else {
              message.error({
                icon: <></>,
                className: 'clone-error-message',
                content: (
                  <div>
                    <CloseCircleFilled style={{ marginRight: 3 }} /> Failed to
                    clone the following territories. please try again
                    <ul style={{ marginTop: 6 }}>
                      {resp?.data?.failure?.map((d: any, index) => {
                        return (
                          <li style={{ textAlign: 'left' }} key={index}>
                            {d?.name?.replace('Copy of ', '')} -{' '}
                            {d?.reason?.exceptionName ===
                            ExceptionName.DUPLICATE_VALUE_EXCEPTION
                              ? 'A territory with this name already exists. Please use a different name.'
                              : d?.reason?.exceptionName}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ),
              });
            }
          }
          if (!resp?.data?.success?.length) {
            setCloneTerritory((prev) => ({ ...prev, loading: false }));
          }
        })
        .catch((error: ErrorResponse) => {
          console.error('error :: ', error);
          setCloneTerritory((prev) => ({ ...prev, loading: false }));
          message.error(
            ErrorMessageConstants.CUSTOM_ERROR(
              error?.error?.message ||
                'Failed to clone territories.please try again'
            )
          );
        });
    }
  };

  return (
    <Modal
      title={
        <div className="font-figtree font-semibold text-xl text-[#07212D]">
          {`Are you sure you want to duplicate the ${
            selectedRows?.length > 1 ? 'territories?' : 'territory?'
          }`}
        </div>
      }
      width={548}
      open={!!cloneTerritory?.isOpen}
      onOk={onCloneTerritory}
      confirmLoading={cloneTerritory?.loading}
      closable={!cloneTerritory?.loading}
      onCancel={() =>
        setCloneTerritory((prev) => ({
          ...prev,
          isOpen: false,
          title: '',
          isManage: false,
          loading: false,
        }))
      }
      okText="Duplicate"
      cancelButtonProps={{
        disabled: cloneTerritory?.loading,
      }}
      okButtonProps={{
        style: {
          background: '#001F45',
          color: '#ffffff',
          opacity:
            (selectedRows?.length === 1 || cloneTerritory.isManage) &&
            !cloneTerritory.title
              ? 0.5
              : 1,
        },
        disabled:
          (selectedRows?.length === 1 || cloneTerritory.isManage) &&
          !cloneTerritory.title,
        loading: cloneTerritory.loading,
      }}
      destroyOnClose
    >
      <div className="font-figtree font-medium text-sm text-[#99ACB5] mb-6">
        The new{' '}
        {selectedRows?.length > 1 && !cloneTerritory.isManage
          ? 'territories'
          : 'territory'}{' '}
        will save the indicated changes as a new territory
      </div>
      {selectedRows?.length === 1 || cloneTerritory.isManage ? (
        <div>
          <div className="medium-title-custom" style={{ color: '#222222' }}>
            New Territory Name:
          </div>
          <Input
            placeholder="Enter territory name"
            allowClear
            value={cloneTerritory.title}
            style={{ margin: '3px 0 16px' }}
            onChange={(e) => {
              setCloneTerritory((prev) => ({
                ...prev,
                title: e.target.value,
              }));
            }}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default CloneTerritory;
