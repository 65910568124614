import { BottomToastProps } from '../../../types/data/common.type';
import TickErrorIcon from '../icons/TickErrorIcon';
import TickSuccessIcon from '../icons/TickSuccessIcon';
import { twMerge } from 'tailwind-merge';
import { useEffect } from 'react';

const BottomToast: React.FC<BottomToastProps> = ({
  children,
  isOpen,
  wrapperClassName = '',
  childrenClassName = '',
  onClose,
  timeOut,
  type,
}) => {
  // effect to close the bottom toast after the timeout
  useEffect(() => {
    setTimeout(() => {
      if (isOpen) onClose();
    }, timeOut || 1000);
  }, [isOpen]);

  return (
    <div
      className={twMerge(
        'z-[10000000] fixed left-1/2 bottom-0 transform -translate-x-1/2 transition-transform duration-500 ease-in-out',
        isOpen ? 'translate-y-[-60px]' : 'translate-y-full',
        wrapperClassName
      )}
    >
      <div
        className={twMerge(
          'bg-[#222222] py-3 px-4 flex gap-2 items-center rounded-[4px] text-white',
          childrenClassName
        )}
      >
        {type === 'success' ? <TickSuccessIcon /> : <TickErrorIcon />}
        {children}
      </div>
    </div>
  );
};

export default BottomToast;
