import './editStateGroup.css';

import {
  Button,
  Card,
  Input,
  Layout,
  Progress,
  Skeleton,
  Spin,
  Tooltip,
  message,
  notification,
} from 'antd';
import CloneTerritory, {
  cloneState,
} from './saveAsNewStateGroup/cloneTerritory';
import {
  CloseOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  StateLicenseValueType,
  StateLicenses,
  producerAssignmentsByIndividual,
  updateProducerAssignmetPayload,
} from '../../agents/onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import {
  getStateCodeFromName,
  getStateNameFromCode,
} from '../../../utils/common.utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { AccountWithProducerDocument } from '../../../types/response/data/agents/agentDocument.type';
import { AgencyService } from '../../../services/agency.service';
import { AgentDetails } from '../../../types/data/agentDetail.type';
import { AgentService } from '../../../services/agent.service';
import { ApplicantType } from '../../../enums/applicantType.enum';
import AssignmentLicenseClass from '../../agents/onboardAgents/individualOnboardV2/assignmentLicenseClass';
import AssignmentStateTerritories from '../../agents/onboardAgents/individualOnboardV2/assignmentStateTerritories';
import DiscardConfirmationModal from './modals/discardChanges';
import EmptyState from '../../../components/common/emptyState/emptyState';
import { ErrorMessageConstants } from '../../../constants/error-message.constants';
import Header from '../../../components/headers/header';
import { LoaDetails } from '../../../types/data/masterData';
import NoStatesSelected from '../../../assets/images/SavedListBucket.svg';
import { PROGRESS_DONUT } from '../../../constants/style.constants';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { STATE_GROUP_NAME } from '../../../constants/regex.constants';
import { UserType } from '../../../enums/userType.enum';
import { adminStore } from '../../../stores/admin.store';
import { agencyProfileStore } from '../../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import { auth0Store } from '../../../stores/auth0Store';
import { buttonStyle } from '../../agents/agentsOverview/organizationView/organizationUtils';
import { getNameString } from '../../../utils/name.utils';
import insureTrekLogo from '../../../assets/images/insureTrekLogo.png';
import { licenseConfigurationsByState } from '../../../types/data/stateGroup.types';
import { listBuilderTitleStyle } from '../../agents/agentsOverview/listView/listBuilderUtils';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import siderImage from '../../../assets/images/agencyLicensingSidebar.png';
import { signupStore } from '../../../stores/signupStore';
import { tourStore } from '../../../stores/tour.store';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../../auth/authProvider';
import { validateAssignmentStep } from '../../agents/onboardAgents/individualOnboardV2/IndividualOnboardHelpers';
import { withRoles } from '../../../auth/useRoles';

const { Content, Sider, Footer } = Layout;
export interface LoaDragItem {
  stateCode: string;
  lcCode: string;
  loaCode: string;
  loaids: string;
}

export interface LcDragItem {
  stateCode: string;
  lcCode: string;
  loaCodes: string[];
  loaids: string[];
}

function EditStateGroup() {
  // error toast setup
  const [api, contextHolder] = notification.useNotification();
  const showError = (message: string) => {
    api['error']({
      message: 'Error',
      description: message,
    });
  };
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isStoreFetching, setStoreIntialising] = useState(false);
  const [discardModalVisibility, setDiscardModalVisibility] = useState(false);
  const [searchStateQuery, setSearchStateQuery] = useState('');
  const [stateGroupId, setStateGroupId] = useState<string>();
  const [stateGroupName, setStateGroupName] = useState<string>();
  const [territoryDetails, setTerritoryDetails] = useState<null | {
    createdBy: string;
    updatedAt: Date;
  }>();
  const { id } = useParams();
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<StateLicenses[]>([]);
  const [selectedState, setSelectedState] = useState('Alabama');
  const [OldStateGroupAgents, setOldStateGroupAgents] = useState<
    AgentDetails[]
  >([]);
  const [searchQuery, setSearchQuery] = useState({
    viewProducers: '',
    selectedStates: '',
  });
  const [bulkAssignmentPayload, setBulkAssignmentPayload] = useState<
    producerAssignmentsByIndividual[]
  >([]);

  const [totalProducersInAgency, setTotalProducersInAgency] =
    useState<number>();
  const [fetchingTotalProducers, setFetchingTotalProducers] = useState(false);
  const [producersModal, setProducersModal] = useState<
    'view' | 'assign' | 'view-close' | 'assign-close' | null
  >(null);
  const [isAssignmentLoading, setIsAssignmentLoading] = useState<{
    get: boolean;
    save: boolean;
  }>({ get: false, save: false });
  const [producerAssignments, setProducerAssignments] = useState<
    AccountWithProducerDocument[]
  >([]);
  const [producerIds, setProducerIds] = useState<string[]>([]);
  const [cloneTerritory, setCloneTerritory] = useState<cloneState>({
    loading: false,
    isOpen: false,
    title: '',
    isManage: false,
  });

  const masterData = [
    ...appStateInfoStore.getLicenseConfigsMasterDataWithoutDuplicates(
      ApplicantType.BOTH
    ),
  ];

  masterData.sort((a, b) => {
    const nameA = getStateNameFromCode(a.stateCode);
    const nameB = getStateNameFromCode(b.stateCode);
    return nameA.localeCompare(nameB);
  });

  useEffect(() => {
    if (id) {
      setStateGroupId(id);
    } else {
      setIsLoading(false);
    }
  }, []);

  const fetchInfo = async () => {
    await fetchAgentForStateGroup(stateGroupId);
    if (stateGroupId) {
      await fetchStateGroup(stateGroupId);
    } else {
      setTerritoryDetails(null);
    }
  };

  const initializeStores = async () => {
    setStoreIntialising(true);
    const token = await getAccessTokenSilently();
    await appStateInfoStore.initializeStore(token);
    if (
      signupStore.userType === UserType.ADMIN &&
      !agencyProfileStore.agencyDetails?.homeState
    )
      await agencyProfileStore.loadAgencyDetails(token);
    setStoreIntialising(false);
  };

  useEffect(() => {
    if (auth0Store.getProfilesLoaded()) {
      initializeStores();
    }
  }, [auth0Store.getProfilesLoaded()]);

  useEffect(() => {
    fetchInfo();
  }, [stateGroupId]);

  // Service Functions START
  const fetchStateGroup = async (stateGroupId: string) => {
    const token = await getAccessTokenSilently();
    const fetchedStateGroup = await AgencyService.findStateGroupById(
      stateGroupId,
      token
    ).catch((err) => {
      console.error(err);
    });

    if (fetchedStateGroup && fetchedStateGroup.data) {
      const stateGroup = fetchedStateGroup?.data?.stateLicenseConfigs?.map(
        (d) => {
          const state: any = {};
          state.name = getStateNameFromCode(d?.stateCode);
          d?.licenseConfigs?.forEach((lc) => {
            state[lc.lcCode] = {
              name: lc.lcName,
              loas: lc.loaConfigs?.map((loa) => {
                return {
                  id: loa.id,
                  loaCode: loa.loaCode,
                  name: loa.loaName,
                };
              }),
            };
          });
          return state;
        }
      );
      setSelectedItems(stateGroup);
      setStateGroupName(fetchedStateGroup.data.name);
      setTerritoryDetails({
        createdBy: fetchedStateGroup.data.createdBy,
        updatedAt: fetchedStateGroup.data.updatedAt,
      });
      appStateInfoStore.setStateGroup(fetchedStateGroup.data.name);
    }
    setIsLoading(false);
  };

  const fetchAgentForStateGroup = async (stateGroupId?: string) => {
    setFetchingTotalProducers(true);
    const token = await getAccessTokenSilently();
    let response;
    if (stateGroupId) {
      response = await AgentService.getAgentsforStateGroup(
        stateGroupId,
        token
      ).catch((error) => {
        console.error('error :: ', error);
      });
    }

    // fetchTotalProducersInAgency
    try {
      const response = await AgentService.getAllExpiryCountForAgents(
        {},
        1,
        10,
        null,
        token
      );
      setTotalProducersInAgency(response?.totalCount);
    } catch (error) {
      console.error(error);
    } finally {
      setFetchingTotalProducers(false);
      if (response?.data) {
        setOldStateGroupAgents(response?.data);
      }
    }
  };

  const handleSaveStateGroup = () => {
    if (stateGroupId) {
      updateStateGroup();
    } else createStateGroup();
  };

  const updateStateGroup = async () => {
    if (validateStateGroupBeforeSave()) {
      if (adminStore.account && stateGroupId) {
        setSaveLoading(true);
        const token = await getAccessTokenSilently();
        try {
          await AgencyService.updateStateGroupById(
            stateGroupId,
            stateGroupName!,
            generatePayload(),
            true,
            token
          );
          setProducersModal('assign');
          if (!isAssignmentLoading?.get && !producerAssignments?.length) {
            getAssignmentDetails();
          }
        } catch (error: any) {
          if (
            error?.error?.message?.includes(
              'A record already exists with (stateGroupName)'
            )
          ) {
            showError(
              'Territory name already exists. Please choose a different name'
            );
          } else {
            if (error?.error?.exceptionName === 'DuplicateValueException') {
              showError(
                ErrorMessageConstants.DUPLICATE_VALUE_ERROR(
                  'Territory Name',
                  error.error.value
                )
              );
            } else {
              showError(ErrorMessageConstants.COMMON);
            }
          }
        } finally {
          setSaveLoading(false);
        }
      }
    }
  };

  const generatePayload = () => {
    const payload: licenseConfigurationsByState[] = [];
    selectedItems.forEach((data) => {
      const licenseConfigurations: string[] = [];
      Object.entries(data).forEach(([k, v]) => {
        if (typeof v === 'string') return;
        v?.loas?.forEach((element) => {
          licenseConfigurations.push(element?.id || '');
        });
      });
      payload.push({
        stateCode: getStateCodeFromName(data.name) || '',
        licenseConfigurations,
      });
    });
    return payload;
  };

  const createStateGroup = async () => {
    try {
      if (validateStateGroupBeforeSave()) {
        let resp: any;
        setSaveLoading(true);
        if (adminStore.account) {
          const token = await getAccessTokenSilently();
          resp = await AgencyService.createStateGroup(
            stateGroupName!,
            generatePayload(),
            token
          );
        }
        if (resp.status === 'SUCCESS') {
          navigate(RouteConstants.stateGroups.path);
        }
      }
    } catch (error: any) {
      if (error?.error?.exceptionName === 'DuplicateValueException') {
        message.error(ErrorMessageConstants.DUPLICATE_TERRITORY_NAME);
      } else if (
        error?.error?.message ===
        'A stateGroup with one of the given names already exists'
      ) {
        message.error(
          'Territory name already exists. Please choose a different name'
        );
      } else {
        message.error(ErrorMessageConstants.COMMON);
      }
    } finally {
      setSaveLoading(false);
    }
  };
  // Service Functions END

  // Local State Actions END

  const validateStateGroupBeforeSave = (): boolean => {
    //name should not be empty
    if (!stateGroupName) {
      message.error('Please add a valid name for the territory');
      return false;
    }

    if (!selectedItems?.length) {
      message.error('Please add atleast one state for the territory');
      return false;
    }

    return !validateAssignmentStep(selectedItems, []);
  };

  const getSelectedStates = (isCount: boolean) => {
    return selectedItems
      ?.filter((state) => {
        // filter
        if (
          !isCount &&
          !state?.name
            ?.trim()
            ?.toLowerCase()
            ?.includes(searchQuery?.selectedStates?.trim()?.toLowerCase())
        )
          return false;
        const keys = Object.keys(state);
        if (keys.length === 1) return false;

        const filteredKeys = keys.filter((key) => key !== 'name');
        if (!filteredKeys?.length) return false;
        for (const key of filteredKeys) {
          const loas = state?.[key]?.loas;
          if (!loas?.length) {
            return false;
          }
        }

        return true;
      })
      ?.sort((a, b) => a?.name?.localeCompare(b?.name));
  };

  const getFilteredStateItems = () => {
    return masterData
      ?.map((data) => {
        const isDisabled = false;
        return {
          code: data.stateCode,
          name: getStateNameFromCode(data.stateCode),
          isDisabled,
          tooltipText: null,
        };
      })
      .filter((data) =>
        data.name
          ?.toLowerCase()
          ?.trim()
          ?.includes(searchStateQuery?.trim()?.toLowerCase())
      );
  };

  //  it takes true for right click and false for left click
  const handleClick = (type: boolean) => {
    const isRight = type ? 1 : -1;
    const states = getFilteredStateItems();
    const currentIndex = states.findIndex(
      (state) => state?.name === selectedState
    );
    const currentStateCode = states?.filter(
      (state) => state.name === states[currentIndex + isRight]?.name
    )?.[0]?.code;
    if (currentStateCode) {
      setSelectedState(getStateNameFromCode(currentStateCode));
    }
  };

  const selectCoupledConfigs = (
    id: string,
    allLoaDetails: LoaDetails[],
    selected?: {
      name: string;
      loaCode: string | undefined;
      id?: string | undefined;
    }[]
  ): any => {
    const licenseConfigs = appStateInfoStore
      ?.getLicenseConfigs()
      ?.find((d) => d.id === id);

    if (!licenseConfigs || !licenseConfigs.isCoupled) return [];

    if (selected) {
      const isSuperSet =
        licenseConfigs?.referenceCoupledConfiguration?.isSuperSet;
      if (isSuperSet) {
        const subsets = licenseConfigs?.referenceCoupledConfiguration?.subSet;
        return allLoaDetails
          ?.filter((d) => subsets?.includes(d.id!))
          ?.map((d) => {
            return {
              name: d?.loaName || '',
              loaCode: d?.loaCode,
              id: d.id,
            };
          });
      } else {
        const superSet =
          licenseConfigs?.referenceCoupledConfiguration?.superSet;

        // supset of the superset
        const subsets =
          appStateInfoStore
            ?.getLicenseConfigs()
            ?.find((d) => superSet?.includes(d.id!))
            ?.referenceCoupledConfiguration?.subSet || [];

        const isAllSubsetSelected = subsets?.every((d) =>
          selected?.map((d) => d.id)?.includes(d)
        );

        if (!isAllSubsetSelected) return [];

        return allLoaDetails
          ?.filter((d) => superSet?.includes(d.id!))
          ?.map((d) => {
            return {
              name: d?.loaName || '',
              loaCode: d?.loaCode,
              id: d.id,
            };
          });
      }
    } else {
      const isSuperSet =
        licenseConfigs?.referenceCoupledConfiguration?.isSuperSet;
      if (isSuperSet) {
        return [];
      } else {
        return licenseConfigs?.referenceCoupledConfiguration?.superSet;
      }
    }
  };

  const handleCheckboxChange = (
    lcCode: string,
    loaName: string,
    loaDetails: { loaId: string; id: string } | null,
    checked: boolean,
    stateName: string,
    lcName: string,
    allLoaDetails: LoaDetails[]
  ) => {
    // while checked
    if (checked) {
      const isStateChecked = selectedItems
        .map((data) => data.name)
        ?.includes(stateName);
      // state is already selected
      if (isStateChecked) {
        setSelectedItems((prev) => {
          return prev.map((data) => {
            if (data.name === stateName) {
              // add loas only if the handle change is triggered by loa
              const selectedLoas = [
                ...(data?.[lcCode]?.loas || []),
                {
                  name: loaName,
                  loaCode: loaDetails?.loaId,
                  id: loaDetails?.id,
                },
              ];
              const loas = loaDetails?.loaId
                ? [
                    ...selectedLoas,
                    ...selectCoupledConfigs(
                      loaDetails?.id,
                      allLoaDetails!,
                      selectedLoas
                    ),
                  ]
                : allLoaDetails?.map((d) => ({
                    // if lc selected
                    name: d?.loaName || d?.loa || '',
                    loaCode: d?.loaCode,
                    id: d.id,
                  })) || [];
              return {
                ...data,
                [lcCode]: {
                  name: lcName,
                  loas,
                },
              };
            }
            return data;
          });
        });
      } else {
        // if state is not selected,
        // 1. add state and lc and loa if loa is selected
        // 2. add state and lc if lc is selected
        const selectedLoas = [
          {
            name: loaName,
            loaCode: loaDetails?.loaId,
            id: loaDetails?.id,
          },
        ];
        const loas = loaDetails?.loaId
          ? [
              ...selectedLoas,
              ...selectCoupledConfigs(
                loaDetails?.id,
                allLoaDetails!,
                selectedLoas
              ),
            ]
          : allLoaDetails?.map((d) => ({
              // if lc selected
              name: d?.loaName || d?.loa || '',
              loaCode: d?.loaCode,
              id: d.id,
            })) || [];
        setSelectedItems((prev) => [
          ...prev,
          {
            name: stateName,
            [lcCode]: {
              name: lcName,
              loas,
            },
          } as StateLicenses,
        ]);
      }
    } else {
      // while uncheck
      setSelectedItems((prev) => {
        return prev.map((data) => {
          if (data.name === stateName) {
            // if loa uncheck
            if (loaDetails?.loaId) {
              data = {
                ...data,
                [lcCode]: {
                  ...(data?.[lcCode] || []),
                  //  then filter the loa alone
                  loas: data?.[lcCode]?.loas?.filter(
                    (d) =>
                      d.loaCode !== loaDetails.loaId &&
                      !selectCoupledConfigs(
                        loaDetails?.id,
                        allLoaDetails!
                      )?.includes(d.id as string)
                  ),
                },
              };
              return { ...data };
            }
            //  if lc uncheck , delete the lc with loa details
            delete data?.[lcCode];
            return {
              ...data,
            };
          }
          return data;
        });
      });
    }
  };

  const getLicensedData = () => {
    const filteredLicenseConfgs = masterData
      .filter((data) => getStateNameFromCode(data.stateCode) === selectedState)
      ?.map((d) => ({
        licenseConfigs: d.licenseClasses,
        stateCode: d.stateCode,
      }));

    const stateCode = filteredLicenseConfgs?.[0]?.stateCode;

    return {
      licenseConfigs: filteredLicenseConfgs?.[0]?.licenseConfigs,
      stateCode,
    };
  };

  useEffect(() => {
    getAssignmentDetails();
  }, []);

  useEffect(() => {
    if (!appStateInfoStore.header) {
      appStateInfoStore.setHeader('Territories');
    }
  }, [appStateInfoStore.header]);

  const getAssignedProducers = (isView: boolean) => {
    const search = searchQuery?.viewProducers?.trim()?.toLowerCase();
    // for viewing the assigned producers
    if (isView) {
      return OldStateGroupAgents.filter(
        (data) =>
          getNameString(data.name)?.toLowerCase()?.trim()?.includes(search) ||
          data.npn?.toLowerCase()?.trim()?.includes(search)
      );
    }

    // for viewing all producers and assign the territory to unassigned producers
    const assignmentDetails = producerAssignments
      .filter(
        (data) =>
          getNameString(data.name)?.toLowerCase()?.trim()?.includes(search) ||
          data.producer?.npn?.toLowerCase()?.trim()?.includes(search)
      )
      ?.map((d) => {
        return {
          name: getNameString(d?.producer?.name),
          npn: d?.producer?.npn,
          isAlreadyAssigned:
            d?.producer?.producerAgencyStateAssignments?.[0]?.stateAssignments?.some(
              (d) => d.territoryId === stateGroupId
            ),
          producerAssignments:
            d?.producer?.producerAgencyStateAssignments?.[0]?.stateAssignments,
          id: d?.producer?.id,
          isAssignmentProcessing:
            d?.producer?.processingFlags?.isAssignmentProcessing,
          isLicenseProcessing:
            d?.producer?.processingFlags?.isLicenseProcessing,
        };
      });
    return assignmentDetails;
  };

  const getAssignmentDetails = async () => {
    try {
      if (producerAssignments?.length) return;
      setIsAssignmentLoading((prev) => ({ ...prev, get: true }));
      const token = await getAccessTokenSilently();
      const response: any = await AgentService.getAllProducersForAgency(
        token,
        1000000000,
        1,
        undefined,
        undefined,
        undefined,
        true
      );
      setProducerAssignments(response?.data || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsAssignmentLoading((prev) => ({ ...prev, get: false }));
    }
  };

  const handleSaveAssignment = async () => {
    if (!validateStateGroupBeforeSave()) return;
    if (bulkAssignmentPayload?.length === 0) {
      navigate(RouteConstants.stateGroups.path);
      return;
    }
    try {
      setIsAssignmentLoading((prev) => ({ ...prev, save: true }));
      const token = await getAccessTokenSilently();
      const response =
        await AgencyService.updateBulkAssignmentsForProducerByIndividualAssignments(
          bulkAssignmentPayload,
          token
        );
      message.success(
        response?.message || 'Producer Assignments Updated Successfully'
      );
      navigate(RouteConstants.stateGroups.path);
    } catch (error: any) {
      console.error(error);
      message.error(error?.response?.data?.message);
    } finally {
      setIsAssignmentLoading((prev) => ({ ...prev, save: false }));
    }
  };

  const handleProducerModalClose = () => {
    setProducersModal((prev) =>
      ['view', 'assign'].includes(prev!)
        ? ((prev + '-close') as 'view-close')
        : null
    );
    setTimeout(() => {
      setProducersModal(null);
      setSearchQuery((prev) => ({
        ...prev,
        viewProducers: '',
      }));
    }, 1000);
  };

  const updateProducerAssignmentPaylod = (
    isAdd: boolean,
    producerId: string
  ) => {
    if (isAdd) {
      setProducerIds((prev) => [...prev, producerId]);

      const payload: updateProducerAssignmetPayload[] = generatePayload()?.map(
        (d) => ({ ...d, territoryId: stateGroupId })
      );
      const existingAssignments = producerAssignments?.find(
        (d) => d?.producer?.id === producerId
      )?.producer?.producerAgencyStateAssignments?.[0]?.stateAssignments;
      const territoryStates = payload
        ?.filter((d) => !d.territoryId)
        ?.map((d) => d.stateCode);
      const oldAssignments =
        existingAssignments?.filter((d) => {
          if (territoryStates.includes(d?.stateCode)) return false;
          return true;
        }) || [];
      const updatedAssignments = [...oldAssignments, ...payload];

      setBulkAssignmentPayload((prev) => [
        ...prev,
        {
          producerIds: [producerId],
          producerStateAssignments: updatedAssignments,
        },
      ]);
    } else {
      setProducerIds((prev) => prev.filter((data) => data !== producerId));
      setBulkAssignmentPayload((prev) =>
        prev.filter((data) => !data?.producerIds?.includes(producerId))
      );
    }
  };

  return (
    <Layout className="min-h-[100vh]">
      {/* Sider Component */}
      <Sider
        style={{
          backgroundImage: `url(${siderImage})`,
          backgroundSize: 'cover',
        }}
        className="h-[100vh] sticky top-0 bg-cover bg-no-repeat"
        width={258}
        theme="light"
      >
        {/* Content inside Sider */}
        <div className="h-full flex px-6  flex-col items-center justify-start">
          <div className="flex items-center justify-center mt-3 mb-10">
            <img
              alt="logo"
              className="w-[164px] h-[42.8px]"
              src={insureTrekLogo}
            />
          </div>
          <div className="figtree font-medium text-lg mb-5 text-white leading-[21.6px]">
            Manage Territory
          </div>
          <div className="flex justify-center w-full mt-3">
            <Button
              className="h-9 w-full px-6 py-3 border-none gap-[10px] flex items-center justify-center bg-[#E8EBEE]"
              onClick={() => {
                navigate(-1);
              }}
            >
              <LeftOutlined className="text-[4px]" />
              Go Back
            </Button>
          </div>
          <div className="w-full mt-6 border border-[#374F6D] h-[1px] " />
          <div className="flex flex-col items-center flex-wrap mt-2">
            <p className="figtree text-xs leading-[14.4px] text-white text-left">
              Add or Edit states State, License Class & LOA’s
            </p>

            <p className="figtree text-xs leading-[14.4px] mt-2 text-[#00A6FB] text-left">
              Select the states, licenses & LOA’s to the right
            </p>
          </div>
        </div>
      </Sider>
      <Layout>
        <Header headerText={appStateInfoStore.header} />
        {isLoading || isStoreFetching ? (
          <div className="grid place-content-center min-h-[calc(100vh-64px)]">
            {' '}
            <Spin />
          </div>
        ) : (
          <Content className="flex-1 p-5 overflow-auto">
            <div className="w-full">
              {contextHolder}
              <div className="flex flex-col w-full mb-3 h-[calc(100vh-192px)]">
                <div className="flex overflow-hidden flex-1 w-full gap-x-[10px]">
                  <Card
                    className="remove-padding w-[60%] flex flex-col"
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div>
                      <div className="flex items-center gap-4 w-full overflow-x-scroll mb-3 pt-6 px-6">
                        <div className="flex gap-1 items-center">
                          {' '}
                          <div
                            className="figtree font-12"
                            style={{
                              ...listBuilderTitleStyle,
                              color: '#001F45',
                            }}
                          >
                            Name:
                          </div>{' '}
                          <Input
                            placeholder="Enter the name"
                            className="w-[120px] h-6 text-xs"
                            value={stateGroupName}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const regex = STATE_GROUP_NAME;
                              if (regex.test(inputValue)) {
                                if (!inputValue.startsWith(' ')) {
                                  setStateGroupName(inputValue);
                                } else {
                                  setStateGroupName('');
                                }
                              }
                            }}
                          />
                        </div>
                        <div className="flex gap-1 items-center">
                          {' '}
                          <div
                            className="figtree font-12"
                            style={{
                              ...listBuilderTitleStyle,
                              color: '#001F45',
                              width: 68,
                            }}
                          >
                            Created by:
                          </div>{' '}
                          <Input
                            className="w-[150px] h-6 text-xs"
                            value={
                              territoryDetails?.createdBy ||
                              getNameString(adminStore.account?.name || '') ||
                              ''
                            }
                            disabled={true}
                          />
                        </div>
                        <div className="flex gap-1 items-center">
                          {' '}
                          <div
                            className="figtree font-12"
                            style={{
                              ...listBuilderTitleStyle,
                              color: '#001F45',
                              width: 96,
                            }}
                          >
                            Last Updated on:
                          </div>{' '}
                          <Input
                            className="w-[100px] h-6 text-xs"
                            value={moment(
                              territoryDetails?.updatedAt || new Date()
                            ).format('MM/DD/YYYY')}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="w-full border-[0.5px] border-[#EAEAEA]"></div>
                      <div className="flex flex-1 gap-[18px] pt-6 px-6 min-h-[100%] overflow-auto">
                        <AssignmentStateTerritories
                          searchQuery={searchStateQuery}
                          setSearchQuery={setSearchStateQuery}
                          isCheckboxNeeded={true}
                          selectedCount={selectedItems}
                          isAssignmentPage={true}
                          disabledItems={[]}
                          placeHolder="States"
                          currentSelected={selectedState}
                          filteredItems={getFilteredStateItems() || []}
                          onCheck={(e, selectedState) => {
                            if (e) {
                              setSelectedItems((prev) => [
                                ...prev,
                                { name: selectedState } as StateLicenses,
                              ]);
                            } else {
                              setSelectedItems((prev) =>
                                prev.filter((d) => d.name !== selectedState)
                              );
                            }
                          }}
                          onSelect={(e) => {
                            setSelectedState(e);
                          }}
                          type="State"
                          activeState={[]}
                          stateStyles={{ height: 'calc(100vh - 385px)' }}
                        />
                        <div className="w-full min-w-[342px] figtree rounded-md border border-[#D6E4EB] p-[30px] gap-[34px] bg-white overflow-y-auto flex-1 h-[calc(100vh-296px)]">
                          <div className="w-full h-[56px] flex justify-between items-center gap-5">
                            <div className="flex flex-col w-full h-full mb-5">
                              <p className="font-medium text-xs text-secondary-color leading-5 m-0 font-figtree">
                                Current State:
                              </p>
                              <p className="leading-7 font-figtree mt-[6px] text-secondary-color text-[26px] font-medium">
                                {selectedState}
                              </p>
                            </div>
                            <div className="flex items-center gap-1 mb-5">
                              <Button
                                icon={<LeftOutlined />}
                                onClick={() => handleClick(false)}
                                disabled={
                                  selectedState ===
                                  getFilteredStateItems()?.[0]?.name
                                }
                                className="w-9 h-9 rounded-md px-[1px] flex items-center justify-center ml-20 bg-[#E8EBEE]"
                              />
                              <Button
                                icon={<RightOutlined className="text-white" />}
                                onClick={() => handleClick(true)}
                                disabled={
                                  selectedState ===
                                  getFilteredStateItems()?.[
                                    getFilteredStateItems()?.length - 1
                                  ]?.name
                                }
                                className="w-9 h-9 rounded-md px-[1px] flex items-center justify-center  bg-[#00A6FB] disabled:bg-[#00A6FB]"
                              />
                            </div>
                          </div>
                          <div className="overflow-y-auto font-figtree">
                            <Spin spinning={isLoading}>
                              {getLicensedData()?.licenseConfigs?.map((d) => {
                                const stateCode = getLicensedData()?.stateCode;
                                const stateName =
                                  getStateNameFromCode(stateCode);
                                const selectedLoaCodes =
                                  selectedItems
                                    ?.filter((d) => d.name === stateName)?.[0]
                                    ?.[d.lcCode]?.loas?.map((d) => d.loaCode) ||
                                  [];

                                return (
                                  <AssignmentLicenseClass
                                    residencyType={d.residencyType}
                                    licenseClassCode={d.lcCode}
                                    key={d.lcCode}
                                    isLicenseChecked={
                                      !!selectedItems?.filter(
                                        (d) => d.name === stateName
                                      )?.[0]?.[d.lcCode]
                                    }
                                    isCheckboxNeeded={true}
                                    disableCheckBox={false}
                                    tooltipText={null}
                                    hideFees={true}
                                    text={`${d.licenseClass} (${d.lcCode})`}
                                    stateCode={stateCode}
                                    LoaDetails={d.loaData}
                                    handleCheckboxChange={(
                                      lc,
                                      loaName,
                                      loa,
                                      isChecked
                                    ) =>
                                      handleCheckboxChange(
                                        lc,
                                        loaName,
                                        loa,
                                        isChecked,
                                        stateName,
                                        d.licenseClass,
                                        d.loaData
                                      )
                                    }
                                    selectedIds={selectedLoaCodes}
                                  />
                                );
                              })}
                            </Spin>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <div className="w-full flex flex-col gap-2">
                    <div className="flex gap-2 w-full">
                      <Card className="w-full remove-padding px-6 pt-6 py-3">
                        <div className="flex flex-col items-center  gap-1 justify-between">
                          <Progress
                            type="circle"
                            strokeWidth={12}
                            trailColor={PROGRESS_DONUT.TRAIL_COLOR}
                            strokeColor={PROGRESS_DONUT.STROKE_COLOR}
                            format={() =>
                              fetchingTotalProducers ? (
                                <Spin size="small" />
                              ) : (
                                <span className="font-medium leading-6 text-[10px] text-black">
                                  {OldStateGroupAgents?.length}/
                                  {totalProducersInAgency || 1}
                                </span>
                              )
                            }
                            percent={
                              (OldStateGroupAgents?.length /
                                (totalProducersInAgency || 1)) *
                              100
                            }
                            size={48}
                          />
                          <div className="medium-title-custom text-center">
                            No. of Producers Assigned
                            <div
                              onClick={() => {
                                if (
                                  fetchingTotalProducers ||
                                  subscriptionStatusInactive
                                )
                                  return;
                                setProducersModal('view');
                              }}
                              className={twMerge(
                                `font-figtree font-semibold text-xs leading-6 underline text-sky-blue`,
                                !fetchingTotalProducers ? 'cursor-pointer' : ''
                              )}
                            >
                              View Producers
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Card className="w-full remove-padding px-6 pt-6 py-3">
                        <div className="flex flex-col gap-1 items-center justify-between">
                          <Progress
                            type="circle"
                            strokeWidth={12}
                            trailColor={PROGRESS_DONUT.TRAIL_COLOR}
                            strokeColor={PROGRESS_DONUT.STROKE_COLOR}
                            format={() => (
                              <span className="font-medium leading-6 text-[10px] text-black">
                                {getSelectedStates(true)?.length} /
                                {masterData?.length || 1}
                              </span>
                            )}
                            percent={
                              (getSelectedStates(true)?.length /
                                (masterData?.length || 1)) *
                              100
                            }
                            size={48}
                          />
                          <div className="medium-title-custom text-center">
                            States Selected
                          </div>
                        </div>
                      </Card>
                    </div>
                    <Card className="remove-padding px-4 py-4">
                      <div className="font-figtree font-semibold text-base leading-7 text-dark-blue">
                        Selected State, License Class & LOA’s
                      </div>
                      <Input
                        placeholder="Search by State"
                        prefix={<SearchOutlined />}
                        allowClear
                        value={searchQuery.selectedStates}
                        className="h-7 font-figtree text-xs leading-[22px] text-[#ABABAB]"
                        style={{ margin: '16px 0' }}
                        onChange={(e) => {
                          setSearchQuery((prev) => ({
                            ...prev,
                            selectedStates: e.target.value,
                          }));
                        }}
                      />
                      <div className="flex flex-col gap-3 box-border h-[calc(100vh-462px)] py-1 overflow-y-scroll">
                        {getSelectedStates(false)?.length === 0 ? (
                          searchQuery?.selectedStates ? (
                            <div className="grid place-content-center mt-12">
                              {' '}
                              <EmptyState
                                image="empty data"
                                heading=""
                                width="100%"
                                content="No States Found"
                              />
                            </div>
                          ) : (
                            <div className="flex justify-center flex-col items-center overflow-y-auto border border-dashed border-[#7BB6FF] rounded-md h-full w-full">
                              <img
                                src={NoStatesSelected}
                                className="text-center"
                                alt="no-saved-listes"
                              />
                              <div className="font-figtree text-sm text-center leading-[14.4px]">
                                Select the states, licenses & LOA’s from the
                                left
                              </div>
                            </div>
                          )
                        ) : null}
                        {getSelectedStates(false)?.map((state) => {
                          return (
                            <div
                              key={state.name}
                              className="border bg-[#D3E7FF] font-figtree px-3 py-2 border-[#00A6FB] rounded-md"
                            >
                              <div className="font-medium mb-2 leading-5 text-base text-black-blue">
                                {state.name}{' '}
                              </div>{' '}
                              <div className="flex gap-x-8 gap-y-4 flex-wrap">
                                {Object.entries(state)?.map(([key, value]) => {
                                  if (key === 'name') return null;
                                  const stateValue =
                                    value as StateLicenseValueType;
                                  return (
                                    <div key={stateValue?.name}>
                                      <div className="text-sm mb-1 font-medium leading-5 text-black-blue">
                                        {stateValue?.name}
                                      </div>
                                      <div className="flex gap-2">
                                        {stateValue?.loas?.map((d) => {
                                          return (
                                            <span
                                              key={d?.name}
                                              className="inline-block border text-black-blue leading-5 text-xs font-normal rounded-[4px] px-2 py-1 border-[#D3E7FF] bg-white"
                                            >
                                              {d.name}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  </div>{' '}
                </div>
                <DiscardConfirmationModal
                  visible={discardModalVisibility}
                  handleVisibility={() => {
                    setDiscardModalVisibility(false);
                  }}
                />
              </div>
              {!producersModal ? null : (
                <div
                  className={`fixed right-0 ${producersModal === 'assign' ? 'bottom-[68px]' : 'bottom-0'} left-[258px] z-10 bg-white h-[72vh] ${
                    !producersModal.endsWith('close')
                      ? 'transition-slide-in'
                      : 'transition-slide-out'
                  }`}
                >
                  {['view', 'view-close'].includes(producersModal) ? (
                    <Card className="h-full">
                      <div className="font-semibold text-base flex items-center justify-between leading-7 font-figtree text-dark-blue">
                        List of producers assigned to this territory
                        <CloseOutlined
                          className="cursor-pointer"
                          onClick={() => {
                            handleProducerModalClose();
                          }}
                        />
                      </div>
                      <div>
                        <Input
                          placeholder="Search by Name, NPN"
                          prefix={<SearchOutlined />}
                          allowClear
                          value={searchQuery.viewProducers}
                          className="h-7 font-figtree text-sm leading-[22px] text-[#ABABAB]"
                          style={{ margin: '16px 0' }}
                          onChange={(e) => {
                            setSearchQuery((prev) => ({
                              ...prev,
                              viewProducers: e.target.value,
                            }));
                          }}
                        />
                        <div className="flex font-figtree flex-col gap-2 h-[calc(72vh-132px)] z-10 overflow-auto">
                          {getAssignedProducers(true)?.length === 0 ? (
                            searchQuery?.viewProducers ? (
                              <div className="grid place-content-center mt-12">
                                {' '}
                                <EmptyState
                                  image="empty data"
                                  heading=""
                                  width="100%"
                                  content="No Producer Found"
                                />
                              </div>
                            ) : (
                              <div className="grid place-content-center mt-12">
                                {' '}
                                <EmptyState
                                  image="empty data"
                                  heading=""
                                  width="100%"
                                  content="No Producers Assigned"
                                />
                              </div>
                            )
                          ) : null}
                          {getAssignedProducers(true)?.map((d) => {
                            return (
                              <div key={d?.npn}>
                                {' '}
                                <div className=" text-sm leading-5 font-normal text-dark-blue tracking-[0.5%]">
                                  {getNameString(d.name)}{' '}
                                </div>{' '}
                                <div
                                  id={d?.npn}
                                  className="font-normal text-[10px] leading-3 text-light-brown"
                                >
                                  {d.npn}{' '}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <>
                      {isAssignmentLoading?.get ? (
                        <Skeleton active className="pt-12 px-12" />
                      ) : (
                        <Card className="h-full">
                          <div className="border py-2 px-3 mb-2 bg-[#FCEFCF] border-[#FFC942] font-figtree rounded-[4px]">
                            <div className="flex">
                              <InfoCircleOutlined
                                color="#E2A200"
                                className="w-[24px] text-E2A200"
                              />
                              <div className="text-base leading-6 font-semibold">
                                Existing assignments for the assigned producers
                                will be updated
                              </div>
                            </div>
                            <div className="text-sm leading-[22px] pl-6">
                              All producers in the territory will have their
                              licenses automatically applied for these new
                              assignments if their payment configurations,
                              employment history, and background questions are
                              setup
                            </div>
                          </div>
                          <div className="font-semibold text-base flex items-center justify-between leading-7 font-figtree text-dark-blue">
                            Assign Producers to this territory
                            <CloseOutlined
                              className="cursor-pointer"
                              onClick={() => {
                                handleProducerModalClose();
                              }}
                            />
                          </div>
                          <div>
                            <Input
                              placeholder="Search All Producers"
                              prefix={<SearchOutlined />}
                              allowClear
                              value={searchQuery.viewProducers}
                              className="h-7 font-figtree text-sm leading-[22px] text-[#ABABAB]"
                              style={{ margin: '16px 0' }}
                              onChange={(e) => {
                                setSearchQuery((prev) => ({
                                  ...prev,
                                  viewProducers: e.target.value,
                                }));
                              }}
                            />
                            <div className="flex flex-col gap-3 h-[calc(72vh-226px)] z-10 overflow-auto">
                              {getAssignedProducers(false)?.length === 0 &&
                              searchQuery?.viewProducers ? (
                                <div className="grid place-content-center mt-12">
                                  {' '}
                                  <EmptyState
                                    image="empty data"
                                    heading=""
                                    width="100%"
                                    content="No Producer Found"
                                  />
                                </div>
                              ) : null}
                              {getAssignedProducers(false)?.map((d: any) => {
                                let tooltipTitle = '';
                                if (d?.isAlreadyAssigned) {
                                  tooltipTitle =
                                    'Territory is already assigned to this producer';
                                } else if (
                                  d?.isLicenseProcessing &&
                                  d?.isAssignmentProcessing
                                ) {
                                  tooltipTitle =
                                    'License(s) and Assignment(s) are currently being processed';
                                } else if (d?.isLicenseProcessing) {
                                  tooltipTitle =
                                    'License(s) currently being processed';
                                } else if (d?.isAssignmentProcessing) {
                                  tooltipTitle =
                                    'Assignment(s) currently being processed';
                                }
                                const producerAdded = producerIds?.includes(
                                  d?.id
                                );
                                return (
                                  <div
                                    key={d?.npn}
                                    className="font-figtree flex items-center justify-between"
                                  >
                                    <div>
                                      {' '}
                                      <div className=" text-sm leading-5 font-normal text-dark-blue tracking-[0.5%]">
                                        {getNameString(d.name)}{' '}
                                      </div>{' '}
                                      <div className="font-normal text-[10px] leading-3 text-light-brown">
                                        {d.npn}{' '}
                                      </div>
                                    </div>
                                    <Tooltip title={tooltipTitle}>
                                      <Button
                                        id={
                                          'assign-producer-to-stategroup_' +
                                          d.npn
                                        }
                                        className="figtree grid place-content-center"
                                        style={{
                                          ...buttonStyle,
                                          margin: 0,
                                          height: 28,
                                          fontSize: 12,
                                          padding: '2px 16px',
                                          width: '90px',
                                          opacity: d?.isAlreadyAssigned
                                            ? 0.7
                                            : 1,
                                          ...(producerAdded
                                            ? {
                                                background: '#ffffff',
                                                color: '#001F45',
                                                border: '1px solid #001F45',
                                              }
                                            : {}),
                                        }}
                                        onClick={() => {
                                          if (d?.isAlreadyAssigned) return;
                                          updateProducerAssignmentPaylod(
                                            !producerAdded,
                                            d?.id
                                          );
                                        }}
                                        disabled={
                                          !!tooltipTitle ||
                                          isAssignmentLoading?.save
                                        }
                                        size="middle"
                                      >
                                        {d?.isAlreadyAssigned
                                          ? 'Assigned'
                                          : producerAdded
                                            ? 'Unassign'
                                            : 'Assign'}
                                      </Button>
                                    </Tooltip>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Card>
                      )}
                    </>
                  )}
                </div>
              )}
              {/* todo in future */}
              {/* {tourStore.manageTerritoryTour && <ManageTerritoryTour />} */}
            </div>
          </Content>
        )}
        <Footer className="w-full box-border justify-end items-center flex bottom-0 gap-1 sticky p-5 bg-white h-[68px] border border-[#E6E6E6]">
          {['assign', 'assign-close'].includes(producersModal!) ? (
            <div className="flex gap-3">
              <Button
                onClick={() => {
                  handleProducerModalClose();
                }}
                className="h-9 px-3 py-1 flex items-center hover:text-black"
                loading={saveLoading}
                disabled={
                  isLoading || isStoreFetching || isAssignmentLoading.save
                }
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveAssignment}
                className="h-9 flex items-center bg-dark-blue text-white hover:text-white p-[2px] px-4 leading-[22px] m-0 text-sm font-medium"
                loading={isAssignmentLoading.save}
                disabled={isLoading || isStoreFetching}
              >
                Save assignments & Finish
              </Button>
            </div>
          ) : (
            <div className="flex gap-3">
              <Button
                onClick={() => {
                  if (validateStateGroupBeforeSave()) {
                    setCloneTerritory((prev) => ({
                      ...prev,
                      isOpen: true,
                      isManage: true,
                    }));
                  }
                }}
                className="h-9 "
                disabled={
                  isLoading ||
                  isStoreFetching ||
                  cloneTerritory.loading ||
                  saveLoading ||
                  subscriptionStatusInactive
                }
              >
                Duplicate
              </Button>
              <Button
                onClick={handleSaveStateGroup}
                className="h-9 bg-dark-blue enabled:hover:bg-dark-blue text-white p-[2px] px-4 leading-[22px] m-0 flex items-center text-sm font-medium"
                loading={saveLoading}
                disabled={
                  isLoading || isStoreFetching || subscriptionStatusInactive
                }
              >
                Save Territory <RightOutlined />
              </Button>
            </div>
          )}
        </Footer>
      </Layout>
      <CloneTerritory
        cloneTerritory={cloneTerritory}
        setCloneTerritory={setCloneTerritory}
        handleSuccess={(id?: string) => {
          setStateGroupId(id);
        }}
        isEdit={true}
        selectedRows={[]}
        stateGroups={[]}
        selectedTerritory={generatePayload()}
      />
    </Layout>
  );
}

export default withRoles(observer(EditStateGroup), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
