import './manageAssignments.css';

import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Col,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  FilterFieldsMetaData,
  FiltersType,
  LabelPairType,
} from '../../../types/common/filters.type';
import React, { useEffect, useState } from 'react';
import { applySort, getStateNameFromCode } from '../../../utils/common.utils';
import axios, { CancelTokenSource } from 'axios';
import {
  getOptionsForSearch,
  getSearchDisplayText,
  getSearchTypeByLabel,
  sortSearchTypesByPage,
} from '../../../utils/search.utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AccountWithProducerDocument } from '../../../types/response/data/agents/agentDocument.type';
import { AgencyService } from '../../../services/agency.service';
import { AgentService } from '../../../services/agent.service';
import { AssignmentAgent } from '../../../types/data/allAgents.type';
import { AssignmentType } from '../../../enums/assignedType.enum';
import { ColumnsType } from 'antd/es/table';
import EmptyState from '../../../components/common/emptyState/emptyState';
import ErrorModal from '../../../components/common/errorModal/errorModal';
import FilterButton from '../../../components/filterButton';
import { FilterType } from '../../../enums/filterType.enum';
import { IdConstants } from '../../../constants/id.constants';
import Lottie from 'lottie-react';
import OffboardConfirmModal from '../offboardAgents/individualOffboard/offboardConfirmationModal';
import { PageConstants } from '../../../constants/page.constants';
import { PlausiblePageConstants } from '../../../constants/plausible-page.constants';
import { RenderFilterChip } from '../../../components/common/simpleFilter/filterOptionTypes/renderFilterChip';
import { RoleType } from '../../../enums/roles.enum';
import { RouteConstants } from '../../../constants/routes.constants';
import { SEARCH_REGEX } from '../../../constants/regex.constants';
import SimpleFilter from '../../../components/common/simpleFilter/simpleFilter';
import WarningBanner from '../../agency/licenses/allLicenses/agency/warningBanner';
import { WarningBannerType } from '../../../enums/warningBannerType.enum';
import { adminStore } from '../../../stores/admin.store';
import { appStateInfoStore } from '../../../stores/appStateInfo.store';
import bulkOnboardAnimation from '../../../assets/lottie/Team.json';
import { cancelPreviousRequest } from '../../../utils/api.utils';
import { getNameString } from '../../../utils/name.utils';
import individualOnboardAnimation from '../../../assets/lottie/User Network.json';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { producerStateAssignments } from '../onboardAgents/individualOnboardV2/individualOnboardV2Type.type';
import { setFilterGroups } from '../../../utils/setSimpleFiltersRequest.utils';
import { useAuth } from '../../../auth/authProvider';
import { useQueryState } from '../../../utils/sync-query-param/use-query-state';
import { withRoles } from '../../../auth/useRoles';

const DEFAULT_SELECTED_FILTER = {
  'Assignment Status': {
    labelValuePair: {
      value: 'unassignedAgents',
      label: 'Unassigned Producers',
    },
    key: 'isAssigned',
  },
};

export const getBackgroundColor = (status: string, isMissingLoa = false) => {
  if (status === 'active' && !isMissingLoa) {
    return {
      background: '#98F5C880',
      border: '1px solid #31EB91',
    };
  } else if (
    status === 'missing' ||
    status === 'missing_loa' ||
    status === 'inactive' ||
    status === 'not_applied' ||
    status === 'expired'
  ) {
    return {
      background: '#F5989880',
      border: '1px solid #EB3131',
    };
  } else if (status === 'pending' || isMissingLoa) {
    return {
      background: '#FCEFCF',
      border: '1px solid #FFC942',
    };
  }
  return {};
};

const DEFAULT_FILTER = [
  {
    operator: 'or',
    filters: [
      {
        key: 'isAssigned',
        condition: 'is',
        value: 'unassignedAgents',
      },
    ],
  },
];

function ManageAssignments() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryText, setQueryText] = useQueryState('search');
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [isLoading, setIsLoading] = useState(true);
  const [agentCount, setAgentCount] = useState({
    all: 0,
    unassigned: 0,
  });
  const { getAccessTokenSilently } = useAuth();
  const [agents, setAgents] = useState<AssignmentAgent[]>([]);
  const [unassignedAgents, setUnassignedAgents] = useState<AssignmentAgent[]>(
    []
  );

  const [sortValue, setSortValue] = useState({});
  const { Search } = Input;

  const [searchText, setSearchText] = useState<string>('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filterInfoLoading, setIsFilterInfoLoading] = useState<boolean>(false);
  const [isProducerDetailsLoading, setProducerDetailsLoading] = useState(false);
  const [isAssignmentProcessing, setIsAssignmentProcessing] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedProducer, setSelectedProducer] =
    useState<AssignmentAgent | null>(null);
  const [paymentConfigs, setPaymentConfigs] = useState({
    isLoading: false,
    initialPaymentPayee: '',
    renewalPaymentPayee: '',
    autoApply: false,
  });

  const [refreshFilterOptions, setRefreshFetchFilter] =
    useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Adding Filters
  const [requestBody, setRequestBody] = useState<object>({});
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({
    data: {},
  });
  const [searchType, setSearchType] = useState('');
  const [searchFilter, setSearchFilter] = useState([]);
  const [displaySearchType, setDisplaySearchType] = useState('');
  const [offboardModal, setOffboardModal] = useState(false);
  const [agentsToBeOffboarded, setAgentsToBeOffboarded] = useState<
    AccountWithProducerDocument[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<
    AccountWithProducerDocument[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newOnboardModal, setNewOnboardModal] = useState(false);
  const [onboardHover, setOnboardHover] = useState('');
  const pageNo = searchParams.get('page');
  const [selectedAssignmentType, setSelectedAssignmentType] =
    useState<AssignmentType>(
      (searchParams.get('quickFilter') as AssignmentType) || AssignmentType.ALL
    );

  const onPageChange = (newPageNumber: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPageNumber(newPageNumber > 0 ? newPageNumber : pageNumber);
    loadAgents(queryText || '', newPageNumber, newPageSize);
    searchParams.set('page', `${newPageNumber}`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const page = Number(pageNo);
    if (!isNaN(page) && pageNo) {
      setPageNumber(page);
    } else {
      setPageNumber(1);
    }
  }, [pageNo]);

  const loadAgents = async (
    searchTextProp?: string,
    page?: number,
    size?: number,
    sort?: object,
    unassigned = false
  ) => {
    setIsLoading(true);
    let filter = {};
    if (searchParams.has('overview')) {
      setSelectedFilters({
        data: DEFAULT_SELECTED_FILTER,
      } as unknown as FiltersType);
      filter = {
        filterGroups: DEFAULT_FILTER,
      };
      searchParams.delete('overview');
      setRequestBody({ ...requestBody, ...filter });
      setSearchParams(searchParams.toString(), { replace: true });
    }

    const token = await getAccessTokenSilently();
    const defaultRequestBody = {};
    const reqBody = {
      ...requestBody,
      ...defaultRequestBody,
      ...filter,
      ...(sort || sortValue || {}),
    };
    if (cancelToken) cancelPreviousRequest(cancelToken);
    const cancelTokenSource = axios.CancelToken.source();
    setCancelToken(cancelTokenSource);
    let response: any;
    if (unassigned) {
      response = await AgentService.getUnassignedAgents(
        page || pageNumber,
        100,
        token,
        searchTextProp ? searchTextProp : queryText || '',
        searchType ? searchType : searchType || '',
        cancelTokenSource,
        reqBody
      ).catch((error) => {
        if (!axios.isCancel(error)) {
          setCancelToken(undefined);
          setIsLoading(false);
        }
      });
    } else {
      response = await AgentService.getMyAgentsAssignments(
        page || pageNumber,
        100,
        token,
        searchTextProp ? searchTextProp : queryText || '',
        searchType ? searchType : searchType || '',
        undefined,
        cancelTokenSource,
        reqBody
      ).catch((error) => {
        if (!axios.isCancel(error)) {
          setCancelToken(undefined);
          setIsLoading(false);
        }
      });
    }
    if (response && response.data) {
      setCancelToken(undefined);
      if (unassigned) {
        setUnassignedAgents(response.data);
        setAgentCount((prevState) => ({
          ...prevState,
          unassigned: response.totalCount,
        }));
      } else {
        setAgents(response.data);
        setAgentCount({
          unassigned: response?.unAssignedProducersCount || 0,
          all: response.totalCount,
        });
      }
      setIsLoading(false);
    }
  };

  const isBulkAssignmentProcessing = async () => {
    const token = await getAccessTokenSilently();
    setIsRefreshing(true);
    const response: any = await AgencyService.isBulkAssignmentProcessing(token);
    setIsAssignmentProcessing(response?.bulkAssignmentProcessing);
    setIsRefreshing(false);
  };

  useEffect(() => {
    const isUnassigned = selectedAssignmentType === AssignmentType.UNASSIGNED;
    loadAgents(
      undefined,
      pageNo ? Number(pageNo) : undefined,
      undefined,
      sortValue,
      isUnassigned
    );
  }, [adminStore.account, queryText, searchType, sortValue]);

  useEffect(() => {
    isBulkAssignmentProcessing();
  }, []);

  useEffect(() => {
    setSearchText(queryText || '');
  }, [queryText]);

  const redirect = (text: string, record: AssignmentAgent) => {
    appStateInfoStore.setHeader(getNameString(text));
    navigate(RouteConstants.agentOverview.path.replace(':agentId', record.id));
  };

  const columns: ColumnsType<AssignmentAgent> = [
    {
      title: <div style={{ paddingLeft: 15 }}>Producer Name</div>,
      dataIndex: 'name',
      key: 'name',
      width: selectedProducer ? '20%' : '35%',
      sorter: true,
      render: (text: string, record: AssignmentAgent) => (
        <Typography.Paragraph
          style={{
            color: '#001F45 !important',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0px',
            paddingLeft: 12,
            lineHeight: 20,
            fontWeight: 400,
          }}
          className="font-14px"
          key={`agent-row-${text}+${record.id}`}
        >
          <span
            className="cursor-pointer"
            onClick={() => {
              redirect(text, record);
            }}
          >
            {getNameString(text)}{' '}
          </span>
        </Typography.Paragraph>
      ),
    },
    {
      title: 'NPN',
      dataIndex: 'npn',
      sorter: true,
      key: 'producer.npn',
      render: (npn: string) => (
        <Typography.Paragraph
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            marginBottom: '0px',
            whiteSpace: 'nowrap',
          }}
          className="font-14px"
        >
          {npn}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Active Licenses',
      dataIndex: 'activeLicensesCount',
      sorter: true,
      key: 'activeLicensesCount',
      className: 'align-center',
      render: (text: string) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 5,
          }}
        >
          <span
            className="custom-badge custom-badge-text"
            style={{
              border: '1px solid var(--Primary-Primary, #00A6FB)',
              background: '#D3E7FF',
            }}
          >
            {text}
          </span>
        </div>
      ),
    },
    {
      title: 'Assigned Territories',
      dataIndex: 'producerAgencyStateAssignments',
      key: 'producerAgencyStateAssignments.AssignedTerritories',
      width: '300px',
      className: 'align-center',
      render: (assignedStates: producerStateAssignments[]) => {
        let isTerritory, showTerritoryCount, showTerritoryName;
        if (selectedAssignmentType === AssignmentType.ALL) {
          const hashmap: { [key: string]: string } = {};
          assignedStates
            .filter((itr) => itr?.territoryId)
            .forEach((itr) => {
              if (itr?.territoryId && itr.territoryName)
                hashmap[itr?.territoryId] = itr.territoryName;
            });
          isTerritory = Object.keys(hashmap).length > 0;
          showTerritoryName = Object.values(hashmap);
          showTerritoryCount =
            showTerritoryName?.length > 1
              ? showTerritoryName?.length - 1
              : null;
        }
        return selectedAssignmentType === AssignmentType.ALL &&
          isTerritory &&
          !(!assignedStates || !assignedStates?.length) ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: 6,
              paddingLeft: 5,
            }}
          >
            {showTerritoryName?.slice(0, 1)?.map((territoryName) => {
              return (
                <div className="custom-territory-name" key={territoryName}>
                  {territoryName}
                </div>
              );
            })}
            {showTerritoryCount ? (
              <Tooltip
                placement="top"
                rootClassName="territory-wrapper"
                title={
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 6 }}>
                    {showTerritoryName
                      ?.slice(1, showTerritoryName?.length)
                      ?.map((territoryName) => {
                        return (
                          <div
                            className="custom-territory-name"
                            key={territoryName}
                          >
                            {territoryName}
                          </div>
                        );
                      })}
                  </div>
                }
              >
                <div className="custom-territory-name">
                  +{showTerritoryCount}
                </div>
              </Tooltip>
            ) : null}
          </div>
        ) : (
          <div className="flex-center">
            <div className="figtree no-territories">
              No Territories Assigned
            </div>
          </div>
        );
      },
    },
    {
      title: 'Assigned States',
      dataIndex: 'producerAgencyStateAssignments',
      key: 'producerAgencyStateAssignments.AssignStates',
      width: '140px',

      render: (assignedStates) => {
        if (!assignedStates || !assignedStates?.length)
          return (
            <div className="figtree no-territories">No States Assigned</div>
          );
        const stateCodes: string[] = [];
        return (
          <Avatar.Group
            size="small"
            maxCount={4}
            className="custom-avatar-group"
            style={{ paddingLeft: 5 }}
          >
            {assignedStates.map((assignedState: producerStateAssignments) => {
              if (stateCodes.includes(assignedState.stateCode)) return null;
              if (assignedState.stateCode)
                stateCodes.push(assignedState.stateCode);
              return (
                <Tooltip
                  key={assignedState.stateCode}
                  title={`${getStateNameFromCode(assignedState.stateCode)} ${
                    !assignedState.territoryId
                      ? ''
                      : `(Inherited from : ${assignedState.territoryName})`
                  }`}
                >
                  <Avatar className="ant-custom-avatar">
                    {assignedState.stateCode}
                  </Avatar>
                </Tooltip>
              );
            })}
          </Avatar.Group>
        );
      },
    },
  ];
  const QUICK_FILTERS = [
    {
      type: AssignmentType.ALL,
      label: `All`,
    },
    {
      type: AssignmentType.UNASSIGNED,
      label: `Unassigned (${agentCount.unassigned})`,
    },
  ];

  const deleteQueryText = () => {};

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(
        selectedRows.map((d) => ({
          ...d,
          producer: { npn: d?.npn, id: d?.id },
        }))
      );
    },
    getCheckboxProps: (record: AssignmentAgent) => ({
      disabled: record.isAssignmentProcessing,
    }),
  };

  const fetchFiltersInfoForFindAll: (
    unassigned?: boolean
  ) => Promise<FilterFieldsMetaData[]> = async (unassigned = false) => {
    try {
      let path;
      if (unassigned) path = '/agency/assignments/unassigned';
      else path = '/agency/assignments';

      setIsFilterInfoLoading(true);
      const token = await getAccessTokenSilently();
      if (token) {
        const response: any = await AgentService.getFilterDetails(
          token,
          pageSize,
          pageNumber,
          path,
          searchText,
          {}
        );
        setSearchFilter(
          sortSearchTypesByPage(
            response?.data?.filter(
              (data: { filterType: string }) =>
                data.filterType === FilterType.SEARCH
            ),
            PageConstants.ASSIGNMENTS
          )
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsFilterInfoLoading(false);
    }
    return [];
  };

  const removeFilter = (
    keyToRemove: string,
    valueToRemove: string,
    removeKey = false
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (removeKey) {
        delete updatedFilters.data[keyToRemove];
      } else {
        if (Array.isArray(updatedFilters.data[keyToRemove]?.labelValuePair)) {
          const filtersDataArray = updatedFilters.data[keyToRemove]
            .labelValuePair as LabelPairType[];
          const updatedArray = filtersDataArray.filter((element) => {
            return element.value !== valueToRemove;
          });
          if (updatedArray.length > 0) {
            updatedFilters.data[keyToRemove].labelValuePair = updatedArray;
          } else {
            delete updatedFilters.data[keyToRemove];
          }
        } else {
          delete updatedFilters.data[keyToRemove];
        }
      }
      const filterGroups = setFilterGroups(updatedFilters);
      const updatedRequestBody = {
        ...requestBody,
        filterGroups,
      };
      setRequestBody(updatedRequestBody);
      return updatedFilters;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({ data: {} });
    const updatedRequestBody = {
      ...requestBody,
      filterGroups: [],
    };
    setRequestBody(updatedRequestBody);
  };

  const handleOffboarding = (value: any) => {
    setAgentsToBeOffboarded(value);
  };

  const getOnboardModalStyles = (value: string) => {
    return {
      width: onboardHover === value ? 367 : 302,
      height: onboardHover === value ? 320 : 265,
      border:
        onboardHover === value ? '2px solid #00A6FB' : '1px solid #A7CEFF',
      backgroundColor: onboardHover === value ? '#001F45' : '#F5FFFF',
      title: {
        fontFamily: 'Poppins',
        fontWeight: onboardHover === value ? 600 : 400,
        fontSize: onboardHover === value ? 20 : '16.57px',
        lineHeight: onboardHover === value ? '28px' : '23.19px',
        color: onboardHover === value ? '#FAFAFA' : '#001F45',
      },
      subTitle: {
        fontFamily: 'Poppins',
        fontWeight: onboardHover === value ? 400 : 500,
        fontSize: onboardHover === value ? 12 : '9.94px',
        lineHeight: onboardHover === value ? '20px' : '16.57px',
        color: onboardHover === value ? '#FAFAFA' : '#99ACB5',
        marginTop: 12,
      },
    };
  };
  const individualStyles = getOnboardModalStyles('individual');
  const bulkStyles = getOnboardModalStyles('bulk');

  useEffect(() => {
    if (Object.keys(requestBody).length) {
      if (selectedAssignmentType === AssignmentType.ALL) {
        loadAgents('', 1);
      } else {
        loadAgents('', 1, undefined, undefined, true);
      }
    }
  }, [requestBody]);

  const handleOnChange = (value: any) => {
    const match = value.match(SEARCH_REGEX);
    if (isEmpty(value)) setQueryText('');
    if (match) {
      const newSearchType = getSearchTypeByLabel(match[1], searchFilter);
      const newSearchText = match[0].replace(`in: ${match[1]}:`, '');
      if (isEmpty(newSearchText)) {
        setQueryText('');
        setSearchText('');
        setSearchType('');
      } else {
        setSearchType(newSearchType);
        setSearchText(newSearchText.trimStart());
      }
    } else {
      if (!value.includes(' in ')) {
        setSearchType('');
        setSearchText(value);
      }
    }
  };

  const handleOnSelect = (value: any) => {
    const selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (selectedOption) {
      const [text, type] = selectedOption.value.split(' in ');
      if (
        queryText !== text ||
        searchType !== getSearchTypeByLabel(type, searchFilter)
      ) {
        if (type === 'any') setSearchType('');
        else {
          setSearchType(getSearchTypeByLabel(type, searchFilter));
          setDisplaySearchType(type);
        }
        setQueryText(text);
        setPageNumber(1);
        setSearchText(text);
      }
    }
  };

  const handleOnSearch = (value: any) => {
    if (queryText !== value) {
      const match = value.match(SEARCH_REGEX);
      if (match) {
        const newSearchText = match[2];
        setQueryText(newSearchText);
      } else {
        setQueryText(value);
      }
      setPageNumber(1);
    }
  };

  const fields = searchFilter.map((filter: any) => filter.filterLabel);
  const options = getOptionsForSearch(fields, searchText);
  const pageName = PlausiblePageConstants.AGENCY_ASSIGNMENTS;
  const subscriptionStatusInactive =
    appStateInfoStore.isInternalSubscriptionStatusInactive();

  const getStateAssignments = () => {
    const assignments = selectedProducer?.producerAgencyStateAssignments || [];
    const data = [];

    const stateCodes: Set<string> = new Set();

    for (const assigned of assignments) {
      if (!stateCodes.has(assigned.stateCode)) {
        stateCodes.add(assigned.stateCode);

        const territories = assignments?.filter(
          (d) => d?.territoryId && d?.stateCode === assigned?.stateCode
        );
        const slicedTerritories = territories?.slice(1, territories?.length);

        data.push({
          stateCode: assigned.stateCode,
          stateName: getStateNameFromCode(assigned.stateCode),
          status: assigned.licenseStatus,
          text: assigned?.territoryId ? (
            <div>
              From {territories?.[0]?.territoryName}
              {slicedTerritories?.length ? (
                <Tooltip
                  title={
                    <div style={{ display: 'flex', gap: 6, flexWrap: 'wrap' }}>
                      {slicedTerritories?.map((d) => (
                        <div
                          key={d?.territoryName}
                          style={{
                            background: 'white',
                            padding: '2px 6px',
                            borderRadius: 6,
                            color: '#000000',
                          }}
                        >
                          {d?.territoryName}
                        </div>
                      ))}
                    </div>
                  }
                >
                  , +{slicedTerritories?.length}
                </Tooltip>
              ) : null}
            </div>
          ) : null,
        });
      }
    }

    return data
      ?.sort((a, b) => a.stateName.localeCompare(b.stateName))
      ?.filter((state) =>
        state?.stateName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
      );
  };

  const getProducerDetailsById = async () => {
    try {
      if (!selectedProducer?.id) return;

      setProducerDetailsLoading(true);
      const bearerToken = await getAccessTokenSilently();
      const response = await AgentService.getAssignmentDetailsById(
        selectedProducer?.id,
        bearerToken
      );
      const producerDetails = response?.data?.[0];
      setPaymentConfigs((prev) => ({
        ...prev,
        autoApply: producerDetails?.paymentConfig?.isAutoRenewalActive,
        initialPaymentPayee:
          producerDetails?.paymentConfig?.licenseInitialPayee,
        renewalPaymentPayee:
          producerDetails?.paymentConfig?.licenseRenewalPayee,
      }));
      setSelectedProducer((prev) =>
        !prev
          ? null
          : {
              ...prev,
              producerAgencyStateAssignments:
                producerDetails?.producerAgencyStateAssignments?.map(
                  (d: any) => ({
                    ...(d?.stateAssignments || {}),
                  })
                ),
            }
      );
    } catch (error) {
      console.error(error);
    } finally {
      setProducerDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedProducer?.id) return;
    getProducerDetailsById();
  }, [selectedProducer?.id]);

  const getTooltip = () => {
    if (isProducerDetailsLoading) return null;

    let tooltipTitle;

    if (
      selectedProducer?.isLicenseProcessing &&
      selectedProducer?.isAssignmentProcessing
    ) {
      tooltipTitle =
        'License(s) and Assignment(s) are currently being processed';
    } else if (selectedProducer?.isLicenseProcessing) {
      tooltipTitle = 'License(s) currently being processed';
    } else if (selectedProducer?.isAssignmentProcessing) {
      tooltipTitle = 'Assignment(s) currently being processed';
    } else {
      tooltipTitle = null;
    }

    return tooltipTitle;
  };

  const hanldeUpdateProducerDetails = async (paymentConfig: any) => {
    try {
      if (!selectedProducer?.id) return;

      const bearerToken = await getAccessTokenSilently();
      await AgencyService.updateProducerDetails(
        selectedProducer?.id,
        {
          paymentConfig: {
            licenseInitialPayee: paymentConfig?.initialPaymentPayee,
            licenseRenewalPayee: paymentConfig?.renewalPaymentPayee,
            isAutoRenewalActive: paymentConfig?.autoApply,
            paymentType: 'full',
          },
        },
        bearerToken
      );
    } catch (error) {
      console.error(error);
      message.error(
        `Updating payment config for ${getNameString(
          selectedProducer?.name || ''
        )} failed.Please try again`
      );
    }
  };

  return (
    <div className="w-full">
      {/* {contextHolder} */}
      <SimpleFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        setApplyFilter={() => {}}
        setRequestBody={setRequestBody}
        requestBody={requestBody}
        filterLoader={filterInfoLoading}
        visibility={isFilterVisible}
        setVisibility={() => {
          setIsFilterVisible(false);
        }}
        fetchFilters={() =>
          selectedAssignmentType === AssignmentType.ALL
            ? fetchFiltersInfoForFindAll()
            : fetchFiltersInfoForFindAll(true)
        }
        clearFilters={() => {
          clearFilters();
        }}
        refreshFetchFilter={refreshFilterOptions}
        setRefreshFetchFilter={setRefreshFetchFilter}
        pageName={pageName}
        filterPage={PageConstants.ASSIGNMENTS}
      />
      <Col span={24} className="flex flex-1">
        <div className="mt-[22px] mr-3 reset-icon-size w-full flex-1 ">
          <div className="flex justify-end gap-2 items-center v2-filter-button gap-x- v2-search v2-filter">
            <AutoComplete
              options={options}
              style={{ width: '100%' }}
              value={getSearchDisplayText(
                searchType,
                displaySearchType,
                searchText
              )}
              disabled={isLoading || subscriptionStatusInactive}
              onSelect={handleOnSelect}
              onChange={handleOnChange}
            >
              <Search
                id={
                  IdConstants.SEARCH_INPUT + '-agency-dowlines-view-invitations'
                }
                className="manage-producers-search"
                size="middle"
                placeholder="Search by Producer or NPN"
                style={{
                  width: '100%',
                  marginRight: '10px',
                }}
                onSearch={handleOnSearch}
              />
            </AutoComplete>

            <Button
              className={`figtree leading-[22px] text-white text-sm font-medium ${subscriptionStatusInactive && ` disabled:text-white`}`}
              style={{
                background: 'var(--Secondary-Secondary, #001F45)',
                height: 28,
              }}
              onClick={() => {
                if (selectedRows.length === 0) setNewOnboardModal(true);
                else {
                  const producerIds = selectedRows.map(
                    (selectedRow) => selectedRow.producer.id
                  );
                  sessionStorage.setItem(
                    'producerIds',
                    JSON.stringify(producerIds)
                  );
                  if (selectedRows.length === 1) {
                    navigate(
                      RouteConstants.manageAssignmentsByProducers.path.replace(
                        ':producerId',
                        selectedRows[0].id
                      )
                    );
                  } else {
                    navigate(
                      RouteConstants.manageBulkAssignmentsByProducers.path
                    );
                  }
                }
              }}
              disabled={subscriptionStatusInactive}
            >
              <PlusOutlined />
              {selectedRows.length > 0 ? 'Assign' : 'Add'}
            </Button>

            <Tooltip
              title={
                subscriptionStatusInactive
                  ? null
                  : selectedRows.length > 0
                    ? null
                    : 'Select Producers to Remove'
              }
            >
              <Button
                id={IdConstants.AGENTS.OFFBOARD.INDIVIDUAL_OFFBOARDING.BUTTON}
                className={`button flex items-center h-7 ${
                  subscriptionStatusInactive
                    ? 'disabled-action-button'
                    : selectedRows.length > 0
                      ? 'important-action-button'
                      : 'disabled-action-button'
                }`}
                disabled={
                  subscriptionStatusInactive
                    ? true
                    : selectedRows.length > 0 && !isLoading
                      ? false
                      : true
                }
                onClick={() => {
                  setAgentsToBeOffboarded(selectedRows);
                  setOffboardModal(true);
                }}
                style={{
                  opacity:
                    selectedRows.length > 0 && !isLoading ? '100%' : '50%',
                  height: 28,
                }}
              >
                <DeleteOutlined /> Remove
              </Button>
            </Tooltip>
            <FilterButton
              {...{
                filterInfoLoading,
                activeData:
                  selectedAssignmentType === AssignmentType.ALL
                    ? agents
                    : unassignedAgents,
                requestBody,
                isLoading,
                setIsFilterVisible,
                pageName,
                styles: {
                  marginLeft: 'unset',
                },
              }}
            />
          </div>
          <RenderFilterChip
            {...{ selectedFilters, removeFilter, clearFilters, pageName }}
          />
          <div
            style={{
              padding: '8px 0',
              gap: '8px',
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'scroll',
              scrollbarWidth: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '8px',
                width: '50%',
              }}
            >
              {QUICK_FILTERS.map((filter) => {
                return (
                  <Button
                    key={filter.type}
                    style={{
                      backgroundColor:
                        selectedAssignmentType === filter.type ? '#001F45' : '',
                      color:
                        selectedAssignmentType === filter.type ? '#fff' : '',
                    }}
                    onClick={() => {
                      const assignmentType = filter.type;

                      if (selectedAssignmentType === assignmentType) return;
                      setSelectedProducer(null);
                      setSelectedAssignmentType(assignmentType);
                      setRefreshFetchFilter(true);
                      if (assignmentType === AssignmentType.ALL) {
                        loadAgents();
                      } else {
                        loadAgents(undefined, undefined, undefined, {}, true);
                      }

                      setRequestBody({});
                      setSearchType('');
                      setSearchText('');
                      deleteQueryText();
                      setSelectedFilters({ data: {} });
                      setPageNumber(1);
                      if (queryText) setQueryText('');

                      const newSearchParams = new URLSearchParams(searchParams);

                      if (queryText) {
                        newSearchParams.delete('search');
                      }

                      newSearchParams.set('quickFilter', filter.type);
                      setSearchParams(newSearchParams);
                    }}
                    className="agency-license-buttons"
                  >
                    {filter.label}
                  </Button>
                );
              })}
            </div>
          </div>
          {isAssignmentProcessing && (
            <WarningBanner
              type={WarningBannerType.ASSIGNMENT_PROCESSING}
              onRefreshAssignments={isBulkAssignmentProcessing}
              isRefreshing={isRefreshing}
            />
          )}
          <div>
            <Row
              className="table table-layout-v2"
              style={{ marginTop: '10px', rowGap: '10px' }}
            >
              <Col
                span={24}
                style={{
                  width: `calc(100vw - ${
                    adminStore.isCollapsed
                      ? selectedProducer?.id
                        ? '430px'
                        : '130px'
                      : selectedProducer?.id
                        ? '600px'
                        : '300px'
                  })`,
                }}
              >
                <Table
                  rowClassName={(record) =>
                    record?.id === selectedProducer?.id
                      ? 'selected-producer-row'
                      : ''
                  }
                  style={{ cursor: 'pointer' }}
                  rowKey={(record) => record.id}
                  rowSelection={
                    !subscriptionStatusInactive ? rowSelection : undefined
                  }
                  columns={columns}
                  dataSource={
                    selectedAssignmentType === AssignmentType.ALL
                      ? agents
                      : unassignedAgents
                  }
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        if (!record.id || selectedProducer?.id === record.id)
                          return;
                        setSelectedProducer(record);
                      },
                    };
                  }}
                  onChange={(pagination, filters, sorter) => {
                    const sort = applySort(sorter, () => {}); // No immediate callback here
                    setSortValue(sort); // Let `useEffect` handle the state update and call `loadAgents`
                  }}
                  pagination={false}
                  loading={isLoading}
                  scroll={{
                    x: selectedProducer ? '900px' : '1200px',
                    y: 'calc(100vh - 262px)',
                  }}
                />
              </Col>
              {(!isEmpty(agents) || !isEmpty(unassignedAgents)) && (
                <Col span={24}>
                  <Row justify="end">
                    <Pagination
                      pageSize={pageSize}
                      current={pageNumber}
                      total={
                        selectedAssignmentType === AssignmentType.ALL
                          ? agentCount.all
                          : agentCount.unassigned
                      }
                      onChange={onPageChange}
                      showSizeChanger={false}
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`
                      }
                      disabled={isLoading}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </div>
        {selectedProducer ? (
          <div
            style={{
              minWidth: 300,
              padding: '12px',
              background: '#ffffff',
              overflow: 'auto',
              height: 'calc(100vh - 72px)',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0 0 12px',
                cursor: 'pointer',
              }}
              className="ant-modal-close-selected-producer"
            >
              <CloseOutlined
                onClick={() => setSelectedProducer(null)}
                style={{ padding: 5, borderRadius: 6 }}
              />
            </div>
            <Card className="remove-padding">
              <div className="figtree">
                <div
                  style={{
                    display: 'flex',
                    gap: 12,
                    flexWrap: 'wrap',
                    background: '#EDF9FF',
                    width: '100%',
                    padding: '16px 20px',
                    borderRadius: '8px 0 0 8px',
                    border: '1px solid #EDF9FF',
                  }}
                >
                  <div
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: '50%',
                      display: 'grid',
                      placeContent: 'center',
                      backgroundColor: '#B5E6FF',
                      fontSize: 16,
                      color: '#0074B0',
                      fontWeight: 'bolder',
                    }}
                  >
                    {selectedProducer?.name?.firstName?.[0] || ''}
                    {selectedProducer?.name?.lastName?.[0] || ''}
                  </div>

                  <div style={{ marginRight: 24 }}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: '500',
                        wordBreak: 'break-word',
                        lineHeight: '24px',
                        color: '#222222',
                        display: 'flex',
                        gap: 16,
                        alignItems: 'center',
                      }}
                    >
                      {selectedProducer?.name?.firstName || ''}{' '}
                      {selectedProducer?.name?.lastName || ''}{' '}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: 24,
                        fontSize: 10,
                        lineHeight: '20px',
                        fontWeight: 400,
                        alignItems: 'center',
                        color: '#1D2C50',
                      }}
                      className="overide-icon-size"
                    >
                      <span style={{ display: 'inline-block', marginTop: 3 }}>
                        NPN: {' ' + selectedProducer?.npn}
                      </span>
                      {/* <PhoneOutlined style={{ marginRight: 6 }} />
                      {agentBioInfo?.agentPhone || '-'} */}
                    </div>
                  </div>
                </div>

                <div
                  className="flex-center"
                  style={{ borderBottom: '1px solid #F5F6F7' }}
                >
                  <Tooltip title={getTooltip()}>
                    <Button
                      className="figtree"
                      style={{
                        background: 'var(--Secondary-Secondary, #001F45)',
                        color: '#ffffff',
                        fontSize: 14,
                        lineHeight: '22px',
                        fontWeight: 500,
                        margin: '12px 0',
                        opacity:
                          selectedProducer?.isAssignmentProcessing ||
                          selectedProducer?.isLicenseProcessing ||
                          isProducerDetailsLoading ||
                          subscriptionStatusInactive
                            ? 0.8
                            : 1,
                      }}
                      loading={isProducerDetailsLoading}
                      disabled={
                        selectedProducer?.isAssignmentProcessing ||
                        selectedProducer?.isLicenseProcessing ||
                        isProducerDetailsLoading ||
                        subscriptionStatusInactive
                      }
                      onClick={() => {
                        navigate(
                          RouteConstants.manageAssignmentsByProducers.path.replace(
                            ':producerId',
                            selectedProducer.id
                          )
                        );
                      }}
                    >
                      Manage State & Territories
                    </Button>
                  </Tooltip>
                </div>
                {isProducerDetailsLoading ? (
                  <div
                    className="flex-center"
                    style={{ minHeight: 'calc(100vh - 260px)', width: 284 }}
                  >
                    {' '}
                    <Spin />{' '}
                  </div>
                ) : (
                  <div className="flex gap-3 pt-3 px-4 items-center mb-[18px] justify-between">
                    <div>
                      <p className="sub-title-custom min-h-4 text-center mb-[6px] text-[#989898]">
                        Initial Payment
                      </p>
                      <Select
                        style={{
                          width: '120px',
                          height: 28,
                        }}
                        disabled={paymentConfigs.isLoading}
                        value={paymentConfigs.initialPaymentPayee || undefined}
                        onChange={(value) => {
                          const paymentConfig = {
                            ...paymentConfigs,
                            initialPaymentPayee: value,
                          };
                          hanldeUpdateProducerDetails(paymentConfig);
                          setPaymentConfigs(paymentConfig);
                        }}
                        placeholder="Select Initial Payee"
                      >
                        <Select.Option value="producer">Producer</Select.Option>
                        <Select.Option value="agency">Agency</Select.Option>
                      </Select>
                    </div>
                    <div>
                      <p
                        className="sub-title-custom"
                        style={{
                          display: 'flex',
                          gap: 8,
                          alignItems: 'center',
                          minHeight: 16,
                          textAlign: 'left',
                          marginBottom: 6,
                          color: '#989898',
                        }}
                      >
                        Renewal Payment{' '}
                        <Switch
                          id={
                            IdConstants.AGENTS.AGENT_OVERVIEW.TABLE
                              .AUTO_RENEWAL + '-manage-assignments'
                          }
                          size="small"
                          checked={paymentConfigs.autoApply}
                          disabled={
                            subscriptionStatusInactive ||
                            paymentConfigs.renewalPaymentPayee === 'producer'
                          }
                          onChange={() => {
                            const paymentConfig = {
                              ...paymentConfigs,
                              autoApply: !paymentConfigs.autoApply,
                            };
                            hanldeUpdateProducerDetails(paymentConfig);
                            setPaymentConfigs(paymentConfig);
                          }}
                        />
                      </p>

                      <Select
                        style={{
                          width: '120px',
                          height: 28,
                        }}
                        disabled={paymentConfigs.isLoading}
                        value={paymentConfigs.renewalPaymentPayee || undefined}
                        onChange={(value) => {
                          const paymentConfig = {
                            ...paymentConfigs,
                            renewalPaymentPayee: value,
                            autoApply:
                              value === 'producer'
                                ? false
                                : paymentConfigs.autoApply,
                          };
                          hanldeUpdateProducerDetails(paymentConfig);
                          setPaymentConfigs(paymentConfig);
                        }}
                        placeholder="Select Renewal Payee"
                      >
                        <Select.Option value="producer">Producer</Select.Option>
                        <Select.Option value="agency">Agency</Select.Option>
                      </Select>
                    </div>
                  </div>
                )}
              </div>
            </Card>
            {isProducerDetailsLoading ? null : (
              <>
                <div>
                  <Input
                    placeholder="Search assigned states"
                    prefix={<SearchOutlined />}
                    allowClear
                    style={{ margin: '16px 0' }}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    maxHeight: 'calc(100vh - 410px)',
                    overflow: 'auto',
                  }}
                >
                  {getStateAssignments()?.length === 0 ? (
                    <div className="grid place-content-center h-[475px]">
                      <EmptyState
                        image="empty data"
                        heading=""
                        width="100%"
                        content="No Assigned States"
                      />
                    </div>
                  ) : null}
                  {getStateAssignments()?.map((state) => {
                    return (
                      <div
                        key={state.stateCode}
                        className="flex justify-between items-center"
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '0 8px',
                            borderRadius: '6px',
                            // backgroundColor:
                            //   currentState === state.name ? '#E2F0FD' : '#FFFFFF',
                          }}
                        >
                          <div
                            style={{
                              width: '24px',
                              height: '24px',
                              borderRadius: '26px',
                              border: '1px solid #437EE1',
                              padding: '2px 6px',
                              background: '#F1F8FE',
                              // backgroundColor:
                              //   currentState === state.name ? '#F1FEF4' : '#F1F8FE',
                              // borderColor:
                              //   currentState === state.name ? '#0F9D58' : '#437EE1',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: '10px',
                                lineHeight: '20px',
                                // color:
                                //   currentState === state.name ? '#0F9D58' : '#437EE1',
                              }}
                            >
                              {state.stateCode}
                            </span>
                          </div>
                          <div>
                            <div
                              style={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '22px',
                                color: '#001F45',
                              }}
                            >
                              {state.stateName}
                            </div>
                            {state.text ? (
                              <div
                                style={{
                                  fontWeight: 400,
                                  fontSize: '10px',
                                  lineHeight: '12px',
                                  color: '#848484',
                                  minHeight: 14,
                                }}
                              >
                                {state.text}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div
                          className="sub-title-custom"
                          style={{
                            color: '#222222',
                            borderRadius: 16,
                            padding: '2px 8px',
                            textTransform: 'capitalize',
                            ...getBackgroundColor(state.status!),
                          }}
                        >
                          {' '}
                          {state.status}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        ) : null}
      </Col>
      <OffboardConfirmModal
        handleVisibility={(isCancel?: boolean) => {
          if (!isCancel) {
            const offboardedProducers = selectedRows.map((d) => d.id);
            if (
              selectedProducer?.id &&
              offboardedProducers.includes(selectedProducer?.id)
            ) {
              setSelectedProducer(null);
            }
            setSelectedRows([]);
          }
          setAgentsToBeOffboarded([]);
          setOffboardModal(false);
        }}
        visibility={offboardModal}
        agentsToBeOffboarded={agentsToBeOffboarded}
        setAgentsToBeOffboarded={handleOffboarding}
        setError={(msg) => {
          setErrorMessage(msg);
        }}
        reloadPage={() => {
          loadAgents();
          return;
        }}
      />
      {errorMessage && (
        <ErrorModal
          errorMessage={errorMessage}
          onClose={() => {
            loadAgents();
            setErrorMessage(null);
          }}
        />
      )}
      <Modal
        open={!!newOnboardModal}
        onCancel={() => {
          setNewOnboardModal(false);
        }}
        footer={null}
        width={'980px'}
      >
        <div className="flex-col-center" style={{ padding: '18px 48px' }}>
          <div
            style={{
              fontFamily: 'Poppins',
              fontSize: 20,
              lineHeight: '28px',
              color: '#07212D',
              fontWeight: 600,
            }}
          >
            How do you want to Onboard New Producer?
          </div>
          {agents?.length ? (
            <div
              style={{
                fontFamily: 'Poppins',
                fontSize: 14,
                lineHeight: '20px',
                color: '#99ACB5',
                fontWeight: 500,
                padding: '12px 0',
              }}
            >
              You&apos;ve currently {agents?.length} Producer
              {agents?.length > 1 ? '’s' : ''} added to your agency.
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              gap: 64,
              alignItems: 'flex-end',
              marginTop: 24,
            }}
          >
            <div
              style={{
                border: individualStyles.border,
                background: individualStyles.backgroundColor,
                padding: 24,
                borderRadius: 28,
                textAlign: 'center',
                width: individualStyles.width,
                height: individualStyles.height,
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
              }}
              onClick={() => {
                sessionStorage.setItem('isOnboarding', 'true');
                navigate(RouteConstants.onboardProducer.path);
              }}
              onMouseEnter={() => setOnboardHover('individual')}
              onMouseLeave={() => setOnboardHover('')}
              className={`flex-col-center override-lottie-180`}
            >
              <Lottie animationData={individualOnboardAnimation} loop={true} />
              <div style={individualStyles.title}>
                Individual Producer Onboard
              </div>
              <div style={individualStyles.subTitle}>
                Onboard your producer one by one individually and assign states
                and territories to your producers
              </div>
            </div>
            <div
              style={{
                border: bulkStyles.border,
                background: bulkStyles.backgroundColor,
                padding: 24,
                borderRadius: 28,
                textAlign: 'center',
                width: bulkStyles.width,
                height: bulkStyles.height,
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
              }}
              onClick={() =>
                navigate(RouteConstants.onboardProducer.path + '?type=bulk')
              }
              onMouseEnter={() => setOnboardHover('bulk')}
              onMouseLeave={() => setOnboardHover('')}
              className="flex-col-center override-lottie-180"
            >
              <Lottie animationData={bulkOnboardAnimation} loop={true} />
              <div style={bulkStyles.title}>Bulk Producer Onboard</div>
              <div style={bulkStyles.subTitle}>
                Onboard your producer in Bulk by uploading a CSV/XLSX File and
                assign states and territories to your producers
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default withRoles(observer(ManageAssignments), [
  RoleType.ADMIN,
  RoleType.SUPER_ADMIN,
]);
