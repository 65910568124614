import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { observer } from 'mobx-react-lite';
import { type MenuProps } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import information from '../../assets/icons/agentInfo.svg';
import { withRoles } from '../../auth/useRoles';
import { IconConstants } from '../../constants/icons.constants';
import { RouteConstants } from '../../constants/routes.constants';
import { RoleType } from '../../enums/roles.enum';
import { tourStore } from '../../stores/tour.store';
import CustomMenu from '../common/customMenu/customMenu';
import CustomIcon from '../common/customiseIcon/customIcon';
import { getDefaultSideBarOpenKey } from '../../utils/siderMenu.utils';
import { siderMenuKey } from '../../types/common/sidebar.type';
import { DollarOutlined } from '@ant-design/icons';
import { agentSideStore } from '../../stores/agentPortalStore';
import { PayeeEnum } from '../../enums/payee.enum';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void,
  disabled?: boolean
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
    disabled,
    id: `sider-menu-${label?.toString().toLowerCase().replace(' ', '-')}`, // changing this will cause issue in the component tour, refer tour.constant.ts file
  } as MenuItem;
}

type menuProp = {
  collapsed: boolean;
  isIndividualProducer?: boolean;
};

function AgentMenu({ collapsed, isIndividualProducer }: menuProp) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      navigate(RouteConstants.agentDashBoard.path);
    }
  }, []);

  const handleNavigate = (path: string): void => {
    navigate(path);
  };

  const agentSiderMenu: MenuItem[] = [
    getItem(
      'Home',
      RouteConstants.agentDashBoard.key,
      RouteConstants.agentDashBoard.icon,
      undefined,
      () => handleNavigate(RouteConstants.agentDashBoard.path)
    ),

    getItem(
      'My Producer',
      siderMenuKey.PRODUCER_PORTAL_MY_PRODUCER,
      <CustomIcon icon={IconConstants.agentLicenses} />,
      [
        getItem(
          'Manage Licenses',
          RouteConstants.agentAllLicenses.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentAllLicenses.path)
        ),
        // agentSideStore.paymentConfig?.renewalPaymentPayee ===
        //   PayeeEnum.AGENCY &&
        // agentSideStore.paymentConfig?.initialPaymentPayee === PayeeEnum.AGENCY
        //   ? null
        //   :
        getItem(
          'Pending Applications',
          RouteConstants.agentApplications.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentApplications.path)
        ),
        getItem(
          'Appointments',
          RouteConstants.agentPortalRTS.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentPortalRTS.path)
        ),
      ],
      undefined
    ),
    getItem(
      'Onboarding',
      siderMenuKey.PRODUCER_PORTAL_ONBOARDING,
      <CustomIcon icon={information} />,
      [
        getItem(
          'Employment History',
          RouteConstants.employmentHistory.key,
          undefined,

          undefined,
          () => handleNavigate(RouteConstants.employmentHistory.path)
        ),
        getItem(
          'Background Questions',
          RouteConstants.backgroundQuestions.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.backgroundQuestions.path)
        ),
        getItem(
          'Supporting Documents',
          RouteConstants.agentSupportDocuments.key,
          undefined,
          undefined,
          () => handleNavigate(RouteConstants.agentSupportDocuments.path)
        ),
      ]
    ),

    agentSideStore.getPaymentConfig().initialPaymentPayee === PayeeEnum.AGENT ||
    agentSideStore.getPaymentConfig().renewalPaymentPayee === PayeeEnum.AGENT
      ? getItem(
          'Billing',
          RouteConstants.produerPortalBilling.key,
          <DollarOutlined />,
          undefined,
          () => handleNavigate(RouteConstants.produerPortalBilling.path)
        )
      : null,
    // getItem(
    //   'Wallet',
    //   RouteConstants.wallet.key,
    //   RouteConstants.wallet.icon,
    //   undefined,
    //   () => handleNavigate(RouteConstants.wallet.path)
    // ),
  ];

  return (
    <CustomMenu
      menuKeys={agentSiderMenu.map((itr) => itr?.key || '')}
      items={agentSiderMenu}
      defaultOpenKey={getDefaultSideBarOpenKey(
        agentSiderMenu as { key: string }[],
        location.pathname,
        tourStore.sideBarOpen,
        true
      )}
      defaultSelectedKey={[RouteConstants.agentDashBoard.key]}
      style={{
        width: collapsed ? 100 : 260,
        boxSizing: 'border-box',
        overflow: 'auto',
        userSelect: 'none',
      }}
    />
  );
}

AgentMenu.displayName = 'AgentMenu';

export default withRoles(observer(AgentMenu), [
  RoleType.AGENT,
  RoleType.INDIVIDUAL_PRODUCER,
]);
