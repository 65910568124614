import { Checkbox, Tooltip, Typography } from 'antd';

import { ApplicantType } from '../../enums/applicantType.enum';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import CustomDropdown from './dropdownDRLP';
import { LicenseClassProps } from './agencyLicensingInterfaces.type';
import Loa from './loa';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { agencyProfileStore } from '../../stores/agencyProfileStore';
import { appStateInfoStore } from '../../stores/appStateInfo.store';
import { getDisplayFees } from '../../utils/common.utils';
import { observer } from 'mobx-react-lite';

const { Text } = Typography;

const LicenseClass: React.FC<LicenseClassProps> = ({
  text,
  ids,
  licenseClassCode,
  isCheckboxNeeded = true,
  loaTexts,
  loaCodes,
  selectedIds,
  handleCheckboxChange,
  prefilledLoaIds,
  drlpOptions,
  stateCode,
  onUpdateDropDown,
  onAddNewProducer,
}) => {
  const handleClassCheckboxChange = (e: CheckboxChangeEvent) => {
    handleCheckboxChange(licenseClassCode, text, null, e.target.checked);
  };

  const isLicenseChecked = selectedIds?.some((data) => ids?.includes(data));

  const getFees = () => {
    if (!isLicenseChecked) return null;

    const agencyHomeState = agencyProfileStore?.agencyDetails?.homeState;
    const preSelectedLoas =
      ids?.map((data, idx) => prefilledLoaIds?.includes(data?.[idx]))?.length ||
      0;
    const loaCount = preSelectedLoas + (selectedIds?.length || 0);

    return (
      appStateInfoStore.calculateFeesForAgencyLicense(
        stateCode,
        loaCount,
        agencyHomeState,
        ApplicantType.FIRM
      ) || null
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        borderRadius: '6px',
        padding: '16px',
        gap: '15px',
        backgroundColor: '#F1F8FE',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginTop: '20px',
      }}
    >
      <div
        className="override-checkbox-bg"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {isCheckboxNeeded && (
          <Checkbox
            defaultChecked={prefilledLoaIds?.some((data) =>
              ids?.includes(data)
            )}
            checked={isLicenseChecked}
            style={{ marginRight: '20px' }}
            onChange={handleClassCheckboxChange}
          />
        )}
        <Text
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '20px',
            color: 'rgba(34, 34, 34, 1)',
            margin: 0,
          }}
        >
          {text}
        </Text>
        <div
          className="override-icon-size"
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '20px',
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            color: '#00A6FB',
            margin: 0,
            marginLeft: '12px',
          }}
        >
          {getFees()?.fees ? (
            <div>
              ( Estimated fees:{' '}
              <span style={{ fontWeight: 500 }}>
                ${getDisplayFees(getFees()?.fees)}
              </span>{' '}
              )
            </div>
          ) : null}{' '}
          {getFees()?.isRetaliatory ? (
            <Tooltip
              title={
                <div>
                  We are currently building fee calculations for retaliatory
                  states. For more information, please refer{' '}
                  <a target="__blank" href={getFees()?.retaliatoryLink}>
                    {getFees()?.retaliatoryLink}
                  </a>
                </div>
              }
            >
              <QuestionCircleOutlined
                style={{
                  cursor: 'pointer',
                  color: '#97AABF',
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '15px',
          marginLeft: '40px',
        }}
      >
        {loaTexts?.map((loaText, index) => (
          <Loa
            key={index}
            text={loaText + ' (' + loaCodes?.[index] + ')'}
            loaId={ids?.[index] || ''}
            licenseClassCode={licenseClassCode}
            selectedIds={selectedIds}
            handleCheckboxChange={handleCheckboxChange}
            isCheckboxNeeded={isCheckboxNeeded}
            prefilledLoaIds={prefilledLoaIds}
          />
        ))}
      </div>
      <div style={{ display: 'flex' }}>
        <p
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            textAlign: 'left',
            color: '#222222',
          }}
        >
          Assign Designated Responsible Licensed Producer
        </p>
        <Tooltip
          title="Pick a producer who is licensed in this state to act as a DRLP. If the user you want is missing from this list, either add them as a producer or make sure they are licensed in this state."
          placement="top"
          style={{ backgroundColor: '#D1D1D1' }}
        >
          <QuestionCircleOutlined
            style={{
              marginLeft: '8px',
              cursor: 'pointer',
              color: '#97AABF',
            }}
          />
        </Tooltip>
      </div>
      <CustomDropdown
        text="Select DRLP's"
        options={drlpOptions}
        stateCode={stateCode}
        onUpdateDropDown={onUpdateDropDown}
        licenseClassCode={licenseClassCode}
        onAddNewProducer={onAddNewProducer}
      />
    </div>
  );
};

export default observer(LicenseClass);
