import HandleAgentSignUpFlow from './handleAgentSignUpFlow';
import Loading from './loading';
import MyLayout from './layout';
import { REDIRECT_SESSION_CONSTANTS } from '../constants/common.constants';
import { RoleType } from '../enums/roles.enum';
import { RouteConstants } from '../constants/routes.constants';
import { UserType } from '../enums/userType.enum';
import { apiStore } from '../stores/api.store';
import { auth0Store } from '../stores/auth0Store';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { signupStore } from '../stores/signupStore';
import { useAuth } from '../auth/authProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OnboardOrAppAccess() {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isOnboarded,
    onboardingStage,
  } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeApp = async () => {
      await apiStore.init();
      await getAccessTokenSilently().catch((e) => console.error('e :: ', e));
    };
    initializeApp();
  }, []);

  useEffect(() => {
    if (!signupStore.isAppLoading)
      if (signupStore.isAdminAuthorized === false) {
        navigate('/unauthorized-page');
      } else if (signupStore.isAgentAuthorized === false) {
        navigate('/unauthorized-page');
      }
  }, [
    signupStore.isAdminAuthorized,
    signupStore.isAgentAuthorized,
    signupStore.isAppLoading,
  ]);

  useEffect(() => {
    if (isAuthenticated()) {
      if (!isOnboarded && !isEmpty(onboardingStage)) {
        navigate(RouteConstants.onboarding.path);
      }
    }
  }, [onboardingStage, isOnboarded, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated()) {
      // Save the current URL in sessionStorage before logging out

      // if an email has both admin and producer and he is logged out from producer portal
      // we don't need to redirect after login
      // that's why clearing the session storage
      if (
        (sessionStorage.getItem(REDIRECT_SESSION_CONSTANTS.ROLE) ===
          RoleType.AGENT &&
          sessionStorage.getItem(
            REDIRECT_SESSION_CONSTANTS.IS_BOTH_ADMIN_AND_PRODUCER
          )) ||
        sessionStorage.getItem(REDIRECT_SESSION_CONSTANTS.ROLE) ===
          RoleType.INDIVIDUAL_PRODUCER
      ) {
        sessionStorage.removeItem(
          REDIRECT_SESSION_CONSTANTS.REDIRECT_AFTER_LOGIN
        );
        sessionStorage.removeItem(REDIRECT_SESSION_CONSTANTS.AUTH_EMAIL);
        sessionStorage.removeItem(
          REDIRECT_SESSION_CONSTANTS.IS_BOTH_ADMIN_AND_PRODUCER
        );
        sessionStorage.removeItem(REDIRECT_SESSION_CONSTANTS.ROLE);
      } else {
        sessionStorage.setItem(
          REDIRECT_SESSION_CONSTANTS.REDIRECT_AFTER_LOGIN,
          window.location.pathname + window.location.search
        );
      }

      navigate(RouteConstants.rootRedirect.path);
    }
  }, [isAuthenticated()]);

  if (
    auth0Store.user?.user_metadata.isOnboarded &&
    (signupStore.isAdminAuthorized || signupStore.isAgentAuthorized)
  )
    return <MyLayout />;
  else {
    if (signupStore.userType === UserType.ADMIN) return <Loading logo />;
    else if (signupStore.userType === UserType.AGENT)
      return signupStore.isInvited === null ? (
        <Loading />
      ) : signupStore.isAgentAuthorized === true ||
        signupStore.isInvited === true ? (
        <HandleAgentSignUpFlow />
      ) : (
        <Loading logo={true} />
      );
    else return <Loading logo={true} />;
  }
}

export default observer(OnboardOrAppAccess);
