import React, { useEffect } from 'react';

import { Button } from 'antd';
import Lottie from 'lottie-react';
import { RouteConstants } from '../constants/routes.constants';
import { appStateInfoStore } from '../stores/appStateInfo.store';
import externalLinkLine from '../assets/icons/external-link-line.svg';
import notFoundIssue from '../assets/lottie/404 Issue.json';
import { useNavigate } from 'react-router';

interface pageNotFoundProps {
  hideHeader?: boolean;
}
const PageNotFound: React.FC<pageNotFoundProps> = ({ hideHeader }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (hideHeader) return;
    appStateInfoStore.setHeader(RouteConstants.pageNotFound.headerText);
  }, []);

  return (
    <div className="content-center items-center flex flex-col bg-white w-[100%] h-[calc(-110px + 100vh)]">
      <Lottie
        animationData={notFoundIssue}
        style={{ height: '306px', width: '306px' }}
        loop={true}
        className="relative top-[70px]"
      />
      <div className="flex relative top-[40px] flex-col items-center gap-4">
        <div className="flex flex-col w-[345px]">
          <span className="font-figtree text-center font-bold text-2xl text-[#001F45] mb-[14px]">
            Error 404: Page Not Found
          </span>
          <span className="text-center font-medium text-sm font-figtree text-[#5D5D5D]">
            In the meantime, you can navigate back to homepage or Contact
            support team for assistance
          </span>
        </div>
        <Button
          onClick={() => navigate('/')}
          className="bg-[#001F45] text-white rounded-lg items-center h-[36px] px-[12px] cursor-pointer"
        >
          Redirect to Homepage
        </Button>
        <div
          className="flex flex-row gap-1 cursor-pointer"
          onClick={() => {
            window.location.href = `mailto:hello@insuretrek.com`;
          }}
        >
          <img src={externalLinkLine} alt="link" />
          <span className="underline text-[#00A6FB] font-figtree font-medium text-sm text-center">
            Contact Support
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
